
export default {
  Tooltip: {
    position: 'absolute',
    height: 'auto',
    maxWidth: 350,
    whiteSpace: 'normal',
    top: -50,
    left: 0,
    padding: [5, 10],
    color: '#fff',
    fontSize: 11,
    fontWeight: 400,
    background: '#2e343e',
    lineHeight: '15px',
    zIndex: 9999,
    pointerEvents: 'none',
  },
};
