import table from 'components/PageTable/sheet';
export default {
  ...table,

  name: {
    width: 300,
  },
  mergeWith: {
    width: 300,
  },
  residences: {
    width: 250,
    flex: '0 0 auto',
  },
  arts: {
    fontFeatureSettings: '"tnum"',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  countScrapedArts: {
    extend: 'arts',
  },
  countSoldArtsInDb: {
    extend: 'arts',
  },
  countTradedArtsInDb: {
    flex: '0 0 auto',
  },
};
