import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  commonFields, InlineDateField, DateField, connectAcceptance, toFloat, DatePicker, defaultCarField,
} from 'fields/common';

import injectSheet from 'lib/sheet';
import { defineColumns } from 'components/PageTable/defineColumns';

import { dictList, dash, dateToString } from 'lib/serialize';
import { dictionaryList } from 'domain/dictionary';
import { tagCarValueMap } from 'domain/const';
import rules from 'components/form/validation';
import Select from 'components/form/element/Select';
import { PureTextArea, sheet as textAreaSheet } from 'components/form/element/TextArea';

import FilterSelect from 'components/form/element/Select/FilterSelect';
import { lnk } from 'lib/routes';
import { acceptanceFields } from 'domain/artist';
import { TagAutocomplete } from "./Autocomplete";

import Dropzone from "react-dropzone";

import { token, addNotification } from 'domain/env';
import { dateFieldProps } from "lib/helpers";
import Message from "components/form/message";

const CountriesAutoComplete = connect({ list: dictionaryList('countries') })(Select);
const CountriesInlineAutoComplete = connect({ list: dictionaryList('countries') })(FilterSelect);
const BiographyTextArea = injectSheet({ ...textAreaSheet, wrapper: { width: '100%', height: 69 } })(PureTextArea);

const MergedWith = ({ value, mergeWith, classes }) => {
  const author = mergeWith.get(value);
  if (!author) return null;
  const { deathDate, fullName, birthDate } = author;
  const dates = birthDate ? [birthDate,deathDate].filter(v => !!v).join(' - ') : '';
  return <Link
    className={classes.link}
    to={lnk('artist', { artistId: value })}
    children={`${fullName}${dates ? ' (' + dates + ')' : ''}`}
  />;
};

MergedWith.propTypes = {
  value: PropTypes.any,
  mergeWith: PropTypes.instanceOf(I.Map),
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

const mergeWithParser = props => props.value ? <MergedWith {...props}/> : null;

mergeWithParser.propTypes = { value: PropTypes.any };

const LifeYears = () => [
  <Field
    key="birthDate"
    name="birthDate"
    validate={[ rules.year ]}
    component={DateField}
    maxLength="4"
  />,
  <Field
    key="deathDate"
    name="deathDate"
    maxLength="4"
    component={DateField}
    validate={[ rules.year, rules.deathDate ]}

  />,
  <div key="label" style={{ lineHeight: '32px' }}><FormattedMessage id="ifAvailable"/></div>,
];

const bannerDisplayDate = () => [
  <Field
    key="bannerDisplayDateFrom"
    name="bannerDisplayDateFrom"
    component={DatePicker}
    maxLength="4"
    validate={[ rules.bannerRequired ]}
    { ...dateFieldProps }
  />,
  <span key="separator" style={{ padding: '0px 5px' }}>-</span>,
  <Field
    key="bannerDisplayDateTo"
    name="bannerDisplayDateTo"
    maxLength="4"
    validate={[ rules.bannerRequired, rules.bannerDisplayDateTo ]}
    component={DatePicker}
    { ...dateFieldProps }
  />,
];

// component is default field rendered, for other render in TableView use tableComponent
// if u want for render Full custom field/fields use formFieldRenderer()

export const ArtistLink = ({
  value: artistId,
  classes: { link },
}) => <Link className={link} to={lnk('artist', { artistId })}>{artistId}</Link>;

ArtistLink.propTypes = {
  value: PropTypes.number.isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

const preffix = 'https://artbnk-artist-ad-banners.s3.amazonaws.com/';

const parseValue = value => value.startsWith('http') || value.startsWith('data') ? value : preffix + value;

const BannerImage = (width) => ({ value }) => {
  return value ? <img alt="Banner" src={parseValue(value)} width={width} height={37} /> : null
}

export const BannerComponent = (width, realWidth) => {
  const Component = (props) => {
    const { meta, input: { onChange }, addNotification } = props;

    const [ value, setValue ] = React.useState();

    React.useEffect(() => {
      if (typeof props.input.value === "string") {
        setValue(props.input.value);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue(reader.result);
        }
        reader.readAsDataURL(props.input.value);
      }
    }, [ props.input.value ]);

    const checkImage = useCallback((file) => {
      const img = new Image();
      img.onload = ({ target: e }) => {
        if (e.width > realWidth || e.height > 120){
          addNotification({ title: 'bannerDimensionError', type: 'error' })
        } else {
          onChange(file)
        }
      };
      img.src = file.preview || file.fileName;
    }, [onChange, addNotification])

    if (value) {
      return <div style={{ display: 'flex', alignItems: 'center' }}>
        { BannerImage(width)({ value }) }
        <button
          style={{
            marginLeft: 10,
            width: 20,
            height: 20,
            border: 0,
            outline: 0,
            backgroundColor: 'transparent',
            backgroundImage: `url(${require('./../components/Actions/icons/ic_ignore.svg')})`,
            backgroundPosition: 'center center',
            cursor: 'pointer',
          }}
          type="button"
          onClick={() => onChange(null)}
        />
      </div>
    } else {
      return <div>
          <Dropzone
          accept="image/jpeg, image/gif, image/png"
          name={props.input.name}
          onDrop={(acceptedFiles) => { acceptedFiles.length && checkImage(acceptedFiles[0]) } }
          style={{
            width,
            height: 37,
            border: '1px dashed',
            borderRadius: 3,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '20px 20px',
            cursor: 'pointer',
            backgroundImage: `url(${require('./../components/ImageDrop/upload.svg')})`,
          }}
        />
        <div>
          { !!meta && meta.error && <Message meta={meta} name={props.input.name} /> }
        </div>
      </div>
    }
  }
  return connect({ token, addNotification })(Component);
}

const config = {
    id: {
        sortable: true,
        className: 'id',
        getValue: ArtistLink,
        parser: ({ value }) => value === undefined ? '' : value,
    },
    mergeWith: { sortable: true, getValue: mergeWithParser },
    fullName: {
        sortable: true,
        inlineEdit: true,
        className: 'name',
        fieldProps: { validate: [ rules.required ] },
    },
    namePrefix: { sortable: true, inlineEdit: true },
    firstName: { sortable: true, inlineEdit: true },
    middleName: { sortable: true, inlineEdit: true },
    lastName: { sortable: true, inlineEdit: true },
    nameSuffix: { sortable: true, inlineEdit: true },
    lifeYears: { formFieldRenderer: LifeYears, hiddenForTable: true },
    birthDate: {
        sortable: true,
        component: InlineDateField,
        inlineEdit: true,
        getValue: dash,
        className: 'year',
        fieldProps: { validate: [ rules.year ] },
    },
    deathDate: {
        sortable: true,
        component: InlineDateField,
        inlineEdit: true,
        getValue: dash,
        className: 'year',
        fieldProps: {
            validate: [ rules.year, rules.deathDate ],
        },
    },
    residences: {
        inlineEdit: true,
        component: CountriesAutoComplete,
        inLineComponent: CountriesInlineAutoComplete,
        getValue: dictList(),
        fieldProps: { isMulti: true },
    },
    biography: { component: BiographyTextArea, hiddenForTable: true },
    countKnownArts: { sortable: true, className: 'arts' },
    countScrapedArts: { sortable: true, className: 'arts' },
    countTradedArtsInDb: { sortable: true, className: 'arts' },
    countSoldArtsInDb: { sortable: true, className: 'arts' },
    showRtv: { sortable: true, getValue: ({ value }) => value ? 'Yes' : 'No' },
    customersAoCount: { sortable: true, className: 'arts' },
    rtvCount: { sortable: true, className: 'arts' },
    carTag: {
      getValue: ({ value, rowData }) => {
          return I.fromJS(value).toJS().map((carTag) => (
            carTag && carTag.id
              // ? `${carTag.smcTag} (${carTag[tagCarValueMap[carTag.defaultCarField]] || ''})`
              ? `${carTag.fullName}`
              : null
          )).join(', ');
      },
      sortable: true,
      component: TagAutocomplete,
      fieldProps: { isMulti: true, isClearable: true }
    },
    defaultCarField,
    lowCarValue: { getValue: toFloat, inlineEdit: true, sortable: true },
    decayCoefficient: { getValue: toFloat, inlineEdit: true, sortable: true },
    carValue: { getValue: toFloat, inlineEdit: true, sortable: true },
    highCarValue: { getValue: toFloat, inlineEdit: true, sortable: true },
    usernameLastUpdate: { sortable: true, getValue: ({ value }) => value || '−' },
    banner343x120: {
      getValue: BannerImage(106),
      component: BannerComponent(106, 343),
      hiddenForTable: true,
      fieldProps: { validate: [ rules.bannerRequired ] }
    },
    banner720x120: {
      getValue: BannerImage(222),
      component: BannerComponent(222, 720),
      hiddenForTable: true,
      fieldProps: { validate: [ rules.bannerRequired ] }
    },
    banner970x120: {
      getValue: BannerImage(300),
      component: BannerComponent(300, 970),
      hiddenForTable: true,
      fieldProps: { validate: [ rules.bannerRequired ] }
    },
    bannerDisplayDate: {
      hiddenForTable: true,
      getValue: ({ rowData: { bannerDisplayDateFrom, bannerDisplayDateTo }}) =>
        [bannerDisplayDateFrom, bannerDisplayDateTo].filter(v => !!v)
          .map(value => dateToString()({ value }))
          .join(' - '),
      formFieldRenderer: bannerDisplayDate,
    },
    bannerUrl: {
      hiddenForTable: true,
      fieldProps: { validate: [ rules.bannerRequired, rules.validURL ] }
    },
    searchCount: { sortable: true, className: 'number', getValue: ({ value }) => `${value}` },
    alertsCount: { sortable: true, className: 'number', getValue: ({ value }) => `${value}` },
    ...commonFields,
};

export const columns = defineColumns(
  connectAcceptance(config, acceptanceFields),
);

export const defaultColumns = [
  'fullName',
  'residences',
  'birthDate',
  'deathDate',
  'mergeWith',
  'sourceType',
  'notes',
];

export const tableParams = { columns, defaultColumns };

export default columns;
