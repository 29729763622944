import React from 'react';

import {
  connectAcceptance,
  createIdLink,
  commonDictionaryFields,
  toNumberOrNull,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import rules from 'components/form/validation';
import { FieldArray } from 'redux-form';
import TextArray from 'fields/TextArray';

const ValuesFn = (props) => <FieldArray name="values" component={TextArray} fieldProps={props} />;

export const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('substrate'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  description: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  order: {
    sortable: true,
    inlineEdit: true,
    getValue: toNumberOrNull,
    className: 'name',
    fieldProps: { validate: [ rules.required ], type: 'number' },
  },
  rank: {
    sortable: true,
    inlineEdit: true,
    getValue: toNumberOrNull,
    className: 'name',
    fieldProps: { validate: [ rules.required ], type: 'number' },
  },
  values: {
    hiddenForTable: true,
    formFieldRenderer: ValuesFn,
  },
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, ['description', 'order','rank']),
);

export const defaultColumns = ['description', 'order','rank'];

export const tableParams = { columns, defaultColumns };

export default columns;
