import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/.*)*'+ // port and path
    '(\\??[;&a-z\\d%_.~\\/+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const checkProtocol = (url, absolute) => !absolute || url.startsWith('http://') || url.startsWith('https://') ? url : '//' + url;

const Link = ({ url, className = 'LinkContainer',  classes, target = '_self', force = false, absolute = false, children }) => {
  const { Link, image } = classes;
  return (
    <div className={classes[className]}>
      { children }
      { (force || validURL(url)) && <a href={checkProtocol(url, absolute)}  className={Link} target={target} rel="noopener noreferrer">
        <span className={image}/>
      </a> }
    </div>
  );
};

Link.propTypes = {
  url: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    Link: PropTypes.string.isRequired,

  }).isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  force: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default injectSheet(sheet)(Link);
