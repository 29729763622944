import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/portfolioList';
import { filterParams } from 'filters/portfolio';
import { connect } from 'cpcs-reconnect';
import ListHeader from 'components/CustomerListHeader';
import { tableParams } from 'fields/portfolio';


const { list: items, selected: selectedItems, onInlineEdit: edit, loading, pagination, stats } = selectors;
const { selectAction } = actions;

const Table = createTable({
  tableName: 'portfolioList',
  tableParams,
  allowEdit: () => false,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    loading,
    pagination,
    stats
  },
});

export default createListPage({
  name: 'Portfolio',
  pagination,
  filterParams,
  Table,
  PageHeader: connect({ stats: selectors.stats })(ListHeader),
});
