import { put, call, takeEvery, fork, select } from 'redux-saga/effects';
import Api, { callApi } from 'domain/api';
import { addNotification } from 'domain/env';
import * as actionCreator from 'domain/const';
import actions from 'components/PageTable/BulkActions/actions';
import {bulkActionsUpdate, bulkActionsSuccess, bulkActionsError, bulkActionsClear} from 'domain/ui';
import {toCamel} from "../lib/helpers";
import { artworkList } from 'domain/artworkList';
import { getEditableData } from 'domain/lib';
import { formValues } from 'domain/artwork/fieldRules';
import {checkArtworkData} from "pages/ArtworkPage/saga";
import {dictionaryById} from "domain/dictionary";
import {getLocationName, reloadPage} from "domain/router";
import { lnk } from 'lib/routes';
import {lotList} from "../domain/lotList";

function* createLink(id){
  const location = yield select(getLocationName);
  switch (location) {
    case 'artwork':
    case 'artworkList':
      return lnk('artwork', { artworkId: id });

    case 'artist':
    case 'artistList':
      return lnk('artist', { artistId: id });

    case 'lotList':
      const lot = (yield select(lotList)).find(v => v.get('id') === id);
      return lot ? lnk('artwork', { artworkId: lot.getIn(['art','id'], 0) }): '/';

    default:
      return '/';
  }
}

function* tryUpdate(params, allErrors, action, id) {
  try {
    yield callApi(action, { query: { isBulkAction: true }, ...params, id });
    yield put(bulkActionsSuccess());
  } catch (error) {
    const errors = {};
    try {
      Object.keys(error.response.data).forEach(field => {
        let err = 'Unknown Error';
        try {
          err = error.response.data[field].join(', ');
        } catch (e) {}
        errors[toCamel(field)] = err;
      });
    } catch (e) {}
    const link = yield call(createLink, id);
    yield put(bulkActionsError({ id, errors, link }));
    allErrors.push(id);
  }
}

const pageToApiUpdateKey = {
  artworkList: 'updateArtwork',
  lotList: 'updateLot',
  artistList: 'updateArtist',
}

function* tryToUpdate({ id, data }, errors) {
  if (data.category) {
    yield call(tryToChangeCategory, { id, data }, errors)
  } else {
    const page = yield select(getLocationName);
    try {
      yield callApi(Api[pageToApiUpdateKey[page]],{ artworkId: id, lotId: id, artistId: id, data, query: { isBulkAction: true } });
      yield put(bulkActionsSuccess());
    } catch (e) {
      errors.push(id);
      const link = yield call(createLink, id);
      try {
        yield put(bulkActionsError({ id, link, errors: e.response.data }));
      } catch (e) {
        yield put(bulkActionsError({ id, link, errors: { id: 'Something went wrong' } }));
      }
    }
  }
}

function* tryToChangeCategory({ id, data: formData }, errors) {
  const list = yield select(artworkList);
  const data = list.find(v => v.id === id);
  if (data) {
    try {
      const category = (yield select(dictionaryById('categories'))).find(v => v.get('id') === formData.category).get('title');
      const dataToSave = getEditableData(formValues[category], data.toJS());
      const checkedData = yield call(checkArtworkData, { ...dataToSave, ...formData });
      yield callApi(Api.updateArtwork,{ artworkId: id, data: checkedData, query: { isBulkAction: true } });
      yield put(bulkActionsSuccess());
    } catch (e) {
      errors.push(id);
      const link = yield call(createLink, id);
      try {
        yield put(bulkActionsError({ id, link, errors: e.response.data }));
      } catch (e) {
        yield put(bulkActionsError({ id, link, errors: { id: 'Something went wrong' } }));
      }
    }
  } else {
    yield put(bulkActionsError({ id, errors: { id: 'Object not found' } }));
  }
};

const getParamsByAction = {
  draft: { data: { wfAcceptance: 'DRAFT' } },
  validate: { data: { wfAcceptance: 'VALID' } },
  ignore: { data: { wfAcceptance: 'IGNORE' } },
  restore: { data: { isDelete: false }, query: { isRestore: true, isBulkAction: true } },
  accept: { data: { wfAcceptance: 'ACCEPTED' } },
  display: { data: { wfAcceptance: 'DISPLAY' } },
};

export function* onRestore(params = {}, action, callBack, ...callBackParams) {
  try {
    yield callApi(action, { ...params, data: { isDelete: false }, query: { isRestore: true } });
    if (callBack) {
      yield put(addNotification({ title: 'objectRestored', type: 'success' }));
      yield fork(callBack, ...callBackParams);
    }
  } catch (e) {
    yield put(addNotification({ title: 'objectNotRestored', type: 'error' }));
  }
}

function* tryToDoAction(model, callBack, callBackArgs, { payload, type }) {
  const apiKey = `${ type === 'REMOVE' ? 'remove' : 'update' }${model}`;
  const idKey = `${model[0].toLowerCase()}${model.substr(1)}Id`;
  let errors = [];
  const params = getParamsByAction[type.toLowerCase()];
  const idList = payload.id.map ? payload.id : [payload.id];
  yield put({
    type: bulkActionsUpdate.type,
    payload: { name: type.toLowerCase(), model, total: idList.length }
  });
  for(let id of idList){
    if (type === 'CHANGE_BULK'){
      yield call(tryToUpdate, { id, data: payload.data }, errors);
    } else {
      yield call(tryUpdate, { [idKey]: id, ...params }, errors, Api[apiKey], id );
    }
  }
  if (errors.length){
    yield put(addNotification({ title: 'actionPartialComplete', type: 'success', translateValues: { errors: errors.length } }));
  } else {
    yield put(bulkActionsClear());
    yield put(addNotification({ title: 'actionComplete', type: 'success' }));
  }
  if (callBack) {
    yield call(callBack, ...callBackArgs);
  } else {
    yield call(reloadPage);
  }
}

const actionTypeList = Object.keys(actions).filter(v => v !== 'merge').map(key => actionCreator[key].type);

export function* watchCommonActionsCall(model, callBack, ...callbackArgs) {
  yield takeEvery(actionTypeList, tryToDoAction, model, callBack, callbackArgs);
}
