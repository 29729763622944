import { DEFAULT_FONTFAMILY } from 'theme/const';
import { Colors, zIndex } from 'theme';

const SELECTABLE_AREA_HEIGHT = 200;
const SELECTED_BG_COLOR = '#A9431E';
const NOW_BG_COLOR = '#DDB4A5';
const FONT = `400 14px/18px ${DEFAULT_FONTFAMILY}`;
const VIEW_PADDING = [10, 15];
const TILE_TEXT_COLOR = '#FFF';
const TILE_TEXT_COLOR_HOVER = Colors.labelLink;
const TILE_TEXT_COLOR_FOCUS = Colors.labelLink;
const tileActive = {
  backgroundColor: SELECTED_BG_COLOR,
  border: `2px solid ${SELECTED_BG_COLOR}`,
  color: '#FFF',
  outline: 'none',
};

const button = {
  background: 'none',
  border: '0 none',
  textAlign: 'center',
  padding: 0,
};

export default {
  'CalendarWrapper': {
    position: 'absolute',
    left: 0,
  },
  'react-calendar': {
    boxShadow: '0 2px 5px 0px rgba(0, 0, 0, 0.21)',
    minWidth: 300,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: zIndex.CALENDAR,
    backgroundColor: '#FFF',
    marginTop: 2,
  },
  navigation: {
    height: 46,
    backgroundColor: SELECTED_BG_COLOR,
    color: '#FFF',
    '&__arrow': {
      ...button,
      flex: '0 0 30px',
      color: '#FFF',
      font: FONT,
    },
    '&__arrow[disabled]': {
      opacity: 0.5,
    },
    '&__label': {
      ...button,
      color: '#FFF',
      font: FONT,
    },
  },
  'month-view': {
    padding: VIEW_PADDING,
    font: FONT,
    '&__days': {
      minHeight: SELECTABLE_AREA_HEIGHT,
      // fix IE11 flex align-items: stretch to work correctly
      height: Math.floor(SELECTABLE_AREA_HEIGHT / 2),
    '&__days__day--neighboringMonth': {
      opacity: 0.35,
    },
    },
    '&__days__day[disabled]': {
      opacity: 0.5,
    },
    '&__weekdays': {
      paddingTop: 5,
      height: 28,
    },
    '&__weekdays__weekday': {
      textAlign: 'center',
    },
  },
  'year-view': {
    padding: VIEW_PADDING,
    font: FONT,
    '&__months': {
      minHeight: SELECTABLE_AREA_HEIGHT,
      // fix IE11 flex align-items: stretch to work correctly
      height: Math.floor(SELECTABLE_AREA_HEIGHT / 2),
    },
    '&__months__month[disabled]': {
      opacity: 0.5,
    },
  },
  'decade-view': {
    padding: VIEW_PADDING,
    font: FONT,
    '&__years': {
      minHeight: SELECTABLE_AREA_HEIGHT,
      // fix IE11 flex align-items: stretch to work correctly
      height: Math.floor(SELECTABLE_AREA_HEIGHT / 2),
    },
    '&__years__year[disabled]': {
      opacity: 0.5,
    },
  },
  'century-view': {
    padding: VIEW_PADDING,
    font: FONT,
    '&__decades': {
      minHeight: SELECTABLE_AREA_HEIGHT,
      // fix IE11 flex align-items: stretch to work correctly
      height: Math.floor(SELECTABLE_AREA_HEIGHT / 2),
    },
    '&__decades__decade[disabled]': {
      opacity: 0.5,
    },
  },
  tile: {
    ...button,
    font: FONT,
    border: '2px solid #FFF',
    margin: 0,
    '&--now': {
      backgroundColor: NOW_BG_COLOR,
      border: `2px solid ${NOW_BG_COLOR}`,
      color: TILE_TEXT_COLOR,
      outline: 'none',
    },
    '&--hasActive': tileActive,
    '&--active': tileActive,
    '&:hover': {
      backgroundColor: '#FFF',
      border: `2px solid ${SELECTED_BG_COLOR}`,
      color: TILE_TEXT_COLOR_HOVER,
    },
    '&:focus': {
      backgroundColor: '#FFF',
      border: `2px solid ${SELECTED_BG_COLOR}`,
      color: TILE_TEXT_COLOR_FOCUS,
      outline: 'none',
    },
    '&--active:focus': {
      extend: tileActive,
    },
    '&--active:hover': {
      extend: tileActive,
    },
  },
};
