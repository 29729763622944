export const WIDTH = 300;
export const MARGIN = 20;

export default {
  DateField: {
    lineHeight: 'initial',
  },
  catchFocusWrapper: {
    // html tag for js onFocus, onBlur
  },
  PositionWatcher: {
    position: 'relative',
  },
};
