import { fork, call, put, select, takeLatest } from 'redux-saga/effects';

import { actions, selectors } from 'domain/purchase';
import { listNavigator } from 'sagas/common';
import { getQuery } from 'domain/router';
import Api, { callApi } from 'domain/api';
import { checkQuery } from 'lib/helpers';
import { loadSingleData } from 'sagas/common';
import { actions as customerAction, selectors as customerSelectors } from 'domain/customer';
import filters from 'filters/purchase';
import { pushAbv } from 'domain/const';
import { addNotification } from 'domain/env';

export function* loadData({ modelName, actions: { fetchListAction, selectAction }, filters }, resetChecked = true) {
  const { id: userId } = yield select(customerSelectors.item);
  const apiKey = !!userId ? `getCustomer${modelName}List` : `get${modelName}List`;
  yield put({ type: fetchListAction.request });
  if (resetChecked) yield put(selectAction(null));
  const query = (yield select(getQuery)) || {};
  try {
    const { data } = yield callApi(Api[apiKey], { userId, query: checkQuery(query, filters) });
    yield put({ type: fetchListAction.success, payload: data });
  } catch (e) { console.error(e); }
}

function* pushAbvRequest({ payload: { id } }){
  try {
    yield put({ type: pushAbv.request, payload: id });
    const { data } = yield callApi(Api.pushAbv, { id });
    yield put({ type: pushAbv.success, payload: data });
    yield put(addNotification({ type: 'success', title: 'pushAbvSuccess' }));
  } catch (e) {
    yield put({ type: pushAbv.failure });
    yield put(addNotification({ type: 'error', title: 'pushAbvFailure' }));
  }
}

function* watchPushAbv(){
  yield takeLatest(pushAbv.type, pushAbvRequest);
}

export default function* navigator({ params: { userId: id } }) {
  if (id) yield call(loadSingleData, { modelName: 'Customer', actions: customerAction, id, initForm: false });
  yield fork(listNavigator, { modelName: 'Purchase', actions, selectors, filters, loadData } );
  yield fork(watchPushAbv);
}
