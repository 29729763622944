import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/coupon';

import { columns } from 'fields/coupon';
import { createSimpleForm } from '../common/Form';
import { createFormConfig } from '../../lib/helpers';

const { item } = selectors;

export const editableFields = ['title', 'expireOn', 'maximumRedemption', 'active'];

export default createItemPage({
  name: 'coupon',
  connections: {
    item,
  },
  config: {
    basicInfo: [
      createFormConfig(columns.select(...editableFields)),
      [],
      columns.select('id'),
    ],
  },
  Form: createSimpleForm({
    form: 'couponForm',
    action: actions.updateItemAction,
  }),
});
