import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const Paper = ({ classes: { Paper }, className = null, children = null }) => {
  return (
    <div className={cx(Paper, { [className]: !!className })}>
      { children }
    </div>
  );
};

Paper.propTypes = {
  classes: PropTypes.shape({
    Paper: PropTypes.string,
  }),
  className: PropTypes.string,
  children: PropTypes.node,
};

export { Paper as PaperPure, sheet };
export default injectSheet(sheet)(Paper);
