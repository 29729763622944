import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';

import {
  tdMarker,
  tdCheck,
  tdActions,
} from 'components/PageTable/columns';

import Wrapper from 'components/OutsideClickDetector';
import { settings, updateSettings } from 'domain/settings';
import { getLocationName } from 'domain/router';
import ColumnsPopup from 'components/PageTable/columnsPopup';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { lnk } from 'lib/routes';
import { orderByToString, orderByToObject, switchKeysAndValues } from 'lib/helpers';
const notEmpty = v => !![v].join('');

const invalidColumns = {
  art: [
    'defaultImageUrl',
    'title',
    'area',
    'physicalSizeRaw',
    'physicalSizeHeight',
    'physicalSizeWidth',
    'physicalSizeUnits',
    'medium',
    'substrateValues',
    'substrates',
    'surfaceValues',
    'surfaces',
    'primarySubstrateRank',
    'primarySurfaceRank',
    'subjects',
    ],
  artist: [
    'fullName',
    'residences',
    'birthDate',
    'deathDate',
  ],
  lot: [
    'imageFileUrl',
    'title',
    'artId',
    'auctionId',
    'auctionDate',
  ],
};

class PureSchema extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    selected: PropTypes.instanceOf(I.List),
    list: PropTypes.instanceOf(I.List),
    update: PropTypes.func.isRequired,
    defaultColumns: PropTypes.array.isRequired,
    columnSections: PropTypes.array,
  }

  state = {
    popupShown: false,
  }

  static defaultProps = {
    selected: new I.List(),
  }

  render(){
    const { classes, children, defaultColumns, columnSections, ...props } = this.props;
    const { popupShown } = this.state;
    return (
      <div
        className={classes.ColumnsSelector}
        data-name="ColumnsSelector"
      >
        <div className={classes.optionsButtonWrapper}>
          <button
            className={cx(classes.optionsButton, { [classes.optionsButtonPushed]: popupShown })}
            type="button"
            onClick={() => this.setState({ popupShown: !popupShown })}
          />
        </div>
        { popupShown && <Wrapper
          className={classes.popupWrapper}
          onDetect={() => this.setState({ popupShown: false })}
        >
          <ColumnsPopup
            title="applyToFilters"
            defaultList={defaultColumns}
            columnSections={columnSections}
            {...props}
            close={() => this.setState({ popupShown: false })}
          />
        </Wrapper> }
        {children}
      </div>
    );
  }
}

const StyledSchema = injectSheet(styles)(PureSchema);

export default ({ columns, tableName, defaultColumns, columnSections, readOnly = false }) => Component => {

  const fieldNameToSortKey = columns.all()
    .filter(v => !!v.sortable)
    .reduce((p, { name, sortByName }) => ({ ...p, [name]: sortByName || name }), {});

  const sortKeyToFieldName = switchKeysAndValues(fieldNameToSortKey);

  class ColumnsSelector extends React.PureComponent {
    static propTypes = {
      settings: PropTypes.instanceOf(I.Map),
      updateSettings: PropTypes.func.isRequired,
      filterTransfer: PropTypes.object.isRequired,
      page: PropTypes.string,
      query: PropTypes.object,
      push: PropTypes.func.isRequired,
      modalMode: PropTypes.string,
      classes: PropTypes.any,
    };

    componentDidMount = () => {
      const { query: { sortBy }, page, settings, updateSettings } = this.props;
      const selected = settings.getIn([ page, 'tables', tableName ], new I.List());
      const activeSorting = orderByToObject(sortBy)
        .map(({ field }) => sortKeyToFieldName[field])
        .filter(v => !!v && v !== 'id');
      // Display hidden columns with activated sorting
      if (activeSorting.filter(v => !selected.includes(v)).length > 0) {
        updateSettings({
          path: [ page, 'tables', tableName ],
          value: selected.concat(...activeSorting.filter(v => !selected.includes(v))),
        });
      }
      this.setState({ orderedBy: I.fromJS(orderByToObject(sortBy)) });
    }

    render() {
      const { query: { sortBy, isInvalid, ...query }, push, classes } = this.props;
      const { page, settings, updateSettings, filterTransfer = {}, ...props } = this.props;
      const selected = settings.getIn([ page, 'tables', tableName ], new I.List());
      const enabledFilters = settings.getIn([ page, 'filters' ], new I.List());
      if (isInvalid) {
        const defaultValue = <div className={classes.errorColumn}></div>;
        return <Component
          {...props}
          location={page}
          defaultValue={defaultValue}
          columns={columns.select('id', ...invalidColumns[isInvalid])}
        />;
      }
      const update = (value, checkbox) => {
        updateSettings({ path: [ page, 'tables', tableName ], value });
        if (checkbox){
          updateSettings({
            path: [ page, 'filters' ],
            value: enabledFilters
              .concat(value.reduce((acc, value) => acc.concat(filterTransfer[value]) ,[]))
              // distinct & not empty
              .filter((v, index, arr) => !!v && index === arr.indexOf(v)),
          });
        }
        // Detect disable columns with activated sorting
        const activeSorting = orderByToObject(sortBy)
          .map(({ field }) => sortKeyToFieldName[field])
          .filter(v => !!v && v !== 'id');
        if (activeSorting.filter(v => !value.includes(v)).length > 0){
          const querySortByKeysToRemove = activeSorting
            .filter(v => !value.includes(v))
            .map(v => fieldNameToSortKey[v]);
          const newSortBy = orderByToString(orderByToObject(sortBy)
            .filter(({ field }) => !querySortByKeysToRemove.includes(field)));

          push(lnk(page, { query: { ...query, sortBy: newSortBy || undefined } }));
        }
      };
      const popupProps = {
        defaultColumns,
        columnSections,
        list: new I.List(columns.all().map(({ name }) => name)).filter(v => v !== 'id'),
        columns,
        selected,
        update,
      };
      const selectedColumns = !readOnly ? [
        tdMarker,
        ...[this.props.modalMode ? null : tdCheck].filter(notEmpty),
        ...columns.select('id', ...selected),
        tdActions,
      ] : [
        tdMarker,
        ...columns.select('id', ...selected),
        tdActions,
      ];
      return (
        <StyledSchema {...popupProps} >
          <Component
            {...props}
            location={page}
            columns={selectedColumns}
          />
        </StyledSchema>
      );
    }
  }
  return connect({ settings, updateSettings, page: getLocationName })(ColumnsSelector);
};

