import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';
import { createForm } from 'pages/common/Form';

import { selectors, actions } from 'domain/auction';
import { filterParams } from 'filters/auction';

import { dictionaryById } from 'domain/dictionary';
import { tableParams, columns } from 'fields/auction';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, createItemAction, updateItemAction } = actions;

const Table = createTable({
  tableName: 'auctionList',
  tableParams,
  updateItemAction,
  allowEdit: () => true,
  connections: {
    items,
    country: dictionaryById('countries'),
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export const Form = createForm({
  form: 'addAuction',
  action: createItemAction,
  formFields: columns.select('name', 'country', 'city'),
});

export default createListPage({
  name: 'Auction',
  pagination,
  filterParams,
  Table,
  Form,
});
