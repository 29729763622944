import { DEFAULT_FONTFAMILY } from 'theme/const';

export default {
  Input: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: DEFAULT_FONTFAMILY,
    // field
    height: 32,
    width: '100%',
    padding: [0, 8],
    backgroundColor: '#ffffff',
    border: '1px solid #d1d3d5',
    borderRadius: '2px',
    fontSize: '14px',
    position: 'relative',
    '-webkit-appearance': 'none',
  },
  timeField: {
    maxWidth: '110px',
    marginRight: '10px',
    padding: '0 5px',
    cursor: 'pointer',
  },
};
