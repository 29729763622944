import { defineColumns } from '../components/PageTable/defineColumns';
import { connectAcceptance } from './common';
import { lnk } from "../lib/routes";
import { artistNormalize } from "../lib/serialize";
import React from "react";
import IndicatorLink from 'components/IndicatorLink';

const ArtistLink = ({ rowData: { artist }, classes, columnDef: { defaultValue = '−' } }) => artist?.id ?
  <IndicatorLink
    to={lnk('artist', { artistId: artist.id })}
    status={artist.wfAcceptance}
    children={artistNormalize(artist)}
  /> : defaultValue;

const ArtistIdLink = ({ rowData: { artist }, classes, columnDef: { defaultValue = '−' } }) => artist?.id ?
  <IndicatorLink
    to={lnk('artist', { artistId: artist.id })}
    status={artist.wfAcceptance}
    children={artist.id}
  /> : defaultValue;

export const config = {
  id: {
    getValue: ArtistIdLink
  },
  artist: {
    title: 'artist',
    className: 'td200',
    getValue: ArtistLink
  },
  count: {
    className: 'number',
  },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);
export const defaultColumns = [
  'artist',
  'count',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
