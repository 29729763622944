import { fork, takeEvery, cancel, select, put, take } from 'redux-saga/effects';
import { getQuery, pathnameChanged } from 'domain/router';
import { get404, err404Action } from 'domain/ui';
import { addBreadCrumbs, userProfile, userProfileAction } from 'domain/env';
import { updateSettings } from 'domain/settings';
import { matchRoute } from 'lib/routes';
import * as routeSaga from './routeSagaList';
import I from 'immutable';

let task;

function* routeMatcher({ payload }) {
  if (yield select(get404)) yield put(err404Action(false));
  if (yield select(pathnameChanged)) yield put(addBreadCrumbs([]));
  const { acl, isSuperuser } = yield select(userProfile);
  if (task) yield cancel(task);
  const m = matchRoute(payload.location.pathname);
  if (!(isSuperuser || acl.size) && !m.route.link.startsWith('/auth')) yield take(userProfileAction.success);
  if (m !== null && routeSaga[m.route.name]) {
    const query = yield select(getQuery);
    yield put(updateSettings({ path: [m.route.name, 'query'], value: new I.Map(query) }));
    task = yield fork(routeSaga[m.route.name], { params: m.match.params, query });
  }
}

export default function* navigator() {
  yield takeEvery('@@router/LOCATION_CHANGE', routeMatcher);
}
