import { generateItems } from 'domain/common';
import { Scrapped } from 'domain/lib';
import { asyncAction } from 'lib/actions';

const { actions: defaultAction, selectors, reducer } = generateItems('Scrapped', Scrapped);

const actions = {
  updateArtItemAction: asyncAction('SCRAPPED_UPDATE_ART_ITEM'),
  ...defaultAction,
};

export { actions, selectors, reducer };
