import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

export default function NextPageLink({ page, disabled, classes, linkBuilder, format }) {
  const props = {
    className: cx(
      classes.item,
      classes.nextPage,
      {
        [classes.disabled]: disabled,
      },
    ),
    children: '',
  };
  return (
    disabled ?
      <span {...props} /> :
      <Link
        to={linkBuilder({ page: format(page) })}
        {...props}
        title={format(page)}
        data-page={page}
      />
  );
}

NextPageLink.propTypes = {
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    nextPage: PropTypes.string,
    disabled: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  linkBuilder: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired,
};
