import React from 'react';
import Loader from "../Loader";
import {connect} from "cpcs-reconnect";
import {isSubmitting} from "domain/ui";
import classes from './style.module.css';

const FormLoader = ({ isSubmitting }) => {
  return isSubmitting ? <div className={classes.FormLoader}>
    <Loader/>
  </div> : null;
}

export default connect({ isSubmitting })(FormLoader);
