import colors, { statusColors } from 'theme/Colors';

export const root = {
  margin: [0, 0, 20, 0],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
};

export default {
  Header: root,
  header: {
    fontWeight: 400,
    padding: [8, 20, 8, 20],
    fontSize: 24,
    color: colors.text,
    boxSizing: 'border-box',
    textTransform: 'capitalize',
  },
  headerBold: {
    fontWeight: 500,
  },
  buttons: {
    whiteSpace: 'nowrap',
  },
  ...statusColors,
};
