import { DEFAULT_FONTFAMILY } from 'theme/const';
import { zIndex, Colors } from 'theme';

import clearBg from './images/round-clear-24px.svg';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const filterSelectSheet = {
  selectedItemsContainer: {
    borderBottom: '1px solid #e3e5e8',
    backgroundColor: '#FFF',
    maxHeight: 300,
    overflowY: 'auto',
  },
  SelectedItemsAll: {
    paddingLeft: 10,
    float: 'left',
  },
  selectedItem: {
    width: '100%',
    boxSizing: 'border-box',
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: [8, 32, 8, 12],
    backgroundColor: 'transparent',
    color: Colors.text,
    lineHeight: '14px',
    '&:hover': {
      backgroundColor: Colors.warning,
    },
  },
  removeItem: {
    position: 'absolute',
    right: 0,
    top: 0,
    borderRadius: '50%',
    margin: [6, 8, 10],
    width: 16,
    height: 16,
    background: {
      repeat: 'no-repeat',
      image: `url(${clearBg})`,
      size: '12px 12px',
      color: Colors.placeholder,
      position: 'center center',
    },
    border: 'none',
  },
};

export const styles = ({ meta: { touched, error }, hasWarning = false, isClearable = true, grouped = false }) => ({
  container: style => ({
    ...style,
    borderRadius: 0,
    fontFamily: DEFAULT_FONTFAMILY,
    boxSizing: 'border-box',
    border: touched && error ? 'solid 1px red' : 'solid 1px #d1d3d5',
    lineHeight: 'normal',
  }),
  placeholder: style => ({ ...style, color: Colors.placeholder }),
  dropdownIndicator: (style, { selectProps: { menuIsOpen } }) => {
    return {
      ...style,
      cursor: 'pointer',
      transform: menuIsOpen ? 'scale(0.8) rotate(-180deg)' : 'scale(0.8)',
      color: Colors.labelLink,
      marginRight: 4,
      padding: [],
      '&:hover': {
        color: Colors.labelLink,
      },
    };
  },
  clearIndicator: style => ({ ...style, cursor: 'pointer', transform: 'scale(0.8)', padding: [] }),
  multiValue: style => ({
    ...style,
    backgroundColor: Colors.buttonHover,
    paddingRight: 14,
    borderRadius: 4,
    margin: '0px 2px',
    position: 'relative',
  }),
  multiValueLabel: style => ({ ...style, color: Colors.text, fontSize: 'inherit' }),
  indicatorSeparator: style => (isClearable ? style : { display: 'none' }),
  indicatorsContainer: style => ({ ...style, position: 'absolute', right: 0, top: 0, height: '100%' }),
  multiValueRemove: () => ({
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    opacity: 0.8,
    justifyContent: 'flex-end',
    position: 'absolute',
    paddingRight: 2,
    paddingTop: 2,
    boxSizing: 'border-box',
  }),
  option: (style, state) => ({
    ...style,
    cursor: 'pointer',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: grouped ? '8px 12px 8px 24px' : '8px 12px',
    backgroundColor: state.isSelected || state.isFocused ? Colors.warning : 'transparent',
    color: Colors.text,
    lineHeight: '14px',
    '&:hover': {
      backgroundColor: Colors.warning,
    },
  }),
  menu: style => ({
    ...style,
    zIndex: zIndex.FORM_FIELD_SELECT,
    marginTop: 4,
    marginBottom: 0,
    borderRadius: 0,
    left: -1,
    width: 'calc(100% + 2px)',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    boxSizing: 'border-box',
  }),
  menuList: (style, state) => ({
    ...style,
    paddingBottom: 10,
    paddingTop: 10,
  }),
  //groupHeading: () => ({}),
  //group: () => ({}),
  control: (style, { isDisabled, isFocused }) => ({
    ...style,
    borderRadius: 0,
    opacity: isDisabled ? 0.5 : 1,
    minHeight: 32,
    height: 'auto',
    border: 'none',
    boxShadow: isFocused ? '0 0 3px 0 #4990e2' : 'none',
    backgroundColor: hasWarning ? Colors.warning : Colors.bg,
}),
  valueContainer: style => ({ ...style, minHeight: 30, width: '100%', paddingRight: 45 }),
  singleValue: style => ({ ...style, width: '100%', paddingRight: 45 }),
  input: style => ({ ...style, boxSizing: 'border-box', overflow: 'hidden' }),
});

export const flatStyles = props => ({
  ...styles({ ...props, meta: {}, isClearable: false }),
  singleValue: style => ({ ...style, width: '100%', paddingRight: 16 }),
});
