import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/coupon';
import { filterParams } from 'filters/coupon';

import { tableParams } from 'fields/coupon';
import { createForm } from '../common/Form';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction, createItemAction } = actions;

const Table = createTable({
  tableName: 'couponList',
  tableParams,
  updateItemAction,
  allowEdit: () => true,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export const Form = createForm({
  form: 'addCoupon',
  action: createItemAction,
  formFields: tableParams.columns.select('title', 'expireOn', 'maximumRedemption'),
  initialValues: { maximumRedemption: 0, active: true },
});

export default createListPage({
  name: 'Coupon',
  pagination,
  filterParams,
  Table,
  softDelete: false,
  Form,
});
