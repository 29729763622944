import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';

export default {
  wrapper: {
    width: 220,
    position: 'relative',
  },
  SearchInput: {
    width: '100%',
    height: 32,
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    padding: [0, 10],
    boxSizing: 'border-box',
  },
  listBox: {
    position: 'absolute',
    left: 0,
    top: '100%',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 2,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16)',
    boxSizing: 'border-box',
    maxHeight: 300,
    overflowY: 'auto',
    paddingBottom: 10,
    paddingTop: 10,
  },
  item: {
    display: 'flex',
    height: 32,
    paddingLeft: 5,
    lineHeight: '32px',
    '&:hover': {
      backgroundColor: colors.warning,
    },
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  number: {
    flex: '0 0 auto',
    fontWeight: 500,
    marginLeft: 5,
  },
};
