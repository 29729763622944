import React from 'react';
import { createItemPage } from 'pages/common/ItemPage';
import ArtItems from './ArtItems';

import { selectors } from 'domain/item';

import { columns } from 'fields/item';
import PropTypes from 'prop-types';

const { item } = selectors;

export const editableFields = [];

const artItems = ({ artItems }) => <ArtItems items={artItems} />;

artItems.propTypes = {
  artItems: PropTypes.any,
};

export default createItemPage({
  name: 'item',
  titleFn: ({ id }) => `Spider Item #${id}`,
  connections: {
    item,
  },
  config: {
    basicInfo: [
      columns.select('type','version','itemUrl','itemListCount'),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
    artItems,
  },
});
