import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/alert';
import { filterParams } from 'filters/alert';

import { tableParams } from 'fields/alert';

import sheet from 'components/PageTable/sheet';
import PageHeader from 'components/CustomerListHeader';
import { connect } from 'cpcs-reconnect';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const Table = createTable({
  tableName: 'alertList',
  tableParams,
  updateItemAction,
  sheet,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export default createListPage({
  name: 'Alert',
  pagination,
  filterParams,
  Table,
  PageHeader: connect({ stats: selectors.stats })(PageHeader),
  csvApiKey: isCustomer => isCustomer ? 'getCustomerAlertListCSV' : 'getAlertListCSV',
});
