export default {
  Link: {
    position: 'absolute',
    right: -32,
    top: 0,
    width: 32,
    height: 32,
  },
  image: {
    width: 32,
    height: 32,
    display: 'block',
    background: {
      size: '18px 18px',
      repeat: 'no-repeat',
      position: 'center center',
      image: `url(${require('./link.svg')})`,
    },
  },
  LinkContainer: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  LinkContainerRelative: {
    position: 'relative',
    zIndex: 1,
  },
};
