import React from 'react';
import { call, fork, take, all } from 'redux-saga/effects';
import { Field } from 'redux-form';
import moment from 'moment';
import toSnakeBase from 'to-snake-case';
import toCamelBase from 'to-camel-case';
import { SubmissionError } from 'redux-form';
import I from 'immutable';

export const toSnake = v => v.split('__').map(toSnakeBase).join('__');
export const toCamel = v => v.split('__').map(toCamelBase).join('__');

const checkField = value => value.includes('.') ? value.split('.').map(toCamel).join('.') : toCamel(value);
const checkDepth = errors => Object.keys(errors)
  .reduce((acc, key) => acc.setIn(key.split('.'), errors[key]), new I.Map())
  .toJS();

export const submit = action => (data, dispatch) => {
  const submitPromise = new Promise((resolve) => {
    dispatch({
      type: action.type,
      payload: data,
      resolve,
    });
  });
  return submitPromise.then((err) => {
    if (err) {
      let errors = {};
      try {
        errors = err.response.data;
      } catch (e) {
        console.error('Wrong form error { response: { data: {} } }', err);
      }
      let errorsObj = {};
      try {
        errorsObj = {
          ...Object.keys(errors)
            .filter(v => v !== 'wf_acceptance')
            .reduce((acc, field) => ({ ...acc, [checkField(field)]: { id: 'customMessage', message: errors[field].join(', ') } }), {}),
        };
      } catch (e) {
        console.error('wrong API answer, expected { "fieldName": [ "error#", ... ], ... }, but got ', errors);
        console.error(e);
      }
      if (Object.keys(errorsObj).length > 0) throw new SubmissionError(checkDepth(errorsObj));
    }
  });
};

const DEFAULT_TIME_FOR_TEST_ENV = '2017-11-13T14:00:00Z';
export const currentTime = process.env.NODE_ENV === 'test' ? moment.utc(DEFAULT_TIME_FOR_TEST_ENV) : moment.utc();

export function ucFirst(value = '') {
  return value.replace(/\b[a-z]/g, function(letter) {
    return letter.toUpperCase();
  });
}

export const setPropTypes = (f, propTypes, displayName) => Object.assign(f, { propTypes, displayName });

export const dataToCase = (data, caseFunc) => {
  if (typeof data === 'object' ){
    if (data.map === undefined) {
      return Object.keys(data).reduce((p,n) => ({ ...p, [caseFunc(n)]: checkValue(data[n], caseFunc) }),{});
    } else {
      return data.map(v => dataToCase(v, caseFunc));
    }
  } else {
    return data;
  }
};

const emptyStringToNull = v => v === '' ? null : v;
const checkValue = (v, caseFunc) => v && typeof v === 'object' ? dataToCase(v, caseFunc) : emptyStringToNull(v);

const roleReplaceList = {
  art: ['artwork','artworksSimilar','artworkIntersections'],
  artwork: ['art'],
  auctionLot: ['lot'],
  customerart: ['art'],
  saleshistory: ['alert'],
  rtvmodelbasedvaluationresult: ['rtv'],
  customer: ['customer','purchase'],
  medium3d: ['medium'],
  cartag: ['smcTag'],
}

export function getAcl({ groupPermissions = [] }) {
  const permissions = new Set();
  try {
    groupPermissions
      .forEach(({ codename }) => {
      if (codename.startsWith('add_')){
        const permission = codename.substr(4);
        let names = roleReplaceList[toCamel(permission)] || [ permission ];
        names.forEach(name => {
          permissions.add(name);
          permissions.add(name + 'List');
        });
      }
      if (codename.startsWith('delete_')){
        const permission = codename.substring(7);
        let names = roleReplaceList[toCamel(permission)] || [ permission ];
        names.forEach(name => {
          permissions.add(name + 'Delete');
          permissions.add(name + 'ListDelete');
        });
      }
    });
  } catch (e) {
    return [];
  };
  return [...permissions];
}

export const doNothing = () => null;

export const getValueListConfig = (fields, edit = false) => edit ? createFormConfig(fields) : fields;

export const createFormConfig = (fields) => fields
  .map(({ formFieldRenderer, component, fieldProps, name, ...config }) => ({
    name,
    ...config,
    isEdit: true,
    parser: (params) =>
      typeof formFieldRenderer === 'function' ? formFieldRenderer({
        ...config,
        ...params,
      }) :
      <Field
        name={name}
        component={component}
        {...fieldProps}
      />,
  }));

export const convertFilters = (filters, rules = {}) => Object.keys(filters)
  .map(key => {
    if (!!rules[key]){
      const { parser = v => `${v}`, tempParser, key: filterKey } = rules[key];
      if (filters[key] === null) return '';
      if (tempParser) return tempParser(key, filters[key]);
      return `(${filterKey || toSnake(key)}:${parser(filters[key], key)})`;
    }
    if (key.endsWith('From')){
      const realKey = key.slice(0, -4);
      const { parser = v => v } = rules[realKey];
      return `(${toSnake(realKey)}:[${parser(filters[key], key)} TO *])`;
    }
    if (key.endsWith('To')){
      const realKey = key.slice(0, -2);
      const { parser = v => v } = rules[realKey];
      return `(${toSnake(realKey)}:[* TO ${parser(filters[key], key)}])`;
    }
    return '';
  })
  .filter(v => !!v)
  .join('AND');

export const switchKeysAndValues = (obj) => Object.keys(obj)
  .reduce((p, key) => ({ ...p, [obj[key]]: key }), {});

export const orderByToString = (fields) => fields
  .map(({ field, direction }) => `${direction === 'ASC' ? '' : '-'}${toSnake(field)}`)
  .join(',');

export const orderByToObject = (str) => !str ? [] : str.split(',')
  .map(v => ({ field: toCamel(v.substr(v.indexOf('-') + 1)), direction: v.indexOf('-') === -1 ? 'ASC' : 'DESC' }));

const invalidParams = {
  art: '(source_type:EXPERT OR source_type:LOT)AND(wf_acceptance:ACCEPTED)AND(category:"2d")AND NOT(area:*) OR' +
    ' NOT(physical_size_units:*) OR NOT(physical_size_height:*) OR NOT(physical_size_width:*)',
  artist: '(source_type:EXPERT OR source_type:LOT)AND(wf_acceptance:ACCEPTED)AND NOT(full_name:*)',
  lot: '(source_type:EXPERT OR source_type:LOT)AND(wf_acceptance:ACCEPTED)AND NOT(auction_date:[* TO *])',
};

export const reservedFilters = {
  wfAcceptance: { parser: (v = []) => '(' + [].concat(v).join(' OR ') + ')' },
  userStatus: { parser: v => v },
  status: { parser: v => v },
  isInvalid: { tempParser: (_, v) => invalidParams[v] },
};

export const splitQuery = (query = {}) => {
  const { page, pageSize, sortBy, wfAcceptance, userStatus, status, isDelete, isInvalid, ...filterValues } = query;
  return { commonParams: { page, pageSize, sortBy, wfAcceptance, userStatus, status, isDelete, isInvalid }, filterValues };
};

export const cleanEmpty = (data) => Object.keys(data)
  .filter(key => !isEmpty(data[key]))
  .reduce((res, key) => ({ ...res, [key]: data[key] }), {});

export const getActiveFilters = (query, filters) => Object.keys(query)
  .filter(key => !['page','pageSize','sortBy','wfAcceptance','isDelete','isInvalid'].includes(key))
  .map(key => key.endsWith('From') ? key.slice(0, -4) : key)
  .map(key => key.endsWith('To') ? key.slice(0, -2) : key)
  .reduce((p,key) => p.includes(key) ? p : [...p, key], []) // filter unique
  .filter(key => Object.keys(filters).includes(key));

export function checkQuery(query = {}, filterRules = {}) {
  let { page = 1, pageSize = 20, sortBy, isDelete, ...filters } = query;
  let filterBy = undefined;
  let orderBy = undefined;
  if (Object.keys(filters).length > 0){
    filterBy = convertFilters(cleanEmpty(filters), { ...reservedFilters, ...filterRules });
  }
  if (sortBy) {
    orderBy = sortBy;
  }
  if (window.name === 'SEARCH_ARTWORK_WINDOW'){
    filterBy = (filterBy || '') + (filterBy ? 'AND' : '') + '(source_type:(EXPERT OR LOT))';
  }
  return { page, pageSize, orderBy, isDelete, filterBy };
}

export function checkAll(list, selected, flag) {
  if (flag) {
    if (flag === 'all') return selected.clear();
    return selected.filter(v => !list.map(v => v.get('id')).includes(v));
  }
  return selected.concat(list.map(v => v.get('id')).filter(v => !selected.includes(v)));
}

const excludedWords = ['and', 'And'];

export const getTerm = term => term.split(/\W/)
  .filter(v => !excludedWords.includes(v))
  .join(' ');

export const isUrl = s => /(https?|blob):|\/\//i.test(s);

export function imgUrl(url, params) {
  if (!params) return `/amazon/${url}`;
  const { width = 80, height = 80 } = params;
  return `https://artbnk-art-resized-images.s3.amazonaws.com/${width}-${height}/${url}`;
}

/**
 * @param pattern 'string' - action name (type)
 * @param gen func* generator - calback
 * @param ...args - arguments for callback, action will be concated
 * gen will be called with (...args, action)
 *
 * watch async to action will be heppen and than call gen with ...[...args, action]
**/
export const takeOnce = (pattern, gen, ...args) => fork(function* () {
  const action = yield take(pattern);
  yield call(gen, ...args, action);
});

/**
 * @param pattern array - actions to be taken before gen will be called
 * @param gen func* generator - calback
 * @param ...args - arguments for callback, actions (array) will be concated
 * gen will be called with (...args, actions)
 *
 * watch async to actions in pattern will be heppend and than call gen with ...[...args, actions]
**/
export const takeAll = (pattern, gen, ...args) => fork(function* () {
  const actions = yield all(pattern);
  yield call(gen, ...args, actions);
});

// empty values: null, undefined
// not empty values: true, false, ...
export const isEmpty = v => !([v].join(''));

const nanToEmpty = v => isNaN(v) ? '' : v;

export const numberFieldProps = {
  normalize: (v = '', p = '') => /[^0-9,']+/.test(v) ? p : nanToEmpty(parseInt(v.replace(/\D/g, ''), 10)),
  format: (v = '') => v ? new Intl.NumberFormat('en-EN').format(v) : v,
};

export const floatFieldProps = {
  normalize: (v = '') => v ? nanToEmpty(parseFloat(v)) : v,
  format: (v = '') => v,
};

export const dateFieldProps = {
  normalize: v => v ? moment(v, 'MM/DD/YYYY').format('YYYY-MM-DD'): '',
  format: v => v ? moment(v, 'YYYY-MM-DD').format('MM/DD/YYYY'): '',
};

export const assignObjectProps = (obj, props) => {
  Object.keys(props).forEach(key => {
    if (this[key] === undefined) obj[key] = props[key];
  });
  return obj;
};

// eslint-disable-next-line
const createRecord = obj => {
  let res = Object.keys(obj).sort().reverse()
    .reduce((acc, key) => ({
      [key.replace(/_\w/g, v => v[1].toUpperCase())]: 'string(),',
      ...acc,
    }), {});
  // eslint-disable-next-line
  console.log(Object.keys(res));
  return res;
};
