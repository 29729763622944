import colors from 'theme/Colors';
import actions from './actions';
import toSnake from 'to-snake-case';

const keys = actions
  .filter(({ name }) => !!name)
  .concat({ name: 'restore' })
  .map(({ name, icon }) => ({ name, icon: icon || toSnake(name) }));

const tryToAddIcon = (icon) => {
  try {
    return {
      backgroundImage: 'url(' + require(`./icons/ic_${icon}.svg`) + ')',
      '&:hover:not(:disabled)': {
        color: colors.attention,
        backgroundImage: 'url(' + require(`./icons/ic_${icon}_hover.svg`) + ')',
      },
    };
  } catch (e){
    return {
      paddingLeft: 5,
      '&:hover:not(:disabled)': {
        color: colors.attention,
      },
    };
  }
};

const addIconStyle = (styles, { name, icon }) => ({
  ...styles,
  [name]: tryToAddIcon(icon),
});

export const sheet = {
  Actions: {
    display: 'flex',
    marginBottom: 20,
    flexShrink: 0,
  },
  button: {
    cursor: 'pointer',
    position: 'relative',
    height: 20,
    lineHeight: '20px',
    fontSize: 14,
    paddingLeft: 25,
    marginRight: 30,
    border: 0,
    outline: 'none',
    textTransform: 'capitalize',
    textDecoration: 'none',
    color: '#000000',
    background: {
      size: '20px 20px',
      repeat: 'no-repeat',
      position: 'left center',
    },
    '&:disabled': {
      opacity: 0.4,
    },
  },
  hasLoader: {},
  ...keys.reduce(addIconStyle, {}),
  divider: {
    opacity: 0.5,
    border: '1px solid #2e343e',
    marginRight: 30,
  },
  '@keyframes ring': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loader: {
    position: 'absolute',
    left: -25,
    top: 0,
    display: 'inline-block',
    width: 20,
    height: 20,
    animation: '$ring 1s linear infinite',
    '&:after': {
      content: '" "',
      display: 'block',
      width: 20,
      height: 20,
      boxSizing: 'border-box',
      borderRadius: '50%',
      border: `2px solid ${colors.attention}`,
      borderColor: `${colors.attention} transparent ${colors.attention} transparent`,
    },
  },
};
