import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { submit } from 'redux-form';
import { push } from 'connected-react-router';
import Table from 'react-immutable-jss-data-table';

import sheet from './sheet';
import tableSheet from 'pages/ArtistListPage/ArtistsListTable/sheet';
import injectSheet from 'lib/sheet';
import { artist, isEditable, updateAeAbvAction, syncWebAbvAction, runRtvAction } from 'domain/artist';
import { dictionaryById } from 'domain/dictionary';
import { authorsById } from 'domain/author';
import { userProfile } from 'domain/env';
import { lnk } from 'lib/routes';
import { getParams as locationParams_sel } from 'domain/router/RouterModel';

import Paper from 'components/Paper';
import Block from 'components/Block';
import Header from 'components/Header';
import Accordion from 'components/Accordion';
import ValuesList, { sheet as valuesListSheet } from 'components/ValuesList';
import Form from './Form';
import Actions from 'components/Actions';
import MergeArtistPopup from 'popups/MergeArtist';
import HistoryPopup from 'popups/History';
import confirmation from 'popups/Confirmation';
import { remove, approve } from 'domain/const';
import Popup from 'components/NewItemPopup';
import Navigation from 'components/Navigation';
import ArtistCARCalculations from 'components/ArtistCARCalculations/ArtistCARCalculations';

import { getClassNamesFactory, getRowClassNames } from 'components/PageTable/helpers';
import { tdMarker } from 'components/PageTable/columns';

import ArtworkForm from 'pages/ArtworkListPage/Form';

import columns from 'fields/artist';
import childrenColumns from 'fields/authors';
import tClasses from '../../components/PageTable/sheet';
import colors from '../../theme/Colors';
import { createFormConfig } from "lib/helpers";

const { value, label } = valuesListSheet;

const RemovePopup = confirmation('remove', remove, 'popupAction.remove.single');
const ApproveUnaccept = confirmation('approveArtist', approve );
const RunAbv = confirmation('runAbv', runRtvAction);
const SyncAbv = confirmation('syncAbv', syncWebAbvAction);
const UpdateAbv = confirmation('updateAbv', updateAeAbvAction);

const artistEditedFields = columns.select(
  'fullName',
  'birthDate',
  'deathDate',
  'residences',
  'biography',
  'notes',
  'carTag',
  'highCarValue',
  'carValue',
  'lowCarValue',
  'defaultCarField',
  'decayCoefficient',
);

const formFields = columns.select(
  'fullName',
  'namePrefix',
  'firstName',
  'middleName',
  'lastName',
  'nameSuffix',
  'lifeYears',
  'residences',
  'biography',
  'notes',
  'carTag',
  'highCarValue',
  'carValue',
  'lowCarValue',
  'defaultCarField',
  'decayCoefficient',
);

const basicInfo = columns.select('id','createdDate','acceptedDate','updatedDate','sourceType','mergeWith','searchCount','alertsCount');

const scrapedList = columns.select('countKnownArts', 'countScrapedArts', 'countTradedArtsInDb', 'countSoldArtsInDb');

const rtv = columns.select('customersAoCount', 'showRtv', 'rtvCount');

const banners = columns.select('banner970x120', 'banner720x120', 'banner343x120');
const bannerStats = columns.select('bannerDisplayDate', 'bannerUrl');

class ArtistPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      ArtistPage: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    artist: PropTypes.shape({}).isRequired,
    allowAccept: PropTypes.bool,
    userProfile: PropTypes.any,
  };

  render() {
    const { classes, artist, submit, push, allowAccept, userProfile,
      locationParams: { artistId }, ...props } = this.props;
    const ArtworkFormInitialValues = {
      artistId: artist.get('id'),
      literature: [{ name: '' }],
      exhibitions: [{ name: '' }],
    };
    const isEdit = isEditable(artist) && !artist.isDelete;
    return (
      <div className={classes.ArtistPage}>
        <Navigation linkBuilder={artistId => lnk('artist', { artistId })} />
        <MergeArtistPopup />
        <HistoryPopup />
        <RemovePopup />
        <ApproveUnaccept />
        <RunAbv/>
        <UpdateAbv/>
        <SyncAbv/>
        <Popup name="ADD_ARTIST_ARTWORK" formName="artworkForm">
          <ArtworkForm initialValues={ArtworkFormInitialValues} />
        </Popup>
        <Header
          title={artist.get('fullName')}
          object={artist}
          onSave={() => submit('artistForm')}
          onCancel={() => push(lnk('artistList'))}
        />
        { !artist.isDelete && <Actions data={artist} userProfile={userProfile} {...props} /> }
        <Paper className={classes.container}>
          <Form classes={classes}>
            <Block>
              <Accordion title="basicInfo">
                <div className={classes.box}>
                  <ValuesList {...props} config={isEdit ? createFormConfig(formFields) : artistEditedFields} rowData={artist} />
                  <ValuesList config={[]} {...props} rowData={{}} />
                  <ValuesList config={basicInfo} {...props} rowData={artist} />
                </div>
              </Accordion>
            </Block>
            <Block>
              <Accordion title="statistics">
                <div className={classes.box}>
                  <ValuesList config={scrapedList} rowData={artist} />
                  <ValuesList config={rtv} rowData={artist} />
                </div>
              </Accordion>
            </Block>
            { !!artist.get('auctionCountList').size &&
            <Block>
              <Accordion title="auctions">
                <div className={classes.auctions}>
                  { artist.get('auctionCountList').map(({ id, name, count }) => <div
                    className={classes.row}
                    key={id}
                  >
                    <div className={classes.label}>{name}</div>
                    <div className={classes.value}>{count}</div>
                  </div>)}
                </div>
              </Accordion>
            </Block> }
            { !!artist.get('children').size &&
            <Block>
              <Accordion title="mergedArtists">
                <div className={classes.box}>
                  <Table
                    {...props}
                    classes={classes}
                    columns={[ tdMarker, ...childrenColumns]}
                    items={artist.get('children')}
                    showColumnsTitles
                    rowStatusIndicator
                    rowStatusBg
                    getCellClassNames={getClassNamesFactory('td')}
                    getThClassNames={getClassNamesFactory('th')}
                    getRowClassNames={getRowClassNames}
                  />
                </div>
              </Accordion>
            </Block> }
            <Block>
              <Accordion title="advertisement">
                <div className={classes.box}>
                  <ValuesList config={isEdit ? createFormConfig(banners) : banners} rowData={artist} />
                  <ValuesList config={isEdit ? createFormConfig(bannerStats) : bannerStats} rowData={artist} />
                </div>
              </Accordion>
            </Block>
            <Block>
              <Accordion title="automatedCARCalculation">
                <ArtistCARCalculations artistId={artistId} />
              </Accordion>
            </Block>
          </Form>
        </Paper>
      </div>
    );
  }
}

export default compose(
  injectSheet({
    ...tableSheet,
    ...sheet,
    row: { display: 'flex', flex: '0 0 auto', width: 590, marginRight: 20, height: 44, marginBottom: 0, alignItems: 'center' },
    value,
    label,
    DataTable: {
      width: '100%',
      overflowY: 'auto',
    },
    trh: {
      ...tClasses.trh,
      backgroundColor: colors.bg,
      borderBottom: `2px solid ${colors.fieldBorder}`,
    },
    fullName: {
      width: 300,
    },
  }),
  connect({
    userProfile,
    artist,
    residences: dictionaryById('countries'),
    mergeWith: authorsById,
    submit,
    push,
    locationParams: locationParams_sel,
  }),
)(ArtistPage);
