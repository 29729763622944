import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Button from 'components/Button';
import cx from 'classnames';

import Loader from 'components/Loader';

import { callApiAction } from 'domain/api';

import injectSheet from 'lib/sheet';

const fetchChartsData = callApiAction('getArtistCarCharts');

const getScatterDiagramConfig = (dataIn, xValKey, title) => {
  if (!dataIn) return null;
  const top = [];
  const middle = [];
  const bottom = [];
  dataIn.forEach((v) => {
    if (v.top20CarValue) {
      top.push({ y: v.top20CarValue, x: v[xValKey] });
    }
    if (v.middle60CarValue) {
      middle.push({ y: v.middle60CarValue, x: v[xValKey] });
    }
    if (v.bottom20CarValue) {
      bottom.push({ y: v.bottom20CarValue, x: v[xValKey] });
    }
  });
  const datasets = [
    {
      label: 'Top 20%',
      data: top,
      borderColor: 'rgb(255, 159, 64)',
      backgroundColor: 'rgba(255, 159, 64, 0.5)',
    },
    {
      label: 'Middle 60%',
      data: middle,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
    },
    {
      label: 'Bottom 20%',
      data: bottom,
      // borderColor: 'rgb(54, 162, 235)',
      // backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(7, 55, 99)',
      backgroundColor: 'rgba(7, 55, 99, 0.9)',
    },
  ];
  const config = {
    type: 'scatter',
    data: { datasets },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: title,
        },
      },
      scales: {
        y: {
          grid: {
            color: (context) => {
              if (context.tick.value === 0) {
                return 'rgba(0, 0, 0, 0.35)';
              }
              return 'rgba(0, 0, 0, 0.1)';
            },
          },
        },
      },
    },
  };
  return config;
};

const getBarDiagramConfig = (dataIn, title) => {
  if (!dataIn) return null;
  const labels = [];
  const data = [...dataIn]
    // sort 1, 2, 3
    .sort((a, b) => a.year - b.year)
    .map(({ year, count }) => {
      labels.push(year);
      return count;
    });
  const datasets = [
    {
      label: 'Sale Year',
      data,
      borderColor: 'rgb(7, 55, 99)',
      backgroundColor: 'rgba(7, 55, 99, 0.9)',
      datalabels: {
        anchor: 'end',
        align: 'start',
        offset: 5,
      },
    },
  ];
  const config = {
    type: 'bar',
    data: {
      labels,
      datasets,
    },
    plugins: [ChartDataLabels],
    options: {
      responsive: true,
      plugins: {
        datalabels: {
          color: 'white',
          font: {
            weight: 'bold',
          },
        },
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: title,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            // tooltipItem: { label, formattedValue }
            label: (tooltipItem) => tooltipItem.formattedValue,
          },
        },
      },
      layout: {
        padding: {
          top: 24,
          right: 16,
          bottom: 0,
          left: 8,
        },
      },
    },
  };
  return config;
};

const getLineChartMedianDiagramConfig = (dataIn) => {
  if (!dataIn) return null;
  const top = [];
  const middle = [];
  const bottom = [];
  const labels = [];
  dataIn.forEach((v) => {
    top.push(v.meanTop20CarValue);
    middle.push(v.meanMiddle60CarValue);
    bottom.push(v.meanBottom20CarValue);
    labels.push(v.year);
  });
  const datasets = [
    {
      label: 'Top 20%',
      data: top,
      borderColor: 'rgb(255, 159, 64)',
      backgroundColor: 'rgba(255, 159, 64, 0.5)',
    },
    {
      label: 'Middle 60%',
      data: middle,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
    },
    {
      label: 'Bottom 20%',
      data: bottom,
      // borderColor: 'rgb(54, 162, 235)',
      // backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(7, 55, 99)',
      backgroundColor: 'rgba(7, 55, 99, 0.9)',
    },
  ];
  const config = {
    type: 'line',
    data: { labels, datasets },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Artist Mean Car Index',
        },
      },
      scales: {
        y: {
          grid: {
            color: (context) => {
              if (context.tick.value === 0) {
                return 'rgba(0, 0, 0, 0.35)';
              }
              return 'rgba(0, 0, 0, 0.1)';
            },
          },
        },
      },
    },
  };
  return config;
};

const getLineChartMeanDiagramConfig = (dataIn) => {
  if (!dataIn) return null;
  const top = [];
  const middle = [];
  const bottom = [];
  const labels = [];
  dataIn.forEach((v) => {
    top.push(v.medianTop20CarValue);
    middle.push(v.medianMiddle60CarValue);
    bottom.push(v.medianBottom20CarValue);
    labels.push(v.year);
  });
  const datasets = [
    {
      label: 'Top 20%',
      data: top,
      borderColor: 'rgb(255, 159, 64)',
      backgroundColor: 'rgba(255, 159, 64, 0.5)',
    },
    {
      label: 'Middle 60%',
      data: middle,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.5)',
    },
    {
      label: 'Bottom 20%',
      data: bottom,
      // borderColor: 'rgb(54, 162, 235)',
      // backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgb(7, 55, 99)',
      backgroundColor: 'rgba(7, 55, 99, 0.9)',
    },
  ];
  const config = {
    type: 'line',
    data: { labels, datasets },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Artist Median Car Index',
        },
      },
      scales: {
        y: {
          grid: {
            color: (context) => {
              if (context.tick.value === 0) {
                return 'rgba(0, 0, 0, 0.35)';
              }
              return 'rgba(0, 0, 0, 0.1)';
            },
          },
        },
      },
    },
  };
  return config;
};

const runScatterChart = (dataIn, valKey, title, artistId) => {
  const config = getScatterDiagramConfig(dataIn, valKey, title);
  if (!config) return;
  const canvasContext = document.getElementById(`chart-${valKey}-${artistId}`).getContext('2d');
  new Chart(canvasContext, config);
}

const runBarChart = (dataIn, canvasKey, title, artistId) => {
  const config = getBarDiagramConfig(dataIn, title);
  if (!config) return;
  const canvasContext = document.getElementById(`chart-${canvasKey}-${artistId}`).getContext('2d');
  new Chart(canvasContext, config);
}

const runLineMedianChart = (dataIn, canvasKey, artistId) => {
  const config = getLineChartMedianDiagramConfig(dataIn);
  if (!config) return;
  const canvasContext = document.getElementById(`chart-${canvasKey}-${artistId}`).getContext('2d');
  new Chart(canvasContext, config);
}

const runLineMeanChart = (dataIn, canvasKey, artistId) => {
  const config = getLineChartMeanDiagramConfig(dataIn);
  if (!config) return;
  const canvasContext = document.getElementById(`chart-${canvasKey}-${artistId}`).getContext('2d');
  new Chart(canvasContext, config);
}

// resp:
// carVsHoldingPeriod: [{ bottom20CarValue: null, middle60CarValue: 9.648970346093932, top20CarValue: null, holdingPeriod: 10 }]
// carVsPurchaseYear: [{ bottom20CarValue: null, middle60CarValue: 9.648970346093932, top20CarValue: null, purchaseYear: 2008 }]
// carVsSaleYear: [{ bottom20CarValue: null, middle60CarValue: 9.648970346093932, top20CarValue: null, saleYear: 2018}]
// repeatSalesCount: [{ count: 21, year: 2018 }]
function Charts({ classes, artistId }) {
  const [loading, setLoading] = useState(true);
  const [resp, setResp] = useState(null);
  const [period, setPeriod] = useState('all');
  const [canvasesLoaded, setCanvasesLoaded] = useState(false);
  const [nothingToShow, setNothingToShow] = useState(false);
  const [doNotShowLineChart, setDoNotShowLineChart] = useState(false);
  const lastCanvasRef = (node) => {
    if (node && !canvasesLoaded) {
      setCanvasesLoaded(true);
    }
  }

  useEffect(() => {
    setResp(null);
    setLoading(true);
    setCanvasesLoaded(false);
    if (!artistId) return;
    fetchChartsData({ urlParams: { artistId, period } })
      .then(({ data: { result = null } = {} }) => {
        setResp(result);
      })
      .catch((err) => {
        console.warn('catch err', err);
      })
      .finally(() => setLoading(false));
  }, [artistId, period]);

  function changePeriod(item) {
    setPeriod(item);
  }

  useEffect(() => {
    if (!resp || !artistId || !canvasesLoaded) return;
    const { carVsHoldingPeriod, carVsPurchaseYear, carVsSaleYear, repeatSalesCount, artistMeanCarIndex, artistMedianCarIndex, } = resp;
    if (artistMeanCarIndex === null || artistMedianCarIndex === null) {
      setDoNotShowLineChart(true);
    } else {
      setDoNotShowLineChart(false);
    }
    if (Object.keys(resp).length === 0) {
      setNothingToShow(true);
      return;
    }
    runScatterChart(carVsHoldingPeriod, 'holdingPeriod', 'CAR vs. Holding Period', artistId);
    runScatterChart(carVsPurchaseYear, 'purchaseYear', 'CAR vs. Purchase Year', artistId);
    runScatterChart(carVsSaleYear, 'saleYear', 'CAR vs. Sale Year', artistId);
    runBarChart(repeatSalesCount, 'repeatSalesCount', 'Repeat Sale Count', artistId);
    runLineMedianChart(artistMeanCarIndex, 'meanCarIndex', artistId);
    runLineMeanChart(artistMedianCarIndex, 'medianCarIndex', artistId);
  }, [resp, artistId, canvasesLoaded]);
  const ITEMS = ['all', 5, 10, 20];
  return (
    <div className={classes.Charts}>
      {
        !!loading &&
          <div className={classes.loading}>
            <Loader />
          </div>
      }
      {
        !loading && !!resp &&
          <React.Fragment>
            <div className={classes.chartWrp}>
              <div className={classes.period}>
                <p className={classes.periodTitle} children="Period (years)"/>
                {ITEMS.map(item => (
                    <Button
                      buttonType={item === period ? "chartPeriod" : "secondary"}
                      className={classes.periodButtons}
                      onClick={() => changePeriod(item)}
                      children={item}
                    />
                ))}
              </div>
              <div className={cx(classes.row, { empty: nothingToShow })}>
                <div className={classes.cell}>
                  <canvas id={`chart-holdingPeriod-${artistId}`} width="800" height="500"/>
                </div>
                <div className={classes.cell}>
                  <canvas id={`chart-purchaseYear-${artistId}`} width="800" height="500"/>
                </div>
              </div>
              <div className={cx(classes.row, { empty: nothingToShow })}>
                <div className={classes.cell}>
                  <canvas id={`chart-repeatSalesCount-${artistId}`} width="800" height="500"/>
                </div>
                <div className={classes.cell} ref={lastCanvasRef}>
                  <canvas id={`chart-saleYear-${artistId}`} width="800" height="500"/>
                </div>
              </div>
              <div className={cx(classes.row, { empty: doNotShowLineChart })}>
                <div className={classes.cell} ref={lastCanvasRef}>
                  <canvas id={`chart-medianCarIndex-${artistId}`} width="800" height="500"/>
                </div>
                <div className={classes.cell}>
                  <canvas id={`chart-meanCarIndex-${artistId}`} width="800" height="500"/>
                </div>
              </div>
            </div>
          </React.Fragment>
      }
    </div>
  );
}

Charts.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const sheet = {
  Charts: {},
  loading: {
    position: 'relative',
    height: 120,
  },
  row: {
    display: 'flex',
    '&.empty': {
      display: 'none',
    },
  },
  chartWrp: {

  },
  period: {
    margin: [60, 0, 20],
  },
  periodTitle: {
    fontWeight: 500,
  },
  periodButtons: {
    margin: [0, 10, 0, 0],
  },
  cell: {
    flex: '1 1 0px',
  },
};

export default injectSheet(sheet)(Charts);
