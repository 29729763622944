import { connect } from 'cpcs-reconnect';

import {
  commonDictionaryFields,
  connectAcceptance,
  createIdLink,
  toNumberOrNull,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import { dict } from 'lib/serialize';
import { dictionaryList } from 'domain/dictionary';
import rules from 'components/form/validation';
import Select from 'components/form/element/Select';

const CountriesAutoComplete = connect({ list: dictionaryList('countries') })(Select);

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('auction'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  name: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  country: {
    inlineEdit: true,
    component: CountriesAutoComplete,
    getValue: dict(),
  },
  city: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
  },
  lotsCount: {
    sortable: true,
    className: 'number',
    getValue: toNumberOrNull,
  },
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, ['name']),
);

export const defaultColumns = [
  'name',
  'country',
  'city',
  'createdDate',
  'updatedDate',
];

export const tableParams = { columns, defaultColumns };

export default columns;
