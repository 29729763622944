import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';
import { connect } from 'cpcs-reconnect';

import { selectors, actions } from 'domain/customer';
import { filterParams } from 'filters/customer';

import { dictionaryById } from 'domain/dictionary';
import { tableParams } from 'fields/customer';
import ListHeader from 'components/CustomerListHeader';

import styles from 'components/PageTable/sheet';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const tableSheet = {
  ...styles,
  tr: {
    ...styles.tr,
    height: 94,
  },
};

const Table = createTable({
  tableName: 'customerList',
  tableParams,
  updateItemAction,
  sheet: tableSheet,
  connections: {
    items,
    country: dictionaryById('countries'),
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
    stats: selectors.stats,
  },
});

export default createListPage({
  name: 'Customer',
  pagination: pagination,
  filterParams,
  Table,
  PageHeader: connect({ stats: selectors.stats })(ListHeader),
  csvApiKey: 'getCustomerListCSV'
});
