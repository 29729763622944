import React, { useEffect, useState } from 'react';
import { connect } from "cpcs-reconnect";
import { token } from 'domain/env'
import SmallLoader from 'components/SmallLoader';
import { updateClusterAction } from "domain/artwork";

import Api from 'domain/api';

const ClusterValue = ({ id, token, updateClusterAction, clusterNumber }) => {

  const [ cluster, setCluster ] = useState('−');
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if (id) {
      Api.getAoCluster({ id, token })
        .then(({ data: { result }}) => {
          setLoading(false);
          setCluster(result?.clusterNumber || '−');
          updateClusterAction({ clusterNumber: result?.clusterNumber })
        }).catch(() => {
        setLoading(false);
        setCluster('ERROR');
      })
    }
  }, [id, token, updateClusterAction])

  return loading ? <SmallLoader/> : <div>{ clusterNumber || cluster }</div>;
}

export default connect({ token, updateClusterAction })(ClusterValue);
