import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import injectSheet from 'lib/sheet';
import ValuesList, { sheet } from 'components/ValuesList';
import { createArtworkAction } from 'domain/artworkList';
import columns from 'fields/artwork';
import { createFormConfig } from 'lib/helpers';
import { authorsById } from 'domain/author';
import { connect } from 'cpcs-reconnect';
import { category } from 'domain/artwork';
import { basicInfo, details } from 'domain/artwork/fieldRules';
import { setFieldsOption } from 'fields/common';

import rules from 'components/form/validation';
import { submit } from 'lib/helpers';


const finalCreateConfigFields = compose(createFormConfig, setFieldsOption(['subjects', 'medium', 'signature', 'physicalSizeRaw', 'imageSizeRaw'], 'required', false));

const StyledValuesList = injectSheet({
  ...sheet,
  ValuesList: { marginRight: 150, width: 440 },
  value: { width: 300, display: 'flex' },
})(ValuesList);

class Form extends React.PureComponent {
  static propTypes = {
    category: PropTypes.string.isRequired,
  }

  render(){
    const { category } = this.props;
    const formFields = columns.select(
      'defaultArtImage',
      ...basicInfo[category],
      ...details[category].reduce((acc, fields = []) => acc.concat(fields) ,[]),
      'exhibitions',
      'literature',
      'notes',
    );

    return (
      <form>
        <StyledValuesList showStars config={finalCreateConfigFields(formFields)} rowData={{}} popupForm />
      </form>
    );
  }
}

export default compose(
  connect({
    artistId: authorsById,
    category,
  }),
  reduxForm({
    form: 'artworkForm',
    onSubmit: submit(createArtworkAction),
    validate: ({ images }) => ({ images: rules.required(images) ? { id: 'defaultImageRequired' } : undefined }),
    initialValues: { literature: [{ name: '' }], exhibitions: [{ name: '' }] },
  }),
)(Form);
