import colors from 'theme/Colors';
import arrow from './arrow.svg';

export default {
  Accordion: {
    display: 'block',
    marginTop: 10,
    '&.clientSpace': {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      // boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.02)',
      borderRadius: 7,
      width: '100%',
      marginTop: 0,
    },
    '&.landingPageBanner': {
      border: '1px solid rgba(0, 0, 0, 0.05)',
    },
    '&.artistReport': {
      border: '1px solid rgba(0, 0, 0, 0.05)',

      marginTop: 12,
    },
    '&.artworkReport': {
      border: '1px solid rgba(0, 0, 0, 0.05)',

      marginTop: 12,
    },
    '&.portfolioReport': {
      border: '1px solid rgba(0, 0, 0, 0.05)',

      marginTop: 12,
    },

    '&.PDFGenerator': {
      border: '1px solid rgba(0, 0, 0, 0.05)',
      marginTop: 20,
    },
  },
  // clientSpace: { color: 'red' },
  header: {
    color: colors.attention,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontWeight: 500,
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '&.clientSpace': {
      color: '#022536',
      padding: 10,
      borderBottom: '2px solid #AA4423',
      width: 'calc(100% - 20px)',
      textTransform: 'none',
    },
    '&.PDFGenerator': {
      color: '#022536',
      padding: 10,
      borderBottom: '2px solid #AA4423',
      width: 'calc(100% - 20px)',
      textTransform: 'none',
    },
    '&.landingPageBanner': {
      color: 'rgba(2, 37, 54, 0.8)',
      padding: 10,
      width: 'calc(100% - 20px)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      textTransform: 'none',
    },
    '&.artistReport': {
      color: 'rgba(2, 37, 54, 0.8)',
      padding: 10,
      width: 'calc(100% - 20px)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      textTransform: 'none',
    },
    '&.artworkReport': {
      color: 'rgba(2, 37, 54, 0.8)',
      padding: 10,
      width: 'calc(100% - 20px)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      textTransform: 'none',
    },
    '&.portfolioReport': {
      color: 'rgba(2, 37, 54, 0.8)',
      padding: 10,
      width: 'calc(100% - 20px)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      textTransform: 'none',
    },
  },
  arrow: {
    width: 12,
    height: 8,
    marginRight: 10,
    transition: '500ms',
    background: {
      image: `url(${arrow})`,
      position: 'center center',
      size: '12px 8px',
      repeat: 'no-repeat',
    },
    '&.landingPageBanner': {
      width: 10,
      height: 6,
      background: {
        size: '10px 6px',
      },
    },
    '&.artistReport': {
      width: 10,
      height: 6,
      background: {
        size: '10px 6px',
      },
    },
    '&.artworkReport': {
      width: 10,
      height: 6,
      background: {
        size: '10px 6px',
      },
    },
    '&.portfolioReport': {
      width: 10,
      height: 6,
      background: {
        size: '10px 6px',
      },
    },
  },
  closed: {
    transform: 'rotate(-180deg)',
  },
  content: {
    position: 'relative',
    '&.PDFGenerator': {
      margin: [12, 10],
    },
    '&.clientSpace': {
      margin: [12, 10],
    },
    '&.landingPageBanner': {
      height: 72,
      padding: [24, 30],
    },
    '&.artistReport': {
      padding: [24, 30],
    },
    '&.artworkReport': {
      padding: [24, 30],
    },
    '&.portfolioReport': {
      padding: [24, 30],
    },
  },
  hidden: {
    display: 'none',
  },
};
