import colors, { statusColors } from 'theme/Colors';
import buttons from 'components/Button/sheet';

export default {
  ListHeader: {
    margin: [0, 0, 20, 0],
    height: 32,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  nav: {
    display: 'flex',
  },
  title: {
    fontSize: 24,
    margin: [0, 40, 0, 0],
    textTransform: 'capitalize',
  },
  active: {
    backgroundColor: colors.buttonHover,
    color: colors.text + '!important',
  },
  link: {
    height: 32,
    lineHeight: '32px',
    fontSize: 16,
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: [0, 20],
    color: colors.attention,
    margin: [0, 1],
    '&:hover': {
      extend: 'active',
    },
    '& span': {
      fontWeight: 500,
    },
  },
  headerBold: {
    fontWeight: 700,
  },
  buttons: {
    whiteSpace: 'nowrap',
  },
  deleted: {
    color: colors.text,
    marginRight: 10,
  },
  notDeleted: {
    ...buttons.ghost,
    ...buttons.primary,
    textDecoration: 'none',
    width: 'auto',
    padding: [0, 10],
  },
  ...statusColors,
};
