import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import injectSheet from 'lib/sheet';
import { Field, Fields } from 'redux-form';
import I from 'immutable';

import Select from 'components/form/element/Select';
import {
  YearPure,
  DateFieldPure,
  sheet as dateFieldSheetOrigin,
} from 'components/form/element/DateField';
import { showPopup } from 'domain/ui';
import {
  PureTextArea,
  sheet as textAreaSheet,
} from 'components/form/element/TextArea';
import { DefaultCarValueFieldsAutoComplete } from './Autocomplete';
import { dateTimeToString } from 'lib/serialize';
import ImageDrop from 'components/ImageDrop';
import UploadImage from 'components/SingleImageUpload';
import { ucFirst } from 'lib/helpers';
import { Link } from 'react-router-dom';
import { lnk } from '../lib/routes';
import Input from 'components/form/fields/input';

export const NotesTextArea = injectSheet({
  ...textAreaSheet,
  wrapper: { width: '100%', height: 86 },
})(PureTextArea);

/**
 * set "required": true for fields configs in "columnsObject":  by "fields: string[]"
 * columnsObject: {[key: string]: {
 *  // cell content
 *  // try { (parser || getValue)({ ...props }) } catch { Value = parser || getValue, <Value /> }
 *  parser: Function | React.Component | React.PureComponent
 *  getValue: Function,
 *  sortable: boolean,
 *  className: string,
 *  sortByName: string,
 *  hiddenForTable: boolean,
 *  // custom path for Immutable getIn(path)
 *  fieldPath: string[],
 *  // component: see { createFormConfig } from 'lib/helpers'; used for forms
 *  component: React.Component | React.PureComponent | Function,
 *  // by default it set to { Field } from 'redux-form'; can be set to custom component
 *  formFieldRenderer: React.Component | React.PureComponent | Function,
 *  fieldProps: Object,
 *  visibleInValueList(): ({ formData = {}, isEdit, rowData = {} }: { formData: {}, isEdit: boolean, rowData: {} }): AnyBooleanLikeValue,
 *  inlineEdit: boolean,
 *  required: boolean,
 *  title: string,
 * }}
 * fields: Array<string>,
 **/
export const connectAcceptance = (columnsObject, fields) => {
  return fields.reduce(
    (obj, key) => ({ ...obj, [key]: { ...obj[key], required: true } }),
    columnsObject,
  );
};

const HistoryLinkPure = ({
  history,
  classes,
  showPopup,
  usernameLastUpdate,
}) => {
  const { dateCreated, username, ...props } = history.toJS
    ? history.toJS()
    : history;
  return (
    <div>
      <span>
        {[
          dateTimeToString()({ value: dateCreated }),
          username || usernameLastUpdate,
        ]
          .filter((v) => !!v)
          .join(', ')}
      </span>
      <button
        className={classes.history}
        type="button"
        onClick={() => showPopup({ name: 'HISTORY', ...props })}
      />
    </div>
  );
};

HistoryLinkPure.propTypes = {
  history: PropTypes.any,
  showPopup: PropTypes.func.isRequired,
  usernameLastUpdate: PropTypes.string,
  classes: PropTypes.shape({
    history: PropTypes.string,
  }).isRequired,
};

export const HistoryLink = connect({ showPopup })(HistoryLinkPure);

export const updated = ({
  value,
  rowData: { history, createdDate, acceptedDate, usernameLastUpdate },
  classes,
}) => {
  if (createdDate === acceptedDate || !history) return null;
  if (history) {
    const props = { history, classes, usernameLastUpdate };
    return <HistoryLink {...props} />;
  } else {
    return dateTimeToString()(value);
  }
};

export const unit = (
  <Field
    name="unit"
    component={Select}
    list={I.fromJS([
      { id: 'MKM', title: 'MKM' },
      { id: 'MM', title: 'MM' },
      { id: 'CM', title: 'CM' },
      { id: 'INCH', title: 'INCH' },
      { id: 'M', title: 'M' },
    ])}
  />
);

const dateFieldSheet = {
  ...dateFieldSheetOrigin,
  wrapper: {
    ...dateFieldSheetOrigin.wrapper,
    display: 'inline-block',
    width: 60,
    marginRight: 16,
  },
};

export const DateField = injectSheet(dateFieldSheet)(YearPure);
export const DatePicker = injectSheet({
  ...dateFieldSheet,
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 142px',
  },
})(DateFieldPure);
export const DatePickerDouble = injectSheet({
  ...dateFieldSheet,
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '0 0 142px',
    maxWidth: '103px',
    paddingRight: '10px',
  },
  element: {
    ...dateFieldSheet.element,
    paddingLeft: '5px',
  }
})(DateFieldPure);

export const PureInput = ({ input, ...props }) => <Input { ...props } { ...input } />;
export const timeInput = injectSheet({
  timeField: {
    border: '2px solid red',
  }
})(PureInput);

export const InlineDateField = injectSheet(dateFieldSheetOrigin)(YearPure);

export const ImageDropField = ({ object: { artImages }, ...props }) => (
  <Fields
    names={['images', 'defaultArtImage']}
    imagesName="images"
    defaultName="defaultArtImage"
    component={ImageDrop}
    source={artImages}
    {...props}
  />
);

ImageDropField.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};
export const ImageDropFieldA = ({ object, ...props }) => {
  // export const ImageDropFieldA = ({ object: { artImages }, ...props }) => {
  console.log(object, props);
  return (
    <Fields
      names={['images', 'enterpriseWebappClientLogo']}
      imagesName="images"
      defaultName="enterpriseWebappClientLogo"
      component={ImageDrop}
      source={object.enterpriseWebappClientLogo}
      {...props}
    />
  );
};

ImageDropFieldA.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadImageField = ({ object: { artImages }, ...props }) => (
  <Fields
    names={['images', 'defaultArtImage']}
    imagesName="images"
    defaultName="defaultArtImage"
    component={UploadImage}
    source={artImages}
    {...props}
  />
);

UploadImageField.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadEnterpriseWebappClientLogo = ({ object, ...props }) => {
  return (
    <Fields
      names={['enterpriseWebappClientLogo']}
      imagesName="enterpriseWebappClientLogo"
      defaultName="enterpriseWebappClientLogo"
      component={UploadImage}
      source={object.enterpriseWebappClientLogo}
      {...props}
    />
  );
};

UploadEnterpriseWebappClientLogo.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadArtistReportCoverPageLogo = ({
  object: { artistReportCoverPageLogo },
  ...props
}) => (
  <Fields
    names={['artistReportCoverPageLogo']}
    imagesName="artistReportCoverPageLogo"
    defaultName="artistReportCoverPageLogo"
    component={UploadImage}
    source={artistReportCoverPageLogo}
    {...props}
  />
);

UploadArtistReportCoverPageLogo.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadArtistReportHeaderLogo = ({
  object: { artistReportHeaderLogo },
  ...props
}) => (
  <Fields
    names={['artistReportHeaderLogo']}
    imagesName="artistReportHeaderLogo"
    defaultName="artistReportHeaderLogo"
    component={UploadImage}
    source={artistReportHeaderLogo}
    {...props}
  />
);

UploadArtistReportHeaderLogo.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadPortfolioClientLogoUrl = ({
  object: { portfolioClientLogoUrl },
  ...props
}) => (
  <Fields
    names={['portfolioClientLogoUrl']}
    imagesName="portfolioClientLogoUrl"
    defaultName="portfolioClientLogoUrl"
    component={UploadImage}
    source={portfolioClientLogoUrl}
    {...props}
  />
);

UploadPortfolioClientLogoUrl.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadPortfolioReportHeaderLogoUrl = ({
  object: { portfolioReportHeaderLogoUrl },
  ...props
}) => (
  <Fields
    names={['portfolioReportHeaderLogoUrl']}
    imagesName="portfolioReportHeaderLogoUrl"
    defaultName="portfolioReportHeaderLogoUrl"
    component={UploadImage}
    source={portfolioReportHeaderLogoUrl}
    {...props}
  />
);

UploadPortfolioReportHeaderLogoUrl.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};


export const UploadArtworkReportHeaderLogo = ({
  object: { artworkReportHeaderLogo },
  ...props
}) => (
  <Fields
    names={['artworkReportHeaderLogo']}
    imagesName="artworkReportHeaderLogo"
    defaultName="artworkReportHeaderLogo"
    component={UploadImage}
    source={artworkReportHeaderLogo}
    {...props}
  />
);

UploadArtworkReportHeaderLogo.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const UploadArtworkReportCoverPageLogo = ({
  object: { artworkReportCoverPageLogo },
  ...props
}) => {
  return (
    <Fields
      names={['artworkReportCoverPageLogo']}
      imagesName="artworkReportCoverPageLogo"
      defaultName="artworkReportCoverPageLogo"
      component={UploadImage}
      source={artworkReportCoverPageLogo}
      {...props}
    />
  );
};

UploadArtworkReportCoverPageLogo.propTypes = {
  object: PropTypes.shape({
    images: PropTypes.instanceOf(I.List),
  }),
};

export const getCurrency = (rowData) =>
  Object.keys(rowData.toJS())
    .filter((v) => ['currency', 'lastLotCurrency'].includes(v))
    .reduce((prev, key) => prev || rowData[key], null);

export const toNumber = ({ value }) =>
  value !== undefined
    ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(
        Math.round(value),
      )
    : null;

export const toFloat = ({ value }) =>
  value
    ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(
        Math.round(value * 100) / 100,
      )
    : '−';
export const toFloatWithUSD = ({ value }) =>
  value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(Math.round(value * 100) / 100)
    : '−';

export const toFloatWithUSD10 = ({ value }) =>
  value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(Math.round(value * 100) / 100)
    : '−';

export const toFloatWithUSD2Symbols = ({ value }) =>
  value
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
      }).format(Math.round(value * 100) / 100)
    : '−';

export const toNumberOrNull = ({ value }) =>
  /\d/.test(value) ? toNumber({ value: `${value}` }) : null;

export const yesNoDash = ({ value, defaultValue }) =>
  value === undefined ? defaultValue : value ? 'Yes' : 'No';

export const toPrice = ({ value, currency: currenciesDictionary, rowData }) => {
  const currencyId = getCurrency(rowData);
  if (value === undefined) return null;
  const currency = !currenciesDictionary
    ? 'USD'
    : currenciesDictionary.has(currencyId)
    ? currenciesDictionary.get(currencyId).get('alpha3')
    : 'USD';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  }).format(value || 0);
};

export const ppsi = ({ value }) =>
  !value
    ? null
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(value);

export const toUsdPrice = ({ value }) =>
  !value && value !== '0'
    ? '−'
    : new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
      }).format(value) + ' USD';

export const toNotEmptyUsdPrice = ({ value = 0 }) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  }).format(value) + ' USD';

export const toPriceRange =
  (keyFrom, keyTo, { currency, displayCurrency = true } = {}) =>
  ({ currency: currenciesDictionary, rowData }) => {
    let definedCurrency = currency;
    if (!currency) {
      const currencyId = getCurrency(rowData);
      definedCurrency = currenciesDictionary.has(currencyId)
        ? currenciesDictionary.get(currencyId).get('alpha3')
        : 'USD';
    }
    if (!rowData || !rowData.toJS) {
      I.fromJS(rowData);
    }
    // const { [keyFrom]: from, [keyTo]: to } = rowData;
    const from = rowData.getIn([].concat(keyFrom));
    const to = rowData.getIn([].concat(keyTo));
    if (from !== undefined && to !== undefined)
      return `${toNumber({ value: from })} - ${toNumber({ value: to })} ${
        displayCurrency ? definedCurrency : ''
      }`;
    return null;
  };

export const commonDictionaryFields = {
  createdDate: {
    sortable: true,
    className: 'date',
    getValue: dateTimeToString('MM/DD/YYYY', '−'),
    parser: dateTimeToString(),
  },
  updatedDate: {
    sortable: true,
    className: 'date',
    getValue: dateTimeToString('MM/DD/YYYY', '−'),
    parser: dateTimeToString(),
  },
};

export const commonFields = {
  notes: { component: NotesTextArea, additional: true },
  sourceType: {
    sortable: true,
    getValue: ({ value = '' }) => ucFirst(value.toLowerCase()),
  },
  ...commonDictionaryFields,
  updatedDate: {
    sortable: true,
    className: 'date',
    getValue: dateTimeToString('MM/DD/YYYY', null),
    parser: updated,
  },
  acceptedDate: {
    sortable: true,
    className: 'date',
    getValue: dateTimeToString('MM/DD/YYYY', null),
    parser: dateTimeToString(),
  },
};

export const createIdLink = (linkName, paramName = 'id', titleFn) => {
  const IdLink = ({ value, classes: { link } }) =>
    value ? (
      <Link
        className={link}
        to={lnk(linkName, { [paramName]: titleFn ? value.id : value })}
      >
        {titleFn ? titleFn(value) : value}
      </Link>
    ) : null;

  IdLink.propTypes = {
    value: PropTypes.any,
    classes: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }).isRequired,
  };
  return IdLink;
};

export const setFieldsOption = (fields, option, value) => {
  const fieldsMap = fields.reduce((m, f) => ({ ...m, [f]: true }), {});
  return (list) =>
    list.map((f) => (fieldsMap[f.name] ? { ...f, [option]: value } : f));
};

export const defaultCarField = {
  // getValue: ({ value }) => value && value.id ? value?.smcTag + (value.carValue ? ` (${value.carValue})` : '') : null,
  sortable: false,
  inlineEdit: true,
  component: DefaultCarValueFieldsAutoComplete,
  fieldProps: {
    isClearable: true,
    parseValue: (v) => (v && v.get ? v.get('id') : v),
  },
};
