const MAX_ITEMS_PER_SIDE = 4;
const ITEMS_FOR_BINDED_SIDE = 5;
const ITEMS_AROUND_CURRENT_PAGE = 2;

const getRange = (start, end) => Array.from({ length: (end - start + 1) }, (v, k) => k + start);

export default ({ dots, pageItem, pageRange, prev2, next2, current }) => ({ pages, page }) => {
  const tailRight = ({ from, to }) => {
    const length = to - from + 1;
    if (length > 2) {
      return [dots('rightSideDots'), pageItem({ page: to })];
    } else {
      return getRange(from, to).map(page => pageItem({ page }));
    }
  };

  const tailLeft = (to) => {
    const length = to + 1;
    if (length > 2) {
      return [pageItem({ page: 0 }), dots('leftSideDots')];
    } else {
      return getRange(0, to).map(page => pageItem({ page }));
    }
  };

  const lastPageNumber = pages - 1;
  const nextDisabled = lastPageNumber < page + 1;
  let items = [];
  const smallRange = pages <= ITEMS_FOR_BINDED_SIDE;
  const bindToLeft = page <= MAX_ITEMS_PER_SIDE;
  const bindToRight = pages - 1 - page <= MAX_ITEMS_PER_SIDE;
  const inCenter = !bindToLeft && !bindToRight;
  /**
   *   0    1    2    3    4    5    6    7    8
   *  bindToLeft
   *  (1)
   *  (1)   2
   *  (1)   2    3
   *  (1)   2    3    4
   *  (1)   2    3    4    5
   *
   *  (1)   2    3    4    5    .    11
   *   1   (2)   3    4    5    .    11
   *   1    2   (3)   4    5    .    11
   *                         | items after current page
   *                                   |
   *   1    2    3   (4)   5    6    .    11
   *   1    2    3    4   (5)   6    7    .    11
   *   1    2    3    4   (5)   6    7    8    9
   *
   *   inCenter
   *   1    .    4    5   (6)   7    8    .    11
   *
   *
   *   bindToRight
   *   1    .    4    5   (6)   7    8    9    10
   *
   *   1    .    5    6   (7)   8    9    10   11
   *   1    .    6    7   (8)   9    10   11
   *   1    .    7    8   (9)   10   11
   *   1    .    7    8    9   (10)  11
   *   1    .    7    8    9    10  (11)
   *
   *             1    .    7    8    9    10  (11)
   *             1    .    7    8    9   (10)  11
   *             1    .    7    8   (9)   10   11
   *        1    .    6    7   (8)   9    10   11
   *   1    .    5    6   (7)   8    9    10   11
   *
   *   bindToLeft && bindToRight
   *   1    2    3    4   (5)   6    7    8    9
  **/
  if (smallRange) {
    items = pageRange({ from: 0, to: pages - 1, current: page });
  } else if (bindToLeft) {
    let rangeLength = Math.max((page + 1) + ITEMS_AROUND_CURRENT_PAGE, ITEMS_FOR_BINDED_SIDE);
    rangeLength = Math.min(pages, rangeLength);
    const lastPageBeforeTail = rangeLength - 1;
    items = pageRange({ from: 0, to: lastPageBeforeTail, current: page });
    // tail
    if (items.length < pages) {
      items = [...items, ...tailRight({ from: lastPageBeforeTail + 1, to: lastPageNumber }) ];
    }
  } else if (bindToRight) {
    const fromPage = Math.min(page - ITEMS_AROUND_CURRENT_PAGE, pages - ITEMS_FOR_BINDED_SIDE);
    items = pageRange({ from: fromPage, to: pages -1, current: page });
    items = [...tailLeft(fromPage - 1), ...items];
  } else if (inCenter) {
    items = [...tailLeft(page - 3), ...prev2(page), current(page), ...next2(page), ...tailRight({ from: page + 3, to: pages - 1 })];
  }
  return {
    items,
    nextDisabled,
  };
};
