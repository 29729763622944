import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { logOut } from 'domain/env';
import { resetSettings } from 'domain/settings';
import { links as r } from 'lib/routes';
import injectSheet from 'lib/sheet';
import { userBarStyles } from './styles';

class UserBar extends React.Component {
  state = { closed: true };

  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    userProfile: PropTypes.instanceOf(I.Record),
    logOut: PropTypes.func.isRequired,
    resetSettings: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  }
  
  render(){
    const { classes, userProfile, logOut, resetSettings, intl: { formatMessage } } = this.props;
    const { closed } = this.state;
    return (
      <div className={classes.UserBar}>
        <div className={classes.email}>{ userProfile.get('email') || 'no-email@gmail.com' }</div>
        <div className={classes.ava}/>
        <div
          tabIndex="0"
          className={classes.arrow}
          onClick={() => this.setState({ closed: !closed })}
        >
          <div className={cx(classes.menu, { [classes.hidden]: closed })}>
            <Link
              className={cx(classes.menuItem, classes.profile)}
              to={r.profile}
            >{formatMessage({ id: 'myAcc' })}</Link>
            <button
              className={cx(classes.menuItem, classes.resetSettings)}
              onClick={() => resetSettings()}
            >{formatMessage({ id: 'resetSettings' })}</button>
            <button
              className={cx(classes.menuItem, classes.logOut)}
              onClick={() => logOut()}
            >{formatMessage({ id: 'logOut' })}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(userBarStyles),
  injectIntl,
  connect({ logOut, resetSettings }),
)(UserBar);
