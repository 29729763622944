import styles from 'components/PageTable/sheet';
import colors from 'theme/Colors';

export default {
  ...styles,
  SimilarPage: {
    display: 'flex',
    flexDirection: 'column',
    padding: [20,20],
    height: '100%',
    overflow: 'hidden',
  },
  DataTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageHeader: {
    height: 29,
    fontSize: 24,
    fontWeight: 400,
    marginBottom: 30,
    color: colors.text,
  },
  pageLink: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 30,
    paddingLeft: 25,
    color: colors.labelLink,
    textDecoration: 'none',
    position: 'relative',
    '&:before': {
      content: '""',
      width: 12,
      height: '100%',
      display: 'block',
      position: 'absolute',
      left: 0,
      transform: 'rotate(180deg)',
      background: {
        size: '12px 12px',
        repeat: 'no-repeat',
        position: 'left center',
        image: `url(${require('./arrow.svg')})`,
      },
    },
  },
  bold: {
    fontWeight: 700,
  },
  artistId: {
    flex: '0 0 300px',
  },
  td: {
    ...styles.td,
    textOverflow: 'ellipsis',
  },
  trh: {
    display: 'flex',
  },
  thead: {
    ...styles.thead,
    backgroundColor: colors.bg,
    height: 48,
    borderBottom: `1px solid ${colors.labelLink}88`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    width: 120,
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
  },
  header: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.attention,
    textTransform: 'uppercase',
    paddingTop: 6,
    paddingBottom: 10,
    marginBottom: 7,
    letterSpacing: '1px',
  },
  tr: {
    ...styles.tr,
    height: 94,
  },
  loadWrapper: {
    width: '100%',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowEmpty: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
  },
  defaultImageUrl: {
    paddingLeft: 20,
  },
};
