import style, { root } from 'pages/LoginPage/sheet';
import colors from 'theme/Colors';

export default {
  ...style,
  ResetPasswordPage: root,
  header: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    margin: [0, 0, 20, 0],
  },
  success: {
    fontWeight: 600,
    marginRight: 5,
  },
  link: {
    textDecoration: 'underline',
    color: colors.attention,

  },
  linkHolder: {
    textAlign: 'center',
    marginTop: 20,
  },
};
