import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/HLoader';
import { loadMore } from 'domain/const';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { Pagination } from 'domain/lib';

function TBody({
  classes: { tbody, loadWrapper, bottomLoader, observer },
  loadMore,
  items = new I.List(),
  pagination = new Pagination(),
  tableName,
  children,
  loading,
}) {
  const ref = React.useRef(null);

  const loadMoreCallback = React.useCallback(() => {
    const { page, pages, pageSize, total } = pagination;
    if (page === pages && total % pageSize === items.size ) return;
    if (!loading) loadMore();
  }, [ pagination, items.size, loadMore, loading ]);

  React.useEffect(() => {
    if (!items.size) {
      if (ref.current) ref.current.disconnect();
    } else {
      const { page, pages, pageSize, total } = pagination;
      if (page === pages && total % pageSize === items.size ) return;
      if (items.size && pageSize > items.size) {
        const callback = ([e]) => e.isIntersecting && loadMoreCallback();
        ref.current = new IntersectionObserver(callback);
        ref.current.observe(document.querySelector(`#${tableName}-Bottom`));
      }
    }
    return () => {
      if (ref.current) ref.current.disconnect();
    };
  },[ tableName, items.size, pagination, loadMoreCallback, ref ]);

  return (
    <div id={`${tableName}-Body`} className={tbody}>
      { loading && items.size === 0 ? <div className={loadWrapper}>
        <Loader/>
      </div> : children }
      <div id={`${tableName}-Bottom`} className={observer} />
      { loading && items.size > 0 && <div className={bottomLoader}><Loader/></div> }
    </div>
  );
};

TBody.propTypes = {
  classes: PropTypes.shape({
    tbody: PropTypes.string.isRequired,
    loadWrapper: PropTypes.string.isRequired,
    bottomLoader: PropTypes.string,
  }).isRequired,
  loadMore: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(I.List),
  pagination: PropTypes.instanceOf(I.Record),
  tableName: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired,
};

export default connect({ loadMore })(TBody);
