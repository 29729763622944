import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import { Field, Fields, FieldArray } from 'redux-form';
import { uploadImageAction } from 'domain/common';
import Provenances from 'fields/Provenances';
import Literature from 'fields/Literature';
import Exhibitions from 'fields/Exhibitions';
import RadioButtons from "fields/RadioButtons";
import { compose } from 'redux';
import { dateTimeToString } from 'lib/serialize';
import ClusterValue from 'components/ClusterValue';
import IndicatorLink from 'components/IndicatorLink';
import { getCarLotFieldsConfig } from 'fields/carLotFields';

import {
  commonFields,
  DateField,
  InlineDateField,
  ImageDropField,
  toNumber,
  toPrice,
  yesNoDash,
  toUsdPrice,
  toPriceRange,
  toFloat,
  toNumberOrNull,
  connectAcceptance,
  ppsi,
} from './common';

import injectSheet from 'lib/sheet';
import { artwork, artworkSelectedAction, acceptanceFields, category } from 'domain/artwork';
import { defineColumns } from 'components/PageTable/defineColumns';
import Image from 'components/SmartImage';
import Gallery from 'components/Gallery';
import ImageDropInline from 'components/ImageDropInline';
import Input, { PureInput, sheet as inputSheet } from 'components/form/element/Input';
import { PureTextArea, sheet as textAreaSheet } from 'components/form/element/TextArea';
import Edition, { editionsList } from 'components/Edition';
import { AuthorAutoComplete } from 'filters/common';
import { getLocationName } from 'domain/router';
import Outliers from 'fields/Outliers';
import { CheckBox as PureCheckBox, sheet as checkBoxSheet } from 'components/form/fields/CheckBox/CheckBox';

import { dash, dateToString, dict, dictList, artistNormalize } from 'lib/serialize';

import rules from 'components/form/validation';

import { lnk } from 'lib/routes';

import IconLink from 'components/Link';

import {
  CategoryAutoComplete,
  //MediumAutoComplete,
  SubjectAutoComplete,
  SubjectsInlineAutoComplete,
} from './Autocomplete';
import colors from '../theme/Colors';
import { basicInfo } from 'domain/artwork/fieldRules';
import { defaults } from 'domain/dictionary';

const Artist = ({ page, ...props }) => {
  const artistId = props.input.value;
  if (page === 'artist' || page === 'artworkList' || !artistId) {
    return <AuthorAutoComplete isDisabled={page === 'artist'} {...props} />;
  }
  return <IconLink url={lnk('artist', { artistId })} force target="_blank">
    <AuthorAutoComplete isDisabled={page === 'artist'} {...props} />
  </IconLink>;
};

Artist.propTypes = {
  page: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  }),
};

const ArtistAutocomplete = connect({ page: getLocationName })(Artist);

const TextArea = injectSheet({ ...textAreaSheet, wrapper: { width: '100%', height: 60 } })(PureTextArea);

const yearRange = key => ({ rowData: { [`${key}From`]: from, [`${key}To`]: to } }) => [from, to]
  .filter(v => !!v)
  .join(' - ');

const Years = () => [
  <Field
    key="yearCreatedFrom"
    name="yearCreatedFrom"
    validate={[ rules.year, rules.artworkYearCreated, rules.artworkYearCreatedFrom ]}
    component={DateField}
    maxLength="4"
    normalize={v => v ? parseInt(v, 10) : v}
  />,
  <Field
    key="yearCreatedTo"
    name="yearCreatedTo"
    maxLength="4"
    component={DateField}
    validate={[ rules.year, rules.artworkYearCreated ]}
    normalize={v => v ? parseInt(v, 10) : v}
  />,
];

const conceptionDate = () => [
  <Field
    key="conceptionDateFrom"
    name="conceptionDateFrom"
    validate={[ rules.year ]}
    normalize={v => v ? parseInt(v, 10) : v}
    component={DateField}
    maxLength="4"
  />,
  <Field
    key="conceptionDateTo"
    name="conceptionDateTo"
    maxLength="4"
    component={DateField}
    validate={[ rules.year ]}
    normalize={v => v ? parseInt(v, 10) : v}
  />,
];

const SizeInput = injectSheet({
  ...inputSheet,
  wrapper: { ...inputSheet.wrapper, width: 60 },
})(PureInput);

const Size = () => <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
  <Field
    key="physicalSizeHeight"
    name="physicalSizeHeight"
    maxLength="4"
    component={SizeInput}
    disabled
  />
  <Field
    key="physicalSizeWidth"
    name="physicalSizeWidth"
    maxLength="4"
    component={SizeInput}
    disabled
  />
  <Field
    key="physicalSizeDepth"
    name="physicalSizeDepth"
    maxLength="4"
    component={SizeInput}
    disabled
  />
  <Field
    key="physicalSizeUnits"
    name="physicalSizeUnits"
    maxLength="4"
    component={SizeInput}
    disabled
  />
</div>;

let ArtworkLink = ({ value, classes, modalMode, artworkSelected }) => {
  if (!value) return null;
  if (modalMode) {
    return <button
      type="button"
      className={classes.link}
      children={value}
      title={value}
      onClick={() => artworkSelected(value)}
    />;
  }
  return <Link
    className={classes.link}
    to={lnk('artwork', { artworkId: value })}
    children={value}
  />;
};

ArtworkLink.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
  modalMode: PropTypes.string,
  artworkSelected: PropTypes.func.isRequired,
};

ArtworkLink = connect({ artworkSelected: artworkSelectedAction })(ArtworkLink);

let ArtworkLinkImage = ({
  value,
  rowData: { id: artworkId },
  classes: { link },
  modalMode,
  artworkSelected,
}) => {
  const img = <Image src={value} params={{ width: 80, height: 80 }} />;
  if (!artworkId) return img;
  if (modalMode) {
    return (
      <button
        type="button"
        className={link}
        children={img}
        onClick={() => artworkSelected(artworkId)}
      />
    );
  }
  return (
    <Link
      className={link}
      to={lnk('artwork', { artworkId })}
      children={img}
    />
  );
};

ArtworkLinkImage.propTypes = {
  value: PropTypes.string,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
  modalMode: PropTypes.string,
  artworkSelected: PropTypes.func.isRequired,
};
ArtworkLinkImage = connect({
  artworkSelected: artworkSelectedAction,
})(ArtworkLinkImage);

const ArtistLink = ({ rowData: { artist } }) => artist.id ?
  <IndicatorLink
    to={lnk('artist', { artistId: artist.id })}
    status={artist.wfAcceptance}
    children={artistNormalize(artist)}
  /> : null;

ArtistLink.propTypes = {
  artistId: PropTypes.instanceOf(I.Map),
  rowData: PropTypes.shape({
    artistId: PropTypes.number.isRequired,
  }).isRequired,
};

const renderImage = ({ rowData: { defaultImageUrl } }) => <Image container="img" src={defaultImageUrl} params={{ width: 230, height: 200 }} />;

renderImage.propTypes = {
  rowData: PropTypes.shape({
    defaultImageUrl: PropTypes.string,
  }).isRequired,
};

const renderGallery = ({ rowData: { defaultArtImage, artImages } }) => <Gallery
  mainId={defaultArtImage}
  images={artImages}
/>;

renderGallery.propTypes = {
  rowData: PropTypes.shape({
    defaultArtImage: PropTypes.string,
    artImages: PropTypes.instanceOf(I.List),
  }).isRequired,
};

const ArtworkImage = connect({
  onUpload: uploadImageAction,
  object: artwork,
})(ImageDropField);

const ArtworkImageInline = ({ rowData: { defaultImageUrl } }) => <Fields
  names={['images', 'defaultArtImage']}
  imagesName="images"
  defaultName="defaultArtImage"
  component={ImageDropInline}
  src={defaultImageUrl}
/>;

ArtworkImageInline.propTypes = {
  rowData: PropTypes.shape({
    defaultImageUrl: PropTypes.string,
  }).isRequired,
};

const CheckBox = injectSheet({
  CheckBox: {
    ...checkBoxSheet.CheckBox,
    border: `1px solid ${ colors.fieldBorder }`,
  },
})(PureCheckBox);

const checkAndRadioStyles = (container) => ({
  [container]: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  checkBox: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 32,
    marginRight: 15,
    alignItems: 'center',
  },
  label: {
    textTransform: 'capitalize',
    marginLeft: 5,
  },
});

const StampPure = ({ category, classes, input }) => {
  const { onChange, value = '', name } = input;
  let values = ['signature','studio','estate'];
  if (category === '3D Edition') values.push('foundry');
  const onCheck = v => {
    if (value.includes(v)){
      onChange(value.split(',').filter(val => !!val && val !== v).join(','));
    } else {
      onChange(value.split(',').concat(v).filter(val => !!val).join(','));
    }
  };
  return (
    <div className={classes.Stamp}>
      { values.map(v => <div key={v} className={classes.checkBox}>
        <CheckBox name={`${name}[]`} value={value.includes(v)} onChange={() => onCheck(v)} />
        <div className={classes.label}>{v}</div>
      </div> )}
    </div>
  );
};

const BoolCheckboxPure = ({ classes, input, props }) => {
  const { onChange, value, name } = input;
  const onCheck = () => {
    console.log('BoolCheckboxPure onChange', value);
    onChange(value ? false : true);
  };
  return (
    <div className={classes.BoolCheckbox}>
      <div key={name} className={classes.checkBox}>
        <CheckBox name={name} value={value} onChange={onCheck} />
      </div>
    </div>
  );
};

StampPure.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  category: PropTypes.string,
};

const Stamp = compose(
  connect({ category }),
  injectSheet(checkAndRadioStyles('Stamp')),
)(StampPure);

const BoolCheckbox = compose(
  injectSheet(checkAndRadioStyles('BoolCheckbox')),
)(BoolCheckboxPure);

const ProvenanceFormsFn = (props) => <FieldArray name="provenances" component={Provenances} fieldProps={props} />;
const LiteratureFn = (props) => <FieldArray name="literature" component={Literature} fieldProps={props} />;
const ExhibitionsFn = (props) => <FieldArray name="exhibitions" component={Exhibitions} fieldProps={props} />;

const SizePure = ({ category, text, classes, ...props }) => {
  if (!category.includes('3D')) return <Input {...props}/>;
  return <div className={classes.AdvancedSize}>
    <Input {...props} />
    <div className={classes.text} children={text}/>
  </div>;
};

SizePure.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
  category: PropTypes.string,
};

const AdvancedSize = compose(
  connect({ category }),
  injectSheet({
    AdvancedSize: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      flexShrink: 0,
      marginLeft: 10,
      whiteSpace: 'nowrap',
      fontSize: '12px',
    },
  }),
)(SizePure);

const displayEdition = ({ value, rowData = {} }) => {
  if (!value) return null;
  let additionalText = '';
  switch (value) {
    case 'numbered':
      additionalText = rowData.numberedNo && rowData.numberedOf ? `(no. ${rowData.numberedNo} of ${rowData.numberedOf})` : '';
      break;
    default:
      additionalText = rowData[value] ? `(${rowData[value]})` : '';
  };
  return editionsList.find(v => v.get('id') === value).get('title') + ' ' + additionalText;
};

const displaySize = key => ({ key: name, rowData: {
  [`${key}Height`]: h,
  [`${key}Width`]: w,
  [`${key}Depth`]: d,
  [`${key}Units`]: unit,
  category: categoryId = 2,
} }) => {
  const category = defaults.categories.find(v => v.id === categoryId).title;
  if (!basicInfo[category].includes(`${name}Raw`)) return null;
  if (!unit) return null;
  return [h, w, d].filter(v => !!v).join(' x ').concat(' ', unit.toLowerCase());
};

const mapArrayValue = (value = []) => value.map(({ name }) => name).join(', ');
const getArrayValue = ({ value }) => mapArrayValue(value);

const getProvenance = ({ value = [], countries, key, title, fieldPath, columnDef, ...props }) => {
  if (!value) return null;
  if (typeof value === 'string') return value;
  if ((value.length | 0) === 0 && (value.size | 0) === 0) return null;
  return <div>
    { value.map(({ id, owner, yearFrom, yearTo, country, city }) => <div key={id}>
      <div>{ owner }</div>
      <div>{ [yearFrom, yearTo].filter(v => !!v).join(' - ') } </div>
      <div>{ [countries.getIn([country, 'title']), city].filter(v => !!v).join(', ') }</div>
    </div> )}
  </div>;
};

getProvenance.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      owner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      yearFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      yearTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      city: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }))
  ]),
  countries: PropTypes.object,
};

const Customer = ({ value: { id }, classes: { link } }) => {
  if (!id) return null;
  return (
    <Link className={link} to={lnk('customer', { id })} target="_blank" children={id}/>
  );
};

Customer.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
  }),
  classes: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
};

const Customers = ({ value, rowData: { id }, classes: { link } }) => {
  if (!value) return null;
  return value ? <Link className={link} to={lnk('customerList')+`?artwork=${id}`} target="_blank">
    { value }
  </Link> : null;
};

Customers.propTypes = {
  value: PropTypes.number,
  rowData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
};

export const config = {
  defaultImageUrl: {
    inlineEdit: true,
    getValue: ArtworkLinkImage,
    inLineFieldRenderer: ArtworkImageInline,
    getFieldValue: ({ images, defaultArtImage }) => ({ images, defaultArtImage }),
  },
  defaultArtImage: {
    hiddenForTable: true,
    component: ArtworkImage,
    getValue: renderGallery,
  },
  category: {
    sortable: true,
    getValue: dict(),
    component: CategoryAutoComplete,
    fieldProps: { validate: [rules.required] },
  },
  medium3d: {
    getValue: dictList(),
    //component: MediumAutoComplete,
    //fieldProps: { isMulti: true, validate: [rules.required] },
  },
  title: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [rules.required] },
  },
  medium: { className: 'td200', sortable: true, inlineEdit: true },
  subjects: {
    title: 'subject',
    inlineEdit: true,
    getValue: dictList(),
    component: SubjectAutoComplete,
    inLineComponent: SubjectsInlineAutoComplete,
  },
  year: { hiddenForTable: true, formFieldRenderer: Years, getValue: yearRange('yearCreated') },
  size: { hiddenForTable: true, formFieldRenderer: Size }, // not used
  yearCreatedFrom: {
    sortable: true,
    component: InlineDateField,
    inlineEdit: true,
    getValue: dash,
    className: 'year',
    fieldProps: {
      validate: [rules.year, rules.artworkYearCreated, rules.artworkYearCreatedFrom],
    },
  },
  yearCreatedTo: {
    sortable: true,
    component: InlineDateField,
    inlineEdit: true,
    getValue: dash,
    className: 'year',
    fieldProps: {
      validate: [rules.year, rules.artworkYearCreated],
    },
  },
  signature: { sortable: true, inlineEdit: true },
  isSignature: { sortable: true },
  id: {
    sortable: true,
    className: 'id',
    getValue: ArtworkLink,
    parser: ({ value }) => value === undefined ? '' : value,
  },
  artistIdId: { sortable: true, getValue: ({ rowData }) => rowData.get('artistId') },
  artistId: {
    getValue: ArtistLink,
    component: ArtistAutocomplete,
    inlineEdit: true,
    sortable: true,
    fieldProps: { validate: [rules.required] },
  },
  abvQualityBenchmark: {
    sortable: true,
    getValue: ({ value }) => value ? parseFloat(value) > 0 ? `+${Math.floor(value)}` : Math.ceil(value) : 'NA'
  },
  surfaces: { getValue: dictList() },
  substrates: { getValue: dictList() },
  surfaceValues: { getValue: dictList() },
  substrateValues: { getValue: dictList() },
  primarySurfaceRank: { sortable: true, getValue: toNumberOrNull },
  primarySubstrateRank: { sortable: true, getValue: toNumberOrNull },
  physicalSizeWidth: { getValue: toFloat, sortable: true },
  physicalSizeHeight: { getValue: toFloat, sortable: true },
  physicalSizeDepth: { getValue: toFloat, sortable: true },
  physicalSizeRaw: {
    component: AdvancedSize,
    fieldProps: { text: 'height required only' },
    sortable: true,
    inlineEdit: true,
    required: true,
  },
  physicalSizeUnits: {},
  area: { sortable: true, getValue: toFloat },
  carValue: { getValue: toFloat, title: 'aoCar', inlineEdit: true, sortable: true },
  currentCarValue: { getValue: toFloat },
  auctionHouseLast: { getValue: dict(), sortable: true },
  lastSaleNumber: { sortable: true, sortByName: 'lastSaleNumberValue' },
  lastLotNumber: { sortable: true, sortByName: 'lastLotNumberValue' },
  auctionDateLast: { sortable: true, getValue: dateToString('MMM DD, YYYY', '−') },

  estimatePriceLast: { getValue: toPriceRange('estimatePriceStartLast', 'estimatePriceEndLast') },
  lastLotCurrency: { getValue: dict() },
  estimatePriceStartLast: { className: 'price', getValue: toPrice },
  estimatePriceEndLast: { className: 'price', getValue: toPrice },
  lastAlertAbv: {
    className: 'price',
    sortable: true,
    getValue: ({ value }) => value ? `$${ toNumber({ value }) }` : '−',
  },
  epLastUsd: { getValue: toPriceRange('epStartLastUsd', 'epEndLastUsd', { currency: 'USD' }) },
  epStartLastUsd: { sortable: true, className: 'price', getValue: toNumber },
  epEndLastUsd: { sortable: true, className: 'price', getValue: toNumber },
  abvLastSoldPrice: { sortable: false, className: 'price', getValue: toNumber },

  spLast: { getValue: toPrice, className: 'price' },
  spLastUsd: { sortable: true, getValue: toNumber, className: 'price' },
  costPerSquareWithCar: { sortable: true, getValue: ppsi },

  rtvLast: { sortable: true, getValue: toUsdPrice, className: 'price' },
  rtvDate: { sortable: true, getValue: dateToString('MM/DD/YYYY h:mm a', '−') },

  catalogRaisonne: { component: TextArea, className: 'td200' },
  lastExhibition: { className: 'td200' },
  lastLiterature: { className: 'td200' },
  lastProvenance: { className: 'td200' },
  // literature: {
  //   fieldPath: ['lastLiterature'],
  //   hiddenForTable: true,
  //   formFieldRenderer: LiteratureFn,
  //   getValue: ({ value }) => {
  //     if (typeof value === 'string') return value;
  //     if (!value || !value.map) return null;
  //     return value.map(v => v.name).join('\n');
  //   },
  // },
  literature: { hiddenForTable: true, formFieldRenderer: LiteratureFn, getValue: getArrayValue },
  exhibitions: { hiddenForTable: true, formFieldRenderer: ExhibitionsFn, getValue: getArrayValue },
  // exhibitions: {
  //   // fieldPath: ['lastExhibition'],
  //   hiddenForTable: true,
  //   formFieldRenderer: ExhibitionsFn,
  //   getValue: val => (val && (val.size || val.length)) ? val.reduce((prev, v) => [...prev, v.get ? v.get('name') || v.name : v], []) : null,
  // },
  lastAcceptedAndDisplaySoldPriceUsd: {
    getValue: ({ value }) => value ? `$${ toNumber({ value }) }` : '−',
  },
  // lastAcceptedAndDisplayLotDate: { getValue: dateToString('MMM DD, YYYY', '−') },
  lastAcceptedAndDisplaySoldPriceUsdWithCar: {
    getValue: ({ value }) => value ? `(CAR) $${ toNumber({ value }) }` : '−',
  },
  // provenances: {
  //   fieldPath: ['lastProvenance'],
  //   hiddenForTable: true,
  //   formFieldRenderer: ProvenanceFormsFn,
  //   getValue: getProvenance,
  // },
  provenances: {
    hiddenForTable: true,
    getValue: getProvenance,
    formFieldRenderer: ProvenanceFormsFn,
  },
  usernameLastUpdate: { sortable: true, getValue: ({ value }) => value || '−' },
  outliers: {
    sortable: true,
    formFieldRenderer: () => <Outliers/>,
    visibleInValueList: ({ formData = {}, isEdit, rowData = {} }) =>
      isEdit ? !!formData.outliers : rowData.outliers,
  },
  imageSizeRaw: {
    getValue: ({ rowData: { physicalSizeRaw } }) => physicalSizeRaw || '−',
    fieldPath: ['physicalSizeRaw'],
    fieldProps: {
      name: 'physicalSizeRaw',
      // validate: (value, values, params, ...args) => {
      //   console.log({ value, values, params, args });
      //   // return { id: 'required' };
      //   return undefined;
      // },
    },
  },
  sheetSizeRaw: {},
  conceptionDate: { formFieldRenderer: conceptionDate, getValue: yearRange('conceptionDate') },
  inscription: {},
  studio: { sortable: true },
  authenticityLetter: {},
  foundry: {},
  stamps: { component: Stamp, getValue: ({ value = '' }) => value.split(',').filter(v => !!v).join(', ') },
  useCarPrice: { component: BoolCheckbox, getValue: ({ value }) => value ? 'Yes' : 'No' },
  edition: { formFieldRenderer: () => <Edition/>, getValue: displayEdition },
  printer: {},
  publisher: {},
  plate: {},
  physicalSize: { hiddenForTable: true, getValue: displaySize('physicalSize') },
  imageSize: { hiddenForTable: true, getValue: displaySize('physicalSize') },
  sheetSize: { hiddenForTable: true, getValue: displaySize('sheetSize') },
  aeRtv: { hiddenForTable: true, getValue: toUsdPrice, className: 'price' },
  defaultRtv: { hiddenForTable: true, getValue: toUsdPrice, className: 'price' },
  customer: {
    hiddenForTable: true,
    getValue: Customer,
  },
  customers: {
    hiddenForTable: true,
    getValue: Customers,
  },
  artistIdWfAcceptance: {
    sortByName: 'artistId__wfAcceptance',
    fieldPath: ['artist','wfAcceptance'],
    sortable: true,
  },
  abvMetricsManifold: { getValue: yesNoDash, sortable: true },
  abvMetricsMediums: { getValue: yesNoDash, sortable: true },
  abvMetricsArea: { getValue: yesNoDash, sortable: true },
  abvMetricsPpsi: { getValue: yesNoDash, sortable: true },
  lastGuarantee: {
    getValue: ({ value }) => !!value ? 'Yes' : 'No',
    sortable: true,
    className: 'number',
  },
  countOfLots: { sortable: true, className: 'price' },
  artistShowRtv: {
    sortByName: 'artistId__showRtv',
    sortable: true,
    className: 'number',
    getValue: ({ rowData }) => rowData?.artist.showRtv ? 'Yes' : 'No',
  },
  hasSimilarImages: { 
    formFieldRenderer: ({rowData}) => <RadioButtons rowData={rowData} name={'hasSimilarImages'}/>,
    sortable: true,
    className: 'price',
    getValue: ({rowData}) => rowData?.hasSimilarImages ? 'Yes' : 'No',
   },
  hasRepeatSales: { 
    formFieldRenderer: ({rowData}) => <RadioButtons rowData={rowData} name={'hasRepeatSales'}/>,
    sortable: true,
    className: 'price',
    getValue: ({rowData}) => rowData?.hasRepeatSales ? 'Yes' : 'No',
  },
  artImageUrl: {
    getValue: ({ value }) => <a href={value} target="_blank" rel="noopener noreferrer">{ value }</a>
  },
  firstAcceptedSoldPriceUsd: { getValue: toUsdPrice, className: 'price' },
  firstAcceptedLotDate: { className: 'td200', getValue: dateToString('MMM DD, YYYY', '−') },
  auctionCar: { getValue: ({ value }) => !!value ? toFloat({ value }) + '%' : '−' },
  auctionInterval: { getValue: ({ value }) => !!value ? value + ' yrs' : '−' },
  lowCarAbv: { getValue: toFloat, sortable: true },
  middleCarAbv: { getValue: toFloat, sortable: true },
  highCarAbv: { getValue: toFloat, sortable: true },
  firstAuctionEstimateUsd: { className: 'td200' },
  firstAuctionPriceUsd: { className: 'td200' },
  firstAuctionDate: { getValue: dateToString('MMM DD, YYYY', '−'), className: 'td200' },
  firstAuctionHouse: { className: 'td200' },
  lowSoldPriceUsdWithCar: { getValue: toFloat, sortable: true },
  middleSoldPriceUsdWithCar: { getValue: toFloat, sortable: true },
  highSoldPriceUsdWithCar: { getValue: toFloat, sortable: true },
  clusterNumber: {
    getValue: ({ rowData: { id, clusterNumber }}) => <ClusterValue id={id} clusterNumber={clusterNumber} />,
    sortable: true,
  },
  additionalInfo: {
    inlineEdit: false,
    fieldPath: ['notes'],
  },
  // alertAbv: { getValue: toFloat },
  alertHighAbv: { getValue: toFloat },
  alertMiddleAbv: { getValue: toFloat },
  alertLowAbv: { getValue: toFloat },
  highEstimate: { getValue: toFloat },
  middleEstimate: { getValue: toFloat },
  lowEstimate: { getValue: toFloat },
  ...commonFields,
  createdDateYear: {
    fieldPath: ['createdDate'],
    sortable: true,
    className: 'date',
    getValue: dateTimeToString('YYYY', '−'),
    parser: dateTimeToString(),
  },
  // prefix used for translate column names
  ...getCarLotFieldsConfig({ fieldPathPrefix: ['auctionCarLotList', 1], prefix: 'carLot0__' }),
  ...getCarLotFieldsConfig({ fieldPathPrefix: ['auctionCarLotList', 2], prefix: 'carLot1__' }),
  ...getCarLotFieldsConfig({ fieldPathPrefix: ['auctionCarLotList', 3], prefix: 'carLot2__' }),
  ...getCarLotFieldsConfig({ fieldPathPrefix: ['auctionCarLotList', 4], prefix: 'carLot3__' }),
  ...getCarLotFieldsConfig({ fieldPathPrefix: ['auctionCarLotList', 5], prefix: 'carLot4__' }),
};

export const columns = defineColumns(
  // set `required: true for field configs by [key: string] from Array<string>`
  connectAcceptance(config, acceptanceFields),
);

export const defaultColumns = [
  'defaultImageUrl',
  'category',
  'sourceType',
  'artistId',
  'title',
  'medium',
  'yearCreatedFrom',
  'signature',
  'physicalSizeRaw',
  'abvQualityBenchmark',
];

// defaultImageUrl,defaultArtImage,category,medium3d,title,medium,subjects,year,size,yearCreatedFrom,yearCreatedTo,signature,isSignature,id,artistIdId,artistId,abvQualityBenchmark,surfaces,substrates,surfaceValues,substrateValues,primarySurfaceRank,primarySubstrateRank,physicalSizeWidth,physicalSizeHeight,physicalSizeDepth,physicalSizeRaw,physicalSizeUnits,area,carValue,currentCarValue,auctionHouseLast,lastSaleNumber,lastLotNumber,auctionDateLast,estimatePriceLast,lastLotCurrency,estimatePriceStartLast,estimatePriceEndLast,lastAlertAbv,epLastUsd,epStartLastUsd,epEndLastUsd,spLast,spLastUsd,costPerSquareWithCar,rtvLast,rtvDate,catalogRaisonne,lastExhibition,lastLiterature,lastProvenance,literature,exhibitions,lastAcceptedAndDisplaySoldPriceUsd,lastAcceptedAndDisplayLotDate,lastAcceptedAndDisplaySoldPriceUsdWithCar,provenances,usernameLastUpdate,outliers,imageSizeRaw,sheetSizeRaw,conceptionDate,inscription,studio,authenticityLetter,foundry,stamps,edition,printer,publisher,plate,physicalSize,imageSize,sheetSize,aeRtv,defaultRtv,customer,customers,artistIdWfAcceptance,abvMetricsManifold,abvMetricsMediums,abvMetricsArea,abvMetricsPpsi,lastGuarantee,countOfLots,artistShowRtv,hasSimilarImages,hasRepeatSales,artImageUrl,firstAcceptedSoldPriceUsd,firstAcceptedLotDate,auctionCar,auctionInterval,lowCarAbv,middleCarAbv,highCarAbv,firstAuctionEstimateUsd,firstAuctionPriceUsd,firstAuctionDate,firstAuctionHouse,lowSoldPriceUsdWithCar,middleSoldPriceUsdWithCar,highSoldPriceUsdWithCar,clusterNumber,alertHighAbv,alertMiddleAbv,alertLowAbv,highEstimate,middleEstimate,lowEstimate,notes,sourceType,createdDate,updatedDate,acceptedDate
const columnSections = [
  {
    title: 'AO',
    columns: [
      'category', // CATEGORY
      'artistId', // ARTIST
      'title', // TITLE
      'defaultImageUrl', // IMAGE
      'artImageUrl',
      'medium', // MEDIUM
      'surfaces', // SURFACES
      'substrates', // SUBSTRATES
      'yearCreatedFrom', // YEAR FROM
      'yearCreatedTo', // YEARS TO
      'imageSize', // IMAGE SIZE !! не выводится (physical_size_width physical_size_height)
      'physicalSizeUnits', // UNIT
      'area', // AREA
      'isSignature', // SIGNED
      // 'lastAcceptedAndDisplayLotDate', // LAST SOLD DATE (учитывая статус лота)
      'auctionDateLast', // AUCTION DATE (не учитывая статус лота)
      'auctionHouseLast', // AUCTION (не учитывая статус)
      'lastSaleNumber', // 'SALE#' (не учитывая статус)
      'lastLotCurrency', // 'CURRENCY'
      'lastLotNumber', // LOT #
      'estimatePriceLast', // ESTIMATE middleEstimate|estimatePriceLast
      'estimatePriceStartLast', // ESTIMATE LOW
      'estimatePriceEndLast', // ESTIMATE HIGH
      'epLastUsd', // ESTIMATE $
      'epStartLastUsd', // ESTIMATE LOW $
      'epEndLastUsd', // ESTIMATE HIGH $
      'spLast', // SOLD PRICE
      'spLastUsd', // SOLD PRICE $
      'lastAcceptedAndDisplaySoldPriceUsd', // LAST SOLD PRICE
      'abvLastSoldPrice', // SOLD$ (CAR)
      // 'lastAcceptedAndDisplaySoldPriceUsdWithCar', // LAST SOLD PRICE (CAR)
      'costPerSquareWithCar', // PPSI abvMetricsPpsi|costPerSquareWithCar !! Спросить Диму
      'currentCarValue' // CURRENT CAR
    ],
  },
  {
    title: 'ADDITIONAL',
    columns: [
      'artistIdId', // ARTIST ID
      'artistIdWfAcceptance', // ARTIST STATUS
      'artistShowRtv', // 'ABV ARTIST'
      'createdDate', // 'CREATED',
      'acceptedDate', // 'ACCEPTED',
      'updatedDate', // 'UPDATED',
      'sourceType', // 'SOURCE',
      'usernameLastUpdate', // 'DATA TEAM',
      // 'IMAGE URL', // show art_image_url as text
      'subjects', // 'SUBJECT',
      'surfaceValues', // 'SURFACE CHILD',
      'substrateValues', // 'SUBSTRATE CHILD',
      'primarySurfaceRank', // 'SURFACE RANK',
      'primarySubstrateRank', // 'SUBSTRATE RANK',
      'physicalSizeRaw', // 'SIZE RAW',
      'physicalSizeHeight', // 'HEIGHT',
      'physicalSizeWidth', // 'WIDTH',
      'physicalSizeDepth', // 'DEPTH',
      'countOfLots', // 'LOTS COUNT',
      'signature', // 'SIGNATURE',
      'hasSimilarImages', // 'Similar AOs',
      'hasRepeatSales', // 'Repeat Sale',
      'lastProvenance', // 'PROVENANCE',
      'lastLiterature', // 'LITERATURE',
      'lastExhibition', // 'EXHIBITION',
      'catalogRaisonne', // 'CATALOG RAISONNE',
      'additionalInfo', // 'INFORMATION',
      'outliers', // 'OUTLIERS',
      'lastGuarantee', // 'GUARANTY',
      'clusterNumber', // Cluster #
    ],
  },
  {
    title: 'ABV',
    columns: [
      'lastAlertAbv', // ALERT ABV alertAbv|lastAlertAbv
      'abvQualityBenchmark', // ABV quality %
      'rtvLast', // WEB ABV
      'highSoldPriceUsdWithCar', // HIGH CAR $
      'middleSoldPriceUsdWithCar', // MIDDLE CAR $
      'lowSoldPriceUsdWithCar', // LOW CAR $
      'highCarAbv', // HIGH ABV alertHighAbv|highCarAbv
      'middleCarAbv', // MIDDLE ABV alertMiddleAbv|middleCarAbv
      'lowCarAbv', // LOW ABV alertLowAbv|lowCarAbv
      'highEstimate', // HIGH EST+BP
      'middleEstimate', // MEDIAN EST+BP
      'lowEstimate', // LOW EST+BP
      'abvMetricsArea', // AREA ABV
      'abvMetricsPpsi', // PPSI FILTER
      'abvMetricsManifold', // IMAGE ABV
      'abvMetricsMediums', // MEDIUM ABV
      'rtvDate', // WEB ABV UPDATED
      'carValue', // CUSTOM AO CAR
    ],
  },
  {
    title: 'EDITIONS',
    columns: [
      'medium3d', // 3D MEDIUMS
      'printer', // PRINTER
      'publisher', // PUBLISHER
      'stamps', // STAMP
      'edition', // EDITION
      'authenticityLetter', // LETTER OF AUTHENTICITY
      'foundry', // FOUNDRY
      'inscription', // INSCRIPTION
      'studio', // STUDIO
      'sheetSizeRaw', // SHEET SIZE
      'conceptionDate', // CONCEPTION DATE
    ],
  },
  {
    title: 'LOT_CAR',
    columns: [
      'carLot0__auctionDate',
      'carLot0__auctionId',
      'carLot0__auctionPriceUsd',
      'carLot0__auctionInterval',
      'carLot0__auctionCar',
      'carLot0__highEstimateUsdPlusBp',
      'carLot0__medianEstimateUsdPlusBp',
      'carLot0__lowEstimateUsdPlusBp',
      'carLot0__estimateDiapasonUsd',
      'carLot1__auctionDate',
      'carLot1__auctionId',
      'carLot1__auctionPriceUsd',
      'carLot1__auctionInterval',
      'carLot1__auctionCar',
      'carLot1__highEstimateUsdPlusBp',
      'carLot1__medianEstimateUsdPlusBp',
      'carLot1__lowEstimateUsdPlusBp',
      'carLot1__estimateDiapasonUsd',
      'carLot2__auctionDate',
      'carLot2__auctionId',
      'carLot2__auctionPriceUsd',
      'carLot2__auctionInterval',
      'carLot2__auctionCar',
      'carLot2__highEstimateUsdPlusBp',
      'carLot2__medianEstimateUsdPlusBp',
      'carLot2__lowEstimateUsdPlusBp',
      'carLot2__estimateDiapasonUsd',
      'carLot3__auctionDate',
      'carLot3__auctionId',
      'carLot3__auctionPriceUsd',
      'carLot3__auctionInterval',
      'carLot3__auctionCar',
      'carLot3__highEstimateUsdPlusBp',
      'carLot3__medianEstimateUsdPlusBp',
      'carLot3__lowEstimateUsdPlusBp',
      'carLot3__estimateDiapasonUsd',
      'carLot4__auctionDate',
      'carLot4__auctionId',
      'carLot4__auctionPriceUsd',
      'carLot4__auctionInterval',
      'carLot4__auctionCar',
      'carLot4__highEstimateUsdPlusBp',
      'carLot4__medianEstimateUsdPlusBp',
      'carLot4__lowEstimateUsdPlusBp',
      'carLot4__estimateDiapasonUsd',
    ],
  },
];

export const tableParams = { columns, defaultColumns, columnSections };

export default columns;
