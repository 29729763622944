import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export function DraggableComponent(WrappedComponent){
  class Draggable extends React.Component {
    static propTypes = {
      connectDragSource: PropTypes.func.isRequired,
      isDragging: PropTypes.bool.isRequired,
      connectDragPreview: PropTypes.func.isRequired,
      classes: PropTypes.shape({
        dragRoot: PropTypes.string.isRequired,
        drag: PropTypes.string.isRequired,
      }),
    };

    render() {
      const { isDragging, connectDragSource, classes, ...props } = this.props;
      return connectDragSource(
        <div className={cx(classes.dragRoot, { [classes.drag]: isDragging })}>
          <WrappedComponent {...props} />
        </div>,
      );
    }
  }
  return Draggable;
}

export function DroppableComponent(WrappedComponent) {
  class Droppable extends React.Component {
    static propTypes = {
      connectDropTarget: PropTypes.func.isRequired,
      isOver: PropTypes.bool.isRequired,
      canDrop: PropTypes.bool.isRequired,
      classes: PropTypes.shape({
        dropRoot: PropTypes.string.isRequired,
        dragOver: PropTypes.string.isRequired,
        canDrop: PropTypes.string.isRequired,
      }),
    };

    render() {
      const { connectDropTarget, canDrop, isOver, classes, ...props } = this.props;
      return connectDropTarget(
        <div className={cx(
          classes.dropRoot,
          {
            [classes.dragOver]: isOver && canDrop,
            [classes.canDrop]: !isOver && canDrop,
          },
        )}>
          <WrappedComponent {...props} />
        </div>,
      );
    }
  }
  return Droppable;
}
