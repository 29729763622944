import colors from 'theme/Colors';
import common from 'popups/sheet';
import tClasses from 'components/PageTable/sheet';

export default {
  root: {},
  ...common,
  ...tClasses,
  DataTable: {
    width: '100%',
    overflowY: 'auto',
  },
  loadWrapper: {
    paddingTop: 100,
    marginLeft: '50%',
    height: 100,
  },
  td: {
    ...tClasses.td,
    width: 65,
  },
  th: {
    ...tClasses.th,
    width: 65,
  },
  trh: {
    ...tClasses.trh,
    backgroundColor: colors.popupBg,
    borderBottom: `2px solid ${colors.fieldBorder}`,
  },
  tr: {
    ...tClasses.tr,
    display: 'flex',
    flex: '1 0 auto',
    height: 48,
    lineHeight: '48px',
    backgroundColor: colors.bg,
    cursor: 'pointer',
  },
  search: {
    width: 300,
    height: 32,
    border: `1px solid ${colors.fieldBorder}`,
    marginRight: 20,
    padding: [8,20,8,36],
    fontSize: 14,
    display: 'inline-block',
    background: {
      color: colors.bg,
      image: `url("${require('./ic-search.svg')}")`,
      repeat: 'no-repeat',
      position: '10px center',
      size: '20px 20px',
    },
  },
  close: {
    width: 16,
    height: 16,
    outline: 'none',
    cursor: 'pointer',
    position: 'absolute',
    left: 278,
    top: 8,
    border: 'none',
    padding: 0,
    background: {
      color: colors.bg,
      image: `url("${require('./close.svg')}")`,
      repeat: 'no-repeat',
      position: 'center center',
      size: '16px 16px',
    },
  },
  searchText: {
    color: colors.labelLink,
    fontStyle: 'italic',
    fontSize: 12,
    fontWeight: 300,
    display: 'inline-block',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    flex: '0 0 32px',
  },
  option: {
    width: 50,
    height: 48,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  id: {
    width: 80,
    pointerEvents: 'none',
  },
  fullName: {
    width: 170,
  },
  ao: {
    width: 60,
    justifyContent: 'flex-end',
  },
  residences: {
    width: 170,
  },
  noResults: {
    height: 28,
    margin: [20,0],
  },
  rowEmpty: {
    width: '100%',
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '26px',
    fontSize: 18,
    color: colors.labelLink,
    textAlign: 'center',
  },
  tdOption: {
    width: 32,
    display: 'flex',
  },
};
