import { action } from 'lib/actions';
import { field } from 'cpcs-reconnect';

export const updateNavigation = action('navigation/update');

export const navigation = field('navigation');

export const reducer = {
  navigation(state = {}, action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case '@@router/LOCATION_CHANGE':
        return {};

      case updateNavigation.type:
        return action.payload;

      default:
        return state;
    }
  },
};
