import React from 'react';
import PropTypes from 'prop-types';

class OutSideDetector extends React.Component {

  static propTypes = {
    onDetect: PropTypes.func.isRequired,
    children: PropTypes.node,
  }

  node = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.node && !this.node.contains(event.target)) {
      this.props.onDetect();
    }
  }

  render() {
    // istanbul-ignore-next
    const { children, onDetect, ...props }  = this.props; //NOSONAR
    return (
      <div {...props} ref={node => this.node = node} >
        { children }
      </div>
    );
  }
}

export default OutSideDetector;
