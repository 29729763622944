import React, { useEffect, useState } from 'react';
import { connect } from "cpcs-reconnect";
import { token } from 'domain/env';
import { getQuery } from 'domain/router';
import Api from 'domain/api';
import Pagination from "components/pagination";
import { lnk } from "lib/routes";
import { Link } from "react-router-dom";
import SmartImage from "components/SmartImage";
import classes from './cluster.module.scss';
import Loader from "components/HLoader";
import columns from 'fields/clustersList';
import { ValuesListPure, sheet as valuesListSheet } from 'components/ValuesList';
import Immutable from 'immutable';
import cx from 'classnames';
import injectSheet from 'lib/sheet';

const ValuesList = injectSheet(valuesListSheet)(ValuesListPure);

const clusters = columns.select(
  'auctionDate',
  'baseSoldPrice',
  'cpiNormalBaseSoldPrice',
  'yearCreatedFrom',
  'primarySurfaceRank',
  'primarySubstrateRank',
  'area',
  'ppsi',
);

const ClusterListPage = ({ token, query = {}, match: { params: { id } } }) => {
  const [ { pagination, list }, setData ] = useState({ list: [], pagination: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && token) {
      Api.getAoClusterList({ token, id, query })
        .then(({ data }) => {
          setLoading(false);
          setData(data);
        })
        .catch(() => setLoading(false));
    }
  }, [id, token, query]);

  return (
    <div className={classes.container}>
      <div className={classes.pagination}>
        <div className={classes.header}>Clusters</div>
        { pagination && <Pagination pagination={pagination} /> }
      </div>
      <div className={classes.table}>
        <div className={classes.th}>
          <div className={classes.clusterHeader}>Cluster</div>
          <div>Artworks</div>
        </div>
        <div className={classes.tbody}>
          { loading && <div className={classes.loader}><Loader/></div>}
          { list.map(({ cluster, count, list }) => <div key={cluster} className={classes.row}>
            <Link className={classes.cluster} target="_blank" to={lnk('artworksByCluster', { artworkId: id, id: cluster })}>
              {`Cluster #${cluster} (${count} ${ count === 1 ? 'art' : 'arts' })`}
            </Link>
            <div className={classes.images}>
              { list.map((item) => {
                const { id: artworkId, image, artClusterType } = item;
                return <Link key={artworkId} className={classes.link} to={lnk('artwork', { artworkId })} target="_blank">
                  <SmartImage originalLink src={image} modifier='clusterListPageImage' onClick={() => null}/>
                  <ValuesList
                    config={clusters}
                    rowData={Immutable.fromJS(item)}
                    modifier={cx('clusterList', `sourceType-${artClusterType}`)}
                  />
                </Link>
                }
              )}
            </div>
          </div> ) }
        </div>
      </div>
    </div>
  )
}

export default connect({ token, query: getQuery })(ClusterListPage);
