import { createItemPage } from 'pages/common/ItemPage';

import { selectors } from 'domain/subject';

import { columns } from 'fields/subject';

const { item } = selectors;

export const editableFields = [];

export default createItemPage({
  name: 'subject',
  connections: {
    item,
  },
  readOnly: true,
  config: {
    basicInfo: [
      columns.select('title','level'),
      [],
      columns.select('id'),
    ],
  },
});
