import { DEFAULT_FONTFAMILY } from 'theme/const';

const TEXT_COLOR_DEFAULT = '#000';

const btn = {
  border: 'none',
  background: 'transparent',
  padding: [8, 25],
  fontFamily: DEFAULT_FONTFAMILY,
  fontSize: '14px',
  fontWeight: 500,
  color: '#a9431e',
  whiteSpace: 'nowrap',
};

export default {
  btn: {
    ...btn,
    outline: 'none',
    cursor: 'pointer',
  },
  btnActive: {
    ...btn,
    backgroundColor: '#e3e5e8',
    color: TEXT_COLOR_DEFAULT,
  },
};
