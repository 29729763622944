import { commonDictionaryFilters, createFilterTransfer } from './common';

export const defaultList = [ 'id', 'spiderName', 'domain' ];

export const filters = {
  ...commonDictionaryFilters,
  domain: {},
  spiderName: {},
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
