import record, { integer, bool, listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';

import { Artist, Pagination, Stats } from 'domain/lib';
import { clearSelection } from 'domain/const';
import { action, asyncAction } from 'lib/actions';
import { updateSelectedList } from 'domain/common';

export const fetchArtistsListAction = asyncAction('artist/GET_LIST');
export const createArtistAction = asyncAction('artist/CREATE');
export const updateArtistInLineAction = asyncAction('artist/UPDATE_INLINE');
export const checkArtistAction = action('artist/CHECK');

export const onEdit = action('artist/EDIT');

const State = record('Artists', {
  list: listOf(Artist),
  pagination: Pagination(),
  stats: Stats(),
  selected: listOf(integer),
  onEdit: integer(),
  loading: bool(true),
});

export const artists = field('artistList');
export const artistList = artists.then(State.$list);
export const selectedArtists = artists.then(State.$selected);
export const artistListPagination = artists.then(State.$pagination);
export const artistListStats = artists.then(State.$stats);
export const edit = artists.then(State.$onEdit);
export const loading = artists.then(State.$loading);
function sortSecondaryTag(inputArray) {
  const newArray = [...inputArray];
  return newArray.sort((a, b) => {
    const extractTag = (obj) => obj.smcTag.match(/(\w+)( \(([\d.]+)\))?/);
    const [, aTag, aTagValue] = extractTag(a) || [];
    const [, bTag, bTagValue] = extractTag(b) || [];
    if (aTagValue && bTagValue) {
      return parseFloat(aTagValue) - parseFloat(bTagValue) || aTag.localeCompare(bTag);
    }
    if (aTagValue) return -1;
    if (bTagValue) return 1;
    return aTag.localeCompare(bTag);
  });
}
export const reducer = {
  artistList(state = new State(), { type, payload }) { //NOSONAR
    switch (type) {

      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$list.clear(),
          State.$selected.clear(),
          State.$loading.set(true),
          State.$onEdit.set(),
        );

      case onEdit.type:
        return state.apply(
          State.$onEdit.set(payload),
        );

      case fetchArtistsListAction.request:
        return state.apply(
          State.$loading.set(true),
        );

      case fetchArtistsListAction.success:
        payload.list.map(item => {
          if (typeof item.carTag === 'object' && !Array.isArray(item.carTag)) {
            let sortedTag = sortSecondaryTag([...item['secondaryCarTag']])
            item.carTag = [item.carTag, ...sortedTag];
          }
        });
        return state.apply(
          State.$list.parsed(payload.list),
          State.$pagination.parsed(payload.pagination),
          State.$stats.parsed(payload.stats),
          State.$loading.set(false),
        );

      case updateArtistInLineAction.success:
        return state.apply(
          State.$list.update(list => list.set(list.findIndex(v => v.get('id') === payload.id), Artist.parse(payload))),
        );

      case checkArtistAction.type:
        return updateSelectedList(state, payload, State);

      case clearSelection.type:
        return state.apply(
          State.$selected.clear(),
        );

      default:
        return state;
    }
  },
};
