import colors from 'theme/Colors';


export default {
  Paper: {
    backgroundColor: 'white',
    display: 'block',
    borderTop: `2px solid ${colors.attention}`,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
    flex: 'auto',
  },
};
