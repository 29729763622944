import {
  put,
  call,
  select,
  takeLatest,
  fork,
  takeEvery,
  cancel,
  take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getQuery, getLocation } from 'domain/router/RouterModel';
import { checkQuery } from 'lib/helpers';
import { addNotification } from 'domain/env';
import {
  err404Action,
  hidePopupByName,
  virtualPage,
  updatePage,
  showPopup,
} from 'domain/ui';
import { loadMore, lock } from 'domain/const';
import { lnk } from 'lib/routes';
import { watchCommonActionsCall } from 'sagas/actions';

import Api, { callApi } from 'domain/api';

// TODO ADD RESTORE AND NAVIGATION

import { initialize } from 'redux-form';
import { getEditableData } from '../domain/lib';
import { remove } from '../domain/const';
import { watchLoadHistory } from '../domain/history/saga';
import { selectors as customerSelectors } from '../domain/customer';

let lockTask = null;

export function* delay(ms) {
  yield call(() => new Promise((res) => setTimeout(res, ms)));
}

function* saveForm(modelName, { payload, resolve }) {
  yield call(delay, 500);
  const apiKey = `add${modelName}`;
  try {
    const {
      data: { id },
    } = yield callApi(Api[apiKey], { data: payload });
    yield put(hidePopupByName(`ADD_${modelName.toUpperCase()}`));
    resolve();
    yield put(push(lnk(modelName.toLowerCase(), { id })));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'itemNotCreated', type: 'error' }));
  }
}

function* updateFormConfirmed(
  { modelName, actions },
  { payload: { id, ...data }, resolve },
) {
  yield call(delay, 500);
  const apiKey = `update${modelName}`;
  try {
    const { data: payload } = yield callApi(Api[apiKey], { id, data });
    resolve();
    yield put(actions.inlineEditAction(null));
    yield put(
      addNotification({
        title: 'Changes were successfully saved.',
        translate: false,
        type: 'success',
      }),
    );
    yield put({
      type: actions.inlineEditAction.success,
      payload,
    });
  } catch (e) {
    resolve(e);
    yield put(
      addNotification({
        title: 'Changes were not saved.',
        translate: false,
        type: 'error',
      }),
    );
  }
}

function* updateForm(confirmation = false, ...args) {
  if (confirmation) {
    yield put(showPopup({ name: 'CONFIRM_UPDATE' }));
    const { type } = yield take(['CONFIRM_UPDATE', hidePopupByName.type]);
    if (type === 'CONFIRM_UPDATE') {
      yield call(updateFormConfirmed, ...args);
    } else {
      args[1].resolve();
    }
  } else {
    yield call(updateFormConfirmed, ...args);
  }
}

let task = null;

function* checkLocationAndRunTask(...args) {
  let { type } = args.pop();
  if (type === '@@router/LOCATION_CHANGE') {
    yield cancel(task);
  } else {
    yield fork(loadListData, ...args);
  }
}

export function* loadListData(
  { modelName, actions: { fetchListAction, selectAction }, filters, selectors },
  resetChecked = true,
  callback,
) {
  const { id: userId } = yield select(customerSelectors.item);
  const apiKey = !!userId
    ? `getCustomer${modelName}List`
    : `get${modelName}List`;

  const vPage = yield select(virtualPage);
  const query = (yield select(getQuery)) || {};
  const { page = 1, pageSize = 20 } = query;
  let updatedQuery = {};
  let currentList = (yield select(selectors.list)).toJS();
  if (pageSize * 1 > 25) {
    updatedQuery = {
      page: (pageSize / 25) * (page - 1) + vPage,
      pageSize: 25,
    };
    if (task) yield cancel(task);
  }
  yield put({ type: fetchListAction.request });
  if (resetChecked) yield put(selectAction(null));
  try {
    const location = yield select(getLocation);
    const { data } = yield callApi(Api[apiKey], {
      userId,
      query: checkQuery({ ...query, ...updatedQuery }, filters),
    });
    if ((yield select(getLocation)) === location) {
      yield put({
        type: fetchListAction.success,
        payload: {
          ...data,
          list: pageSize * 1 > 25 ? currentList.concat(data.list) : data.list,
          pagination: {
            ...data.pagination,
            pages: Math.ceil(data.pagination.total / pageSize),
            page,
            pageSize,
          },
        },
      });
      if (pageSize * 1 > 25) {
        yield put(updatePage());
      }
      if (typeof callback === 'function') yield fork(callback, data);
      if (data.pagination.page !== data.pagination.pages && pageSize > 25) {
        task = yield takeLatest(
          [loadMore.type, '@@router/LOCATION_CHANGE'],
          checkLocationAndRunTask,
          ...arguments,
        );
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* listNavigator({
  modelName,
  actions,
  filters = {},
  selectors,
  confirmUpdate = false,
  loadData = loadListData,
}) {
  if (Object.keys(filters).length === 0) {
    console.log('DEV MSG: No filters connected, please ask Admin');
  }
  yield fork(loadData, { modelName, actions, selectors, filters }, false);
  yield takeLatest(actions.createItemAction.type, saveForm, modelName);
  yield takeLatest(actions.updateItemAction.type, updateForm, confirmUpdate, {
    modelName,
    actions,
    filters,
  });
  yield fork(watchCommonActionsCall, modelName, loadData, {
    modelName,
    actions,
    selectors,
    filters,
  });
}

function* reInitForm(name, data) {
  yield put(initialize(name, data));
}

// Single Page
const Logos = [
  'enterpriseWebappClientLogo',
  'artistReportCoverPageLogo',
  'portfolioClientLogoUrl',
  'artworkReportCoverPageLogo',
  'artistReportHeaderLogo',
  'portfolioReportHeaderLogoUrl',
  'artworkReportHeaderLogo',
];

export function* loadSingleData({
  modelName,
  actions,
  editableFields,
  id,
  initForm = true,
}) {
  const apiKey = `get${modelName}`;
  try {
    const { data } = yield callApi(Api[apiKey], { id });

    if (modelName === 'Enterprise') {
      // Logos.forEach((logo) => {
      //   localStorage.setItem(logo, data[logo]);
      // });

      localStorage.setItem(
        'enterpriseWebappClientLogo',
        data.enterpriseWebappClientLogoId,
      );
      localStorage.setItem(
        'artistReportCoverPageLogo',
        data.artistReportCoverPageLogoId,
      );
      localStorage.setItem(
        'portfolioClientLogoUrl',
        data.portfolioClientLogoUrlId,
      );
      localStorage.setItem(
        'artworkReportCoverPageLogo',
        data.artworkReportCoverPageLogoId,
      );
      localStorage.setItem(
        'artistReportHeaderLogo',
        data.artistReportHeaderLogoId,
      );
      localStorage.setItem(
        'portfolioReportHeaderLogoUrl',
        data.portfolioReportHeaderLogoUrlId,
      );
      localStorage.setItem(
        'artworkReportHeaderLogo',
        data.artworkReportHeaderLogoId,
      );

      // set default value
      data.artistReportPrimaryColor1 =
        data.artistReportPrimaryColor1 || '#304C95';
      data.artistReportSecondaryColor1 =
        data.artistReportSecondaryColor1 || '#5B6178';
      data.artistReportSecondaryColor2 =
        data.artistReportSecondaryColor2 || '#1E202C';
      data.portfolioPrimaryColor =
        data.portfolioPrimaryColor || '#304C95';
      data.portfolioSecondaryColor1 =
        data.portfolioSecondaryColor1 || '#5B6178';
      data.portfolioSecondaryColor2 =
        data.portfolioSecondaryColor2 || '#1E202C';
      data.artworkReportPrimaryColor1 =
        data.artworkReportPrimaryColor1 || '#E3A52C';
      data.artworkReportSecondaryColor1 =
        data.artworkReportSecondaryColor1 || '#5B6178';
      data.artworkReportSecondaryColor2 =
        data.artworkReportSecondaryColor2 || '#1E202C';
      data.artworkReportTextColor = data.artworkReportTextColor || '#FFFFFF';
      data.artistReportTextColor = data.artistReportTextColor || '#FFFFFF';
      data.artistArtBnkCoverPageLogo = data.artistArtBnkCoverPageLogo || 'White';
      data.artistArtBnkLastPageLogo = data.artistArtBnkLastPageLogo || 'White';
      data.artistArtBnkLogo = data.artistArtBnkLogo || 'White';
      data.artworkArtBnkLogo = data.artworkArtBnkLogo || 'White';
      data.artworkArtBnkCoverPageLogo = data.artistArtBnkCoverPageLogo || 'White';
      data.artworkArtBnkLastPageLogo = data.artistArtBnkLastPageLogo || 'White';
      data.portfolioCoverPageTextColor = data.portfolioCoverPageTextColor || '#FFFFFF';
      data.portfolioArtdaiLogoColor = data.portfolioArtdaiLogoColor || 'White';
      data.portfolioArtdaiLastPageLogoColor = data.portfolioArtdaiLastPageLogoColor || 'White';
      data.portfolioArtdaiLogo = data.portfolioArtdaiLogo || 'White';
    }

    yield put({ type: actions.fetchItemAction.success, payload: data });
    if (initForm) {
      yield call(
        watchLockAction,
        `${modelName.toLowerCase()}Form`,
        getEditableData(editableFields, data),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(err404Action(true));
  }
}

function* watchLockAction(...formParams) {
  yield put(initialize(...formParams));
  if (lockTask) yield cancel(lockTask);
  lockTask = yield takeEvery(lock.type, reInitForm, ...formParams);
}

function* saveSingleForm(
  { modelName, actions, editableFields, id },
  { payload, resolve = () => null },
) {
  const apiKey = `update${modelName}`;

  try {
    if (modelName === 'Enterprise') {
      // localStorage.setItem('artworkArtBnkLogo', payload.artworkArtBnkLogo);
      // localStorage.setItem('artistArtBnkLogo', payload.artistArtBnkLogo);
      // localStorage.setItem('portfolioArtdaiLogo', payload.portfolioArtdaiLogo);
      localStorage.setItem('artistArtBnkCoverPageLogo',payload.artistArtBnkCoverPageLogo);
      localStorage.setItem('artistArtBnkLastPageLogo',payload.artistArtBnkLastPageLogo);
      localStorage.setItem('portfolioArtdaiLogoColor',payload.portfolioArtdaiLogoColor);
      localStorage.setItem('portfolioArtdaiLastPageLogoColor',payload.portfolioArtdaiLastPageLogoColor);
      localStorage.setItem('artworkArtBnkCoverPageLogo',payload.artworkArtBnkCoverPageLogo);
      localStorage.setItem('artworkArtBnkLastPageLogo',payload.artworkArtBnkLastPageLogo);
      payload.enterpriseWebappClientLogo =
        typeof payload.enterpriseWebappClientLogo === 'string'
          ? payload.enterpriseWebappClientLogoId
          : Array.isArray(payload.enterpriseWebappClientLogo)
          ? undefined
          : payload.enterpriseWebappClientLogo;
      payload.artistReportCoverPageLogo =
        typeof payload.artistReportCoverPageLogo === 'string'
          ? payload.artistReportCoverPageLogoId
          : Array.isArray(payload.artistReportCoverPageLogo)
          ? undefined
          : payload.artistReportCoverPageLogo;
      payload.portfolioClientLogoUrl =
        typeof payload.portfolioClientLogoUrl === 'string'
          ? payload.portfolioClientLogoUrlId
          : Array.isArray(payload.portfolioClientLogoUrl)
          ? undefined
          : payload.portfolioClientLogoUrl;
      payload.artworkReportCoverPageLogo =
        typeof payload.artworkReportCoverPageLogo === 'string'
          ? payload.artworkReportCoverPageLogoId
          : Array.isArray(payload.artworkReportCoverPageLogo)
          ? undefined
          : payload.artworkReportCoverPageLogo;
      payload.artistReportHeaderLogo =
        typeof payload.artistReportHeaderLogo === 'string'
          ? payload.artistReportHeaderLogoId
          : Array.isArray(payload.artistReportHeaderLogo)
          ? undefined
          : payload.artistReportHeaderLogo;
      payload.portfolioReportHeaderLogoUrl =
        typeof payload.portfolioReportHeaderLogoUrl === 'string'
          ? payload.portfolioReportHeaderLogoUrlId
          : Array.isArray(payload.portfolioReportHeaderLogoUrl)
          ? undefined
          : payload.portfolioReportHeaderLogoUrl;
      payload.artworkReportHeaderLogo =
        typeof payload.artworkReportHeaderLogo === 'string'
          ? payload.artworkReportHeaderLogoId
          : Array.isArray(payload.artworkReportHeaderLogo)
          ? undefined
          : payload.artworkReportHeaderLogo;

      // Logos.forEach((logo) => {
      //   payload[logo] =
      //     typeof payload[logo] === 'string'
      //       ? payload[logo + 'Id']
      //       : Array.isArray(payload[logo])
      //       ? undefined
      //       : payload[logo];
      // });
      // save
      // Logos.forEach((logo) => {
      //   if (localStorage.getItem(logo) !== 'undefined') {
      //     payload[logo] = localStorage.getItem(logo);
      //   }
      // });
      if (localStorage.getItem('enterpriseWebappClientLogo') !== 'undefined') {
        payload.enterpriseWebappClientLogo = localStorage.getItem(
          'enterpriseWebappClientLogo',
        );
      }
      if (localStorage.getItem('artistReportCoverPageLogo') !== 'undefined') {
        payload.artistReportCoverPageLogo = localStorage.getItem(
          'artistReportCoverPageLogo',
        );
      }
      if (localStorage.getItem('portfolioClientLogoUrl') !== 'undefined') {
        payload.portfolioClientLogoUrl = localStorage.getItem(
          'portfolioClientLogoUrl',
        );
      }
      if (localStorage.getItem('artworkReportCoverPageLogo') !== 'undefined') {
        payload.artworkReportCoverPageLogo = localStorage.getItem(
          'artworkReportCoverPageLogo',
        );
      }
      if (localStorage.getItem('artistReportHeaderLogo') !== 'undefined') {
        payload.artistReportHeaderLogo = localStorage.getItem(
          'artistReportHeaderLogo',
        );
      }
      if (localStorage.getItem('portfolioReportHeaderLogoUrl') !== 'undefined') {
        payload.portfolioReportHeaderLogoUrl = localStorage.getItem(
          'portfolioReportHeaderLogoUrl',
        );
      }
      if (localStorage.getItem('artworkReportHeaderLogo') !== 'undefined') {
        payload.artworkReportHeaderLogo = localStorage.getItem(
          'artworkReportHeaderLogo',
        );
      }
      // del
      if (
        localStorage.getItem('delenterpriseWebappClientLogo') !== 'undefined'
      ) {
        payload.enterpriseWebappClientLogo = undefined;
        payload.enterpriseWebappClientLogoId = undefined;
        localStorage.setItem('enterpriseWebappClientLogo', undefined);
        localStorage.setItem('delenterpriseWebappClientLogo', undefined);
      }

      if (
        localStorage.getItem('delartistReportCoverPageLogo') !== 'undefined'
      ) {
        payload.artistReportCoverPageLogo = undefined;
        payload.artistReportCoverPageLogoId = undefined;
        localStorage.setItem('artistReportCoverPageLogo', undefined);
        localStorage.setItem('delartistReportCoverPageLogo', undefined);
      }

      if (localStorage.getItem('delartistReportHeaderLogo') !== 'undefined') {
        payload.artistReportHeaderLogo = undefined;
        payload.artistReportHeaderLogoId = undefined;
        localStorage.setItem('artistReportHeaderLogo', undefined);
        localStorage.setItem('delartistReportHeaderLogo', undefined);
      }
      if (
        localStorage.getItem('delportfolioClientLogoUrl') !== 'undefined'
      ) {
        payload.portfolioClientLogoUrl = undefined;
        payload.portfolioClientLogoUrlId = undefined;
        localStorage.setItem('portfolioClientLogoUrl', undefined);
        localStorage.setItem('delportfolioClientLogoUrl', undefined);
      }
      if (localStorage.getItem('delportfolioReportHeaderLogoUrl') !== 'undefined') {
        payload.portfolioReportHeaderLogoUrl = undefined;
        payload.portfolioReportHeaderLogoUrlId = undefined;
        localStorage.setItem('portfolioReportHeaderLogoUrl', undefined);
        localStorage.setItem('delportfolioReportHeaderLogoUrl', undefined);
      }

      if (localStorage.getItem('delartworkReportHeaderLogo') !== 'undefined') {
        payload.artworkReportHeaderLogo = undefined;
        payload.artworkReportHeaderLogoId = undefined;
        localStorage.setItem('artworkReportHeaderLogo', undefined);
        localStorage.setItem('delartworkReportHeaderLogo', undefined);
      }

      if (
        localStorage.getItem('delartworkReportCoverPageLogo') !== 'undefined'
      ) {
        payload.artworkReportCoverPageLogo = undefined;
        payload.artworkReportCoverPageLogoId = undefined;
        localStorage.setItem('artworkReportCoverPageLogo', undefined);
        localStorage.setItem('delartworkReportCoverPageLogo', undefined);
      }
      // Logos.forEach((logo) => {
      //   if (localStorage.getItem('del' + logo) !== 'undefined') {
      //     payload[logo] = undefined;
      //     payload[logo + 'Id'] = undefined;
      //     localStorage.setItem(logo, undefined);
      //     localStorage.setItem(logo + 'Id', undefined);
      //   }
      // });
    }

    const { data } = yield callApi(Api[apiKey], { id, data: payload });
    resolve();
    yield put({ type: actions.updateItemAction.success, payload: data });
    yield call(
      watchLockAction,
      `${modelName.toLowerCase()}Form`,
      getEditableData(editableFields, data),
    );
    yield put(
      addNotification({
        title: 'Changes were successfully saved.',
        translate: false,
        type: 'success',
      }),
    );
  } catch (e) {
    resolve(e);
    yield put(
      addNotification({
        title: 'Changes were not saved.',
        translate: false,
        type: 'error',
      }),
    );
  }
}

function* onRemoveItem({ modelName, id }) {
  const apiKey = `remove${modelName}`;
  try {
    yield callApi(Api[apiKey], { id });
    yield put(addNotification({ title: 'objectRemoved', type: 'success' }));
    yield put(push(lnk(`${modelName.toLowerCase()}List`)));
  } catch (e) {
    yield put(addNotification({ title: 'objectNotRemoved', type: 'remove' }));
    console.error(e);
  }
}

export function* itemNavigator(
  {
    modelName,
    actions,
    editableFields,
    loadItemData = loadSingleData,
    selectors,
  },
  { params: { id } },
) {
  const params = { modelName, actions, editableFields, id, selectors };
  yield fork(loadItemData, params);

  yield takeLatest(actions.updateItemAction.type, saveSingleForm, params);
  yield takeEvery(remove.type, onRemoveItem, { modelName, id });
  yield fork(watchLoadHistory);
}
