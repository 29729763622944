import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/form/fields/input';

class JumpToPage extends React.PureComponent
{
  static propTypes = {
    classes: PropTypes.shape({
      JumpToPage: PropTypes.string,
      JumpToPageLabel: PropTypes.string,
      JumpToPageInput: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pages: PropTypes.number,
  };

  state = {
    value: '',
  }

  onChange = value => {
    if (/\d+/.test(value) && parseInt(value, 10)) this.setState({ value: parseInt(value, 10) });
  };

  onSubmit = () => {
    const { value } = this.state;
    const { page, pages } = this.props;
    this.setState({ value: '' }, () => {
      if (value && parseInt(value, 10) && parseInt(page, 10) !== value && pages >= value){
        this.props.onChange(value);
      }
    });
  };

  onKeyPress = (e) => {
    const { classes } = this.props;
    if (e.key === 'Enter') {
      document.getElementsByClassName(classes.JumpToPageInput)[0].blur();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <label
        className={classes.JumpToPage}
      >
        <span
          children="Jump to"
          className={classes.JumpToPageLabel}
        />
        <Input
          id="asdasdas"
          name="page"
          placeholder="zero"
          className={classes.JumpToPageInput}
          onChange={this.onChange}
          value={this.state.value}
          onBlur={this.onSubmit}
          onKeyPress={this.onKeyPress}
        />
      </label>
    );
  }
}

export default JumpToPage;
