import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

function Message({ meta: { touched, error, submitError }, intl: { formatMessage }, classes, customClass, name }) {
  if (!touched || !(error || submitError)) return null;
  const { id, ...values } = error || submitError;
  const translatedName = name ? formatMessage({ id: name }) : name;
  return (
    <div
      className={cx(classes.message, classes.error, customClass)}
      dangerouslySetInnerHTML={{
        __html: formatMessage({ id }, { name: translatedName , ...values }),
      }}
    />
  );
}

Message.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  customClass: PropTypes.string,
  name: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectIntl(injectSheet(sheet)(Message));
