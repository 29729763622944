import ValuesListPure, { sheet as valuesListSheet } from 'components/ValuesList';
import injectSheet from 'lib/sheet';

export { valuesListSheet };

const ValuesList = injectSheet({
  ...valuesListSheet,
  ValuesList: { marginRight: 150, width: 460 },
  value: { width: 300, display: 'flex' },
})(ValuesListPure);

export default ValuesList;
