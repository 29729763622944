import { sheet } from 'components/Actions/sheet';
import colors from 'theme/Colors';

const { accept, ignore, validate, draft, lock, unlock, display } = sheet;

export default {
  dots: {
    width: 16,
    height: 32,
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    background: {
      size: '4px 16px',
      repeat: 'no-repeat',
      position: 'center center',
      image: 'url(' + require('./dots.svg') + ')',
    },
  },
  LotMenu: {
    width: 180,
    padding: [10, 0],
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '100%',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.26)',
    backgroundColor: colors.bg,
    zIndex: 3,
  },
  divider: {
    width: 160,
    margin: [10, 0],
    borderTop: '1px solid gray',
    alignSelf: 'center',
  },
  btn: {
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    height: 32,
    lineHeight: '32px',
    paddingLeft: 42,
    fontSize: 14,
    fontWeight: 400,
    background: {
      size: '20px 20px',
      repeat: 'no-repeat',
      position: '10px center',
    },
    '&:hover': {
      backgroundColor: colors.warning,
    },
  },
  accept,
  ignore,
  delete: sheet.delete,
  validate,
  draft,
  lock,
  unlock,
  display,
  save: {
    backgroundImage: 'url(' + require('./save.svg') + ')',
  },
  cancel: {
    backgroundImage: 'url(' + require('./cancel.svg') + ')',
  },
  guarantee: {
    backgroundImage: 'url(' + require('./guarantee.svg') + ')',
  },
  privateSale: {
    backgroundImage: 'url(' + require('./privateSale.png') + ')',
  },
  notPrivateSale: {
    backgroundImage: 'url(' + require('./privateSale.png') + ')',
  }
};
