import React from 'react';
import PropTypes from 'prop-types';
import { defineColumns } from '../components/PageTable/defineColumns';
import { connectAcceptance, createIdLink, toNumber } from './common';
import { ArtistLink } from './webAppArtwork';
import { dateToString } from '../lib/serialize';
import Image from '../components/SmartImage';
import Api from 'domain/api';
import ClusterValue from 'components/ClusterValue';

const Artist = ({ rowData: { artwork: { artist } }, classes }) => <ArtistLink rowData={{ artist }} classes={classes} />;

Artist.propTypes = {
  classes: PropTypes.object,
  rowData: PropTypes.any,
};

const IdImage = ({ rowData: { artwork: { pictures: [ picture ] } } }) => <Image src={picture} params={{ width: 80, height: 80 }}/>;

IdImage.propTypes = {
  rowData: PropTypes.any,
};

class PushBtn extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    rowData: PropTypes.any,
    showPopup: PropTypes.func.isRequired,
  }

  render() {
    const { classes, showPopup, rowData: { id, abvLoading } } = this.props;
    return abvLoading ? <div className={classes.loader} /> : <button
      type="button"
      className={classes.Button}
      onClick={() => showPopup({ name: 'PUSH_ABV', id })}
    >
      Push Update
    </button>;
  }
}

class Download extends React.Component {
  state = {
    loading: false,
  }

  static propTypes = {
    classes: PropTypes.object,
    rowData: PropTypes.any,
    token: PropTypes.string,
  }

  load = async() => {
    const { rowData: { id }, token } = this.props;
    this.setState({ loading: true });
    const { data } = await Api.getSecuredPurchasePdf({ id, token, responseType: 'blob' });
    window.open(URL.createObjectURL(data));
    this.setState({ loading: false });
  }

  render(){
    const { classes } = this.props;
    return this.state.loading ? <div className={classes.loader} /> : <button
      type="button"
      className={classes.pdfLink}
      children="Download"
      onClick={this.load}
    />;
  }
}

export const config = {
  id: {
    getValue: ({
       rowData: { artwork: { id: value } },
       classes,
    }) => createIdLink('artwork', 'artworkId')({ value, classes }),
  },
  image: {
    getValue: IdImage,
  },
  title: { sortable: true, className: 'artistList', getValue: ({ rowData: { artwork: { title } } }) => title },
  artist: {
    sortable: true,
    className: 'artistList',
    getValue: Artist,
  },
  rtvPrice: { sortable: true, getValue: toNumber, className: 'price' },
  purchasedDate: { sortable: true, getValue: dateToString('MMM DD, YYYY', '−') },
  current: {
    sortable: true,
    getValue: ({ value }) => !!value ? 'Current' : 'Outdated',
  },
  pdf: {
    getValue: Download,
  },
  push: {
    getValue: PushBtn,
  },
  clusterNumber: {
    getValue: ({ rowData: { artwork: { id, clusterNumber } }}) =>
      <ClusterValue id={id} clusterNumber={clusterNumber} />,
    sortable: true,
    sortByName: 'artId__clusterNumber',
  },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);
export const defaultColumns = [
  'image',
  'title',
  'artist',
  'rtvPrice',
  'purchasedDate',
  'current',
  'pdf',
  'push',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
