import { put, fork, call, takeEvery, all } from 'redux-saga/effects';
import { delay } from 'sagas/common';
import { push } from 'connected-react-router';
import { watchCommonActionsCall } from 'sagas/actions';
import { loadListData } from 'sagas/common';

import {
  fetchArtworksListAction,
  checkArtworkAction,
  createArtworkAction,
  onEdit,
  updateArtworkInLineAction,
  fetchLatestRtv,
  artworkList,
} from 'domain/artworkList';

import { checkArtworkData } from 'pages/ArtworkPage/saga';

import { lnk } from 'lib/routes';
import { addNotification } from 'domain/env';
import filters from 'filters/artwork';
import { hidePopupByName } from 'domain/ui';

import Api, { callApi } from 'domain/api';

const ENV = process.env.REACT_APP_ENV || 'dev';

export function* loadAuthor(artistId) {
  try {
    const { data } = yield callApi(Api.getAuthor, { artistId });
    return data;
  } catch (e) {}
}

function* loadRtv(id) {
  try {
    const { data: { aeRtv } } = yield callApi(Api.getLastRtv, { id });
    yield put({
      type: fetchLatestRtv.success,
      payload: { id, value: aeRtv },
    });
  } catch (e) {
    yield put({
      type: fetchLatestRtv.failure,
      payload: e,
    });
  }
}

function* getLastRTV({ list = [] }) {
  if (list.length > 0 && ENV === 'dev' && false) {
    yield all(list.map(({ id }) => fork(loadRtv, id)));
  }
}

function* loadData(resetChecked = true) {
  const params = {
    modelName: 'Artworks',
    actions: { fetchListAction: fetchArtworksListAction, selectAction: checkArtworkAction },
    filters,
    selectors: { list: artworkList },
  };
  yield call(loadListData, params, resetChecked, getLastRTV );
}

export function* saveForm({ payload, resolve }) {
  yield call(delay, 500);
  try {
    const data = yield call(checkArtworkData, payload, true);
    const { data: { id: artworkId } } = yield callApi(Api.addArtwork, { data });
    yield put(hidePopupByName('ADD_ARTWORK'));
    yield put(hidePopupByName('ADD_ARTIST_ARTWORK'));
    resolve();
    yield put(push(lnk('artwork', { artworkId })));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'itemNotCreated', type: 'error' }));
  }
}

function* updateForm({ payload: { id: artworkId, ...data }, resolve }) {
  try {
    const { data: payload } = yield callApi(Api.updateArtwork, { artworkId, data });
    resolve();
    yield put(onEdit(null));
    yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success' }));
    yield put({
      type: updateArtworkInLineAction.success,
      payload,
    });
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}

export default function* navigator() {
  yield fork(loadData, false);
  yield takeEvery(updateArtworkInLineAction.type, updateForm);
  yield takeEvery(createArtworkAction.type, saveForm);
  yield fork(watchCommonActionsCall, 'Artwork');
}
