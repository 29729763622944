import record, { string, bool, listOf, indexedSetOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';

import { Author } from 'domain/lib';
import { action, asyncAction } from 'lib/actions';

export const fetchAuthorsAction = asyncAction('authors/ADD_TO_DICTIONARY');
export const updateAuthorAction = action('authors/UPDATE');
export const searchAuthorsAction = asyncAction('authors/SEARCH');

const State = record('Authors', {
  term: string(),
  list: listOf(Author),
  dictionary: indexedSetOf(Author, Author.$id),
  defaultList: listOf(Author),
  loading: bool(true),
});

export const authors = field('authors');
export const authorsList = authors.then(State.$list);
export const authorsById = authors.then(State.$dictionary);
export const authorsStorage = selector(authorsById, v => v.toList());
export const loading = authors.then(State.$loading);

export const reducer = {
  authors(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$list.clear(),
        );

      case updateAuthorAction.type:
        return state.apply(
          State.$list.update(list => list
            .map(v => v.get('id') === parseInt(action.payload.id, 10) ? new Author.parse(action.payload) : v ),
          ),
          State.$dictionary.update(v => {
            if (v.has(parseInt(action.payload.id, 10))){
              return v.set(parseInt(action.payload.id, 10), new Author.parse(action.payload));
            }
            return v;
          }),
        );

      case searchAuthorsAction.type:
      case searchAuthorsAction.request:
        return state.apply(
          State.$list.clear(),
          State.$loading.set(true),
        );

      case fetchAuthorsAction.success:
        return state.apply(
          State.$dictionary.updateBy('merge', State.$dictionary.type.parse(action.payload)),
        );

      case searchAuthorsAction.success:
        return state.apply(
          State.$loading.set(false),
          State.$list.set(State.$list.type.parse(action.payload)),
          State.$dictionary.updateBy('merge', State.$dictionary.type.parse(action.payload)),
        );

      default:
        return state;
    }
  },
};
