import {
  connectAcceptance,
  createIdLink,
  commonDictionaryFields,
  toNumberOrNull,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import rules from 'components/form/validation';

export const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('medium'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  order: {
    sortable: true,
    inlineEdit: true,
    getValue: toNumberOrNull,
    className: 'name',
    fieldProps: { validate: [ rules.required ], type: 'number' },
  },
  rank: {
    sortable: true,
    inlineEdit: true,
    getValue: toNumberOrNull,
    className: 'name',
    fieldProps: { validate: [ rules.required ], type: 'number' },
  },
  value: {
    sortable: true,
    inlineEdit: true,
    fieldProps: { validate: [ rules.required ] },
  },
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, ['value', 'order','rank']),
);

export const defaultColumns = ['value', 'order','rank'];

export const tableParams = { columns, defaultColumns };

export default columns;
