import { delay } from 'sagas/common';
import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { change, getFormValues, initialize, submit } from 'redux-form';
import { takeOnce } from 'lib/helpers';
import checkData from 'lib/dataMutatotrs';
import { editions } from 'components/Edition';
import toCamel from 'to-camel-case';

import Api, { callApi } from 'domain/api';
import {
  addLotAction,
  artwork,
  disableOutliersAction,
  enableOutliersAction,
  fetchArtworkAction,
  applyArtworkAction,
  isEditable,
  pushUpdateAction,
  removeArtworkAction,
  updateArtworkAction, updateClusterAction,
} from 'domain/artwork';

import { commonFields, formValues } from 'domain/artwork/fieldRules';

import {
  changeStatusAction as changeLotStatusAction, editableFields as lotEditableFields, updateLotAction,
} from 'domain/lot';
import { fetchAwLotsAction, lot, lotList, onEdit } from 'domain/lotList';
import { fetchAuthorsAction } from 'domain/author';
import { getEditableData } from 'domain/lib';
import { approve, changeStatusAction, lock, remove, restore } from 'domain/const';
import { addNotification, userProfile } from 'domain/env';
import { err404Action, hidePopupByName, showPopup } from 'domain/ui';
import { watchLoadHistory } from 'domain/history/saga';
import { dictionaryById, fetchDictionaryItemsAction } from 'domain/dictionary';
import { fetchRTVAction, rtv } from 'domain/rtv';
import { loadNavigation } from 'domain/navigation/saga';
import { onRestore } from 'sagas/actions';
import moment from 'moment';

export const dictionaries = [
  'surfaces',
  'surfaceValues',
  'substrates',
  'substrateValues',
];

export function* loadArtworkData(artworkId, initForm = true) {
  const { isSuperuser, acl } = yield select(userProfile);
  try {
    let { data: payload } = yield callApi(Api.getArtwork, { artworkId });
    if (payload.sourceType !== 'CUSTOMER' && payload.wfAcceptance === 'ACCEPTED'){
      try {
        const { data: { pagination: { total } } } = yield callApi(Api.getCustomerList, { query: {
            page: 1,
            pageSize: 1,
            filterBy: `(artwork:${artworkId})`,
          } });
        payload.customers = total;
      } catch (e) {
        payload.customers = 0;
      }
    }

    const { hasLots, hasRtvClusters } = payload;
    if (hasLots) yield fork(loadAwLots, { artworkId });

    if (payload.artistId) yield put(fetchAuthorsAction([payload.artistId]));
    yield all(dictionaries
      .filter(name => !!payload[name] && [].concat(payload[name]).length)
      .map(name => put(fetchDictionaryItemsAction({ name, ids: [].concat(payload[name]) }))),
    );
    yield put({ type: fetchArtworkAction.success, payload });
    if (initForm) {
      yield call(tryToInitArtForm);
    }
    if (isSuperuser || acl.includes('rtv')){
      if (hasRtvClusters) {
        try {
          const resp = yield callApi(Api.getRTVActions);
          const { data: { intersectActions: [{ name: action }] } } = resp;
          yield put(fetchRTVAction({ artworkId, action }));

          const { data: rtvData } = yield callApi(Api.getWebAppRtv, { id: artworkId });
          yield put({ type: applyArtworkAction.success, payload: {
              ...Object.keys(rtvData).reduce((p, n) => ({ ...p, [n]: rtvData[n].value }), {}),
            } });
        } catch(e) {
          console.error(e);
        }
      }
    }
  } catch (e) {
    console.error(e);
    yield put(err404Action(true));
  }
}

function* removeArtwork(artworkId) {
  try {
    const { data: payload } = yield callApi(Api.removeArtwork, { artworkId });
    yield put({ type: removeArtworkAction.success, payload });
    yield put(addNotification({ title: 'artworkRemoved' }));
  } catch (e) {
    yield put(addNotification({ title: 'artworkNotRemoved' }));
  }
}

function* approveLotAction(status = 'ACCEPTED') {
  const { wfAcceptance, auctionDate, soldPriceUsd } = yield select(lot);
  const { wfAcceptance: artAcceptance, lastLotSoldAuctionDate, lastLotSoldPriceUsd } = yield select(artwork);
  if (
    wfAcceptance === status &&
    artAcceptance === status &&
    auctionDate === lastLotSoldAuctionDate &&
    soldPriceUsd === lastLotSoldPriceUsd
  ){
    yield put(showPopup({ name: 'APPROVE_LOT', status }));
    const { type } = yield take([ hidePopupByName.type, approve.type ]);
    return type === approve.type;
  } else { return true; }
}

function* removeLot(lotId, artworkId) {
  const approve = yield call(approveLotAction);
  if (!approve) return;
  try {
    yield callApi(Api.removeLot, { lotId });
    yield put(addNotification({ title: 'lotRemoved' }));
    yield call(loadArtworkData, artworkId);
  } catch (e) {
    yield put(addNotification({ title: 'lotNotRemoved' }));
  }
}

function* changeStatusAndSubmit(form, { payload }){
  yield all(Object.keys(payload).map(key => put(change(form, key, payload[key]))));
  yield call(delay, 100);
  yield put(submit(form));
}

export function* saveForm(artworkId, { payload, resolve }) {
  yield call(delay, 500);
  const { wfAcceptance, ...dataToSave } = yield call(checkArtworkData, payload);
  let approved = true;

  // Save Form
  try {
    const newData = (yield callApi(Api.updateArtwork, { artworkId, data: dataToSave })).data;
    yield put({ type: updateArtworkAction.success, payload: newData });
    yield call(tryToInitArtForm);
    if (!wfAcceptance) {
      yield put(addNotification({ title: 'Changes were successfully saved', type: 'success', translate: false }));
    }
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved', type: 'error', translate: false }));
    return;
  }

  // Update Status if needed
  if (wfAcceptance){
    const { wfAcceptance: wfAcceptancePrevious } = yield select(artwork);

    if (['ACCEPTED','DISPLAY'].includes(wfAcceptancePrevious)){
      const lots = yield select(lotList);
      if (!!lots.find(v => v.wfAcceptance === wfAcceptancePrevious)){
        yield put(showPopup({ name: 'APPROVE_ARTWORK', status: wfAcceptancePrevious }));
        const { type } = yield take([ hidePopupByName.type, approve.type ]);
        if (type === hidePopupByName.type) approved = false;
      }
    }

    if (approved) {
      try {
        const { data } = yield callApi(Api.updateArtwork, { artworkId, data: { wfAcceptance } });
        yield put({ type: updateArtworkAction.success, payload: data });
        yield put(addNotification({ title: 'Changes were successfully saved', type: 'success', translate: false }));
        if (wfAcceptance === 'DISPLAY' || wfAcceptancePrevious === 'DISPLAY'){
          yield fork(loadAwLots, { artworkId });
        }
        resolve();
      } catch (e) {
        const title = e.response.data.lot ? e.response.data.lot.join(' ') : (e.response.data?.wfAcceptance?.join(', ') || 'Changes were saved, but state was no updated');
        resolve(e);
        yield put(addNotification({ title, type: 'error', translate: false }));
      }
    }
  } else {
    resolve();
  }
}

function* tryToInitArtForm() {
  const data = (yield select(artwork)).toJS();
  if (!data.literature.length) {
    data.literature.push({ artId: data.id });
  }
  if (!data.exhibitions.length) {
    data.exhibitions.push({ artId: data.id });
  }
  if (!data.provenances.length) {
    data.provenances.push({ art: data.id });
  }
  const category = (yield select(dictionaryById('categories'))).getIn([ data.category, 'title' ], '2D');
  if (isEditable(data)){
    yield put(initialize('artworkForm', getEditableData(formValues[category], data )));
  }
  if (data.sourceType === 'CUSTOMER'){
    yield put(initialize('artworkForm', getEditableData(['outliers'], data )));
  }
}

function* tryToInitLotForm() {
  const data = yield select(artwork);
  const lotData = yield select(lot);
  const lotDataJs = lotData.toJS();
  if (lotDataJs.literature && !lotDataJs.literature.length) {
    lotDataJs.literature.push({ artId: lotDataJs.artId });
  }
  if (lotDataJs.exhibition && !lotDataJs.exhibition.length) {
    lotDataJs.exhibition.push({ artId: lotDataJs.artId });
  }

  let { lotUrls } = lotDataJs;
  lotUrls = (lotUrls && lotUrls.length) ? lotUrls : [{}];
  if (isEditable(lotData)) {
    yield put(initialize('lotForm', getEditableData(lotEditableFields, {
      ...lotDataJs,
      lotUrls,
      artId: data.id,
    } )));
  }
}

function* loadRTV({ payload: { artworkId, action } }, force = false) {
  const rtvMap = yield select(rtv);
  if (rtvMap[action] && !force) return;
  yield put({ type: fetchRTVAction.request, action });
  try {
    const { data } = yield callApi(Api.getRTV, { artworkId, action });
    yield put({
      type: fetchRTVAction.success,
      payload: data,
    });
  } catch (e) { console.error(e); }
}

function* loadAwLots({ artworkId }) {
  try {
    const { data } = yield callApi(Api.getAwLots, { artworkId });

    yield put({
      type: fetchAwLotsAction.success,
      payload: data,
    });
    yield fork(tryToInitLotForm);
  } catch (e) {
    console.error(e);
    yield put({ type: fetchAwLotsAction.failure });
  }
}

// @params ...args, action
function* loadSecondRtv({ artworkId }, action) {
  try {
    const { name } = action.payload.normal_actions[0];
    yield fork(loadRTV, { payload: { artworkId, action: name } });
  } catch (e) {/* do nothing */}
}

export function* createLot(artId, { payload, resolve }) {
  yield call(delay, 500);
  try {
    yield callApi(Api.addLot, { data: { ...checkData(payload), artId } });
    yield call(updateArtImages, payload, true);
    yield put(addNotification({ title: 'lotAdded', type: 'success' }));
    yield put(hidePopupByName('ADD_LOT'));
    yield fork(loadArtworkData, artId, false);
    resolve();
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'lotNotAdded', type: 'error' }));
  }
}

function* updateArtImages(lotFormData = {}, create = false) {
  try {
    const { images: lotImages = [] } = lotFormData;
    const { images: oldLotImages } = create ? { images: [] } : yield select(lot);
    const { images: artImages, defaultArtImage } = (yield select(getFormValues('artworkForm'))) || {};
    const newImages = lotImages.filter(v => !oldLotImages.includes(v));
    if (newImages.length){
      yield put(change('artworkForm', 'images', artImages.concat(newImages)));
      if (!defaultArtImage ){
        yield call(delay, 100);
        yield put(change('artworkForm', 'defaultArtImage', newImages[0]));
      }
    }
  } catch (e) {
    console.error('Something wrong while updating ArtImages', e);
  }
}

function* updateLot(artworkId, { payload = {}, resolve }) {
  const { wfAcceptance, ...data } = payload;
  const { id: lotId } = yield select(lot);
  if (data.auctionStartDate){
    data.auctionStartDate = moment
    .utc(moment(data.auctionStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
    .format();
  }
  // if (data.auctionDate){
  //   data.auctionDate = moment
  //   .utc(moment(data.auctionDate).format('YYYY-MM-DDTHH:mm:ss[Z]'))
  //   .format();
  // }

  yield call(delay, 500);
  try {
    yield callApi(Api.updateLot, { data: { ...checkData(data) }, lotId });
    yield call(updateArtImages, payload);
    if (wfAcceptance){
      const { wfAcceptance: wfAcceptancePrevious } = yield select(lot);
      if (wfAcceptancePrevious === 'ACCEPTED' || wfAcceptancePrevious === 'DISPLAY' ){
        const approve = yield call(approveLotAction, wfAcceptancePrevious);
        if (!approve) return;
      }
      try {
        yield callApi(Api.updateLot, { data: { wfAcceptance }, lotId });
      } catch (e) {
        yield fork(loadArtworkData, artworkId, false);
        resolve(e);
        yield put(addNotification({
          title: 'Changes were successfully saved. But state not updated.',
          type: 'success', translate: false,
        }));
        return;
      }
    }
    yield fork(loadArtworkData, artworkId, false);
    resolve();
    yield put(addNotification({ title: 'lotUpdated', type: 'success' }));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'lotNotUpdated', type: 'error' }));
  }
}

function* onRemove(artworkId, { payload }) {
  if (payload.id === 'lot') {
    const { id } = yield select(lot);
    yield call(removeLot, id, artworkId);
  } else {
    yield call(removeArtwork, artworkId);
  }
}

function* changeOutliers(val) {
  yield put(change('artworkForm', 'outliers', val));
}

function* onEnableOutliers() {
    const { outliers } = yield select(artwork);
    yield call(changeOutliers, outliers || 'HIGH');
}

function* checkLock({ payload: { subject } }) {
  if (subject === 'Artwork') yield fork(tryToInitArtForm);
  if (subject === 'Lot') yield fork(tryToInitLotForm);
}

const multipleFields = ['literature','exhibitions','provenances'];
const clearMultipleField = values => values
  .filter(data => Object.keys(data)
    .filter(key => ![ 'id','artId','art','uuid','testIgnore'].includes(key))
    .map(key => data[key] || '').join('') !== '');

export function* checkArtworkData(data, isCreate = false) {
  if (!isCreate){
    const { sourceType } = (yield select(artwork)).toJS();
    if ( sourceType === 'CUSTOMER' ) return { outliers: data.outliers };
  }
  const editionsFields = editions.map(toCamel)
    .filter(v => !['unknown','numbered'].includes(v))
    .concat(['numberedNo','numberedOf']);
  const category = (yield select(dictionaryById('categories'))).getIn([ data.category, 'title' ], '2D');
  const hasEdition = formValues[category].includes('edition');
  const editionFieldsToSave = hasEdition ? editionsFields.filter(v => v.includes(data.edition)) : [];
  const fieldsToClear = Object.keys(formValues)
    .filter(v => v!== category)
    .reduce((acc, key) => [ ...formValues[key].filter(v => !commonFields.includes(v)), ...acc ] ,[])
    .filter(field => !formValues[category].includes(field))
    .filter(field => !editionFieldsToSave.includes(field))
    .filter(field => !['medium','mediumRaw'].includes(field)); // medium must stay
  return {
    ...data,
    ...multipleFields
      .filter(key => !!data[key])
      .reduce((acc, key) => ({ [key]: clearMultipleField(data[key]), ...acc }), {}),
    ...fieldsToClear.reduce((acc,key) => ({ ...acc, [key]: null }), {}),
  };
}

function* pushUpdate({ payload: id }) {
  yield call(delay, 500);
  try {
    yield put({ type: pushUpdateAction.request });
    yield callApi(Api.pushUpdate, { id });
    yield put({ type: pushUpdateAction.success });
    yield put(addNotification({ title: 'pushUpdateSuccess', type: 'success' }));
  } catch (e) {
    yield put({ type: pushUpdateAction.failure });
    yield put(addNotification({ title: 'pushUpdateFailure', type: 'error' }));
  }
}

function* reloadCustomerRtv(artworkId, { payload: { clusterNumber }}) {
  if (clusterNumber) {
    yield call(loadRTV, { payload: { artworkId, action: 'customer_manifold_clusters' }}, true);
  }
}

export default function* navigator({ params: { artworkId } }) {
  yield fork(loadArtworkData, artworkId);
  yield fork(loadNavigation, 'art', artworkId);
  yield takeLatest(pushUpdateAction.type, pushUpdate);
  yield takeEvery(onEdit.type, tryToInitLotForm);
  yield takeEvery(fetchRTVAction.type, loadRTV);
  yield takeOnce(fetchRTVAction.success, loadSecondRtv, { artworkId });
  yield takeEvery(updateClusterAction.type, reloadCustomerRtv, artworkId);
  yield takeEvery(enableOutliersAction.type, onEnableOutliers);
  yield takeEvery(disableOutliersAction.type, changeOutliers, null);

  yield takeEvery(changeStatusAction.type, changeStatusAndSubmit, 'artworkForm');
  yield takeEvery(changeLotStatusAction.type, changeStatusAndSubmit, 'lotForm');
  yield takeLatest(updateArtworkAction.type, saveForm, artworkId);
  yield takeLatest(updateLotAction.type, updateLot, artworkId);

  yield takeLatest(addLotAction.type, createLot, artworkId);

  yield takeEvery(lock.type, checkLock);
  yield takeEvery(remove.type, onRemove, artworkId);
  yield takeEvery(restore.type, onRestore, { artworkId }, Api.updateArtwork, loadArtworkData, artworkId);
  yield fork(watchLoadHistory);
}
