import React from "react";
import { callApiAction } from "domain/api";
import Accordion from "components/Accordion";
import PublishedSectionsTable from "../PublishedSectionsTable";
import classes from "./style.module.css";

function PublishedSections({ sectionName, data }) {
  const handleApiBtn = async (url) => {
    const postData = await callApiAction(url);
    postData()
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  return (
    <div className={classes.section}>
      <div className={classes.btnWrapper}>
        {data.calculate && (
          <button onClick={() => handleApiBtn(data.calculate)}>
            Calculate
          </button>
        )}
        {data.publish && (
          <button onClick={() => handleApiBtn(data.publish)}>Publish</button>
        )}
        {data.generateQuarterly && (
          <button onClick={() => handleApiBtn(data.generateQuarterly)}>
            Generate Quarterly
          </button>
        )}
        {data.generateSemiannual && (
          <button onClick={() => handleApiBtn(data.generateSemiannual)}>
            Generate Semiannual
          </button>
        )}
      </div>
      <Accordion title={sectionName} className={classes.accordion}>
        {data.tables.map((table, _idx) => {
          return (
            <PublishedSectionsTable
              key={_idx}
              table={table}
              sectionName={sectionName}
            />
          );
        })}
      </Accordion>
    </div>
  );
}

export default PublishedSections;
