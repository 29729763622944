import colors from 'theme/Colors';

export default {
  Edition: {
    display: 'block',
    width: '100%',
  },
  box: {
    marginTop: 12,
  },
  star: {
    color: colors.attention,
  },
  numberedBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
