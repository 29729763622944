import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import injectSheet from 'lib/sheet';
import styles from './sheet';

const NotFoundPage = ({ classes: { NotFound, header, subHeader, image } }) => <div className={NotFound}>
  <div className={header}><FM id="nfError"/></div>
  <div className={subHeader}><FM id="nfErrorMessage"/></div>
  <div className={image}></div>
</div>;

NotFoundPage.propTypes = {
  classes: PropTypes.shape({
    NotFound: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(NotFoundPage);
