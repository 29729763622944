import { fork, put, select } from 'redux-saga/effects';

import { actions } from 'domain/cluster';
import { listNavigator } from 'sagas/common';
import { getParams, getQuery } from 'domain/router';
import Api, { callApi } from 'domain/api';
import { checkQuery } from '../../lib/helpers';
import { filters } from 'filters/cluster'

function* loadData({ actions: { fetchListAction } } ) {
  yield put({ type: fetchListAction.request });
  const { artworkId, action } = (yield select(getParams)) || {};
  const query = (yield select(getQuery)) || {};
  try {
    const { data } = yield callApi(Api.getClusterList, { artworkId, action, query: checkQuery(query, filters) });
    yield put({ type: fetchListAction.success, payload: data });
  } catch (e) { console.error(e); }
}

export default function* navigator() {
  yield fork(listNavigator, { modelName: 'Cluster', actions, filters, loadData } );
}
