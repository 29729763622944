import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function RowEmpty({ classes: { rowEmpty, tdEmpty } }){
  return <div className={rowEmpty}>
    <div className={tdEmpty}>
      <FormattedMessage id="noResults"/>
      <br/>
      <FormattedMessage id="checkCriteria"/>
    </div>
  </div>;
}

RowEmpty.propTypes = {
  classes: PropTypes.shape({
    rowEmpty: PropTypes.string.isRequired,
    tdEmpty: PropTypes.string.isRequired,
  }).isRequired,
};
