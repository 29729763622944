import {
  DEFAULT_FONTFAMILY,
} from 'theme/const';
import { zIndex } from 'theme';
import colors from 'theme/Colors';

export default {
  ColumnsPopup: {
    padding: [30, 16, 16, 16],
    position: 'absolute',
    minWidth: 460,
    right: 14,
    top: 48,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.16)',
    zIndex: zIndex.COLUMNS_POPUP,
    backgroundColor: '#FFF',
    maxHeight: 600,
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: [0, 14],
  },
  setDefaultButton: {
    border: 'none',
    color: '#000',
    fontFamily: DEFAULT_FONTFAMILY,
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    appearance: 'none',
    cursor: 'pointer',
    outline: 'none',
  },
  accordion: {
    marginTop: 20,
  },
  columnsListWrapper: {
    display: 'flex',
    paddingTop: 10,
  },
  columnsList: {
    margin: 0,
    paddingLeft: 0,
    flex: '0 0 148px',
    marginLeft: 8,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  columnItem: {
    minHeight: 32,
    padding: [5, 0],
    lineHeight: '22px',
    boxSizing: 'border-box',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: colors.attention,
  },
  checkbox: {
    marginRight: '10px!important',
    cursor: 'pointer',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [0, 14],
  },
};
