import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM, FormattedHTMLMessage as FHM } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import toSnake from 'to-snake-case';

import { ConnectedPopup, sheet as popupSheet } from 'components/Popup';
import { PureButton, sheet as buttonSheet } from 'components/Button';

import { hidePopupByName, getPopupParamsByName } from 'domain/ui';

import sheet from './sheet';
import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';

const Button = injectSheet({
  ...buttonSheet,
  ghost: {
    ...buttonSheet.ghost,
    width: undefined,

  },
})(PureButton);

export const confirmationSheet = {
  ...popupSheet,
  container: {
    ...popupSheet.container,
    position: 'fixed',
    right: '50%',
    top: '50%',
    width: 400,
    height: 'auto',
    transform: 'translateY(-50%) translateX(50%)',
    boxShadow: `30px -30px ${colors.attention}`,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    position: 'relative',
    fontSize: 24,
    fontWeight: 700,
    boxSizing: 'border-box',
    padding: [30,20,10,40],
    alignItems: 'flex-start',
  },
  content: {
    padding: [20, 30, 10, 40],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  close: {
    ...popupSheet.close,
    right: 20,
    top: 30,
  },
};

export const Popup = injectSheet(confirmationSheet)(ConnectedPopup);

const confirmationPopup = (name, action, message = '') => {
  class ConfirmationPopup extends React.PureComponent {
    static propTypes = {
      classes: PropTypes.shape({}).isRequired,
      hidePopupByName: PropTypes.func.isRequired,
      action: PropTypes.func.isRequired,
      popupParams: PropTypes.shape({
        modelId: PropTypes.number,
        modelName: PropTypes.string,
      }).isRequired,
    };

    render(){
      const {
        classes: { buttons, content }, action,
        hidePopupByName, popupParams } = this.props;
      return (
        <Popup name={toSnake(name).toUpperCase()}>
          <div className={content}>
            <FHM id={message ? message : `popupAction.${name}`} values={ popupParams } />
          </div>
          <div className={buttons}>
            <Button
              type="button"
              buttonType="ghostActive"
              onClick={() => hidePopupByName(toSnake(name).toUpperCase())}
            ><FM id="cancel" /></Button>
            <Button
              type="button"
              buttonType="ghost"
              onClick={() => { action(popupParams); hidePopupByName(toSnake(name).toUpperCase()); }}
            ><FM id={name} defaultMessage="Ok"/></Button>
          </div>
        </Popup>
      );
    }
  }

  return compose(
    injectSheet(sheet),
    connect({
      popupParams: getPopupParamsByName(toSnake(name).toUpperCase()),
      action,
      hidePopupByName,
    }),
  )(ConfirmationPopup);
};

export default confirmationPopup;
