import { sheet as table } from 'react-immutable-jss-data-table';
import arrowDownBg from 'components/PageTable/images/arrowDown.svg';
import arrowUpBg from 'components/PageTable/images/arrowUp.svg';
import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors, { statusColors, marksBgColors } from 'theme/Colors';
import { actionsList } from 'components/PageTable/BulkActions/sheet';

export const commonColumns = {
  number: {
    fontFeatureSettings: '"tnum"',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  artistList: {
    width: 400,
  },
  td300: {
    width: 300,
  },
  td200: {
    width: '200px!important',
  },
  td150: {
    width: '150px!important',
  },
  td400: {
    width: 400,
  },
  id: {
    width: 120,
    fontFeatureSettings: '"tnum"',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  observer: {
    height: 10,
    width: '100%',
    position: 'absolute',
    backgroundColor: 'transparent',
    display: 'block',
    zIndex: -1,
    left: 0,
    bottom: '500px',
  },
  date: { width: 150 },
  sourceType: { width: 110 },
  year: { width: 90 },
  country: { width: 300 },
  loader: {
    display: 'inline-block',
    width: 20,
    height: 20,
    userSelect: 'none',
    pointerEvents: 'none',
    '&:after': {
      content: '" "',
      display: 'block',
      width: 16,
      height: 16,
      margin: 2,
      borderRadius: '50%',
      border: '2px solid',
      borderColor: `${colors.attention} transparent ${colors.attention} transparent`,
      animation: '$dualRing 1.2s linear infinite',
    },
  },
  '@keyframes dualRing': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  pdfLink: {
    outline: 0,
    paddingLeft: 28,
    lineHeight: '24px',
    border: 'none',
    cursor: 'pointer',
    display: 'block',
    background: {
      size: '24px 24px',
      position: 'left center',
      repeat: 'no-repeat',
      image: `url(${require('./images/ic-pdf.svg')})`,
    },
    color: colors.text,
    textDecoration: 'none',
    fontSize: '13px',
    letterSpacing: 'normal',

    '&:hover': {
      color: colors.attention,
    },
  },
};

export default {
  tableWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  DataTable: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    flex: '1 1 100%',
    overflow: 'auto',
    backgroundColor: '#FFF',
    '&.ArtistCARCalculations__table': {
      boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)',
    },
  },
  ColumnsSelector: {
    height: '100%',
    position: 'relative',
  },
  thead: {
    display: 'flex',
    position: 'sticky',
    top: '0',
    height: 53,
    zIndex: 1,
  },
  tbody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 53px)',
  },
  bottomLoader: {
    width: '100%',
    padding: '10px 50%',
    height: 30,
    boxSizing: 'border-box',
    position: 'relative',
  },
  rowEmpty: {
    width: '100%',
    paddingTop: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '26px',
    fontSize: 18,
    color: colors.labelLink,
    textAlign: 'center',
    position: 'absolute',
  },
  loadWrapper: {
    width: '100%',
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'absolute',
  },
  tr: {
    flexGrow: 1,
    borderBottom: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    height: 47,
    position: 'relative',
    boxSizing: 'border-box',
    '&:hover &:not($trEdit)': {
      backgroundColor: `${colors.warning}`,
    },
    '&:hover $actions': {
      display: 'flex',
    },
  },
  trEdit: {
    height: 70,
    lineHeight: '70px',
    '& $actions': {
      display: 'flex',
    },
  },
  actions: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  visible: {
    display: 'inline-flex',
    height: 70,
  },
  actionButton: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    border: 0,
    outline: 'none',
    padding: [0, 10],
    position: 'relative',
    margin: 5,
    background: {
      size: '20px 20px',
      position: 'center center',
      repeat: 'no-repeat',
    },
  },
  edit: {
    backgroundImage: `url(${require('./images/ic-edit.svg')})`,
    '&:hover': {
      backgroundImage: `url(${require('./images/ic-edit-hover.svg')})`,
    },
  },
  more: {
    backgroundImage: `url(${require('./images/ic-more.svg')})`,
    '&:hover': {
      '& $list': {
        display: 'flex',
      },
      backgroundImage: `url(${require('./images/ic-more-hover.svg')})`,
    },
  },
  save: {
    backgroundImage: `url(${require('./images/ic-accept.svg')})`,
    '&:hover': {
      backgroundImage: `url(${require('./images/ic-accept-hover.svg')})`,
    },
  },
  cancel: {
    backgroundImage: `url(${require('./images/ic-remove.svg')})`,
    backgroundSize: '16px 16px',
    '&:hover': {
      backgroundImage: `url(${require('./images/ic-remove-hover.svg')})`,
    },
  },
  rowWrapper: {
    display: 'flex',
  },
  th: {
    color: colors.text,
    fontWeight: 500,
    fontSize: 13,
    fontFamily: DEFAULT_FONTFAMILY,
    '&:hover $orderArrow': {
      opacity: 0.3,
    },
    whiteSpace: 'nowrap',
    height: '100%',
    padding: [0,25,0,5],
    boxSizing: 'border-box',
    width: 150,
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    '&.ArtistCARCalculations__td': {
      paddingRight: 5,
      width: 120,
      whiteSpace: 'normal',
    },
  },
  td: {
    fontFamily: DEFAULT_FONTFAMILY,
    display: 'block',
    padding: [0,25,0,5],
    boxSizing: 'border-box',
    width: 150,
    flex: '0 0 auto',
    '&.ArtistCARCalculations__td': {
      paddingRight: 5,
      width: 120,
      '&__label': {
        fontWeight: 600,
        textTransform: 'capitalize',
      },
    },
  },
  trEven: table.trEven,
  trOdd: {
    ...table.trOdd,
    '&.ArtistCARCalculations__row': {
      backgroundColor: '#f7f7f7',
    },
  },
  tdEmpty: table.tdEmpty,
  trh: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: '#FFF',
    borderBottom: `2px solid ${colors.attention}`,
    alignItems: 'center',
  },
  thOrdered: {
    '& $orderArrow': {
      opacity: 1,
    },
    '&:hover $orderArrow': {
      opacity: 1,
    },
  },
  tdMarkerTh: {
    flex: '0 0 4px',
    height: '100%',
    width: '4px',
  },
  tdMarker: {
    width: 4,
    flex: '0 0 auto',
    height: '100%',
  },
  tdActions: {
    flexGrow: 1,
    padding: 0,
    width: 65,
    display: 'flex',
  },
  tdCheck: {
    ...table.td,
    flex: '0 0 auto',
    width: 54,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: [0, 5],
  },
  tdOptions: {
    ...table.td,
    flex: '0 0 60px',
    padding: [19, 20, 18, 20],
    display: 'flex',
  },
  thTitle: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  orderArrow: {
    width: 12,
    height: 12,
    display: 'inline-block',
    border: 'none',
    appearance: 'none',
    opacity: 0,
    background: {
      image: `url("${arrowUpBg}")`,
      repeat: 'no-repeat',
      position: 'left top',
      size: '12px 12px',
      color: 'transparent',
    },
    marginLeft: 5,
    cursor: 'pointer',
  },
  orderedDesc: {
    backgroundImage: `url("${arrowDownBg}")`,
  },
  removeOrderByField: {
    fontSize: '10px',
    color: '#a9431e',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    padding: 5,
    cursor: 'pointer',
  },
  trMarkNew: {
    ...marksBgColors.new,
  },
  trMarkDraft: {
    ...marksBgColors.draft,
  },
  trMarkValid: {
    ...marksBgColors.valid,
  },
  trMarkAccepted: {
    ...marksBgColors.accepted,
  },
  trMarkDisplay: {
    ...marksBgColors.display,
  },
  trMarkIgnored: {
    ...marksBgColors.ignore,
  },
  // checked row
  trChecked: {
    backgroundColor: '#fbf2e1',
  },
  // markers
  tdMarkerNew: {
    extend: 'tdMarker',
    ...statusColors.new,
  },
  tdMarkerDisplay: {
    extend: 'tdMarker',
    ...statusColors.display,
  },
  tdMarkerDraft: {
    extend: 'tdMarker',
    ...statusColors.draft,
  },
  tdMarkerValid: {
    extend: 'tdMarker',
    ...statusColors.valid,
  },
  tdMarkerAccepted: {
    extend: 'tdMarker',
    ...statusColors.accepted,
  },
  tdMarkerIgnored: {
    extend: 'tdMarker',
    ...statusColors.ignore,
  },
  textBox: {
    position: 'relative',
    // overflow: 'hidden',
    minWidth: 0,
  },
  contentWrp: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  fieldBox: {
    display: 'flex',
    height: 32,
    fontWeight: 400,
    paddingRight: 5,
  },
  link: {
    textDecoration: 'none',
    color: colors.links,
    border: 'none',
    background: 'transparent',
    padding: 0,
    display: 'block',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:visited': {
      color: colors.links,
    },
  },
  ...actionsList,
  customer: {
    fontWeight: 500,
  },
  price: {
    fontFeatureSettings: '"tnum"',
    justifyContent: 'flex-end',
    textAlign: 'right',
  },
  errorColumn: {
    height: 13,
    background: {
      image: `url("${require('./images/warning.svg')}")`,
      repeat: 'no-repeat',
      position: 'left center',
      size: '14px 13px',
    },
  },
  Button: {
    backgroundColor: '#000',
    color: '#fff',
    border: 0,
    lineHeight: '30px',
    width: '100%',
    fontWeight: 500,
  },
  highOutlier: {
    backgroundColor: '#75a7ff77',
  },
  lowOutlier: {
    backgroundColor: '#fffbba77',
  },
  ...commonColumns,
};
