import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage as FM } from 'react-intl';
import { Link } from 'react-router-dom';

import { lnk } from 'lib/routes';
import { status as baseStatusList, clearSelection } from 'domain/const';
import { modalModeSelector } from 'domain/ui';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import Button from 'components/Button';
import { splitQuery } from 'lib/helpers';

const StatusLink = ({ linkName, quantity = 0, wfAcceptance, clearSelection, classes: { link, active }, query = {} }) => {
  const { wfAcceptance: wfAcceptanceFromQuery = [], ...restQueryParams } = query;
  const activeStatuses = [].concat(wfAcceptanceFromQuery);
  const queryValue = baseStatusList[wfAcceptance];
  const isActive = queryValue ? activeStatuses.includes(queryValue) : activeStatuses.length === 0;
  const isActiveList = queryValue ? activeStatuses.filter(v => v !== queryValue) : undefined;
  const isNotActiveList = queryValue ? activeStatuses.concat(queryValue) : undefined;
  const params = {
    query: {
      ...restQueryParams,
      page: undefined,
      wfAcceptance: isActive ? isActiveList : isNotActiveList
    }
  };
  return <Link
    onClick={() => clearSelection()}
    to={lnk(linkName, params)}
    className={cx(link, { [active]: isActive })}
  >
    <FM id={wfAcceptance}/>
    { ` (${new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(quantity || 0)})` }
  </Link>;
};

StatusLink.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
  }),
  wfAcceptance: PropTypes.string,
  query: PropTypes.object,
  linkName: PropTypes.string,
  clearSelection: PropTypes.func.isRequired,
  quantity: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};

const ListHeader = ({
  classes,
  className,
  title,
  stats = {},
  linkName,
  query,
  action,
  modalMode,
  actionTextKey = 'cancel',
  clearSelection,
  softDelete = true,
  showInvalid = '',
}) => {
  const { display, ...status } = baseStatusList;
  if (['artworkList','lotList'].includes(linkName)) status['display'] = display;

  const list = [
    {
      wfAcceptance: 'all',
      quantity: Object.keys(status).reduce((p,n) => p + (stats[n] || 0)*1, 0),
      classes, linkName, query,
    },
    ...Object.keys(status).map(wfAcceptance => ({
      wfAcceptance,
      quantity: stats[wfAcceptance],
      classes, linkName, query,
    })),
  ];
  const { filterValues } = splitQuery(query);
  return (
    <div className={cx(classes.ListHeader, { [className]: !!className })}>
      <div className={classes.container}>
        <div className={classes.nav}>
          <h2 className={classes.title}>
            { !!query.isDelete && <FM id="deletedPrefix"/> }
            { !!query.isInvalid && <FM id="invalidPrefix"/> }
            <FM id={title}/>
          </h2>
          {[ !query.isDelete && !query.isInvalid && Object.keys(stats).length > 0 && list.map(params => <StatusLink
            clearSelection={clearSelection}
            key={params.wfAcceptance}
            classes={classes}
            {...params}
          />) ]}
        </div>
        <div className={classes.buttons}>
          {
            !!softDelete && !query.isDelete && !query.isInvalid &&
              <Link
                className={classes.deleted}
                to={lnk(linkName, { query: { pageSize: query.pageSize, ...filterValues, isDelete: true } })}
              >
                <FM id="showDeleted" />
              </Link>
          }
          {
            showInvalid && !query.isInvalid && !query.isDelete &&
            <Link
              className={classes.deleted}
              to={lnk(linkName, { query: { isInvalid: showInvalid } })}
            >
              <FM id="showInvalid" />
            </Link>
          }
          {
            !query.isDelete && !query.isInvalid && !!action && !modalMode && actionTextKey &&
              <Button
                type="button"
                buttonType="primary"
                onClick={() => action()}
              >
                <FM id={actionTextKey} />
              </Button>
          }
          { ((!!softDelete && !!query.isDelete) || (!!showInvalid && !!query.isInvalid)) && <Link
            className={classes.notDeleted}
            to={lnk(linkName, { query: { pageSize: query.pageSize, ...filterValues } })}
            >
            <FM id={query.isDelete ? 'showNotDeleted' : 'showNotInvalid'} />
            </Link>
          }
        </div>
      </div>
    </div>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.shape({
    ListHeader: PropTypes.string,
  }),
  className: PropTypes.string,
  stats: PropTypes.object,
  query: PropTypes.object,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  action: PropTypes.func,
  linkName: PropTypes.string.isRequired,
  actionTextKey: PropTypes.string,
  modalMode: PropTypes.string,
  clearSelection: PropTypes.func.isRequired,
  softDelete: PropTypes.bool,
  showInvalid: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  connect({
    modalMode: modalModeSelector,
    clearSelection,
  }),
)(ListHeader);
