import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { delay } from 'sagas/common';
import { push } from 'connected-react-router';
import { watchCommonActionsCall } from 'sagas/actions';

import {
  fetchLotsListAction,
  createLotAction,
  onEdit,
  updateLotInLineAction,
  checkLotAction,
  lotList,
  getPricesAction,
  updatePriceAction,
} from 'domain/lotList';

import { addNotification } from 'domain/env';
import { hidePopupByName, showPopup } from 'domain/ui';
import { apiFilters } from 'filters/lot';
import checkData from 'lib/dataMutatotrs';
import { lnk } from 'lib/routes';

import Api, { callApi } from 'domain/api';
import { loadListData } from 'sagas/common';

function* saveForm({ payload, resolve }) {
  yield call(delay, 500);
  try {
    const { data: { artId: artworkId } } = yield callApi(Api.addLot, { data: checkData(payload) });
    resolve();
    yield put(hidePopupByName('ADD_LOT'));
    yield put(push(lnk('artwork', { artworkId })));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'itemNotCreated', type: 'error' }));
  }
}

function* updateForm({ payload: { id: lotId, ...data }, resolve }) {
  yield call(delay, 500);
  try {
    const { data: payload } = yield callApi(Api.updateLot, { lotId, data });
    yield put(onEdit(null));
    yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success' }));
    resolve();
    yield put({
      type: updateLotInLineAction.success,
      payload,
    });
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}

function* loadData(resetChecked = true) {
  const params = {
    modelName: 'Lots',
    actions: { fetchListAction: fetchLotsListAction, selectAction: checkLotAction },
    filters: apiFilters,
    selectors: { list: lotList },
  };
  yield call(loadListData, params, resetChecked );
}

function* getPricesList({ payload: { lotId, title } }) {
  const { data } = yield callApi(Api.getLotPrices, { lotId } );
  if (data && data.length) yield put(showPopup({ name: 'NEW_PRICES', lotId, title, prices: data }));
}

function* updatePrice({ payload: { lotId, ...data } }) {
  try {
    yield call(delay, 200);
    const { data: newData } = yield callApi(Api.updateLot, { lotId, data: { isSoldPriceUpdated: false, ...data } });
    yield put({ type: updatePriceAction.success, payload: newData });
    yield put(hidePopupByName('NEW_PRICES'));
    yield put(addNotification({ title: 'lotPriceUpdated' }));
  } catch (e) {
    yield put(addNotification({ title: 'lotPriceNotUpdated', type: 'error' }));
  }
}

export default function* navigator() {
  yield fork(loadData, false);
  yield takeLatest(createLotAction.type, saveForm);
  yield takeLatest(updateLotInLineAction.type, updateForm);
  yield takeLatest(getPricesAction.type, getPricesList);
  yield takeLatest(updatePriceAction.type, updatePrice);
  yield fork(watchCommonActionsCall, 'Lot', loadData);
}
