var overriddenStop =  Event.prototype.stopPropagation;
Event.prototype.stopPropagation = function() {
  this.isPropagationStopped = true;
  overriddenStop.apply(this, arguments);
};

export default class EventDispatcher {
  events = {};

  addEventListener(eventName, listener, listenerName) {
    const { events } = this;
    if (!(eventName in events)) {
      events[eventName] = [];
    }
    events[eventName].unshift({ listener, name: listenerName });
  }

  removeEventListener(eventName, listener) {
    const { events } = this;
    if (!(eventName in events)) {
      events[eventName] = [];
      return;
    }
    events[eventName] = events[eventName].filter(l => l.listener !== listener);
  }

  dispatch(eventName, event) {
    const { events } = this;
    const listenerList = events[eventName] || [];
    try {
      for (let l of listenerList) {
        const isPropagationStopped = ((typeof event.isPropagationStopped === 'function') ? event.isPropagationStopped() : event.isPropagationStopped);
        if (event.defaultPrevented || isPropagationStopped) {
          break;
        } else {
          l.listener(event);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  setListenerName(eventName, listener, newName) {
    const { events } = this;
    const listenerList = events[eventName] || [];
    for (let l of listenerList) {
      if (l.listener === listener) {
        l.name = newName;
        break;
      }
    }
  }
}

export const defaultDispatcher = new EventDispatcher();
