import { put, call, fork, select, takeEvery, all } from 'redux-saga/effects';
import Api, { callApi } from 'domain/api';
import { mergeArtworkAction, fetchArtworkAction } from 'domain/artwork';
import { authorsById, fetchAuthorsAction } from 'domain/author';
import { fetchArtworksListAction, removeFromListById } from 'domain/artworkList';
import { addNotification } from 'domain/env';
import { loadingCompleteAction } from 'domain/ui';

let mergeArtToken = null;

function* loadAuthor(artistId, dictionary, authors) {
  if (artistId && !dictionary.has(artistId)) {
    const { data } = yield callApi(Api.getAuthor, { artistId });
    authors.push(data);
  }
}

function* loadSimilar(artworkId) {
  yield put({ type: fetchArtworksListAction.request });
  const { data: { list, mergeArtToken: token } } = yield callApi(Api.getSimilarArtworks, { artworkId });
  mergeArtToken = token;
  const dictionary = yield select(authorsById);
  let authors = [];
  yield all(list
    .map(({ artistId }) => artistId)
    .filter((v, index, arr) => index === arr.indexOf(v))
    .map(artistId => call(loadAuthor, artistId, dictionary, authors )));
  yield put({ type: fetchAuthorsAction.success, payload: authors });
  yield put({
    type: fetchArtworksListAction.success,
    payload: { list },
  });
}

export function* mergeArtwork(artworkId, { payload: { id } }) {
  try {
    const { data: { updatedMergeArtToken } } = yield callApi(Api.mergeArtwork, { artworkId, id, data: { mergeArtToken } });
    mergeArtToken = updatedMergeArtToken;
    yield put(addNotification({ title: 'artworkMerged' }));
    yield put(removeFromListById(id));
  } catch (e){
    yield put(addNotification({ title: 'artworkNotMerged' }));
  }
}

function* watchers(artworkId) {
  yield takeEvery(mergeArtworkAction.type, mergeArtwork, artworkId);
}

export function* loadArtworkData(artworkId) {
  try {
    const { data: payload } = yield callApi(Api.getArtwork, { artworkId });
    if (payload.artistId) yield put(fetchAuthorsAction([payload.artistId]));
    yield put({ type: fetchArtworkAction.success, payload });
  } catch (e) {

  }
}

export default function* navigator({ params: { artworkId } }) {
  yield fork(watchers, artworkId);
  yield call(loadArtworkData, artworkId);
  yield put(loadingCompleteAction());
  yield call(loadSimilar, artworkId);
}
