import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import cx from 'classnames';

function PureButton({ classes, className, buttonType, type = 'button', ...props }) {
  delete props.sheet;
  return (
    <button type={type} className={cx(classes[buttonType], className)} {...props} />
  );
};

PureButton.propTypes = {
  classes: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    ghost: PropTypes.string.isRequired,
    ghostActive: PropTypes.string.isRequired,
  }).isRequired,
  sheet: PropTypes.any,
  className: PropTypes.string,
  buttonType: PropTypes.oneOf(['primary', 'secondary','ghost','ghostActive']),
  type: PropTypes.oneOf(['button', 'submit']),
};

export { sheet, PureButton };
export default injectSheet(sheet)(PureButton);
