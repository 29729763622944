import React from 'react';
import queryString from 'query-string';

const Debug = (props) => () => {
  return <span>{JSON.stringify({ ...props })}</span>;
};

export default ({ PageNumberLink, Dots }) => {
  const pageItem = ({ page, current }) => {
    return {
      Component: PageNumberLink,
      page,
      active: page === current,
    };
  };

  const pageRange = ({ from, to, current }) => {
    const ret = [];
    for (let i = from; i <= to; i++) {
      ret.push(pageItem({ page: i, current }));
    }
    return ret;
  };

  return {
    pageItem,

    dots: (key) => {
      return {
        Component: Dots,
        page: key,
      };
    },

    pageRange,

    prev2: (page) => {
      return pageRange({ from: page - 2, to: page - 1, current: page });
    },

    next2: (page) => {
      return pageRange({ from: page + 1, to: page + 2, current: page });
    },

    current: (page) => {
      return pageItem({ page, current: page });
    },

    debug: (...args) => ({
      Component: Debug(args),
    }),
  };

};

export const urlSetParams = (location, params) => {
  const { hash, pathname, search } = location;
  const query = queryString.parse(search || '', { arrayFormat: 'bracket' });
  return pathname + '?' +
        queryString.stringify({ ...query, ...params }, { arrayFormat: 'bracket' }) +
        (hash || '');
};

// IN0 - inner count (zero based)
// OUT to IN0
export const parsePage = pageStr => {
  const pageInt = parseInt(pageStr, 10);
  return isNaN(pageInt) ? 0 : pageInt - 1;
};
// IN0 to OUT
export const formatPage = page => page + 1;
