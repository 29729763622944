export default {
  ColumnsSelector: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  popupWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
  },
  optionsButtonWrapper: {
    position: 'absolute',
    top: 0,
    right: 16,
    zIndex: 2,
    width: 60,
    height: 51,
    backgroundColor: '#FFF',
  },
  optionsButton: {
    outline: 'none',
    float: 'right',
    marginRight: 20,
    marginTop: 15,
    border: 'none',
    appearance: 'none',
    borderRadius: 0,
    width: 20,
    height: 20,
    boxSizing: 'border-box',
    background: {
      image: `url("${require('./options.svg')}")`,
      size: '20px 20px',
      repeat: 'no-repeat',
      color: 'transparent',
      position: 'left top',
    },
    '&:hover': {
      backgroundImage: `url("${require('./optionsPushed.svg')}")`,
    },
  },
  optionsButtonPushed: {
    extend: 'optionsButton',
    backgroundImage: `url("${require('./optionsPushed.svg')}")`,
  },
};
