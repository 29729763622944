import React from "react";
import classes from "./style.module.css";

function IndexTableBody({ rowHeader, data }) {
  return (
    <table className={classes.table}>
      <thead className={classes.thead}>
        <tr>
          <th style={{ color: "red" }}>{rowHeader[0]}</th>
          {rowHeader.slice(1).map((kn, i) => {
            return <th key={i}>{kn}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i} className={classes.tr}>
            {Object.values(item).map((value, i) => {
              return <td key={i}>{value}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default IndexTableBody;
