import {
  connectAcceptance,
  createIdLink,
  toNumber,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import { dateTimeToString } from '../lib/serialize';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('session'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  jobId: { sortable: true, className: 'td300' },
  spiderName: { sortable: true, className: 'td200' },
  pendingDate: {
    sortable: true,
    getValue: dateTimeToString('MM/DD/YYYY H:mm', '−'),
    parser: dateTimeToString(),
  },
  openDate: {
    sortable: true,
    getValue: dateTimeToString('MM/DD/YYYY H:mm', '−'),
    parser: dateTimeToString(),
  },
  closeDate: {
    sortable: true,
    getValue: dateTimeToString('MM/DD/YYYY H:mm', '−'),
    parser: dateTimeToString(),
  },
  createdCountLots: { sortable: true, className: 'price', getValue: toNumber },
  updatedCountLots: { sortable: true, className: 'price', getValue: toNumber },
  totalCountLots: { sortable: true, className: 'price', getValue: toNumber },
  wfAcceptance: { sortable: true },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
