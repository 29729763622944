import { filterParams as base } from './artwork';
import {
  createFilterTransfer,
  stringFilter,
} from './common';

const columnsTransfer = {
  artistId: [ 'artist' ],
  isSignature: [ 'signature', 'isSignature' ],
  artistIdId: [ 'artist' ],
};

export const filterTransfer = createFilterTransfer(base.filters, columnsTransfer);

export const filterParams = {
  ...base,
  filters: {
    ...base.filters,
    title: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    artistId: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    category: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    medium: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    lastSaleNumber: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    lastLotNumber: stringFilter('artwork', null, 'AND(customer_arts__is_considerations:1)'),
    auctionHouseLast: stringFilter('artwork', 'auction_lots__auction_id', 'AND(customer_arts__is_considerations:1)'),
    artistIdWfAcceptance: stringFilter('artwork', 'artist_id__wf_acceptance', 'AND(customer_arts__is_considerations:1)'),
    usernameLastUpdate: stringFilter('artwork'),
  },
  columnsTransfer,
  filterTransfer,
};

export default filterParams.filters;
