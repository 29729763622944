import record, { integer, bool, listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';

import { JunkCharacter, Pagination } from 'domain/lib';
import { action, asyncAction } from 'lib/actions';
import { updateSelectedList } from 'domain/common';

export const fetchJunkCharacterListAction = asyncAction('junk_character/GET_LIST');
export const createJunkCharacterAction = asyncAction('junk_character/CREATE');
export const checkJunkCharacterAction = action('junk_character/CHECK');
export const updateJunkCharacterInLineAction = asyncAction('junk_character/UPDATE_INLINE');
export const removeFromListById = action('junk_character/REMOVE_FROM_LIST');
export const onEditAction = action('junk_character/EDIT');

const State = record('Artworks', {
  list: listOf(JunkCharacter),
  pagination: Pagination(),
  selected: listOf(integer),
  onEdit: integer(),
  loading: bool(true),
});


const base = field('junkCharacter');
export const junkCharacterList = base.then(State.$list);
export const selectedjunkCharacters = base.then(State.$selected);
export const junkCharacterListPagination = base.then(State.$pagination);
export const junkCharacterListStats = base.then(State.$stats);
export const edit = base.then(State.$onEdit);
export const loading = base.then(State.$loading);

export const reducer = {
  junkCharacter(state = new State(), { type, payload }) { //NOSONAR
    switch (type) {

      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$list.clear(),
          State.$selected.clear(),
          State.$loading.set(true),
          State.$onEdit.set(),
        );

      case onEditAction.type:
        return state.apply(
          State.$onEdit.set(payload),
        );

      case fetchJunkCharacterListAction.request:
        return state.apply(
          State.$loading.set(true),
        );

      case fetchJunkCharacterListAction.success:
        return state.apply(
          State.$list.parsed(payload.list),
          State.$pagination.parsed(payload.pagination),
          State.$loading.set(false),
        );

      case updateJunkCharacterInLineAction.success:
        return state.apply(
          State.$list.update(list => list.set(list.findIndex(v => v.get('id') === payload.id), JunkCharacter.parse(payload))),
        );

      case createJunkCharacterAction.success:
        return state.apply(
          State.$list.updateBy('push', JunkCharacter.parse(payload)),
        );

      case checkJunkCharacterAction.type:
        return updateSelectedList(state, payload, State);

      case removeFromListById.type:
        return state.apply(
          State.$list.update(list => list.filter(v => v.get('id') !== payload)),
        );

      default:
        return state;
    }
  },
};
