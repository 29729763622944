import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import FileDownload from 'js-file-download';
import I from 'immutable';

import Api from 'domain/api';
import Loader from 'components/Loader';
import { checkQuery, toSnake } from 'lib/helpers';

import { getQuery, getParams, getLocationName } from 'domain/router';
import { settings } from 'domain/settings';
import {addNotification, token} from 'domain/env';
import injectSheet from 'lib/sheet';
import styles from './sheet';

class Exporter extends React.Component {

  static propTypes = {
    token: PropTypes.string,
    apiKey: PropTypes.string,
    query: PropTypes.shape({}),
    settings: PropTypes.instanceOf(I.Map),
    location: PropTypes.string,
    filters: PropTypes.shape({}),
    tableName: PropTypes.string.isRequired,
    classes: PropTypes.shape({}),
  }

  state = {
    loading: false,
  }

  download = () => {
    const { token, apiKey, query: pageQuery, settings, tableName, location, filters = {}, params, addNotification } = this.props;
    const fields = settings.getIn([location, 'tables', tableName], []).map(toSnake).join(',');
    const query = {
      ...checkQuery(pageQuery, filters),
      fields,
    };
    this.setState({ loading: true }, () => {
      Api[apiKey]({ query, token, ...params })
        .then(({ data, headers }) => {
          this.setState({ loading: false }, () => {
            FileDownload(data, headers['content-disposition'].split('"')[1]);
          });
        })
        .catch((e) => {
          addNotification({ type: 'error', title: 'Error while getting data', trans: false });
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { classes, apiKey, tableName } = this.props;
    if (!apiKey || !Api[apiKey] || !tableName ) return null;
    const { loading } = this.state;
    return (
      <button
        className={classes.exporter}
        type="button"
        disabled={loading}
        onClick={() => this.download()}
      >
        { loading && <Loader /> }
        CSV
      </button>
    );
  }
}

export default compose(
  connect({
    query: getQuery,
    params: getParams,
    settings, location: getLocationName,
    token,
    addNotification
  }),
  injectSheet(styles),
)(Exporter);
