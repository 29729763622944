import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import Message from 'components/form/message';

import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';
import injectSheet from 'lib/sheet';

export const sheet = {
  wrapper: { width: '100%', display: 'flex', flexWrap: 'wrap' },
  element: {
    width: '100%',
    height: 32,
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    padding: [0, 10],
    boxSizing: 'border-box',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  error: {
    borderColor: `${colors.error}!important`,
  },
  warning: {
    backgroundColor: colors.warning,
  },
};

export class Input extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    type: PropTypes.string,
    Wrapper: PropTypes.string,
    validate: PropTypes.arrayOf(
      PropTypes.func,
    ),
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    hasWarning: PropTypes.bool,
    messageKey: PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
    autoComplete: 'disabled',
    hasWarning: false,
  }

  render() {
    const { classes: { wrapper, element, error, warning },
      intl: { formatMessage }, input, meta = {},
      autoComplete, placeholder, hasWarning, messageKey, ...props } = this.props;
    return (
      <div className={wrapper}>
        <input
          autoComplete={autoComplete}
          placeholder={formatMessage({ id: placeholder || `placeholder.${input.name}` })}
          className={cx(element, { [error]: meta.touched && (meta.error || meta.submitError), [warning]: hasWarning })}
          {...props}
          {...input}
        />
        <Message meta={meta} name={messageKey || input.name} />
      </div>
    );
  }
}

export const PureInput = injectIntl(Input);
export default injectSheet(sheet)(PureInput);
