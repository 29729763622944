import { DEFAULT_FONTFAMILY } from 'theme/const';
import nextBg from './images/next.svg';
import nextBgDisabled from './images/next-disabled.svg';
import prevBg from './images/prev.svg';
import prevBgDisabled from './images/prev-disabled.svg';
import colors from 'theme/Colors';

const fontFamily = DEFAULT_FONTFAMILY;

const prevAndNext = {
  background: {
    repeat: 'no-repeat',
    size: '8px 12px',
    position: 'center center',
    image: `url("${prevBg}")`,
  },
  flex: '0 0 15px',
  paddingLeft: 0,
  paddingRight: 0,
  '&:hover': {
    borderColor: 'transparent',
    textDecoration: 'underline',
  },
  '&$disabled': {
    color: '#babcbf',
    backgroundImage: `url("${prevBgDisabled}")`,
  },
};

export default {
  Pagination: {
    // root
    //userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    font: `400 14px/16px ${DEFAULT_FONTFAMILY}`,
  },
  item: {
    padding: [0, 5],
    borderRadius: '15px',
    border: '1px solid transparent',
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    color: colors.menu,
    background: 'transparent',
    fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '26px',
    height: 26,
    minWidth: 26,
    marginLeft: 5,
    '&:hover': {
      borderColor: colors.attention,
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
  active: {
    borderColor: colors.attention,
    backgroundColor: colors.attention,
    color: '#ffffff',
    '&:hover': {
      borderColor: colors.attention,
    },
  },
  dots: {
    cursor: 'default',
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  nextPage: {
    extend: prevAndNext,
    backgroundImage: `url("${nextBg}")`,
    '&$disabled': {
      color: '#babcbf',
      backgroundImage: `url("${nextBgDisabled}")`,
    },
  },
  prevPage: {
    extend: prevAndNext,
  },
  disabled: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  ItemsPerPage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  ItemsPerPageLabel: {
    flex: '1 1 30px',
    whiteSpace: 'nowrap',
  },
  ItemsPerPageSelect: {
    flex: '0 0 70px',
    marginLeft: 10,
    width: '100%',
  },
  JumpToPage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30,
    justifyContent: 'flex-end',
  },
  JumpToPageLabel: {
    flex: '0 0 30px',
    whiteSpace: 'nowrap',
  },
  JumpToPageInput: {
    flex: '0 0 60px',
    marginLeft: 10,
    width: 50,
  },
};
