import { put, call, select, all, fork, takeEvery, cancel } from 'redux-saga/effects';
import toSnake from 'to-snake-case';

import { ucFirst } from 'lib/helpers';
import { watchSearch as authorsSearch, watchLoadAuthors } from 'domain/author/saga';

import Api, { callApi } from 'domain/api';
import { isAuthorized, userProfileAction } from 'domain/env';
import {
  dictionaries,
  dictionariesList,
  fetchDictionaryAction,
  fetchDictionaryItemsAction,
  dictionaryById,
} from './index';

function* loadByName(name, parser) {
  const apiFunctionKey = `get${ucFirst(name)}`;
  if (!Api[apiFunctionKey]){
    return;
  }
  try {
    const { data: { list } } = yield callApi(Api[apiFunctionKey]);
    yield put({ type: fetchDictionaryAction.success, payload: { list: list.map(parser), name } });
  } catch (err) {
    yield put({ type: fetchDictionaryAction.failure, payload: err });
  }
}

function* loadDictionaryItems({ payload: { name, ids = [] } }) {
  const dictionary = yield select(dictionaryById(name));
  const idList = ids.filter(id => !dictionary.includes(id));
  if (!idList.length) return;
  try {
    const list = yield all(idList.map(id => callApi(Api.getDictionaryItem, { id, name: toSnake(name) })));
    yield put({
      type: fetchDictionaryItemsAction.success,
      payload: { name, list: list.map(({ data }) => data) },
    });
  } catch (err) {
    yield put({ type: fetchDictionaryItemsAction.failure, payload: err });
  }
};

function* checkDictionaries() {
  const dictionary = yield select(dictionaries);
  yield all(Object.keys(dictionariesList)
    .filter(key => dictionary.get(key).size === 0)
    .map(key => fork(loadByName, key, dictionariesList[key])));
}


let task = null;

function* forceLoadDictionaries() {
  if (yield select(isAuthorized)){
    yield call(checkDictionaries);
    yield cancel(task);
  }
}

const matchAuth = ({ type }) => [
  userProfileAction.success,
].includes(type);

export function* ensureDictionaries() {
  const hasAuth = yield select(isAuthorized);
  if (hasAuth || process.env.NODE_ENV === 'test') {
    yield fork(checkDictionaries);
  } else {
    if (!task) task = yield takeEvery(matchAuth, forceLoadDictionaries);
  }
  yield fork(authorsSearch);
  yield fork(watchLoadAuthors);
  yield takeEvery(fetchDictionaryItemsAction.type, loadDictionaryItems);
}
