import { defineColumns } from 'components/PageTable/defineColumns';
import { dictList } from 'lib/serialize';
import { ArtistLink } from 'fields/artist';

const columns = defineColumns(
  { id: {
    className: 'id',
    getValue: ArtistLink,
    parser: ({ value }) => value === undefined ? '' : value,
  } },
  'fullName',
  {
    ao: { getValue: ({ rowData: { countScrapedArts } }) => countScrapedArts || 0 },
    residences: { getValue: dictList() },
  },
  'birthDate','deathDate',
);

export default columns.all();
