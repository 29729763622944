import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isValidReactComponent } from 'react-immutable-jss-data-table';
import { FormattedHTMLMessage } from 'react-intl';

class PaginationSchema extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pages: PropTypes.number,
    linkBuilder: PropTypes.func.isRequired,
    pageSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSizeChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    parse: PropTypes.func.isRequired,
    format: PropTypes.func.isRequired,
    calculate: PropTypes.func.isRequired,
    className: PropTypes.string,
    PageItem: isValidReactComponent,
    PrevPageLink: isValidReactComponent,
    NextPageLink: isValidReactComponent,
    ItemsPerPage: isValidReactComponent,
    JumpToPage: isValidReactComponent,
  };

  render() {
    const { classes, page: pageStr, pages, total = 0, linkBuilder, pageSize, onSizeChange, onPageChange, parse, format, calculate, className } = this.props;
    const { PageItem, PrevPageLink, NextPageLink, ItemsPerPage, JumpToPage } = this.props;
    const page = parse(pageStr);
    const { items, nextDisabled } = calculate({ pages, page });
    const value = new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(total || 0);
    return (
      <div
        className={cx(classes.Pagination, className)}
      >
        <FormattedHTMLMessage id="totalItems" values={{ value }} />
        { items.length > 1 && <PrevPageLink
          classes={classes}
          page={page - 1}
          linkBuilder={linkBuilder}
          format={format}
        />}
        {
          items.length > 1 && items.map((v, k) => (
            <PageItem {...v} key={k} classes={classes} linkBuilder={linkBuilder} format={format} />
          ))
        }
        { items.length > 1 && <NextPageLink
          classes={classes}
          page={page + 1}
          disabled={nextDisabled}
          linkBuilder={linkBuilder}
          format={format}
        /> }
        <ItemsPerPage limit={pageSize} onChange={onSizeChange} classes={classes} linkBuilder={linkBuilder} />
        <JumpToPage classes={classes} onChange={onPageChange} page={pageStr} pages={pages} />
      </div>
    );
  }
}

export default PaginationSchema;
