import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { submit } from 'redux-form';
import { push } from 'connected-react-router';
import injectSheet from 'lib/sheet';
import Accordion from 'components/Accordion';
import ValuesList from 'components/ValuesList';
import { userProfile } from 'domain/env';
import { columns } from 'fields/enterprise';
import { createFormConfig } from 'lib/helpers';
import ReloadImg from './reload.svg';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const ResetDefault = ({ onClick: handleClick }) => {
  return (
    <div
      style={{
        fontWeight: 500,
        fontSize: 16,
        color: 'rgba(2, 37, 54, 0.8)',
        position: 'relative',
        height: 10,
      }}
      onClick={handleClick}
    >
      <div
        style={{
          position: 'absolute',
          right: -15,
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            backgroundImage: `url("${ReloadImg}")`,
            width: 18,
            height: 18,
            marginTop: 2,
            marginRight: 16,
          }}
        ></div>
        Reset to default
      </div>
    </div>
  );
};

const sheet = {
  ItemPage: {
    display: 'flex',
    flexDirection: 'column',
    // padding: [20, 20],
    // height: 'calc(100vh - 110px)',
    boxSizing: 'border-box',
    width: '100%',
  },
  container: {},
  box: {
    padding: [20, 0],
    display: 'flex',
  },
};

const getHeaderTitle = (item) => item.get('name') || item.get('title');

export const createItemList = ({
  name,
  config,
  Form,
  connections,
  titleFn = getHeaderTitle,
  readOnly = false,
}) => {
  class ItemPage extends React.Component {
    static propTypes = {
      classes: PropTypes.shape({
        ItemPage: PropTypes.string,
        container: PropTypes.string,
        logo: PropTypes.string,
        link: PropTypes.string,
        button: PropTypes.string,
      }).isRequired,
      submit: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
      item: PropTypes.shape({}).isRequired,
    };

    renderItems = () => {
      const { classes, item, updateItemAction, ...props } = this.props;
      const itemObj = Array.from(item).reduce(
        (itemObj, [key, value]) => Object.assign(itemObj, { [key]: value }),
        {},
      );
      return [
        <div style={{ width: '100%' }} key="clientSpace">
          <Accordion title="enterpriseWebApp" modifier="clientSpace">
            <Accordion title="landingPageBanner" modifier="landingPageBanner">
              <ValuesList
                key={'enterpriseWebappClientLogo'}
                config={createFormConfig(
                  columns.select('enterpriseWebappClientLogo'),
                )}
                {...props}
                rowData={item}
              />
              {/* <UploadImage /> */}
            </Accordion>
          </Accordion>
          <Accordion title="PDFGenerator" modifier="PDFGenerator">
            <Accordion
              title="artistReport"
              modifier="artistReport"
              key={`artistReport`}
            >
              <ValuesList
                key={`artistReport-vl`}
                config={createFormConfig(
                  columns.select(
                    'artistReportCoverPageLogo',
                    'artistArtBnkCoverPageLogo',
                    'artistReportTitle',
                    'artistReportSubtitle',
                    'artistReportTextColor',
                    'artistReportHeaderLogo',
                    'artistReportPrimaryColor1',
                    'artistReportSecondaryColor1',
                    'artistReportSecondaryColor2',
                    'artistArtBnkLastPageLogo',
                  ),
                )}
                {...props}
                rowData={item}
              />
              <ResetDefault
                onClick={() => {
                  this.forceUpdate();
                  updateItemAction({
                    ...itemObj,
                    artistReportPrimaryColor1: '#304C95',
                    artistReportSecondaryColor1: '#5B6178',
                    artistReportSecondaryColor2: '#1E202C',
                    artistArtBnkLogo: 'White',
                    artworkArtBnkLogo:
                      itemObj.artworkArtBnkLogo ||
                      localStorage.getItem('artworkArtBnkLogo') ||
                      'White',
                    portfolioArtdaiLogo:
                      itemObj.portfolioArtdaiLogo ||
                      localStorage.getItem('portfolioArtdaiLogo') ||
                      'White',
                      artistArtBnkCoverPageLogo:
                      itemObj.artistArtBnkCoverPageLogo ||
                      localStorage.getItem('artistArtBnkCoverPageLogo') ||
                      'White',
                      artistArtBnkLastPageLogo:
                      itemObj.artistArtBnkLastPageLogo ||
                      localStorage.getItem('artistArtBnkLastPageLogo') ||
                      'White',
                    artistReportTextColor: '#FFFFFF',
                    artworkReportTextColor: '#FFFFFF',
                    portfolioCoverPageTextColor: '#FFFFFF',
                  });
                  localStorage.removeItem('artistArtBnkCoverPageLogo');
                  localStorage.removeItem('artistArtBnkLastPageLogo');
                }}
              />
            </Accordion>
            <Accordion
              title="artworkReport"
              modifier="artworkReport"
              key={`artworkReport`}
            >
              <ValuesList
                key={`artworkReport-vl`}
                config={createFormConfig(
                  columns.select(
                    'artworkReportCoverPageLogo',
                    'artworkArtBnkCoverPageLogo',
                    'artworkReportTitle',
                    'artworkReportSubtitle',
                    'artworkReportTextColor',
                    'artworkReportHeaderLogo',
                    'artworkReportPrimaryColor1',
                    'artworkReportSecondaryColor1',
                    'artworkReportSecondaryColor2',
                    'artworkArtBnkLastPageLogo',
                  ),
                )}
                {...props}
                rowData={item}
              />
              <ResetDefault
                onClick={() => {
                  this.forceUpdate();
                  updateItemAction({
                    ...itemObj,
                    artworkReportPrimaryColor1: '#E3A52C',
                    artworkReportSecondaryColor1: '#5B6178',
                    artworkReportSecondaryColor2: '#1E202C',
                    artworkArtBnkLogo: 'White',
                    artistArtBnkLogo:
                      itemObj.artistArtBnkLogo ||
                      localStorage.getItem('artistArtBnkLogo') ||
                      'White',
                      portfolioArtdaiLogo:
                      itemObj.portfolioArtdaiLogo ||
                      localStorage.getItem('portfolioArtdaiLogo') ||
                      'White',
                      artworkArtBnkLogoCoverPage:
                      itemObj.artworkArtBnkCoverPageLogo ||
                      localStorage.getItem('artworkArtBnkCoverPageLogo') ||
                      'White',
                      artworkArtBnkLastPageLogo:
                      itemObj.artworkArtBnkLastPageLogo ||
                      localStorage.getItem('artworkArtBnkLastPageLogo') ||
                      'White',
                      
                    artworkReportTextColor: '#FFFFFF',
                    artistReportTextColor: '#FFFFFF',
                    portfolioCoverPageTextColor: '#FFFFFF',
                  });
                  localStorage.removeItem('artworkArtBnkCoverPageLogo');
                  localStorage.removeItem('artworkArtBnkLastPageLogo');
                }}
              />
            </Accordion>
            <Accordion
              title="portfolioReport"
              modifier="portfolioReport"
              key={`portfolioReport`}
            >
              <ValuesList
                key={`portfolioReport-vl`}
                config={createFormConfig(
                  columns.select(
                    'portfolioClientLogoUrl',
                    'portfolioArtdaiLogoColor',
                    'portfolioReportTitle',
                    'portfolioReportSubtitle',
                    'portfolioCoverPageTextColor',
                    'portfolioReportHeaderLogoUrl',
                    'portfolioPrimaryColor',
                    'portfolioSecondaryColor1',
                    'portfolioSecondaryColor2',
                    'portfolioArtdaiLastPageLogoColor',
                  ),
                )}
                {...props}
                rowData={item}
              />
              <ResetDefault
                onClick={() => {
                  this.forceUpdate();
                  updateItemAction({
                    ...itemObj,
                    portfolioPrimaryColor: '#304C95',
                    portfolioSecondaryColor1: '#5B6178',
                    portfolioSecondaryColor2: '#1E202C',
                    portfolioArtdaiLogo: 'White',
                    artworkArtBnkLogo:
                      itemObj.artworkArtBnkLogo ||
                      localStorage.getItem('artworkArtBnkLogo') ||
                      'White',
                      portfolioArtdaiLogoColor:
                      itemObj.portfolioArtdaiLogoColor ||
                      localStorage.getItem('portfolioArtdaiLogoColor') ||
                      'White',
                      portfolioArtdaiLastPageLogoColor:
                      itemObj.portfolioArtdaiLastPageLogoColor ||
                      localStorage.getItem('portfolioArtdaiLastPageLogoColor') ||
                      'White',
                      artistArtBnkLogo:
                      itemObj.artistArtBnkLogo ||
                      localStorage.getItem('artistArtBnkLogo') ||
                      'White',
                      portfolioCoverPageTextColor: '#FFFFFF',
                      artistReportTextColor: '#FFFFFF',
                      artworkReportTextColor: '#FFFFFF',
                  });
                  localStorage.removeItem('portfolioArtdaiLogoColor');
                  localStorage.removeItem('portfolioArtdaiLastPageLogoColor');
                }}
              />
            </Accordion>
          </Accordion>
        </div>,
      ];
    };

    render() {
      const { classes, item } = this.props;
      if (!item.id) return null;
      return (
        <div className={classes.ItemPage}>
          {/* <Navigation linkBuilder={(id) => lnk(name, { id })} />
          <HistoryPopup />
          <RemovePopup />
          <Header
            title={titleFn(item)}
            object={item}
            onSave={readOnly || !Form ? null : () => submit(`${name}Form`)}
            onCancel={() => this.props.push(lnk(`${name}List`))}
          />
          {!item.isDelete && <Actions data={item} {...props} />} */}
          {/* <Paper className={classes.container}> */}
          {this.renderItems()}
          {/* </Paper> */}
        </div>
      );
    }
  }

  return compose(
    injectSheet(sheet),
    connect({
      ...connections, //{item... updateItemAction...}
      submit,
      userProfile,
      push,
    }),
  )(ItemPage);
};
