import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import { restore } from 'domain/const';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import Button from 'components/Button';
import toCamel from 'to-camel-case';

const Header = ({
  classes,
  className,
  title,
  onSave,
  onCancel,
  object = { wfAcceptance: 'DRAFT', isDelete: false },
  saveTextKey = 'save',
  cancelTextKey = 'cancel',
  getStatus = ({ wfAcceptance = '', userStatus = '' }) => toCamel((wfAcceptance || userStatus).toLowerCase()),
  restore,
  disabled = false,
}) => {
  const { isDelete } = object;
  const status = getStatus(object);
  return (
    <h2 className={cx(classes.Header, { [className]: !!className })}>
      <div className={cx(classes.header, classes[isDelete ? 'ignore' : status])}>
        { !!status && <FM id={isDelete ? 'deleted' : status} /> }
        { !!status && ' - ' }
        <span className={classes.headerBold}>{ title }</span>
      </div>
      <div className={classes.buttons}>
        { !!onCancel && !isDelete && <Button type="button" buttonType="secondary" onClick={() => onCancel()}><FM id={cancelTextKey} /></Button> }
        { !!onSave && !isDelete && <Button type="button" disabled={disabled} buttonType="primary" onClick={() => onSave()}><FM id={saveTextKey} /></Button> }
        { !!isDelete && <Button type="button" buttonType="primary" onClick={() => restore()}><FM id="restore" /></Button> }
      </div>
    </h2>
  );
};

Header.propTypes = {
  classes: PropTypes.shape({
    Header: PropTypes.string,
  }),
  className: PropTypes.string,
  object: PropTypes.object,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  getStatus: PropTypes.func,
  restore: PropTypes.func,
  saveTextKey: PropTypes.string,
  cancelTextKey: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(
  injectSheet(sheet),
  connect({ restore }),
)(Header);
