import { fork, call } from 'redux-saga/effects';

import { actions, selectors } from 'domain/alert';
import { listNavigator } from 'sagas/common';
import { loadSingleData } from 'sagas/common';
import { actions as customerAction } from 'domain/customer';
import filters from 'filters/alert';

export default function* navigator({ params: { userId: id } }) {
  if (id) yield call(loadSingleData, { modelName: 'Customer', actions: customerAction, id, initForm: false });
  yield fork(listNavigator, { modelName: 'Alert', actions, selectors, filters } );
}
