import React from 'react';
import { reduxForm } from 'redux-form';

import injectSheet from 'lib/sheet';
import ValuesList, { sheet } from 'components/ValuesList';
import { createArtistAction } from 'domain/artistList';
import columns from 'fields/artist';
import { createFormConfig, submit } from 'lib/helpers';

const formFields = columns.select(
  'fullName',
  'lifeYears',
  'residences',
  'carTag',
);

const StyledValuesList = injectSheet({
  ...sheet,
  ValuesList: { marginRight: 150, width: 440, marginBottom: 20 },
  value: { width: 300, display: 'flex' },
})(ValuesList);

class Form extends React.PureComponent {
  render(){
    return (
      <form>
        <StyledValuesList showStars config={createFormConfig(formFields)} rowData={{}} />
      </form>
    );
  }
}

export default reduxForm({ form: 'createArtistForm', onSubmit: submit(createArtistAction) })(Form);
