import React, { useEffect, useState } from "react";
import classes from "./style.module.css";

const SubColumns = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(data);

  useEffect(() => {
    setLoading(true);
    if (data && data.data) {
      // Check if data and data.data exist before accessing properties
      const sortedData = data.data
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      setDataTable({ ...data, data: sortedData });
    }
    setLoading(false);
  }, [data]);

  if (loading === true || !dataTable || !dataTable.data) return <></>;

  return (
    <>
      {dataTable && (
        <table className={classes.tableSub}>
          <thead className={classes.thead}>
            <tr>
              <th
                style={{ color: "red", borderRight: "1px solid black" }}
                rowSpan={2}
              >
                {dataTable.name.split("_").slice(1).join("_")}
              </th>
              {dataTable.data.map((quintile, index) => (
                <th
                  key={index + quintile.name}
                  colSpan={2}
                  style={{
                    borderRight: "1px solid black",
                    borderBottom: "1px solid red",
                  }}
                >
                  {quintile.name}
                </th>
              ))}
            </tr>
            <tr>
              {dataTable.data.map((__, index) => (
                <React.Fragment key={index + __.name}>
                  <th
                    key={index + __.name}
                    colSpan={1}
                    style={{
                      backgroundColor: "white",
                      borderRight: "1px solid black",
                    }}
                  >
                    x
                  </th>
                  <th
                    key={index}
                    colSpan={1}
                    style={{
                      backgroundColor: "white",
                      borderRight: "1px solid black",
                    }}
                  >
                    y
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataTable.data.length > 0 &&
              dataTable.data[0].data.map((__, rowIndex) => (
                <tr key={rowIndex} className={classes.tr}>
                  <td style={{ userSelect: "none", color: "transparent" }}>
                    {dataTable.name.split("_").slice(1).join("_")}
                  </td>
                  {dataTable.data.map((quintile, quintileIndex) => (
                    <React.Fragment key={rowIndex + quintileIndex}>
                      <td>{quintile.data[rowIndex][0]}</td>
                      <td>{quintile.data[rowIndex][1]}</td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default SubColumns;
