import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { getTooltipData } from 'domain/ui';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class TooltipPure extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Tooltip: PropTypes.string,
    }),
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.instanceOf(I.Record),
  };

  render() {
    const {
      classes: { Tooltip },
      data,
      intl: { formatMessage },
    } = this.props;
    if (!data) {
      return null;
    }
    const { x, y, text: id, translate } = data;
    return (
      <div
        className={Tooltip}
        dangerouslySetInnerHTML={{ __html: translate ? formatMessage({ id }) : id }}
        style={{ top: (y-26)+'px', left: (x-10)+'px' }}
      />
    );
  }
};

export default compose(
  injectIntl,
  injectSheet(sheet),
  connect({ data: getTooltipData }),
)(TooltipPure);
