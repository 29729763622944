import React from 'react';
import PropTypes from 'prop-types';
import { getDocument, getWindow } from 'lib/envGlobals';

export class PositionWatcherWrapper extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number.isRequired,
    margin: PropTypes.number,
    document: PropTypes.shape({
      addEventListener: PropTypes.func.isRequired,
      removeEventListener: PropTypes.func.isRequired,
      body: PropTypes.shape({
        getBoundingClientRect: PropTypes.func.isRequired,
      }).isRequired,
    }),
    window: PropTypes.shape({
      addEventListener: PropTypes.func.isRequired,
      removeEventListener: PropTypes.func.isRequired,
    }),
    classes: PropTypes.shape({
      PositionWatcher: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node,
  };
  static defaultProps = {
    margin: 0,
  };
  state = {
    style: {},
  };
  get document() {
    return this.props.document || getDocument();
  }
  get window() {
    return this.props.window || getWindow();
  }
  componentDidMount() {
    this.window.addEventListener('resize', this.onResize);
    this.onResize();
  }
  componentWillUnmount() {
    this.window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { width, margin } = this.props;
    const rect = this.document.body.getBoundingClientRect();
    const { width: docWidth } = rect;
    const { x } = this.ref.parentElement.getBoundingClientRect();
    const distance = docWidth - x - margin;
    if (distance < width) {
      this.setState({ style: { left: `${-(width - distance)}px` } });
    } else if (this.state.style.left) {
      this.setState({ style: {} });
    }
  }
  render() {
    const { classes, children } = this.props;
    return (
      <div
        className={classes.PositionWatcher}
        children={children}
        ref={(node) => this.ref = node}
        style={this.state.style}
      />
    );
  }
}

export function positionWatcherDecorator(WrappedComponent) {
  class PositionWatcher extends React.PureComponent {
    static propTypes = {
      classes: PropTypes.shape({
        PositionWatcher: PropTypes.string.isRequired,
      }).isRequired,
    };

    render() {
      const { classes } = this.props;
      return (
        <PositionWatcherWrapper classes={classes}>
          <WrappedComponent {...this.props} />
        </PositionWatcherWrapper>
      );
    }
  }
  return PositionWatcher;
}

