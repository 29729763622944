import { put, takeEvery, call } from 'redux-saga/effects';
import { SEARCH_ARTWORK_WINDOW } from 'domain/const';
import { setModalModeAction } from 'domain/ui';
import { artworkSelectedAction } from 'domain/artwork';


function* onArtworkSelected({ payload }) {
  if (process.env.NODE_ENV === 'test') return;
  const { onArtworkSelected } = global.opener || {};
  if (!onArtworkSelected) return;
  yield call(onArtworkSelected, payload, global);
}

export default function* checkModalMode() {
  if (process.env.NODE_ENV === 'test') return;
  if (global.name === SEARCH_ARTWORK_WINDOW) {
    yield put(setModalModeAction(global.name));
    yield takeEvery(artworkSelectedAction.type, onArtworkSelected);
  }
}
