import { boolFilter, commonDictionaryFilters, createFilterTransfer } from './common';


export const defaultList = [ 'id', 'isCorrect' ];

export const filters = {
  ...commonDictionaryFilters,
  isCorrect: { formFieldRenderer: boolFilter('isCorrect') },
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
