import React from 'react';
import PropTypes from 'prop-types';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

const Option = ({ selected, classes }) => <div className={classes.Option}>
  { selected && <div className={classes.dot}/> }
</div>;

Option.propTypes = {
  selected: PropTypes.bool,
  classes: PropTypes.shape({
    Option: PropTypes.string.isRequired,
    dot: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectSheet(sheet)(Option);
