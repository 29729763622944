import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import injectSheet from 'lib/sheet';
import styles, { updateLoaderTheme } from './sheet.js';
import { rtv, fetchRTVAction } from 'domain/rtv';
import { bindLoaderOrChildrenTheme } from 'components/Loader';
import IconLink from 'components/Link';
import { lnk } from '../../lib/routes';
import Cluster from "components/Cluster";

const Loader = bindLoaderOrChildrenTheme(updateLoaderTheme);

const { Bokeh } = global;

const rtvReady = (plot = {}) => plot && plot.json_item;
const noRtvData = (rtvMap, action) => rtvMap && rtvMap[action] && rtvMap[action].plot === null;

class Graph extends React.Component {
  static propTypes = {
    rtv: PropTypes.object,
    id: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      Graph: PropTypes.string.isRequired,
    }).isRequired,
  };

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    const { rtv: { json_item }, id } = this.props;
    Bokeh.embed.embed_item({ ...json_item, target_id: id });
  }

  render() {
    const { classes, id } = this.props;
    return (
      <div
        className={classes.Graph}
      >
        <div key={id} id={id} className="bk-root" />
      </div>
    );
  }
}

class RTV extends React.Component {
  static propTypes = {
    fetchRtv: PropTypes.func.isRequired,
    rtvMap: PropTypes.object.isRequired,
    artworkId: PropTypes.number,
    classes: PropTypes.shape({
      btnSwitchAction: PropTypes.string.isRequired,
      btnSwitchActionActive: PropTypes.string.isRequired,
      RTV: PropTypes.string.isRequired,
      cell: PropTypes.string.isRequired,
    }).isRequired,
    clusterNumber: PropTypes.number,
  };

  state = {
    rightSideAction: null,
    leftSideAction: null,
    intersectActions: void 0,
    normalActions: void 0,
  };

  static getDerivedStateFromProps(props, state) {
    const rtv = props.rtvMap || {};
    let ret = null;
    // save intersect actions
    if (!state.intersectActions && rtv.intersect_actions) {
      ret = { intersectActions: rtv.intersect_actions };
    }
    // save normal actions
    if (!state.normalActions && rtv.normal_actions) {
      ret = ret || {};
      ret = { ...ret, normalActions: rtv.normal_actions };
    }
    // get first action from normal_actions
    if (!state.rightSideAction && rtv.normal_actions) {
      ret = ret || {};
      ret = { ...ret, rightSideAction: rtv.normal_actions[0].name };
    }
    // get first action from intersect_actions
    if (!state.leftSideAction && rtv.intersect_actions) {
      ret = ret || {};
      ret = { ...ret, leftSideAction: rtv.intersect_actions[0].name };
    }
    return ret;
  }

  setAction = (side, name) => {
    const { fetchRtv, artworkId } = this.props;
    this.setState({ [side]: name });
    fetchRtv({ artworkId, action: name });
  }

  renderActions(arr = [], side) {
    const { classes, artworkId } = this.props;
    const action = this.state[side];
    return (
      <div className={classes.actionsList}>
        {
          arr.map(actObj => (
            <div key={actObj.name} className={classes.btnBox}>
              <IconLink
                force
                className={'LinkContainerRelative'}
                url={lnk('artworkIntersectionsList', { artworkId, action: actObj.name })}
                target="_blank"
              >
                <button
                  className={cx(classes.btnSwitchAction, { [classes.btnSwitchActionActive]: actObj.name === action })}
                  type="button"
                  children={actObj.title}
                  onClick={() => this.setAction(side, actObj.name)}
                />
              </IconLink>
            </div>
          ))
        }
      </div>
    );
  }

  render() {
    const { rtvMap, classes, artworkId, clusterNumber } = this.props;
    const { rightSideAction, leftSideAction, intersectActions, normalActions } = this.state;
    if (!rightSideAction || !leftSideAction) return null;
    const plotLeft = (rtvMap[leftSideAction] && rtvMap[leftSideAction].plot) || {};
    const plotRight = (rtvMap[rightSideAction] && rtvMap[rightSideAction].plot) || {};
    return (
      <div className={classes.RTV}>
        <div className={`${classes.cell}`}>
          {
            this.renderActions(intersectActions, 'leftSideAction')
          }
          <Loader loading={rtvMap.loading[leftSideAction]}>
            {
              rtvReady(plotLeft.raw) &&
                <Graph id={`id-${leftSideAction}`} classes={classes} rtv={plotLeft.raw} key={`${leftSideAction}`} />
            }
            {
              noRtvData(rtvMap, leftSideAction) &&
                <div className={classes.noData}>
                  <FormattedMessage id="noRtvData" />
                </div>
            }
          </Loader>
        </div>
        <div className={`${classes.cell}`}>
          {
            this.renderActions(normalActions, 'rightSideAction')
          }
          {
            this.state['rightSideAction'] === 'customer_manifold_clusters' &&
              <Cluster clusterNumber={clusterNumber} id={artworkId} />
          }
          <Loader loading={rtvMap.loading[rightSideAction]}>
            {
              rtvReady(plotRight.raw) &&
                <Graph id={`id-${rightSideAction}`} classes={classes} rtv={plotRight.raw} key={`${rightSideAction}`} />
            }
            {
              noRtvData(rtvMap, rightSideAction) &&
                <div className={classes.noData}>
                  <FormattedMessage id="noRtvData" />
                </div>
            }
          </Loader>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    rtvMap: rtv,
    fetchRtv: fetchRTVAction,
  }),
)(RTV);
