import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';

export const styles = {
  '@keyframes ring': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 25px)',
    top: 'calc(50% - 25px)',
    display: 'inline-block',
    width: 50,
    height: 50,
    animation: '$ring 1s linear infinite',
    '&:after': {
      content: '" "',
      display: 'block',
      width: '38px',
      height: '38px',
      margin: '1px',
      borderRadius: '50%',
      border: `5px solid ${colors.attention}`,
      borderColor: `${colors.attention} transparent ${colors.attention} transparent`,
    },
  },
  LoaderWrapper: {
    position: 'relative',
    height: 50,
    minWidth: 50,
  },
};

export const Loader = ({ classes }) => <div className={classes.loader}></div>;

Loader.propTypes = { classes: PropTypes.shape({ loader: PropTypes.string.isRequired }) };

export default injectSheet(styles)(Loader);

const LoaderOrChildrenPure = ({ loading, children, classes }) =>
  loading ?
    <div className={classes.LoaderWrapper}>
      <div className={classes.loader}></div>
    </div>
    :
    children;

LoaderOrChildrenPure.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.shape({
    LoaderWrapper: PropTypes.string.isRequired,
    loader: PropTypes.string.isRequired,
  }),
};

export const bindLoaderOrChildrenTheme = (f) => injectSheet(f(styles))(LoaderOrChildrenPure);
