import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

class Radio extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    checked: PropTypes.bool,
    name: PropTypes.string,
    modifier: PropTypes.string,
    classes: PropTypes.shape({
      Radio: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => null,
  };

  onClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes, className, checked, value, modifier, ...props } =
      this.props;
    return (
      <input
        className={cx(classes.Radio, className, modifier)}
        checked={checked}
        value={value}
        {...props}
        onClick={this.onClick}
        type="radio"
      />
    );
  }
}

export default injectSheet(sheet)(Radio);
