import { createItemPage } from 'pages/common/ItemPage';

import { selectors } from 'domain/consideration';

import { dictionaryById } from 'domain/dictionary';
import { basicInfo, details } from 'domain/artwork/fieldRules';
import { columns } from 'fields/consideration';
import { statistics, auctions } from 'pages/ArtworkPage';
import { authorsById } from 'domain/author';

const { item } = selectors;

const basicFields = Object.keys(basicInfo)
  .map(key => basicInfo[key]).flat()
  .map(v => v === 'artistId' ? 'artist' : v)
  .filter((v, index, arr) => arr.indexOf(v) === index);

const detailFields = Object.keys(details)
  .map(key => details[key]).flat(2)
  .filter((v, index, arr) => arr.indexOf(v) === index);

export default createItemPage({
  name: 'Consideration',
  connections: {
    item,
    artistId: authorsById,
    category: dictionaryById('categories'),
    subjects: dictionaryById('subjects'),
    surfaces: dictionaryById('surfaces'),
    substrates: dictionaryById('substrates'),
    surfaceValues: dictionaryById('surfaceValues'),
    substrateValues: dictionaryById('substrateValues'),
    currency: dictionaryById('currencies'),
    countries: dictionaryById('countries'),
  },
  config: {
    basicInfo: [
      columns.select(...basicFields),
      columns.select('defaultImageUrl'),
      columns.select('id', 'sourceType', 'createdDate', 'acceptedDate', 'updatedDate'),
    ],
    details: [
      columns.select(...detailFields.slice(0, Math.ceil(detailFields.length / 2))),
      columns.select(...detailFields.slice(Math.ceil(detailFields.length / 2))),
    ],
    statistics: [ statistics, auctions ],
  },
  readOnly: true,
  titleFn: item => item.get('title'),
});
