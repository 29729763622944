import { connectAcceptance, createIdLink, yesNoDash } from './common';
import { connect } from 'cpcs-reconnect';
import { uploadImageAction } from 'domain/common';
import { defineColumns } from 'components/PageTable/defineColumns';
import { boolFilter } from 'filters/common';
import rules from 'components/form/validation';
import { dateFieldProps, numberFieldProps } from 'lib/helpers';
import DateField from 'components/form/element/DateField';
import { dateToString } from 'lib/serialize';
import LockDetector from 'fields/LockDetector';
import Input from 'components/form/element/Input';
import { enterprise } from 'domain/enterprise';
import Gallery from 'components/Gallery';
import {
  UploadEnterpriseWebappClientLogo,
  UploadArtworkReportCoverPageLogo,
  UploadArtistReportCoverPageLogo,
  UploadPortfolioClientLogoUrl,
  UploadArtistReportHeaderLogo,
  UploadPortfolioReportHeaderLogoUrl,
  UploadArtworkReportHeaderLogo,
} from './common';
import React from 'react';
import I from 'immutable';
import PropTypes from 'prop-types';
import ColorInput from 'components/form/element/ColorInput';
import TextAreaField from 'components/form/element/TextArea';
import CheckSelect from 'components/form/fields/CheckSelect';

const renderGallery = ({ rowData }) => {
  return (
    <Gallery mainId={rowData.defaultArtImage} images={rowData.artImages} />
  );
};
renderGallery.propTypes = {
  rowData: PropTypes.shape({
    defaultArtImage: PropTypes.string,
    artImages: PropTypes.instanceOf(I.List),
  }).isRequired,
};

const EnterpriseWebappClientLogo = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadEnterpriseWebappClientLogo);

const ArtistReportCoverPageLogo = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadArtistReportCoverPageLogo);

const ArtistReportHeaderLogo = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadArtistReportHeaderLogo);
// ------------------
const PortfolioClientLogoUrl = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadPortfolioClientLogoUrl);

const PortfolioReportHeaderLogoUrl = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadPortfolioReportHeaderLogoUrl);
// -------------------
const ArtworkReportCoverPageLogo = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadArtworkReportCoverPageLogo);

const ArtworkReportHeaderLogo = connect({
  onUpload: uploadImageAction,
  object: enterprise,
})(UploadArtworkReportHeaderLogo);

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('enterprise'),
    parser: ({ value }) => (value === undefined ? '' : value),
  },
  enterpriseName: {
    sortable: true,
    inlineEdit: true,
    fieldProps: { validate: [rules.required] },
  },
  abvPurchases: { sortable: true, getValue: ({ value }) => `${value}` },
  active: {
    sortable: true,
    inlineEdit: true,
    getValue: ({ value }) => (value ? 'Active' : 'Disabled'),
    title: 'status',
    formFieldRenderer: () => boolFilter('active', 'Active', 'Disabled', false),
    fieldProps: { validate: [rules.required] },
  },
  includedAbv: {
    sortable: true,
    inlineEdit: true,
    component: LockDetector(Input),
    fieldProps: {
      type: 'number',
      min: 0,
      ...numberFieldProps,
      defaultValue: 0,
      display: ({ limitedAbv }) => !!limitedAbv,
    },
  },
  limitedAbv: {
    sortable: true,
    inlineEdit: true,
    getValue: yesNoDash,
    formFieldRenderer: () =>
      boolFilter('limitedAbv', undefined, undefined, false),
  },
  expirationDate: {
    sortable: true,
    inlineEdit: true,
    component: DateField,
    getValue: dateToString('MM/DD/YYYY'),
    fieldProps: dateFieldProps,
  },
  enterpriseWebappClientLogo: {
    hiddenForTable: true,
    component: EnterpriseWebappClientLogo,
    getValue: renderGallery,
  },

  //! Artist  Report
  artistReportCoverPageLogo: {
    hiddenForTable: true,
    component: ArtistReportCoverPageLogo,
    getValue: renderGallery,
  },
  artistReportTitle: {
    sortable: true,
    inlineEdit: true,
    // fieldProps: { validate: [rules.required] },
  },
  artistReportSubtitle: {
    sortable: true,
    inlineEdit: true,
    component: TextAreaField,
    // fieldProps: { validate: [rules.required] },
  },
  artistReportPrimaryColor1: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artistReportSecondaryColor1: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artistReportSecondaryColor2: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artistReportTextColor: {
    sortable: true,
    inlineEdit: true,
    // fieldProps: { validate: [rules.required] },
    component: ColorInput,
  },
  artistArtBnkLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artistArtBnkCoverPageLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artistArtBnkLastPageLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artistReportHeaderLogo: {
    hiddenForTable: true,
    component: ArtistReportHeaderLogo,
    getValue: renderGallery,
  },

//! Portfolio  Report
portfolioClientLogoUrl: {
  hiddenForTable: true,
  component: PortfolioClientLogoUrl,
  getValue: renderGallery,
},
portfolioReportTitle: {
  sortable: true,
  inlineEdit: true,
  // fieldProps: { validate: [rules.required] },
},
portfolioReportSubtitle: {
  sortable: true,
  inlineEdit: true,
  component: TextAreaField,
  // fieldProps: { validate: [rules.required] },
},
portfolioPrimaryColor: {
  sortable: true,
  inlineEdit: true,
  component: ColorInput,
},
portfolioSecondaryColor1: {
  sortable: true,
  inlineEdit: true,
  component: ColorInput,
},
portfolioSecondaryColor2: {
  sortable: true,
  inlineEdit: true,
  component: ColorInput,
},
portfolioCoverPageTextColor: {
  sortable: true,
  inlineEdit: true,
  // fieldProps: { validate: [rules.required] },
  component: ColorInput,
},
portfolioArtdaiLogo: {
  inlineEdit: true,
  component: CheckSelect,
  fieldProps: { values: ['White', 'Black', 'Hide'] },
},
portfolioArtdaiLogoColor: {
  inlineEdit: true,
  component: CheckSelect,
  fieldProps: { values: ['White', 'Black', 'Hide'] },
},
portfolioArtdaiLastPageLogoColor: {
  inlineEdit: true,
  component: CheckSelect,
  fieldProps: { values: ['White', 'Black', 'Hide'] },
},
portfolioReportHeaderLogoUrl: {
  hiddenForTable: true,
  component: PortfolioReportHeaderLogoUrl,
  getValue: renderGallery,
},

  //! Artwork  Report
  artworkReportCoverPageLogo: {
    hiddenForTable: true,
    component: ArtworkReportCoverPageLogo,
    getValue: renderGallery,
  },
  artworkReportTitle: {
    sortable: true,
    inlineEdit: true,
    // fieldProps: { validate: [rules.required] },
  },
  artworkReportSubtitle: {
    sortable: true,
    inlineEdit: true,
    component: TextAreaField,
    // fieldProps: { validate: [rules.required] },
  },
  artworkReportPrimaryColor1: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artworkReportSecondaryColor1: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artworkReportSecondaryColor2: {
    sortable: true,
    inlineEdit: true,
    component: ColorInput,
  },
  artworkReportTextColor: {
    sortable: true,
    inlineEdit: true,
    // fieldProps: { validate: [rules.required] },
    component: ColorInput,
  },
  artworkArtBnkLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artworkArtBnkCoverPageLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artworkArtBnkLastPageLogo: {
    inlineEdit: true,
    component: CheckSelect,
    fieldProps: { values: ['White', 'Black', 'Hide'] },
  },
  artworkReportHeaderLogo: {
    hiddenForTable: true,
    component: ArtworkReportHeaderLogo,
    getValue: renderGallery,
  },
};

export const columns = defineColumns(connectAcceptance(config, []));

export const defaultColumns = [
  'enterpriseName',
  'abvPurchases',
  'active',
  'includedAbv',
  'limitedAbv',
  'expirationDate',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
