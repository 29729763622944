import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';

export default function paginationDefaultProps(WrappedComponent) {
  class PaginationDefaultProps extends React.Component {
    static propTypes = {
      pagination: PropTypes.oneOfType([PropTypes.instanceOf(I.Record), PropTypes.object]),
      linkBuilder: PropTypes.func.isRequired,
      redirect: PropTypes.func.isRequired,
    };

    onPageChange = (page) => {
      const { linkBuilder, redirect } = this.props;
      redirect(linkBuilder({ page }));
    }

    onSizeChange = (pageSize) => {
      const { linkBuilder, redirect } = this.props;
      redirect(linkBuilder({ pageSize, page: 1 }));
    }

    render() {
      const { pagination } = this.props;
      if (!pagination) return null;
      const { page, pages, pageSize, total } = pagination;
      return (
        <WrappedComponent
          {...this.props}
          page={page}
          pages={pages}
          pageSize={pageSize}
          total={total}
          onSizeChange={this.onSizeChange}
          onPageChange={this.onPageChange}
        />
      );
    }
  }

  return connect({
    redirect: push,
  })(PaginationDefaultProps);
}
