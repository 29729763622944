import I from 'immutable';
import moment from 'moment';

export function listToMap(list = new I.List(), fieldPath = ['id']) {
  return list.reduce((AC, V) => AC.set(V.getIn(fieldPath, 0), V), new I.Map());
}

export const dateTimeToString = (format = 'MMM DD, YYYY', defaultValue = '−') => {
  return ({ value }) => !!value && moment(value).isValid() ? moment(value)
    .utcOffset(-5).format(format) : defaultValue;
};

export const dateToString = (format = 'MMM DD, YYYY', defaultValue = '−') => {
  return ({ value }) => !!value && moment(value).isValid() ? moment(value)
    .format(format) : defaultValue;
};

export const Entity = ({ value }) => value.title || value.name;

export const listOfEntity = ({ value = [] }) => value.map(v => Entity({ value: v })).join(', ');

export const dict = (titleKey = 'title', { paramName = '' } = {}) => ({ name, value, ...props }) => {
  if (!value) return '−';
  const key = paramName || name || props.columnDef.name;
  if (!props[key] || !props[key].getIn){
    return `missed dictionary "${key}"`;
  }
  return props[key].getIn([value, titleKey], `no value for "${value}" in "${key}"`);
};

export const dictList = (titleKey = 'title') => ({ value = [], ...props }) => {
  return value.map(value => dict(titleKey)({ value, ...props })).join(', ');
};

export const completeness = ({ value = '0' }) => `${value} %`;

export const dash = ({ value = '−' }) => value;

export const digitsNormalize = (v, p = '') => v !== '' ? parseInt(v, 10) || p : v;

export const artistNormalize = (params) => {
  if (!params) return '−';
  const { fullName, birthDate, deathDate } = params;
  const dates = birthDate ? [birthDate,deathDate].filter(v => !!v).join(' - ') : '';
  return `${fullName}${dates ? ' (' + dates + ')' : ''}`;
};
