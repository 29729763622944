import { put, call, select, takeEvery, takeLatest, all, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { delay } from 'sagas/common';

import {
  fetchArtistsListAction,
  createArtistAction,
  onEdit,
  updateArtistInLineAction,
  checkArtistAction,
  artistList,
} from 'domain/artistList';
import { mergeArtistAction } from 'domain/artist';
import { authorsById, fetchAuthorsAction } from 'domain/author';
import { addNotification } from 'domain/env';
import { hidePopupByName } from 'domain/ui';
import filters from 'filters/artist';
import { lnk } from 'lib/routes';
import { watchCommonActionsCall } from 'sagas/actions';

import Api, { callApi } from 'domain/api';
import { loadListData } from 'sagas/common';
import { loadAuthor } from 'pages/ArtworkListPage/saga';

function* saveForm({ payload, resolve }) {
  yield call(delay, 500);
  try {
    if(payload.carTag){
      if(payload['carTag'].length>1){
        let tempArray = payload['carTag']?.slice(1);
        payload['carTag'] = payload['carTag'][0];
        payload['secondaryCarTag'] = [...tempArray];
      }else if(payload['carTag'].length==1){
        payload['carTag'] = payload['carTag'][0];
        payload['secondaryCarTag'] = [];
      }else if(payload['carTag'].length === 0){
        payload['carTag'] = null;
        payload['secondaryCarTag'] = [];
      }
    }
    const { data: { id: artistId } } = yield callApi(Api.addArtist, { data: payload });
    yield put(hidePopupByName('ADD_ARTIST'));
    resolve();
    yield put(push(lnk('artist', { artistId })));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'itemNotCreated', type: 'error' }));
  }
}

function* updateForm({ payload: { id: artistId, ...data }, resolve }) {
  yield call(delay, 500);
  try {
    const { data: payload } = yield callApi(Api.updateArtist, { artistId, data });
    resolve();
    yield put(onEdit(null));
    yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success' }));
    yield put({
      type: updateArtistInLineAction.success,
      payload,
    });
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}

function* loadAuthors({ list }) {
  if (list.length === 0) return;
  const dictionary = yield select(authorsById);
  try {
    const authors = yield all(list
      .reduce((acc, { mergeWith }) => !!mergeWith && !acc.includes(mergeWith) ? acc.concat(mergeWith) : acc, [])
      .filter(v => !dictionary.has(v))
      .map(artistId => call(loadAuthor, artistId )));
    if (authors.length) yield put({ type: fetchAuthorsAction.success, payload: authors });
  } catch (e) { console.error(e); }
}

function* loadData(resetChecked = true) {
  const params = {
    modelName: 'Artists',
    actions: { fetchListAction: fetchArtistsListAction, selectAction: checkArtistAction },
    filters,
    selectors: { list: artistList },
  };
  yield call(loadListData, params, resetChecked, loadAuthors );
}

function* mergeArtists({ payload: { mergeWith, children } }) {
  yield put(hidePopupByName('MERGE_ARTIST'));
  yield put({
    type: 'CHANGE_BULK',
    payload: { data: { mergeWith }, id: children }
  });
}

export default function* navigator() {
  yield fork(loadData, false);
  yield takeLatest(createArtistAction.type, saveForm);
  yield takeLatest(updateArtistInLineAction.type, updateForm);
  yield takeEvery(mergeArtistAction.type, mergeArtists);
  yield fork(watchCommonActionsCall, 'Artist', loadData);
}
