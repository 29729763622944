import React from 'react';
import { Field } from 'redux-form';
import I from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';

import injectSheet from 'lib/sheet';
import { dateFieldProps, floatFieldProps, numberFieldProps, toSnake } from 'lib/helpers';

import Select, { AsyncMultiAutocomplete } from 'components/form/element/Select';
import { authorsList, authorsStorage, fetchAuthorsAction, searchAuthorsAction } from 'domain/author';
import Input from 'components/form/fields/input';
import { DateFieldPure, sheet as dateFieldSheetOrigin, YearPure } from 'components/form/element/DateField';
import { dictionaryList } from 'domain/dictionary';
import FilterSelect from 'components/form/element/Select/FilterSelect';
import { artistNormalize } from 'lib/serialize';
import IndicatorLink from 'components/IndicatorLink';

import SearchInput from 'components/SearchInput';

export const PureInput = ({ input, ...props }) => <Input { ...props } { ...input } />;

const isValidFormattedFloat = v => [
  v => /^$|(?:^(?:-?0|-?[1-9][0-9]*)(?:\.[0-9]{1,2})?$)/.test(v),
  v => v !== '-0',
].reduce((acc, fn) => fn(v) && acc, true);

const isValidTempFloat = v => !![
  v => /^-$/.test(v),
  v => /^-?(0|[1-9][0-9]*)*(\.\d{0,2})?$/.test(v),
].find(fn => !!fn(v));

class NumberInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = { value: props.input.value || '' };
  }

  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      onChange: PropTypes.func,
    }),
  };

  onInputChange = value => {
    if (isValidTempFloat(value) || isValidFormattedFloat(value)) {
      this.setState({ value }, () => {
        if (isValidFormattedFloat(value)) this.props.input.onChange(value);
      });
    }
  };


  checkValue = () => {
    if (this.props.input.value !== this.state.value) {
      if (this.props.input.value === '') {
        this.setState({ value: '' });
      } else {
        if (!isValidFormattedFloat(this.state.value)) {
          this.props.input.onChange('');
        }
      }
    }
  };

  componentDidUpdate({ input: { value: prevPropsValue } }) {
    const { input: { value } } = this.props;
    if (value !== prevPropsValue && value !== this.state.value) {
      this.setState({ value });
    }
  }

  render() {
    const { input: { onChange, ...input }, ...props } = this.props;
    return <Input
      { ...props }
      { ...input }
      onChange={ this.onInputChange }
      value={ this.state.value }
      onBlur={ this.checkValue }
    />;
  }
}

PureInput.propTypes = {
  input: PropTypes.object,
};

const searchParser = v => [
  `${ v }`.substr(0, 1),
  `${ v }`.slice(1, -1).trim().replace(/  +/g, ' ').replace(/[+\-&|!(){}[\]^"~*?:/ ]/g, '\\$&'),
  `${ v }`.substr(-1),
].join('');

export const stringFilter = (model = 'art', fieldName, postFix = '') => ({
  component: SearchInput,
  tempParser: (key, value) => `(${ fieldName || toSnake(key) }:${ searchParser(value) })${postFix}`,
  fieldProps: {
    model,
    fieldName,
    format: (v = '') => `${ v }`.length > 2 ? `${ v }`.slice(1, -1) : `${ v }`,
  },
});

export const noCasingStringFilter = (fieldName, postFix = '') => ({
  tempParser: (key, value) => `(${ fieldName || key }:${ searchParser(value) })${postFix}`,
  fieldProps: {
    fieldName,
    format: (v = '') => `${ v }`,
  },
});
export const noCasingIntFilter = (model, fieldName, { postFix, min = 0 } = {}) => ({
  component: PureInput,
  tempParser: (key, value) => `(${ fieldName || key }:${ parseInt(value) })`,
  fieldProps: { type: 'number', min, format: v=> parseInt(v) },
});

// @todo how to use fieldName and model
export const intFilter = (model, fieldName, { postFix, min = 0 } = {}) => ({
  component: PureInput,
  parser: v => v,
  fieldProps: { type: 'number', min },
});

export const dateFieldSheet = {
  ...dateFieldSheetOrigin,
  element: {
    ...dateFieldSheetOrigin.element,
    height: 32,
  },
  wrapper: {
    ...dateFieldSheetOrigin.wrapper,
    display: 'inline-block',
    width: 105,
  },
};
const DateInput = injectSheet(dateFieldSheet)(DateFieldPure);
const YearInput = injectSheet(dateFieldSheet)(YearPure);

const rangeFactory = (Component, fieldProps = {}) => (name, placeholder = false) => {
  placeholder = placeholder === true ? `filter.placeholder.${ name }` : placeholder;
  return [
    <Field { ...fieldProps } key={ `${ name }From` } name={ `${ name }From` } component={ Component }
           placeholder={ placeholder || 'min' }/>,
    <div key="separator" style={ { lineHeight: '32px' } }>-</div>,
    <Field { ...fieldProps } key={ `${ name }To` } name={ `${ name }To` } component={ Component }
           placeholder={ placeholder || 'max' }/>,
  ];
};

export const yearRange = rangeFactory(YearInput);
/**
 * dateFieldProps:
 *  { normalize: fn, format: fn }
**/
export const dateRange = rangeFactory(DateInput, dateFieldProps);
export const floatRange = rangeFactory(NumberInput, floatFieldProps);
export const intRange = rangeFactory(PureInput, numberFieldProps);

export const source = <Field
  name="sourceType"
  component={ Select }
  parseValue={ v => v.get('id') }
  isClearable
  list={ I.fromJS([
    { id: 'LOT', title: 'Lot' },
    { id: 'EXPERT', title: 'Expert' },
    { id: 'CUSTOMER', title: 'Customer' },
    { id: 'CSV', title: 'CSV' },
    { id: 'SCRAPER', title: 'SCRAPER' },
  ].filter(v => window.name === 'SEARCH_ARTWORK_WINDOW' ? v.id !== 'CUSTOMER' : true)) }
/>;

export const purchaseStatus = <Field
  name="current"
  component={ Select }
  parseValue={ v => v.get('id') }
  isClearable
  list={ I.fromJS([
    { id: 'true', title: 'Current' },
    { id: 'false', title: 'Outdated' },
  ]) }
/>;

export const dateParser = (v, name) => name.endsWith('To') ? v + 'T23:59:59Z' : v + 'T00:00:00Z';
export const arrayParser = v => '(' + v.join(' OR ') + ')';

export const formatOptionLabel = (data) => <Label data={ data }/>;

const Label = ({ data }) =>
  <IndicatorLink
    status={data.wfAcceptance}
    children={artistNormalize(data)}
    modifier="commonFiltersLabel"
  />;

Label.propTypes = {
  data: PropTypes.any,
};

const AuthorsPropsTransfer = ({ search, ...props }) => {
  const onInputChange = (value) => {
    search(value);
    return value;
  };
  const newProps = { ...props, onInputChange };
  return <AsyncMultiAutocomplete formatOptionLabel={ formatOptionLabel } { ...newProps } />;
};

AuthorsPropsTransfer.propTypes = {
  search: PropTypes.func.isRequired,
};

export const AuthorAutoComplete = connect({
  list: authorsList,
  storage: authorsStorage,
  getValues: fetchAuthorsAction,
  search: (term) => searchAuthorsAction({ term }),
})(AuthorsPropsTransfer);

const SingleSelectFilter = ({ ...props }) => <Select isClearable { ...props } />;

export const CountriesAutoComplete = connect({ list: dictionaryList('countries') })(FilterSelect);
export const MediumsFilterAutoComplete = connect({ list: dictionaryList('mediums') })(FilterSelect);
export const CountryAutoComplete = connect({ list: dictionaryList('countries') })(SingleSelectFilter);
export const CategoryAutoComplete = connect({ list: dictionaryList('categories') })(SingleSelectFilter);
export const ConditionsAutoComplete = connect({ list: dictionaryList('conditions') })(FilterSelect);

export const OutliersAutoComplete = <Field
  name="outliers"
  component={ Select }
  parseValue={ v => v.get('id') }
  list={ I.fromJS([ { id: 'HIGH', title: 'HIGH' }, { id: 'LOW', title: 'LOW' }, { id: 'NULL', title: 'Disabled' } ]) }
  isClearable
/>;

export const SignatureAutoComplete = <Field
  name="isSignature"
  component={ Select }
  parseValue={ v => v.get('id') }
  list={ I.fromJS([ { id: 'yes', title: 'Yes' }, { id: 'no', title: 'No' }, { id: 'unknown', title: 'Unknown' } ]) }
  isClearable
/>;

export const boolFilter = (name, yesTitle = 'Yes', noTitle = 'No', isClearable = true) => <Field
  name={ name }
  component={ Select }
  parseValue={ v => v.get('id') }
  isClearable={ isClearable }
  normalize={ v => v ? v === 'true' : v }
  format={ v => v ? 'true' : (v === false ? 'false' : v) }
  list={ I.fromJS([
    { id: 'true', title: yesTitle },
    { id: 'false', title: noTitle },
  ]) }
/>;

export const NewPrice = <Field
  name="isSoldPriceUpdated"
  component={ Select }
  parseValue={ v => v.get('id') }
  list={ I.fromJS([ { id: 'true', title: 'Yes' }, { id: 'false', title: 'No' } ]) }
  isClearable
/>;

export const commonDictionaryFilters = {
  id: { component: PureInput, parser: v => v, fieldProps: { type: 'number', min: 0 } },
  createdDate: { parser: dateParser, formFieldRenderer: dateRange('createdDate', 'placeholder.date') },
  updatedDate: { parser: dateParser, formFieldRenderer: dateRange('updatedDate', 'placeholder.date') },
};

export const commonFilters = {
  ...commonDictionaryFilters,
  sourceType: { formFieldRenderer: source },
  acceptedDate: { parser: dateParser, formFieldRenderer: dateRange('acceptedDate', 'placeholder.date') },
};

/**
 * filter = { a: {}, b: {} }, columnsTransfer = { a: ['a', 'a2'] }
 *   => { a: 'a', a2: 'a', b: 'b' }
 **/
export const createFilterTransfer = (filters, columnsTransfer = {}) => Object.keys(filters)
  .reduce((acc, filterKey) => ({
    ...acc,
    ...[].concat(columnsTransfer[filterKey] || filterKey)
      .reduce((obj, key) => ({ ...obj, [key]: (acc[key] || []).concat(filterKey) }), {}),
  }), {});
