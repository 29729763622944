import { Tabs } from 'theme';

export const updateLoaderTheme = (theme) => ({
  ...theme,
  LoaderWrapper: {
    ...theme.LoaderWrapper,
    height: 640,
  },
});

export default {
  RTV: {
    width: '100%',
  },
  Graph: {
    margin: [20, 0],
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  cell: {
    minHeight: 1280,
  },
  actionsList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  btnBox: {
    display: 'flex',
    marginRight: 40,
  },
  btnSwitchAction: Tabs.btn,
  btnSwitchActionActive: Tabs.btnActive,
  noData: {
    textAlign: 'center',
    fontWeight: 500,
    padding: [30, 0],
  },
  '@global': {
    '.bk-root .bk-tooltip': {
      width: 280,
    },
  },
};
