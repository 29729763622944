import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import injectSheet from 'lib/sheet';
import ValuesList, { sheet } from 'components/ValuesList';
import { createFormConfig, submit } from 'lib/helpers';

const StyledValuesList = injectSheet({
  ...sheet,
  ValuesList: { marginRight: 150, width: 440, marginBottom: 20 },
  value: { width: 300, display: 'flex' },
})(ValuesList);

export const createForm = ({ form, action, formFields, ...props }) => {
  class Form extends React.PureComponent {
    render(){
      return (
        <form>
          <StyledValuesList showStars config={createFormConfig(formFields)} rowData={{}} />
        </form>
      );
    }
  }

  return reduxForm({ form, onSubmit: submit(action), ...props })(Form);
};

export const createSimpleForm = ({ form, action }) => {
  class Form extends React.PureComponent {

    static propTypes = {
      children: PropTypes.any,
    }

    render(){
      const { children } = this.props;
      return (
        <form>
          { children }
        </form>
      );
    }
  }

  return reduxForm({ form, onSubmit: submit(action) })(Form);
};
