import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, selector } from 'cpcs-reconnect';
import { submit, getFormValues } from 'redux-form';
import { push } from 'connected-react-router';
import I from 'immutable';

import sheet from './sheet';
import injectSheet from 'lib/sheet';
import { artwork, isEditable, acceptanceFields, category } from 'domain/artwork';
import { lotList } from 'domain/lotList';
import { authorsById, updateAuthorAction } from 'domain/author';
import { setStorageItem } from 'lib/storage';
import { dictionaryById } from 'domain/dictionary';
import { enableOutliersAction, disableOutliersAction, pushUpdateAction } from 'domain/artwork';
import { lnk } from 'lib/routes';
import AwLots from 'pages/ArtworkPage/AwLots';
import ValuesList, { valuesListSheet } from 'pages/ArtworkPage/ValuesList';
import { createFormConfig, getValueListConfig } from 'lib/helpers';

import Form, { LotForm } from './Form';
import AddLotForm from './AddLotForm';
import Paper from 'components/Paper';
import Block from 'components/Block';
import Header from 'components/Header';
import Accordion from 'components/Accordion';
import Actions from 'components/Actions';
import HistoryPopup from 'popups/History';
import confirmation from 'popups/Confirmation';
import { remove, approve } from 'domain/const';
import Rtv from 'components/RTV';
import Navigation from 'components/Navigation';
import LotList from "./LotList";

import Popup from 'components/NewItemPopup';

import columns from 'fields/artwork';
import { details, basicInfo } from 'domain/artwork/fieldRules';
import { userProfile, requestList } from 'domain/env';

const { value, label } = valuesListSheet;

const RemovePopup = confirmation('remove', remove, 'popupAction.remove.single');
const ArtworkApprove = confirmation('approveArtwork', approve);
const LotApprove = confirmation('approveLot', approve);

const image = columns.select('defaultArtImage');

const history = (showCustomers) => [
  ...columns.select(
    'id',
    'sourceType',
    'createdDate',
    'acceptedDate',
    'updatedDate',
  ),
  ...(showCustomers ? columns.select('customer', 'customers') : []),
];

const outliersFields = columns.select('outliers');

const provenances = columns.select(
  'provenances',
);

export const statistics = columns.select(
  'medium3d',
  'surfaces',
  'surfaceValues',
  'primarySurfaceRank',
  'substrates',
  'substrateValues',
  'primarySubstrateRank',
  'isSignature',
  'physicalSize',
  'imageSize',
  'sheetSize',
  'area',
  // 'lastAcceptedAndDisplayLotDate',
  'lastAcceptedAndDisplaySoldPriceUsd',
  'lastAcceptedAndDisplaySoldPriceUsdWithCar',
  'costPerSquareWithCar',
);

export const auctions = (showRtv = false) => [
  ...columns.select(
    'rtvLast',
    'rtvDate',
  ),
  ...(showRtv ? columns.select('aeRtv', 'defaultRtv', 'abvQualityBenchmark' ) : []),
  ...columns.select(
    'highSoldPriceUsdWithCar',
    'middleSoldPriceUsdWithCar',
    'lowSoldPriceUsdWithCar',
    'highCarAbv',
    'middleCarAbv',
    'lowCarAbv',
  ),
];

const metrics = columns.select(
  'lastAlertAbv',
  'alertHighAbv',
  'alertMiddleAbv',
  'alertLowAbv',
  'highEstimate',
  'middleEstimate',
  'lowEstimate',
  'abvMetricsManifold',
  'abvMetricsMediums',
  'abvMetricsArea',
  'abvMetricsPpsi',
);

export const additionalInfo = columns.select(
  'catalogRaisonne',
  'exhibitions',
);

export const literature = columns.select('literature');

const notes = columns.select('notes', 'useCarPrice', 'carValue');
const cv = columns.select('clusterNumber');

class ArtworkPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    artwork: PropTypes.shape({}).isRequired,
    allowAccept: PropTypes.bool,
    lotList: PropTypes.instanceOf(I.Collection),
    currentCategory: PropTypes.string.isRequired,
    updateAuthorAction: PropTypes.func.isRequired,
    requestList: PropTypes.instanceOf(I.Collection),
    userProfile: PropTypes.shape({}),
  };

  componentDidMount() {
    const { updateAuthorAction } = this.props;
    setStorageItem('acceptedArtist', null);
    window.onstorage = ({ key, newValue }) => {
      if ( key === 'acceptedArtist' && !!newValue ){
        updateAuthorAction(JSON.parse(newValue));
        setStorageItem('acceptedArtist', undefined);
      }
    };
  }

  render() {
    const { classes, artwork, submit, push, lotList, currentCategory, userProfile, requestList, ...props } = this.props;
    const showRtv = userProfile.isSuperuser || userProfile.acl.includes('rtv');
    const showCustomers = userProfile.acl.includes('customer');
    const { hasLots, hasRtvClusters, clusterNumber, auctionCarLotList } = artwork;
    const isEdit = !artwork.isDelete && isEditable(artwork);
    const artworkEditedFields = columns.select(...basicInfo[currentCategory]);
    const detailFields = details[currentCategory].map(v => columns.select(...v));
    const outliers = (isEdit || artwork.sourceType === 'CUSTOMER') ? createFormConfig(outliersFields) : outliersFields;
    return (
      <div className={classes.ArtworkPage}>
        <Navigation linkBuilder={artworkId => lnk('artwork', { artworkId })} />
        <Popup name="ADD_LOT" formName="addLotForm">
          <AddLotForm />
        </Popup>
        <HistoryPopup />
        <RemovePopup />
        <ArtworkApprove />
        <LotApprove />
        <Header
          title={artwork.get('title')}
          object={artwork}
          onSave={() => submit('artworkForm')}
          onCancel={() => push(lnk('artworkList'))}
          disabled={!isEdit && artwork.sourceType !== 'CUSTOMER'}
        />
        { !artwork.isDelete && <Actions {...{ ...props, data: artwork, acceptanceFields, userProfile, requestList }} /> }
        <Paper className={classes.container}>
          <Form {...props}>
            <Block>
              <Accordion title="basicInfo">
                <div className={classes.box}>
                  <ValuesList
                    showStars={isEdit}
                    {...props}
                    rowData={artwork}
                    config={getValueListConfig(artworkEditedFields, isEdit)}
                  />
                  <ValuesList
                    showStars={isEdit}
                    config={getValueListConfig(image, isEdit)}
                    {...props}
                    rowData={artwork}
                  />
                  <ValuesList
                    config={isEdit ?
                      [ ...history(showCustomers), ...outliers, ...createFormConfig(notes), ...cv ]
                      : [ ...history(showCustomers), ...outliers, ...notes, ...cv ]
                    }
                    {...props}
                    rowData={artwork}
                    isEdit={isEdit}
                  />
                </div>
              </Accordion>
            </Block>
            { detailFields.length > 0 && <Block>
              <Accordion title="details">
                <div className={classes.box}>
                  { detailFields.map((fields, index) => <ValuesList
                    key={`${currentCategory}-${index}`}
                    showStars={isEdit}
                    {...props}
                    rowData={artwork}
                    config={getValueListConfig(fields, isEdit)}
                  />)}
                </div>
              </Accordion>
            </Block> }
            <Block>
              <Accordion title="statistics">
                <div className={classes.box}>
                  <ValuesList {...props} config={statistics} rowData={artwork} />
                  <ValuesList config={auctions(showRtv)} rowData={artwork} />
                  <ValuesList config={metrics} rowData={artwork} />
                </div>
              </Accordion>
            </Block>
            {/* <Block>
              <Accordion title="additionalInfo">
                <div className={classes.box}>
                  <ValuesList config={getValueListConfig(additionalInfo, isEdit)} rowData={artwork} />
                  <ValuesList config={getValueListConfig(literature, isEdit)} rowData={artwork} />
                </div>
              </Accordion>
            </Block> */}
            {/* <Block>
              <Accordion title="provenance">
                <div className={classes.box}>
                  <ValuesList
                    {...props}
                    config={getValueListConfig(provenances, isEdit)}
                    rowData={artwork}
                  />
                </div>
              </Accordion>
            </Block> */}
          </Form>
          { !!hasLots && !!lotList.size && <LotForm {...props}>
            <Block>
              <Accordion title="auctionLot">
                <div className={classes.box}>
                  <AwLots list={lotList} artwork={artwork}/>
                </div>
              </Accordion>
            </Block>
          </LotForm> }
          { auctionCarLotList.size > 0 && <Block>
            <Accordion title="auctionCar">
              <div className={classes.box}>
                <LotList map={auctionCarLotList} />
              </div>
            </Accordion>
          </Block> }
          { showRtv && hasRtvClusters && <Block>
            <Accordion title="rtv">
              <div className={classes.box}>
                <Rtv clusterNumber={clusterNumber} artworkId={artwork.id} />
              </div>
            </Accordion>
          </Block> }
        </Paper>
      </div>
    );
  }
}

export default compose(
  injectSheet({
    ...sheet,
    row: { display: 'flex', flex: '0 0 590px', marginRight: 20, height: 44, marginBottom: 0, alignItems: 'center' },
    value,
    label,
  }),
  connect({
    lotList,
    artwork,
    currentCategory: category,
    artistId: authorsById,
    updateAuthorAction,
    pushUpdateAction,
    category: dictionaryById('categories'),
    subjects: dictionaryById('subjects'),
    surfaces: dictionaryById('surfaces'),
    substrates: dictionaryById('substrates'),
    surfaceValues: dictionaryById('surfaceValues'),
    substrateValues: dictionaryById('substrateValues'),
    currency: dictionaryById('currencies'),
    countries: dictionaryById('countries'),
    medium3d: dictionaryById('mediums'),
    formData: selector(getFormValues('artworkForm'), v => v),
    enableOutliers: enableOutliersAction,
    disableOutliers: disableOutliersAction,
    userProfile,
    requestList,
    submit,
    push,
  }),
)(ArtworkPage);
