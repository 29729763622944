
export default {
  buttons: {
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 500,
  },
  content: {
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 20,
  },
  btnList: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    backgroundColor: 'transparent',
    border: '2px solid var(--placeholder)',
    borderRadius: 2,
    margin: 10,
    outline: 'none',
    cursor: 'pointer',
  },
  active: {
    borderColor: 'var(--attention)',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: [5, 0],
  },
  check: {
    flex: '0 0 30px',
    display: 'flex',
  },
  input: {
    flex: '1 0 auto',
  },
};
