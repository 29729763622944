import * as DT from 'react-immutable-jss-data-table';

import {
  REVIEW_STATUS_NEW,
  REVIEW_STATUS_DRAFT,
  REVIEW_STATUS_VALID,
  REVIEW_STATUS_ACCEPTED,
  REVIEW_STATUS_IGNORED, REVIEW_STATUS_DISPLAY,
} from 'domain/const';

const statusToClass = {
  [REVIEW_STATUS_NEW]: 'trMarkNew',
  [REVIEW_STATUS_DRAFT]: 'trMarkDraft',
  [REVIEW_STATUS_VALID]: 'trMarkValid',
  [REVIEW_STATUS_ACCEPTED]: 'trMarkAccepted',
  [REVIEW_STATUS_IGNORED]: 'trMarkIgnored',
  [REVIEW_STATUS_DISPLAY]: 'trMarkDisplay',
};

export const getRowClassNames = (props) => {
  // no row class for thead
  if (!props.rowData) return undefined;

  const { rowData, classes, isRowSelected, rowStatusBg, rowStatusIndicator } = props;
  let rowClassNames = DT.getRowClassNames(props);
  if (rowData.sourceType === 'CUSTOMER') rowClassNames.push(classes.customer);

  // skip tr with headers
  if (!rowData) return rowClassNames || classes.trh;
  const statusClass = classes[statusToClass[rowData.get('wfAcceptance')]];
  if (rowStatusBg && statusClass) {
    rowClassNames = [...rowClassNames, statusClass];
  }
  if (rowStatusIndicator) {
    //rowClassNames = [...rowClassNames, classes[(rowData.get('wfAcceptance') || 'draft').toLowerCase()]];
  }
  if (isRowSelected) {
    rowClassNames = [...rowClassNames, classes.trChecked];
  }
  if (props.edit === props.rowData.get('id') && !!props.rowData.get('id')){
    rowClassNames.push(classes.trEdit);
  }

  const cluster = rowData.get('cluster');
  const outlier = rowData.get('unitFilterPpsiFilterPpsi')
  if (cluster === 0 && outlier) rowClassNames.push(classes[`${outlier}Outlier`])
  return rowClassNames;
};

export const getClassNamesFactory = baseKey => (props) => {
  const { classes, cellClasses = {}, columnDef, isEdit } = props;
  const ret = [classes[baseKey]];
  if (columnDef.className) {
    const key = columnDef.className;
    ret.push(cellClasses[key] || classes[key] || key);
  }
  if (classes[columnDef.name]) ret.push(classes[columnDef.name]);
  ret.push(isEdit && columnDef.inlineEdit ? classes.fieldBox : classes.textBox);
  return ret;
};
