import React from 'react';
import PropTypes from 'prop-types';

import I from 'immutable';
import { FormattedMessage as FM } from 'react-intl';

import CheckBox from 'components/form/fields/CheckBox/CheckBox';
import { ConnectedAccordion, sheet as acSheet } from 'components/Accordion';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import Columns from './Columns';
import { PureButton, sheet as btnSheet } from 'components/Button';

const Accordion = injectSheet({
  ...acSheet,
  header: {
    ...acSheet.header,
    paddingLeft: 14,
  },
})(ConnectedAccordion);

const Button = injectSheet({
  ...btnSheet,
  ghost: {
    ...btnSheet.ghost,
    width: 'auto',
    padding: [0, 10],
  },
})(PureButton);

class ColumnsPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.instanceOf(I.List),
    // TODO one type
    list: PropTypes.any.isRequired,
    defaultList: PropTypes.any.isRequired,
    title: PropTypes.string,
    update: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    columns: PropTypes.any,
    context: PropTypes.string,
    columnSections: PropTypes.array,
  };

  state = {
    selected: new I.List(['id']),
    checkBoxChecked: false,
  }

  reload = () => this.setState({ selected: this.props.selected });

  componentDidMount = () => this.reload();

  update = () => {
    const { close, update } = this.props;
    const { selected, checkBoxChecked } = this.state;
    update(selected, checkBoxChecked);
    close();
  };

  move = (name, newIndex = -1) => {
    let { selected } = this.state;
    this.setState({
      selected: selected
        .filter(v => v !== name)
        .reduce((prev, next, index) => prev.concat(index === newIndex ? [ name, next ] : next), new I.List())
        .update(list => list.size === newIndex ? list.concat(name) : list),
    });
  }

  click = (name) => {
    let { selected } = this.state;
    this.setState({ selected: selected.includes(name) ? selected.filter(v => v !== name) : selected.concat(name) });
  }

  renderDefaultSections() {
    const { classes, list, columns, context } = this.props;
    const { selected } = this.state;
    const unselectedList = list.filter(v => !selected.includes(v));
    return (
      <Accordion title="additional" className={classes.accordion}>
        <Columns context={context} list={unselectedList} columns={columns} onClick={this.click} />
      </Accordion>
    );
  }

  renderCustomSections() {
    const { classes, columns, columnSections, context } = this.props;
    const { selected } = this.state;
    const isNotSelected = v => !selected.includes(v);
    return (
      <>
        {
          columnSections.map(section => (
            <Accordion key={section.title} title={section.title} className={classes.accordion}>
              <Columns context={context} list={section.columns.filter(isNotSelected)} columns={columns} onClick={this.click} />
            </Accordion>
          ))
        }
      </>
    );
  }

  render() {
    const { classes, close, title, defaultList, columns, columnSections, context } = this.props;
    const { selected, checkBoxChecked } = this.state;
    return (
      <div
        className={classes.ColumnsPopup}
      >
        <div className={classes.header}>
          <label className={classes.label}>
            <CheckBox
              value={checkBoxChecked}
              onChange={() => this.setState({ checkBoxChecked: !checkBoxChecked })}
              className={classes.checkbox}
            />
            <FM id={title} />
          </label>
          <button
            type="button"
            children="Set to default"
            className={classes.setDefaultButton}
            onClick={() => this.setState({ selected: defaultList })}
          />
          <div className={classes.buttons}>
            { <Button type="button" buttonType="ghost" onClick={() => close()}><FM id="cancel"/></Button> }
            { <Button type="button" buttonType="ghostActive" onClick={() => this.update()}><FM id="save" /></Button> }
          </div>
        </div>
        <Accordion title="default" className={classes.accordion}>
          <Columns context={context} list={selected} columns={columns} move={this.move} onClick={this.click} checked />
        </Accordion>
        {
          columnSections ? this.renderCustomSections() : this.renderDefaultSections()
        }
      </div>
    );
  }
}

export { ColumnsPopup, styles };
export default injectSheet(styles)(ColumnsPopup);
