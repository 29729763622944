import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import Select from 'components/form/element/Select';
import FilterSelect from 'components/form/element/Select/FilterSelect';
import { dictionaryList, subjectsList } from 'domain/dictionary';
import I from 'immutable';
import l from 'js-levenshtein';
import { token } from 'domain/env';
import { tagCarValueMap } from 'domain/const';
import Api from 'domain/api'


const filter = ({ data: { lv } }, term) => {
  return term ? lv <= 50 : true;
};

const getMinLevenstein = (firstString = '', secondString = '') => {
  const [search, longStr] = [firstString, secondString].sort((a,b) => a.length - b.length);
  const values = Array.from(Array(longStr.length - search.length).keys())
    .map(index => l(longStr.substr(index, search.length), search));
  return Math.min(...values);
};

const SubjectFiltered = Component => {
  class Subjects extends React.Component {

    state = {
      term: '',
    }

    render() {
      const { list, ...props } = this.props;

      let updatedList = I.fromJS(list);
      if (list && list[0] && list[0].options){
        const { value = [] } = props.input;
        const primarySelected = list[0].options.filter(({ value: id }) => value.includes(id));
        if (primarySelected.length) {
          updatedList = updatedList.update(0, v => v.set('options', new I.fromJS(primarySelected)));
        }
      }
      const onInputChange = (term = '') => {
        this.setState({ term });
        return term;
      };

      const { term = '' } = this.state;
      const sortedList = term ? updatedList
        .map(subListMap => subListMap.update('options', optList => optList
          .map(listItem => {
            let lDist = listItem.get('label').includes(term) ? 0 : getMinLevenstein(term, listItem.get('label'));
            return listItem.set('lv', lDist * 1000 + listItem.get('label').length);
          }).sortBy(options => options.get('lv')))) : updatedList;

      const parseValue = ({ value }) => value;
      const parseLabel = ({ label }) => label;
      return <Component
        list={sortedList.toJS()}
        parseValue={parseValue}
        parseLabel={parseLabel}
        onInputChange={onInputChange}
        filterOption={filter}
        isMulti
        grouped
        {...props}
      />;
    }
  };

  Subjects.propTypes = {
    list: PropTypes.array,
    input: PropTypes.shape({
      value: PropTypes.any,
    }),
  };

  return Subjects;
};

const tagCarValueMapper = (type) => ({ id, smcTag, fullName, defaultCarField = 'MIDDLE', ...props }) => {
  // const valueField = tagCarValueMap[defaultCarField];
  // const value = props[valueField];
  // let title = smcTag + (value ? ` (${value})` : '');
  // if (type !== 'Inline')
    const title = fullName;
  return { id, title };
}

const AsyncSelect = ({ token, apiKey,  type, ...props }) => {
  const [list, setList] = useState([])
  
  useEffect(() => {
    if (apiKey && token) {
      (async () => {
        try {
          const { data: list} = await Api[apiKey]({ token, query: {primary: true, secondary: true} });
          setList(I.fromJS(list
            .map(tagCarValueMapper(type))
            ));
        } catch (e) {}
      })()
    }
  }, [apiKey,token])

  return (
    <>
      {type === "Inline" ? (
        <FilterSelect {...props} list={list} />
      ) : (
        <Select {...props} list={list} />
      )}
    </>
  );
}

const filterCurrency = list => list.filter(item => ['USD', 'GBP', 'EUR', 'HKD', 'CHF'].includes(item.get('title')));

const acWrapper = (apiKey, type) => ({ ...props }) => <AsyncSelect apiKey={apiKey} type={type} { ...props } />

export const CountryAutoComplete = connect({ list: dictionaryList('countries') })(Select);

export const CategoryAutoComplete = connect({ list: dictionaryList('categories') })(Select);

export const SubjectAutoComplete = connect({ list: subjectsList })(SubjectFiltered(Select));

export const MediumAutoComplete = connect({ list: dictionaryList('mediums') })(Select);

export const DefaultCarValueFieldsAutoComplete = connect({ list: dictionaryList('defaultCarValueFields') })(Select);

export const AuctionAutoComplete = connect({ list: dictionaryList('auctions') })(Select);

export const CurrencyAutocomplete = connect({ list: dictionaryList('currencies') })(Select);

export const BulkCurrencyAutocomplete = connect({ list: dictionaryList('currencies', filterCurrency) })(Select);

export const ConditionsAutocomplete = connect({ list: dictionaryList('conditions') })(Select);

export const SubjectsInlineAutoComplete = connect({ list: subjectsList })(SubjectFiltered(FilterSelect));

export const TagAutocomplete = connect({ token })(acWrapper('getSmctagList'));

export const TagAutocompleteInline = connect({ token })(acWrapper('getSmctagList', 'Inline'));
