import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';

import { settings, updateSettings } from 'domain/settings';
import { getLocationName } from 'domain/router';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class PureAccordion extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}),
    collapsed: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    collapsed: false,
  };

  onChange = () => this.setState({ collapsed: !this.state.collapsed });

  render() {
    const {
      classes: { Accordion: root, arrow, closed, header, content, hidden },
      collapsed,
      children,
      title,
      onChange = this.onChange,

      modifier,
    } = this.props;
    const calculatedCollapse =
      collapsed === undefined ? this.state.collapsed : collapsed;

    return (
      <div className={cx(root, modifier)}>
        <div className={cx(header, modifier)} onClick={() => onChange()}>
          <div
            className={cx(arrow, modifier, { [closed]: !calculatedCollapse })}
          />
          <FormattedMessage id={title} />
        </div>
        <div
          className={cx(content, modifier, { [hidden]: calculatedCollapse })}
        >
          {children}
        </div>
      </div>
    );
  }
}

const connectAccordion = (Component) => {
  const decorator = ({ settings, updateSettings, page, ...props }) => {
    if (!page || !settings) return null;
    const path = [page, 'accordions', props.title];
    const collapsed = settings.getIn(path, false);
    const onChange = () => updateSettings({ path, value: !collapsed });
    return <Component collapsed={collapsed} onChange={onChange} {...props} />;
  };

  decorator.propTypes = {
    settings: PropTypes.instanceOf(I.Map).isRequired,
    updateSettings: PropTypes.func.isRequired,
    page: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  return decorator;
};

connectAccordion.propTypes = {
  settings: PropTypes.instanceOf(I.Map).isRequired,
  updateSettings: PropTypes.func.isRequired,
};

export { sheet };

export const ConnectedAccordion = compose(
  connect({ settings, updateSettings, page: getLocationName }),
  connectAccordion,
)(PureAccordion);

export default injectSheet(sheet)(ConnectedAccordion);
