import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import I from 'immutable';
import { FormattedMessage as FM } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { showTooltip, hideTooltip } from 'domain/ui';

class ButtonPure extends React.Component {
  static propTypes = {
    showTooltip: PropTypes.func.isRequired,
    hideTooltip: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }

  componentWillUnmount = () => {
    this.props.hideTooltip();
  }

  node = null;

  render(){
    const { title: id, showTooltip, hideTooltip, intl: { formatMessage }, ...props } = this.props;
    return (
      <button
        ref={(node) => this.node = node}
        {...props}
        onMouseOver={() => showTooltip(this.node, formatMessage({ id }))}
        onMouseOut={() => hideTooltip()}
      />
    );
  }
}

const Button = compose(
  injectIntl,
  connect({ showTooltip, hideTooltip }),
)(ButtonPure);

function RenderOrderedColumnTh({ className, classes, columnDef, toggleOrderBy, removeOrderBy, orderedBy }) {
  const title = columnDef.title || columnDef.name;
  if (!columnDef.sortable || !orderedBy) {
    return <div className={className} data-column-name={columnDef.name}><FM id={title}/></div>;
  }
  const field = columnDef.sortByName || columnDef.name;
  const entry = orderedBy.findEntry(v => v.get('field') === field);
  const orderedDesc = entry && entry[1].get('direction') === 'DESC';
  return (
    <div className={cx(className, { [classes.thOrdered]: !!entry })} data-column-name={columnDef.name}>
      <div
        className={classes.thTitle}
        onClick={() => toggleOrderBy(field)}
      ><FM id={title}/></div>
      <Button
        key="1"
        type="button"
        className={cx(classes.orderArrow, { [classes.orderedDesc]: orderedDesc })}
        onClick={() => toggleOrderBy(field)}
        title="toggleSorting"
      />
      {
        entry &&
        <Button
          type="button"
          className={classes.removeOrderByField}
          children={entry[0] + 1}
          onClick={() => removeOrderBy(field)}
          title="removeFromSorting"
        />
      }
    </div>
  );
}
RenderOrderedColumnTh.propTypes = {
  className: PropTypes.string,
  columnDef: PropTypes.shape({
    orderBy: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    thOrdered: PropTypes.string,
    thTitle: PropTypes.string,
    removeOrderByField: PropTypes.string,
    orderArrow: PropTypes.string,
    orderedDesc: PropTypes.string,
  }).isRequired,
  toggleOrderBy: PropTypes.func,
  removeOrderBy: PropTypes.func,
  orderedBy: PropTypes.instanceOf(I.Collection),
};

export default RenderOrderedColumnTh;
