import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

export { sheet };
const defaultTrueFn = () => true;

export const Row = (props) => {
  const {
    name: key,
    title,
    titleFn,
    rowData,
    classes,
    parser,
    getter,
    getValue,
    defaultValue,
    required,
    visibleInValueList = defaultTrueFn,
    namePrefix,
    modifier,
  } = props;
  const parseValue = parser || getValue;
  const value = getter(key, rowData);
  let toRender = null;
  try {
    toRender = parseValue({ key, value: value, ...props }) || defaultValue;
  } catch (e) {
    const Value = parser || getValue;
    toRender = <Value {...{ key, value: value || defaultValue, ...props }} />;
  }
  if (
    !visibleInValueList({ ...props, value }) ||
    [null, '', undefined].includes(toRender)
  )
    return null;
  return (
    <div className={cx(classes.row, modifier)} data-column-name={key}>
      <div
        className={cx(classes.label, modifier, {
          [classes.required]: required,
        })}
      >
        {namePrefix}{' '}
        <FormattedMessage
          id={typeof titleFn === 'function' ? titleFn(rowData) : title}
        />
      </div>
      <div className={cx(classes.value, modifier)}>{toRender}</div>
    </div>
  );
};

Row.propTypes = {
  classes: PropTypes.shape({
    row: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  modifier: PropTypes.string,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rowData: PropTypes.shape({}).isRequired,
  parser: PropTypes.func,
  getValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  getter: PropTypes.func,
  required: PropTypes.bool,
  defaultValue: PropTypes.node,
  visibleInValueList: PropTypes.func,
  namePrefix: PropTypes.string,
};

class ValuesList extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      ValuesList: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    modifier: PropTypes.string,
    showStars: PropTypes.bool,
    // `columns` is better name. allaround the project config is hashtable of columns, not the array
    config: PropTypes.array.isRequired,
    rowData: PropTypes.shape({}).isRequired,
    renderer: PropTypes.func,
    parser: PropTypes.func,
    getter: PropTypes.func,
    namePrefix: PropTypes.string,
  };

  static defaultProps = {
    renderer: (props) => <Row key={props.name} {...props} />,
    getValue: ({ value }) => (value === undefined ? '' : value),
    getter: (key, data) => (data && data.getIn ? data.getIn([key], '') : ''),
    config: {},
    showStars: false,
  };

  render() {
    const { config, renderer, showStars, ...props } = this.props;
    return (
      <div className={cx(this.props.classes.ValuesList, props.modifier)}>
        {config.map((params) =>
          renderer({
            ...props,
            ...params,
            required: showStars ? params.required : false,
          }),
        )}
      </div>
    );
  }
}

export { ValuesList as ValuesListPure };
export default injectSheet(sheet)(ValuesList);
