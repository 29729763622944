import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import { navigation } from 'domain/navigation';
import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';
import { Link } from 'react-router-dom';

const sheet = {
  Navigation: {
    marginBottom: 20,
    position: 'relative',
    height: 20,
    width: '100%',
    flex: '0 0 auto',
  },
  img: {
    content: '""',
    display: 'block',
    width: 20,
    height: 20,
    position: 'absolute',
    top: 0,
    background: {
      size: '12px 12px',
      repeat: 'no-repeat',
      position: 'center center',
      image: `url(${require('./navigation.svg')})`,
    },
  },
  link: {
    color: colors.labelLink,
    textDecoration: 'none',
    position: 'absolute',
    lineHeight: '20px',
    maxWidth: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  prevLink: {
    extend: 'link',
    left: 0,
    paddingLeft: 20,
    '&:before': {
      extend: 'img',
      left: 0,
      transform: 'rotate(180deg)',
    },
  },
  nextLink: {
    extend: 'link',
    right: 0,
    paddingRight: 20,
    '&:before': {
      extend: 'img',
      right: 0,
    },
  },
};

class Navigation extends React.PureComponent {
  static propTypes = {
    linkBuilder: PropTypes.func.isRequired,
    navigation: PropTypes.object,
    classes: PropTypes.object,
  }

  render(){
    const { navigation: { prev = {}, next = {} }, linkBuilder, classes: { Navigation, prevLink, nextLink } } = this.props;
    if ((!prev || !prev.id) && (!next || !next.id)) return null;
    return (
      <div className={Navigation}>
        { !!prev && !!prev.id && <Link to={linkBuilder(prev.id)} className={prevLink}>{prev.title}</Link> }
        { !!next && !!next.id && <Link to={linkBuilder(next.id)} className={nextLink}>{next.title}</Link> }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({ navigation }),
)(Navigation);
