import checkedBg from 'components/form/fields/CheckBox/images/chevron.svg';
import colors from 'theme/Colors';

export default {
  CheckBox: {
    border: `1px solid ${colors.labelLink}`,
    appearance: 'none',
    background: '#FFF',
    borderRadius: 0,
    width: 14,
    height: 14,
    flex: '0 0 14px',
    boxSizing: 'border-box',
    margin: 0,
    outline: 'none',
    '&:checked': {
      border: '1px solid #2e343e',
      background: {
        color: '#2e343e',
        image: `url("${checkedBg}")`,
        repeat: 'no-repeat',
        position: 'center center',
        size: '12px 9px',
      },
    },
  },
};
