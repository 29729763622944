const colors = {
  transparentHover: '#f0f0f0',
  // accents
  attention: '#AA4423', //Attention
  menu: '#032537', //Menu
  // primary
  text: '#000000', //text
  labelLink: '#2e343e', //labelLink
  links: '#0058bf',
  placeholder: '#babcbf', //placeholder
  fieldBorder: '#d1d3d5', //fieldBorder
  buttonHover: '#e3e5e8', //buttonHover
  popupBg: '#f7f7f7', //popupBg
  bg: '#ffffff', //bg

  warning: '#fbf1e0', //warning
  error: '#d0021b',
  orange: '#ffc4ab',
};

export default colors;

export const statusColors = {
  new: { backgroundColor: '#b8d3f3' },
  draft: { backgroundColor: '#d1d6dc' },
  valid: { backgroundColor: '#d7b8e5' },
  accepted: { backgroundColor: '#a9dcae' },
  ignore: { backgroundColor: '#e8aeae' },
  display: { backgroundColor: '#e9f5d6' },
  activeStripe: { backgroundColor: '#b8d3f3' },
  trial: { backgroundColor: '#d1d6dc' },
  beta: { backgroundColor: '#d7b8e5' },
  activeByPayment: { backgroundColor: '#a9dcae' },
  inactive: { backgroundColor: '#e8aeae' },
};

export const marksBgColors = {
  new: { backgroundColor: '#e8f2ff' },
  draft: { backgroundColor: '#f7f7f8' },
  valid: { backgroundColor: '#f3eaf7' },
  accepted: { backgroundColor: '#e5f5e7' },
  ignore: { backgroundColor: '#f8e7e7' },
  display: { backgroundColor: 'rgba(233,245,214,0.51)' },
  'active stripe': { backgroundColor: '#b8d3f3' },
  trial: { backgroundColor: '#d1d6dc' },
  beta: { backgroundColor: '#d7b8e5' },
  'active by payment': { backgroundColor: '#a9dcae' },
  inactive: { backgroundColor: '#e8aeae' },
};

export const statusColorsList = {
  new: '#e8f2ff',
  draft: '#f7f7f8',
  valid: '#f3eaf7',
  accepted: '#e5f5e7',
  ignore: '#f8e7e7',
  display: '#e9f5d6',
};
