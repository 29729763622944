import bg from 'components/form/fields/Radio/images/radio.svg';
import activeBg from 'components/form/fields/Radio/images/radio-active.svg';

import ckBg from 'components/form/fields/Radio/images/check.svg';
import activeCkBg from 'components/form/fields/Radio/images/check-active.svg';

export default {
  Radio: {
    appearance: 'none',
    borderRadius: 0,
    width: 22,
    height: 22,
    flex: '0 0 22px',
    boxSizing: 'border-box',
    margin: 0,
    outline: 'none',
    cursor: 'pointer',
    background: {
      color: 'transparent',
      image: `url("${bg}")`,
      repeat: 'no-repeat',
      position: 'center center',
      size: '20px 20px',
    },
    '&:checked': {
      backgroundImage: `url("${activeBg}")`,
    },
    '&.checkbox': {
      backgroundImage: `url("${ckBg}")`,
      '&:checked': {
        backgroundImage: `url("${activeCkBg}")`,
      },
    },
  },
};
