import I from 'immutable';
import { field, selector, at } from 'cpcs-reconnect';

import { action } from 'lib/actions';
import * as env from 'domain/env/EnvActions';
import { Tooltip } from 'domain/lib';

export const err404Action = action('ui/ERROR/404');
export const updatePage = action('ui/UPDATE_PAGINATION');
export const showPopup = action('ui/SHOW_POPUP');
export const hidePopupByName = action('ui/HIDE_POPUP');
export const setModalModeAction = action('ui/SET_SUBWINDOW_MODE');
export const loadingCompleteAction = action('ui/PAGE_LOADING_COMPLETE');
export const bulkActionsUpdate = action('ui/BULK_ACTIONS_UPDATE');
export const bulkActionsSuccess = action('ui/BULK_ACTION_DONE');
export const bulkActionsError = action('ui/BULK_ACTION_FAILURE');
export const bulkActionsClear = action('ui/BULK_ACTIONS_CLEAR');

export const showTooltip = (node, text, translate = false) => {
  const { x, y } = node.getBoundingClientRect();
  return { payload: { x,y,text, translate }, type: 'ui/SHOW_TOOLTIP' };
};
export const hideTooltip = action('ui/HIDE_TOOLTIP');

const bulkActionsDefaultState = {
  name: '',
  progress: 0,
  total: 0,
  errors: []
};

const State = I.fromJS({
  popups: [],
  bulkActions: bulkActionsDefaultState,
  errors: {
    err404: false,
  },
  modalMode: '',
  loading: true,
  page: 1,
});

const form = field('form');
const ui = field('ui');
export const get404 = selector(ui, s => s.getIn(['errors', 'err404']));
export const loading = ui.then(at('loading'));
export const virtualPage = selector(ui, s => s.get('page'));
export const visiblePopupsNames = selector(ui, s => s.get('popups').toJS().map(({ name }) => name ));
export const isSubmitting = selector(form, visiblePopupsNames, (form, popups) => popups
  .length === 0 && Object.keys(form).filter(key => form[key].submitting === true).length > 0)
export const getTooltipData = selector(ui, s => s.get('tooltip'));
export const getBulkActionsData = selector(ui, s => s.get('bulkActions').toJS());
export const getPopupParamsByName = (popupName) => selector(ui, s => s
  .get('popups').toJS().find(({ name }) => name === popupName) || {},
);
export const modalModeSelector = ui.then(at('modalMode'));

export const reducer = {
  ui(state = State, action) { //NOSONAR
    switch (action.type) {

      case '@@router/LOCATION_CHANGE':
        return state
          .set('loading', true)
          .set('page', 1)
          .setIn(['errors', 'err404'], false);

      case updatePage.type:
        return state
          .update('page', v => v + 1)

      case loadingCompleteAction.type:
        return state.set('loading', false);

      case showPopup.type:
        return state.update('popups', list => list.push(action.payload));

      case hidePopupByName.type:
        return state.update('popups', list => list.filter(({ name }) => name !== action.payload));

      case 'ui/SHOW_TOOLTIP':
        return state.set('tooltip', Tooltip.parse(action.payload));

      case hideTooltip.type:
        return state.set('tooltip', null);

      case env.logOut.type:
        return State;

      case err404Action.type:
        return state
          .setIn(['errors', 'err404'], action.payload);

      case bulkActionsUpdate.type:
        return state
          .set('bulkActions', I.fromJS({ progress: 0, errors: [], ...action.payload }))

      case bulkActionsSuccess.type:
        return state
          .updateIn(['bulkActions', 'progress'], v => v + 1)

      case bulkActionsError.type:
        return state
          .updateIn(['bulkActions', 'progress'], v => v + 1)
          .updateIn(['bulkActions','errors'], v => v.push(action.payload))

      case bulkActionsClear.type:
        return state.set('bulkActions', I.fromJS(bulkActionsDefaultState))

      case setModalModeAction.type:
        return state
          .set('modalMode', action.payload);

      default:
        return state;
    }
  },
};
