import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import getPageTable from 'components/PageTable';
import injectSheet from 'lib/sheet';
import styles from 'components/PageTable/sheet';
import { getQuery } from 'domain/router';

export const createTable = ({
  tableName,
  tableParams,
  updateItemAction,
  sheet = styles,
  connections = {},
  ...tableProps
}) => {
  const PageTable = getPageTable({ tableName, ...tableParams });
  class ListTable extends React.PureComponent {
    static propTypes = {
      query: PropTypes.object,
    };

    render() {
      const { query, ...props } = this.props;
      return (
        <PageTable
          rowStatusBg={!query.wfAcceptance}
          updateAction={updateItemAction}
          {...tableProps}
          {...props}
        />
      );
    }
  }

  return compose(
    injectSheet(sheet),
    connect({
      query: getQuery,
      ...connections,
    }),
  )(ListTable);
};
