import { field } from 'cpcs-reconnect';

import { Lot } from 'domain/lib';
import { asyncAction, action } from 'lib/actions';
import { lock } from 'domain/const';

export const editableFields = [
  'auctionId',
  'images',
  'defaultArtImage',
  'auctionDate',
  'saleNumber',
  'lotNumber',
  'lotUrls',
  'auctionTitle',
  'auctionLocation',
  'auctionSaleUrl',
  'auctionStartDate',
  // 'auctionEndDate',
  'auctionStartTime',
  'auctionEndTime',
  'currency',
  'estimatePriceStart',
  'estimatePriceEnd',
  'soldPrice',
  'artId',
  'condition',
  'provenance',
  'exhibition',
  'catalogRaisonne',
  'literature',
  'conditionRaw',
  'guarantee',
];

export const acceptanceFields = [
  'artId',
  'auctionId',
  'auctionDate',
  'lotUrls',
  'currency',
];

export const fetchLotAction = asyncAction('lot/GET_BY_ID');
export const updateLotAction = asyncAction('lot/UPDATE');
export const changeStatusAction = action('lot/CHANGE_STATUS');
export const mergeLotAction = asyncAction('lot/MERGE');
export const removeLotAction = asyncAction('lot/REMOVE');
export const getChildrenAction = asyncAction('lot/GET_CHILDREN');
export const lockGuarantee = action('lot/GUARANTEE_LOOK');

export const lot = field('lot');

export const reducer = {
  lot(state = new Lot(), action) { //NOSONAR
    switch (action.type) {

      case mergeLotAction.success:
      case removeLotAction.success:
      case updateLotAction.success:
      case fetchLotAction.success:
        return Lot.parse(action.payload);

      case getChildrenAction.success:
        return state.apply(
          Lot.$children.parsed(action.payload),
        );

      case lock.type:
        return action.payload.subject === 'Lot' ? state.set('locked', action.payload) : state;

      default:
        return state;
    }
  },
};
