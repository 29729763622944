import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import injectSheet from 'lib/sheet';

import rules from 'components/form/validation';
import { PureInput, sheet } from 'components/form/element/Input';
import { forgotPassword } from 'domain/env/EnvActions';

const Input = injectSheet({
  ...sheet,
  element: { ...sheet.element, height: 42, lineHeight: '42px' },
  wrapper: { marginBottom: 20 },
})(PureInput);

function onSubmit(data, dispatch) {
  const submitPromise = new Promise((resolve) => {
    dispatch(forgotPassword({ data, resolve }));
  });
  return submitPromise.then((err) => {
    if (err) {
      throw new SubmissionError({
        email: { id: 'customMessage', message: 'Email not found in our system' },
        _error: 'Save failed!',
      });
    }
  });
}

const fields = [
  {
    component: Input,
    name: 'email',
    autoComplete: 'email',
    validate: [ rules.required, rules.email ],
  },
];

class Form extends React.PureComponent {
  render(){
    return (
      <form>
        { fields.map(props => <Field key={props.name} {...props} />) }
      </form>
    );
  }
}

export default reduxForm({ form: 'forgot', onSubmit })(Form);
