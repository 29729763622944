import React from 'react';
import { reduxForm } from 'redux-form';

import { updateArtistAction } from 'domain/artist';
import { submit } from 'lib/helpers';

function onSubmitFail() {
  const { change } = arguments[3] || {};
  change('wfAcceptance', '');
}

class Form extends React.PureComponent {
  render(){
    return (
      <form>
        { this.props.children }
      </form>
    );
  }
}

export default reduxForm({ form: 'artistForm', onSubmit: submit(updateArtistAction), onSubmitFail })(Form);
