import sheet from 'components/PageTable/sheet';

export default {
  ...sheet,
  createdDate: {
    width: 170,
  },
  updatedDate: {
    width: 170,
  },
}
