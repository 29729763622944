import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { urlSetParams } from './helpers';

export default function(WrappedComponent) {
  class LinkBuilderDecorator extends PureComponent {
    static propTypes = {
      location: PropTypes.shape({
        hash: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
      }).isRequired,
    };

    /**
     * params like { page: 1, size: 20}
    **/
    buildLink = (params) => {
      const { location } = this.props;
      return urlSetParams(location, params);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          linkBuilder={this.buildLink}
        />
      );
    }
  }

  return withRouter(LinkBuilderDecorator);
}
