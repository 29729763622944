import { put, takeLatest } from 'redux-saga/effects';

import { fetchHistoryAction } from 'domain/history';
import Api, { callApi } from 'domain/api';

function* loadHistory({ payload }){
  try {
    yield put({ type: fetchHistoryAction.request });
    const { data: { list } } = yield callApi(Api.getHistory, payload );
    yield put({
      type: fetchHistoryAction.success,
      payload: list,
    });
  } catch (e) {

  }
}

export function* watchLoadHistory() {
  yield takeLatest(fetchHistoryAction.type, loadHistory);
}
