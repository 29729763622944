import common from 'popups/sheet';
import tClasses from 'components/PageTable/sheet';
import btn from 'theme/Buttons';

const { tbody, tdEmpty, loadWrapper, contentWrp, rowEmpty } = tClasses;

export default {
  ...common,
  table: {
    ...common.table,
    margin: 0,
  },
  createdDate: {
    width: 150,
  },
  soldPriceRaw: {
    width: 150,
    fontWeight: 500,
  },
  url: {
    height: 32,
    marginTop: 8,
    width: 0,
  },
  actions: {
    flex: '1 0 110px',
  },
  apply: {
    ...btn.add,
    width: 80,
    height: 32,
  },
  thead: {},
  tbody,
  tdEmpty,
  rowEmpty,
  loadWrapper,
  contentWrp,
};
