import React, { useCallback, useState } from 'react';
import { connect } from "cpcs-reconnect";
import { token } from 'domain/env'
import cx from 'classnames';

import Api from 'domain/api';

import classes from './style.module.scss';
import Button from "components/Button";
import { addNotification } from "domain/env/EnvActions";
import { updateClusterAction } from "domain/artwork";
import Link from "components/Link";
import { lnk } from 'lib/routes';

const Cluster = ({ id, token, clusterNumber = '', updateClusterAction, addNotification }) => {

  const [ cluster, setCluster ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const change = useCallback(e => setCluster(e.target.value), [setCluster]);

  const onClick = useCallback(() => {
    setLoading(true);
    let data = {}, apiKey = 'addAoCluster';
    if (cluster) {
      data = { cluster };
      apiKey = 'updateAoCluster';
    }
    Api[apiKey]({ id, token, data })
      .then(({ data: { result }}) => {
        setLoading(false);
        updateClusterAction({ clusterNumber: result?.newCluster || cluster });
        setCluster('');
        addNotification({ title: `Cluster ${!!cluster ? 'updated' : 'created'}`, type: 'success', translate: false });
      }).catch(() => {
        setLoading(false);
        addNotification({ title: 'Something went wrong', type: 'error', translate: false });
    })
  }, [id, token, cluster, setLoading, addNotification, updateClusterAction])

  return (
    <div className={classes.container}>
      { `CLUSTER # ${clusterNumber}` }
      <input className={classes.input} value={cluster} onChange={change} type="number" min="1"/>
      <Button
        onClick={onClick}
        className={cx(classes.btn, !!cluster ? classes.update : classes.create )}
        title={!!cluster ? 'Change cluster' : 'Add new cluster'}
        disabled={loading}
      />
      <div className={classes.link}>
        <Link force={true} url={lnk('clusterList', { id })} target="_blank" />
      </div>
    </div>
  )
}

export default connect({ token, addNotification, updateClusterAction })(Cluster);
