import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage as FM } from 'react-intl';
import { Link } from 'react-router-dom';

import { lnk } from 'lib/routes';
import { paramNameByLocation, clearSelection } from 'domain/const';
import { modalModeSelector } from 'domain/ui';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import { withRouter } from 'react-router-dom';
import { selectors } from 'domain/customer';

const StatusLink = ({ linkName, quantity = 0, clearSelection, name, classes: { link, active }, query = {}, ...routeParams }) => {
  const paramName = paramNameByLocation[linkName];
  let params = { ...routeParams, query: { ...query, page: undefined, [paramName]: name } };
  if (linkName === 'portfolioList') params = { ...routeParams, query: { ...query, page: undefined, [paramName]: name.toUpperCase() } };
  if (params.query[paramName] === 'all' || params.query[paramName]==='ALL') delete(params.query[paramName]);
  return <Link
    onClick={() => clearSelection()}
    to={lnk(linkName, params)}
    className={cx(link, { [active]: (name || 'all') === (query[paramName] || 'all') })}
  >
    <FM id={name}/>
    { ` (${new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(quantity || 0)})` }
  </Link>;
};

StatusLink.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
  }),
  query: PropTypes.object,
  linkName: PropTypes.string,
  clearSelection: PropTypes.func.isRequired,
  quantity: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  name: PropTypes.string.isRequired,
};

const ListHeader = ({
  classes,
  className,
  title,
  stats = {},
  linkName,
  query,
  clearSelection,
  user,
  userProfile,
  match: {
    params,
  },
}) => {

  const list = !!stats ? Object.keys(stats).map(name => ({
    name,
    quantity: stats[name],
    classes,
    linkName,
    query,
    ...params,
  })) : [];
  return (
    <div className={cx(classes.ListHeader, { [className]: !!className })}>
      { !!params.userId && !!user.id && <div>
        <Link className={classes.userLink} to={lnk('customer', { id: params.userId })}>
          <FM id="backToUser"/>
          <strong>{ `${user.firstName} ${user.lastName}` }</strong>
        </Link>
      </div>}
      <div className={classes.container}>
        <div className={classes.nav}>
          <h2 className={classes.title}>
            { !!query.isDelete && <FM id="deletedPrefix"/> }
            { !!query.isInvalid && <FM id="invalidPrefix"/> }
            <FM id={title}/>
          </h2>
          {[ !!stats && Object.keys(stats).length > 0 && list.map(params => <StatusLink
            clearSelection={clearSelection}
            key={params.name}
            classes={classes}
            {...params}
          />) ]}
        </div>
        { userProfile?.isSuperuser && (<a href="https://enterprise.artd.ai/auth/user-account">Create Customer</a>)}
      </div>
    </div>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.shape({
    ListHeader: PropTypes.string,
  }),
  className: PropTypes.string,
  stats: PropTypes.object,
  query: PropTypes.object,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  linkName: PropTypes.string,
  clearSelection: PropTypes.func.isRequired,
  user: PropTypes.any,
  userProfile: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default compose(
  injectSheet(sheet),
  connect({
    modalMode: modalModeSelector,
    clearSelection,
    user: selectors.item,
  }),
  withRouter,
)(ListHeader);
