import {
  arrayParser, boolFilter,
  commonFilters,
  createFilterTransfer,
  dateParser,
  dateRange, floatRange,
  intRange,
  MediumsFilterAutoComplete,
  OutliersAutoComplete,
  SignatureAutoComplete,
  stringFilter,
  yearRange,
  PureInput,
} from 'filters/common';

export const defaultList = [
  'id',
  'category',
  'sourceType',
  'artistId',
  'title',
  'medium',
  'yearCreatedFrom',
  'isSignature',
  'area',
];
// only different logic same fields transfer as is ( category => category )
export const columnsTransfer = {
  artistId: [ 'artistId', 'artistIdId' ],
  isSignature: [ 'signature', 'isSignature' ],
  artistIdId: [ 'artistId', 'artistIdId' ],
};

//TODO Remove this
const tempParser = (key, value) => value === 'NULL' ? ' NOT((outliers:LOW)OR(outliers:HIGH))' : `(outliers:${ value })`;

export const filters = {
  id: {},
  title: stringFilter('art'),
  artistId: stringFilter('art'),
  artistIdId: {},
  category: stringFilter('art'),
  medium: stringFilter('art'),
  isSignature: { formFieldRenderer: SignatureAutoComplete },
  lastSaleNumber: stringFilter('art'),
  lastLotNumber: stringFilter('art'),
  auctionDateLast: { parser: dateParser, formFieldRenderer: dateRange('auctionDateLast', 'placeholder.date') },
  medium3d: { parser: arrayParser, component: MediumsFilterAutoComplete, fieldProps: { isMulti: true } },
  //physicalSizeWidth: { formFieldRenderer: intRange('physicalSizeWidth') },
  //physicalSizeHeight: { formFieldRenderer: intRange('physicalSizeHeight') },
  //physicalSizeDepth: { formFieldRenderer: intRange('physicalSizeDepth') },
  yearCreatedFrom: { formFieldRenderer: yearRange('yearCreatedFrom', 'placeholder.year') },
  yearCreatedTo: { formFieldRenderer: yearRange('yearCreatedTo', 'placeholder.year') },
  primarySurfaceRank: { formFieldRenderer: intRange('primarySurfaceRank') },
  primarySubstrateRank: { formFieldRenderer: intRange('primarySubstrateRank') },
  //physicalSizeUnits: stringFilter('art'),
  area: { formFieldRenderer: intRange('area') },
  auctionHouseLast: stringFilter('art', 'auction_lots__auction_id'),
  abvQualityBenchmark: { formFieldRenderer: floatRange('abvQualityBenchmark') },
  //estimatePriceStartLast: { formFieldRenderer: intRange('estimatePriceStartLast') },
  //estimatePriceEndLast: { formFieldRenderer: intRange('estimatePriceEndLast') },
  //epLastUsd: { formFieldRenderer: intRange('epLastUsd') },
  epStartLastUsd: { formFieldRenderer: intRange('epStartLastUsd') },
  epEndLastUsd: { formFieldRenderer: intRange('epEndLastUsd') },
  spLastUsd: { formFieldRenderer: intRange('spLastUsd') },
  rtvLast: { formFieldRenderer: intRange('rtvLast') },
  lastAlertAbv: { formFieldRenderer: intRange('lastAlertAbv') },
  outliers: { formFieldRenderer: OutliersAutoComplete, tempParser },
  artistIdWfAcceptance: stringFilter('art', 'artist_id__wf_acceptance'),
  usernameLastUpdate: stringFilter('art'),
  abvMetricsManifold: { formFieldRenderer: boolFilter('abvMetricsManifold') },
  abvMetricsMediums: { formFieldRenderer: boolFilter('abvMetricsMediums') },
  abvMetricsArea: { formFieldRenderer: boolFilter('abvMetricsArea') },
  abvMetricsPpsi: { formFieldRenderer: boolFilter('abvMetricsPpsi') },
  lastGuarantee: { formFieldRenderer: boolFilter('lastGuarantee') },
  artistShowRtv: { formFieldRenderer: boolFilter('artistShowRtv'), key: 'artist_id__show_rtv' },
  countOfLots: { formFieldRenderer: intRange('countOfLots') },
  hasSimilarImages: { formFieldRenderer: boolFilter('hasSimilarImages'), key: 'has_similar_images'  },
  hasRepeatSales: { formFieldRenderer: boolFilter('hasRepeatSales'), key: 'has_repeat_sales' },
  physicalSizeRaw: stringFilter('art'),
  carValue: { formFieldRenderer: floatRange('carValue') },
  lowCarAbv: { formFieldRenderer: floatRange('lowCarAbv') },
  middleCarAbv: { formFieldRenderer: floatRange('middleCarAbv') },
  highCarAbv: { formFieldRenderer: floatRange('highCarAbv') },
  lowSoldPriceUsdWithCar: { formFieldRenderer: floatRange('lowSoldPriceUsdWithCar') },
  middleSoldPriceUsdWithCar: { formFieldRenderer: floatRange('middleSoldPriceUsdWithCar') },
  highSoldPriceUsdWithCar: { formFieldRenderer: floatRange('highSoldPriceUsdWithCar') },
  clusterNumber: { component: PureInput, parser: v => v, fieldProps: { type: 'number', min: 0 } },
  ...commonFilters,
};

export const filterTransfer = createFilterTransfer(filters, columnsTransfer);
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer };

export default filters;
