import { createSimpleForm } from 'pages/common/Form';
import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/sale';
import { createFormConfig } from 'lib/helpers';

import { dictionaryById } from 'domain/dictionary';
import { columns } from 'fields/sale';

const { item } = selectors;
const { updateItemAction } = actions;

export const editableFields = ['title', 'auction', 'saleNumber'];

export default createItemPage({
  name: 'sale',
  titleFn: ({ id, title }) => title || 'Untitled Auction Sale - ' + id,
  connections: {
    item,
    auction: dictionaryById('auctions'),
  },
  config: {
    basicInfo: [
      createFormConfig(columns.select(...editableFields)),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
  },
  Form: createSimpleForm({
    form: 'saleForm',
    action: updateItemAction,
  }),
});
