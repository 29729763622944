import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import {
  checkJunkCharacterAction,
  updateJunkCharacterInLineAction,
  onEditAction,
  junkCharacterList,
  edit,
  selectedjunkCharacters,
  loading,
} from 'domain/junkCharacter';
// import { modalModeSelector } from 'domain/ui';
import getPageTable from 'components/PageTable';
import { tableParams } from 'fields/junkCharacter';
import { JUNK_CHARACTER_LIST_TABLE } from 'domain/const';
import { getQuery } from 'domain/router';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const PageTable = getPageTable({ tableName: JUNK_CHARACTER_LIST_TABLE, ...tableParams });

class JunkCharacterListPage extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object,
  };

  allowEdit = ({ sourceType }) => sourceType !== 'CUSTOMER';

  render() {
    const { query, ...props } = this.props;
    return (
      <PageTable
        allowEdit={this.allowEdit}
        rowStatusBg={(query.wfAcceptance || []).length !== 1}
        updateAction={updateJunkCharacterInLineAction}
        {...props}
        filterTransfer={{}}
      />
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    items: junkCharacterList,
    query: getQuery,
    toggleRowCheck: checkJunkCharacterAction,
    selectedItems: selectedjunkCharacters,
    edit,
    onEdit: onEditAction,
    loading,
    // modalMode: modalModeSelector,
  }),
)(JunkCharacterListPage);
