import {
  connectAcceptance,
  commonDictionaryFields,
  defaultCarField,
} from 'fields/common';
import { defineColumns } from 'components/PageTable/defineColumns';

const config = {
  id: {
    sortable: true,
    className: 'id',
  },
  smcTag: { sortable: true, className: 'td300', inlineEdit: true, },
  fullName: { sortable: true, className: 'td300', inlineEdit: true, },
  lowCarValue: { sortable: true, className: 'td200', inlineEdit: true },
  carValue: { sortable: true, className: 'td200', inlineEdit: true },
  highCarValue: { sortable: true, className: 'td200', inlineEdit: true },
  defaultCarField,
  ...commonDictionaryFields
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns};

export default columns;
