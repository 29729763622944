import colors, { statusColors } from 'theme/Colors';
import buttons from 'components/Button/sheet';

export default {
  ListHeader: {
    margin: [0, 0, 20, 0],
    height: 52,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  nav: {
    display: 'flex',
  },
  title: {
    fontSize: 24,
    margin: [0, 40, 0, 0],
    textTransform: 'capitalize',
  },
  active: {
    backgroundColor: colors.buttonHover,
    color: colors.text + '!important',
  },
  link: {
    height: 32,
    lineHeight: '32px',
    fontSize: 16,
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: [0, 20],
    color: colors.attention,
    '&:hover': {
      extend: 'active',
    },
    '& span': {
      fontWeight: 500,
    },
  },
  userLink: {
    height: 28,
    lineHeight: '28px',
    fontSize: 14,
    textDecoration: 'none',
    textTransform: 'capitalize',
    paddingLeft: 28,
    color: colors.menu,
    display: 'inline-block',
    position: 'relative',
    fontWeight: 500,
    marginBottom: 10,
    '&:hover': {
      color: colors.attention,
    },
    '&:before': {
      content: '""',
      display: 'block',
      width: 28,
      height: 28,
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'rotate(180deg)',
      background: {
        size: '12px 12px',
        repeat: 'no-repeat',
        position: 'center center',
        image: `url(${require('./navigation.svg')})`,
      },
    },
  },
  headerBold: {
    fontWeight: 700,
  },
  buttons: {
    whiteSpace: 'nowrap',
  },
  deleted: {
    color: colors.text,
    marginRight: 10,
  },
  notDeleted: {
    ...buttons.ghost,
    ...buttons.primary,
    textDecoration: 'none',
    width: 'auto',
    padding: [0, 10],
  },
  ...statusColors,
};
