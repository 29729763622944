import { field } from 'cpcs-reconnect';

import { Artist } from 'domain/lib';
import { asyncAction } from 'lib/actions';
import { lock } from 'domain/const';

export const editableFields = [
  'fullName',
  'namePrefix',
  'firstName',
  'middleName',
  'lastName',
  'nameSuffix',
  'birthDate',
  'deathDate',
  'residences',
  'biography',
  'notes',
  (data) => {
    return ({ carTag: data?.carTag?.filter(ct=>ct !== undefined).map(ct=> ct?.id)})},
  'lowCarValue',
  'decayCoefficient',
  'carValue',
  'highCarValue',
  'defaultCarField',
  'banner970x120',
  'banner720x120',
  'banner343x120',
  'bannerDisplayDateFrom',
  'bannerDisplayDateTo',
  'bannerUrl',
];

export const acceptanceFields = [
  'fullName',
];

export const isEditable = ({ wfAcceptance, isDelete, locked, sourceType }) => {
  if (sourceType === 'CUSTOMER') return false;
  if (isDelete) return false;
  return wfAcceptance !== 'ACCEPTED' || !locked;
};
function sortSecondaryTag(inputArray) {
  const newArray = [...inputArray];
  return newArray.sort((a, b) => {
    const extractTag = (obj) => obj.smcTag.match(/(\w+)( \(([\d.]+)\))?/);
    const [, aTag, aTagValue] = extractTag(a) || [];
    const [, bTag, bTagValue] = extractTag(b) || [];
    if (aTagValue && bTagValue) {
      return parseFloat(aTagValue) - parseFloat(bTagValue) || aTag.localeCompare(bTag);
    }
    if (aTagValue) return -1;
    if (bTagValue) return 1;
    return aTag.localeCompare(bTag);
  });
}

export const fetchArtistAction = asyncAction('artist/GET_BY_ID');
export const updateArtistAction = asyncAction('artist/UPDATE');
export const mergeArtistAction = asyncAction('artist/MERGE');
export const removeArtistAction = asyncAction('artist/REMOVE');
export const getChildrenAction = asyncAction('artist/GET_CHILDREN');
export const runRtvAction = asyncAction('artist/RUN_RTV');
export const getRtvAction = asyncAction('artist/GET_RTV');
export const updateAeAbvAction = asyncAction('artist/UPDATE_AE_ABV');
export const syncWebAbvAction = asyncAction('artist/SYNC_AE_ABV');

export const artist = field('artist');

export const reducer = {
  artist(state = new Artist(), action) {
    switch (action.type) {

      case mergeArtistAction.success:
      case removeArtistAction.success:
      case updateArtistAction.success:
      case fetchArtistAction.success:
        action.payload.secondaryCarTag = sortSecondaryTag(action.payload.secondaryCarTag)
        action.payload.carTag = [action.payload.carTag , ...action.payload.secondaryCarTag]
        return Artist.parse(action.payload);

      case getChildrenAction.success:
        return state.apply(
          Artist.$children.parsed(action.payload),
        );

      case runRtvAction.type:
        return state.set('rtvCalc', 'Status: PENDING');

      case getRtvAction.success:
        return state.set('rtvCalc', `Status: ${action.payload}`);

      case lock.type:
        return action.payload.subject === 'Artist' ? state.set('locked', action.payload.value) : state;

      default:
        return state;
    }
  },
};
