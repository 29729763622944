import { config as artworkConfig, defaultColumns } from './webAppArtwork';
import { defineColumns } from '../components/PageTable/defineColumns';
import { connectAcceptance, createIdLink } from './common';

const config = {
  ...artworkConfig,
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('artwork', 'artworkId'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
