import fieldsArrSheet from 'fields/FieldsArrSheet';

export default {
  Provenances: {
    // root
    width: '100%',
  },
  diapasonFields: {
    display: 'flex',
  },
  rangeSeparator: {
    flex: '0 0 20px',
  },
  ...fieldsArrSheet,
};
