import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import {
  artworkList,
  selectedArtworks,
  checkArtworkAction,
  edit,
  onEdit,
  updateArtworkInLineAction,
  loading,
} from 'domain/artworkList';
import { modalModeSelector } from 'domain/ui';
import { dictionaryById } from 'domain/dictionary';
import getPageTable from 'components/PageTable';
import { tableParams } from 'fields/artwork';
import { ARTWORK_LIST_TABLE } from 'domain/const';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { getQuery } from 'domain/router';
import { filterTransfer } from 'filters/artwork';

const PageTable = getPageTable({ tableName: ARTWORK_LIST_TABLE, ...tableParams });

class ArtworksListTable extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object,
  };

  allowEdit = ({ sourceType }) => sourceType !== 'CUSTOMER';

  render() {
    const { query, ...props } = this.props;
    return (
      <PageTable
        allowEdit={this.allowEdit}
        rowStatusBg={(query.wfAcceptance || []).length !== 1}
        updateAction={updateArtworkInLineAction}
        {...props}
        {...{ filterTransfer }}
      />
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    items: artworkList,
    category: dictionaryById('categories'),
    subjects: dictionaryById('subjects'),
    currency: dictionaryById('currencies'),
    lastLotCurrency: dictionaryById('currencies'),
    surfaces: dictionaryById('surfaces'),
    substrates: dictionaryById('substrates'),
    surfaceValues: dictionaryById('surfaceValues'),
    substrateValues: dictionaryById('substrateValues'),
    auctionHouseLast: dictionaryById('auctions'),
    medium3d: dictionaryById('mediums'),
    auctionId: dictionaryById('auctions'),
    query: getQuery,
    toggleRowCheck: checkArtworkAction,
    selectedItems: selectedArtworks,
    edit,
    onEdit,
    loading,
    modalMode: modalModeSelector,
  }),
)(ArtworksListTable);
