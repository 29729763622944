import {
  connectAcceptance,
  createIdLink,
  commonDictionaryFields,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('runner'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  domain: { className: 'td300' },
  spiderName: { className: 'td300' },
  type: {},
  ...commonDictionaryFields,
  wfAcceptance: {},
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
