import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Dots({ classes }) {
  return (
    <span className={cx(classes.item, classes.dots)} children="…" />
  );
}

Dots.propTypes = {
  classes: PropTypes.shape({
    item: PropTypes.string,
    dots: PropTypes.string,
  }).isRequired,
};
