import {
  connectAcceptance,
  createIdLink,
} from './common';
import { numberFieldProps, dateFieldProps } from '../lib/helpers';

import { active as Active } from 'filters/coupon';
import { defineColumns } from 'components/PageTable/defineColumns';

import rules from 'components/form/validation';
import DateField from 'components/form/element/DateField';
import { dateToString } from '../lib/serialize';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('coupon'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  title: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  expireOn: {
    inlineEdit: true,
    component: DateField,
    getValue: dateToString('MM/DD/YYYY'),
    fieldProps: dateFieldProps,
  },
  maximumRedemption: {
    getValue: ({ value }) => value ? value : '~',
    className: 'redemption',
    fieldProps: { type: 'number', min: 0, ...numberFieldProps },
  },
  active: {
    sortable: true,
    title: 'state',
    getValue: ({ value }) => value ? 'Active' : 'Disabled',
    formFieldRenderer: () => Active,
  },
};

export const columns = defineColumns(
  connectAcceptance(config, ['title','expireOn']),
);

export const defaultColumns = [
  'title',
  'expireOn',
  'maximumRedemption',
  'active',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
