import { action, asyncAction } from 'lib/actions';
import { currentTime } from 'lib/helpers';

export const addBreadCrumbs = action('BC/UPDATE');
export const resetPasswordTokenChecked = action('AUTH/RESET_PASSWORD/TOKEN_CHECK');
const notifyAC = ({ timestamp = parseInt(currentTime.format('x'), 10), ...data }) => ({ payload: { ...data, timestamp } });
export const addNotification = action('NOTIFICATION/ADD', notifyAC);
export const removeNotification = action('NOTIFICATION/REMOVE');
export const clearOutdatedNotifications = action('NOTIFICATION/CLEAR');

export const checkAuth = asyncAction('AUTH/CHECK');
export const logIn = asyncAction('AUTH/LOG_IN');
export const userProfileAction = asyncAction('USER/GET_PROFILE');
export const forgotPassword = asyncAction('AUTH/FORGOT_PASSWORD');
export const resetPassword = asyncAction('AUTH/RESET_PASSWORD');
export const logOut = action('AUTH/LOG_OUT');
