import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { lot } from 'domain/lotList';
import Radio from 'components/form/fields/Radio';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { connect } from "cpcs-reconnect";
import { compose } from 'redux';
import cx from 'classnames';

let FieldComponent = ({ input: { onChange, value }, classes, lot: { guaranteeLocked } }) => {
  return <div className={cx(classes.Outliers, { [classes.disabled]: guaranteeLocked })}>
    <label className={classes.label}>
      <Radio
        value="1"
        checked={value === '1'}
        onChange={onChange}
        className={classes.radio}
        disabled={guaranteeLocked}
      />
      <span className={classes.labelText} children="Yes" />
    </label>
    <label className={classes.label}>
      <Radio
        value="0"
        checked={value === '0'}
        onChange={onChange}
        className={classes.radio}
        disabled={guaranteeLocked}
      />
      <span className={classes.labelText} children="No" />
    </label>
  </div>;
};

FieldComponent.propTypes = {
  classes: PropTypes.shape({
    Outliers: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radio: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

FieldComponent = compose(
  injectSheet(styles),
  connect({ lot })
)(FieldComponent);

export default class Guarantee extends React.PureComponent {
  render() {
    return (
      <Field name="guarantee" format={v => !!v ? '1' : '0' } normalize={v => v === '1'} component={FieldComponent} />
    );
  }
}
