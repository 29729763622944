import {
  createFilterTransfer,
  intRange,
  boolFilter,
  dateRange,
} from './common';

export const defaultList = [ 'id', 'enterpriseName' ];

export const filters = {
  id: {},
  enterpriseName: {},
  abvPurchases: { formFieldRenderer: intRange('abvPurchases') },
  active: { formFieldRenderer: boolFilter('active', 'Active', 'Disabled') },
  includedAbv: { formFieldRenderer: intRange('includedAbv') },
  limitedAbv: { formFieldRenderer: boolFilter('limitedAbv') },
  expirationDate: { formFieldRenderer: dateRange('expirationDate') },
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
