import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import I from 'immutable';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import { modalModeSelector } from 'domain/ui';
import { settings } from 'domain/settings';
import { getLocationName } from 'domain/router';
import { lnk } from 'lib/routes';

import injectSheet from 'lib/sheet';
import sheet from './styles';
import UserBar from './UserBar';

const foreignRoutes = {
  clusterList: 'artworkList',
  artworksByCluster: 'artworkList',
  artworkIntersectionsList: 'artworkList',
};

function Menu({
  classes,
  userProfile,
  modalMode,
  items,
  isSingle,
  location: { pathname },
  intl: { formatMessage },
  settings,
  currentLocation,
}) {
  const [ location, setLocation ] = React.useState('');

  React.useEffect(() => {
    setLocation(pathname);
  }, [ pathname, setLocation ]);

  const renderLink = React.useCallback((defaultClassName, activeClassName) => {
    return ({ title: id, url, linkName = '' }) => {
      if (linkName){
        const query = settings.getIn([ linkName, 'query' ], new I.Map()).toJS() || {};
        if (Object.keys(query).length) url = lnk(linkName, { query });
      }
      const classNames = [ classes[defaultClassName] || classes.menuItem ];
      if (linkName.startsWith(currentLocation) || linkName === foreignRoutes[currentLocation]) {
        classNames.push(classes[activeClassName] || classes.menuItemActive);
      }
      return <Link
        key={url}
        to={url}
        className={cx(...classNames)}
        children={formatMessage({ id })}
      />;
    }
  }, [ settings, classes, currentLocation, formatMessage ]);

  const renderSubMenuItems = React.useCallback((items = []) => {
    if (items.length === 0) return null;
    return [
      items.map(renderLink('subMenuItem', 'subMenuItemActive')),
    ];
  }, [ renderLink ]);

  const renderSubMenu = React.useCallback(({ url, children = [] }) => {
    if (location && location.includes(url)) return renderSubMenuItems(children);
  }, [ location, renderSubMenuItems ]);

  const renderMenu = React.useCallback((defaultClassName, activeClassName) => {
    return ({ title: id, url }) => {
      const btnClasses = [classes[defaultClassName] || classes.menuItem];
      if (location && location.includes(url)) btnClasses.push(classes[activeClassName] || classes.menuItemActive);
      return <button
        type="button"
        key={url}
        onClick={() => setLocation(url)}
        className={cx(...btnClasses)}
        children={formatMessage({ id })}
      />;
    }
  }, [ classes, formatMessage, location, setLocation ]);

  return (
    <div className={classes.Menu}>
      <div className={classes.logo}>
        ART<span className={classes.logoBold}>Expert</span>
      </div>
      {
        (!modalMode) ?
          <Fragment>
            <div className={classes.mainMenu}>
              <div className={classes.menu}>
                { items.map(isSingle ? renderLink('menuItemOrange', 'menuItemActiveOrange') : renderMenu())}
              </div>
              <UserBar userProfile={userProfile}/>
            </div>
            <div className={classes.subMenu}>
              { items.map(renderSubMenu) }
            </div>
          </Fragment>
          :
          <div className={classes.modalNotification}>
            <FormattedMessage id="menuModalNote.select.artwork" />
          </div>
      }
    </div>
  );
}

Menu.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  items: PropTypes.array,
  isSingle: PropTypes.bool,
  userProfile: PropTypes.instanceOf(I.Record),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  modalMode: PropTypes.string,
  settings: PropTypes.instanceOf(I.Map),
  currentLocation: PropTypes.string,
};

export default compose(
  injectSheet(sheet),
  withRouter,
  injectIntl,
  connect({
    modalMode: modalModeSelector,
    settings,
    currentLocation: getLocationName,
  }),
)(Menu);
