import colors from 'theme/Colors';
import { DEFAULT_FONTFAMILY } from 'theme/const';

export default {
  field: {
    width: '100%',
    height: 32,
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    paddingLeft: 10,
    boxSizing: 'border-box',
  },
  date: {
    width: 129,
  },
};
