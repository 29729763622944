import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/customer';

import { dictionaryById } from 'domain/dictionary';
import { columns } from 'fields/customer';
import { createSimpleForm } from 'pages/common/Form';

import { createFormConfig } from 'lib/helpers';

const { item } = selectors;

export default createItemPage({
  name: 'customer',
  connections: {
    item,
    updateItemAction: actions.updateItemAction,
    country: dictionaryById('countries'),
  },
  config: {
    basicInfo: [
      columns.select('firstName', 'lastName', 'email', 'country', 'occupations','byMobile','active'),
      columns.select('picture'),
      columns.select('id', 'createdDate', 'updatedDate', 'lastLogin', 'marketingEmailConsent'),
    ],
    statistics: [
      columns.select('artsCount', 'considerationsCount', 'alertsCount'),
      columns.select('sharedWithUser','sharedByUser','totalAbv'),
    ],
    subscription: [
      [
        ...createFormConfig(columns.select('configuration', 'enterprise')),
        ...columns.select('userStatus', 'subscription'),
        ...createFormConfig(columns.select('trialRemaining')),
      ],
      [
        ...columns.select('renewalDate','abvPurchases','stripeUserUrl'),
        ...createFormConfig(columns.select('freeRtvPurchaseCount')),
      ],
      [
        ...createFormConfig(columns.select('seatType')),
      ],
    ],
  },
  Form: createSimpleForm({
    form: 'customerForm',
    action: actions.updateItemAction,
  }),
  titleFn: item => [ item.get('lastName'), item.get('firstName') ].join(' '),
});
