import icon from './revert.svg';
import { zIndex } from 'theme';

export const notificationBase = {
  padding: [11, 20],
  lineHeight: '20px',
  color: '#fff',
  borderRadius: 2,
  fontSize: 13,
  whiteSpace: 'nowrap',
};

export default {
  '@keyframes fly': {
    from: {
      transform: 'scale(2)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
  notify: {
    bottom: 50,
    //left: 'calc(50% + 125px)',
    left: 270,
    position: 'fixed',
    //transform: 'translateX(-50%)',
    animation: 'fly 500ms linear',
    zIndex: zIndex.NOTIFICATIONS,
    display: 'inline-flex',
    ...notificationBase,
  },
  default: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  success: {
    extend: 'default',
    //backgroundColor: 'rgba(60, 180, 100, 0.9)',
  },
  error: {
    backgroundColor: 'rgba(255, 60, 60, 0.9)',
  },
  warning: {
    backgroundColor: 'rgba(180, 180, 0, 0.9)',
  },
  controls: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  control: {
    textDecoration: 'none',
    color: '#ff0097',
    fontWeight: 600,
    cursor: 'pointer',
    marginLeft: 20,
    outline: 'none',
  },
  restore: {
    paddingRight: 30,
    background: {
      image: `url(${icon})`,
      position: 'right center',
      size: '20px 16px',
      repeat: 'no-repeat',
    },
  },
};
