import React from 'react';
import PropTypes from 'prop-types';

export default function PageItem({ Component, classes, ...params }) {
  return <Component {...params} classes={classes} />;
}

PageItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  params: PropTypes.object,
  Component: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]).isRequired,
};
