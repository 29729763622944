import { fork } from 'redux-saga/effects';

import { actions } from 'domain/enterprise';
import { itemNavigator } from 'sagas/common';

export default function* navigator(args) {
  yield fork(
    itemNavigator,
    {
      modelName: 'Enterprise',
      actions,
      editableFields: [
        'enterpriseName',
        'abvPurchases',
        'active',
        'includedAbv',
        'limitedAbv',
        'expirationDate',
        'artistReportCoverPageLogo',
        'artistArtBnkCoverPageLogo',
        'artistReportSubtitle',
        'artistReportPrimaryColor1',
        'artistReportSecondaryColor1',
        'artistReportSecondaryColor2',
        'artistReportTextColor',
        'artistReportTitle',
        'portfolioClientLogoUrl',
        'portfolioArtdaiLogoColor',
        'portfolioReportSubtitle',
        'portfolioPrimaryColor',
        'portfolioSecondaryColor1',
        'portfolioSecondaryColor2',
        'portfolioCoverPageTextColor',
        'portfolioReportTitle',
        'artworkReportCoverPageLogo',
        'artworkArtBnkCoverPageLogo',
        'artworkReportPrimaryColor1',
        'artworkReportSecondaryColor1',
        'artworkReportSecondaryColor2',
        'artworkReportSubtitle',
        'artworkReportTextColor',
        'artworkReportTitle',
        'enterpriseWebappClientLogo',
        'landingPageBanner',
        'landingPageHero',
        'artistReportCoverPageLogoId',
        'portfolioClientLogoUrlId',
        'artworkReportCoverPageLogoId',
        'enterpriseWebappClientLogoId',
        'artistArtBnkLastPageLogo',
        'portfolioArtdaiLastPageLogoColor',
        'artworkArtBnkLastPageLogo',
        'artistArtBnkLogo',
        'artistReportHeaderLogo',
        'artistReportHeaderLogoId',
        'portfolioArtdaiLogo',
        'portfolioReportHeaderLogoUrl',
        'portfolioReportHeaderLogoUrlId',
        'artworkReportHeaderLogo',
        'artworkReportHeaderLogoId',
      ],
    },
    args,
  );
}
