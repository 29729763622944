import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/subject';
import { filterParams } from 'filters/subject';

import { tableParams } from 'fields/subject';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const Table = createTable({
  tableName: 'subjectList',
  tableParams,
  updateItemAction,
  allowEdit: () => false,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export default createListPage({
  name: 'Subject',
  pagination,
  filterParams,
  Table,
  softDelete: false,
});
