import colors from 'theme/Colors';

export default {
  exporter: {
    position: 'absolute',
    border: 0,
    outline: 0,
    borderRadius: 2,
    left: 150,
    top: -54,
    height: 34,
    backgroundColor: 'transparent',
    color: colors.links,
    cursor: 'pointer',
  },
};
