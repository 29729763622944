import { field, selector } from 'cpcs-reconnect';
import I from 'immutable';
import queryString from 'query-string';
import { matchRoute } from 'lib/routes';
import { logOut } from 'domain/env'

const deepCopy = o => o ? I.fromJS(o).toJS() : o;

const routing = field('router').then(r => deepCopy(r));
export const getLocation = selector(routing, r => r ? r.location : null);
const ensureLocation = location => {
  if (location) return location;
  /**
   * this error is better for debug then '.match of undefined'
  **/
  throw new Error('location undefined');
};

export const getMatch = selector(routing, r => ensureLocation(r.location).match);
export const getLocationName = selector(getMatch, r => r ? r.name : '');
export const getParams = selector(getMatch, m => m.params);

export const search = selector(getLocation, l => l.search);
export const getQuery = selector(getLocation, l => l.query);
export const currentPage = selector(getQuery, ({ page }) => parseInt(page, 10) || 1 );

export const getPrevLocation = selector(routing, r => r ? r.prevLocation : null);
export const pathnameChanged = selector(getLocation, getPrevLocation, (l, p) => !p || (l.pathname !== p.pathname) );
export const searchWithoutPage = selector(search, (s = '') => {
  const clean = s.replace(/page=\d+/, '').replace('?&', '?').replace('&&', '&');
  return clean === '?' ? '' : clean;
});

export const state = selector(getLocation, l => l.state);

const extractMaching = (locationPathname) => {
  const m = matchRoute(locationPathname);
  if (m) {
    return {
      match: {
        ...m.match,
        name: m.route.name,
      },
    };
  }
  return { match: { params: {} } };
};

export const prevLocationReducer = (state, action) => {
  if (action.type === '@@router/LOCATION_CHANGE' && !state.location.pathname.startsWith('/auth')) {
    return {
      ...state,
      prevLocation: state.location,
    };
  }
  if (action.type === logOut.type) {
    return undefined;
  }
  return state;
};

export const reducer = (state, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const location = {
      ...state.location,
      ...extractMaching(state.location.pathname),
      query: queryString.parse(state.location.search, { arrayFormat: 'bracket' }),
    };
    return { ...state, location };
  }
  return state;
};
