import React from 'react';
import { createItemPage } from 'pages/common/ItemPage';
import { createItemList } from 'pages/EnterprisePage/ItemList';
import { selectors, actions } from 'domain/enterprise';
import { columns } from 'fields/enterprise';
import { createSimpleForm } from 'pages/common/Form';
import { createFormConfig } from 'lib/helpers';
import UserList from 'pages/EnterprisePage/userList';

const { item } = selectors;

export default createItemPage({
  name: 'enterprise',
  connections: {
    item,
    updateItemAction: actions.updateItemAction,
  },
  config: {
    basicInfo: [
      createFormConfig(
        columns.select(
          'enterpriseName',
          'active',
          'limitedAbv',
          'includedAbv',
          'expirationDate',
        ),
      ),
      [],
      columns.select('id', 'abvPurchases'),
    ],
    customers: (item) => <UserList id={item.id} />,

    clientPersonalizationSpace: createItemList({
      name: 'enterprise',
      connections: {
        item,
        updateItemAction: actions.updateItemAction,
      },

      Form: createSimpleForm({
        form: 'enterpriseForm',
        action: actions.updateItemAction,
      }),
      titleFn: (item) => item.get('enterpriseName'),
    }),
  },
  Form: createSimpleForm({
    form: 'enterpriseForm',
    action: actions.updateItemAction,
  }),
  titleFn: (item) => item.get('enterpriseName'),
});
