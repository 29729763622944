import { generateItems } from 'domain/common';
import { Purchase } from 'domain/lib';
import { pushAbv } from 'domain/const';

const additionalReducer = (state, { type, payload }) => {
  switch (type) {
    case pushAbv.success:
      return state
        .update('list', list => list
          .set(list.findIndex(v => v.get('id') === payload.id), Purchase.parse(payload)));

    case pushAbv.request:
    case pushAbv.failure:
      return state
        .update('list', list => list
          .update(list.findIndex(v => v.get('id') === payload), v => v.update('abvLoading', v => !v)));

    default:
      return state;
  }
};

const { actions, selectors, reducer } = generateItems('Purchase', Purchase, additionalReducer);

export { actions, selectors, reducer };
