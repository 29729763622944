import React from 'react';
import { createFilterTransfer, dateRange } from './common';
import { numberFieldProps } from '../lib/helpers';
import { Field } from 'redux-form';
import Select from '../components/form/element/Select';
import I from 'immutable';

export const defaultList = [ 'id', 'title' ];

export const active = <Field
  name="active"
  component={ Select }
  parseValue={ v => v.get('id') }
  isClearable
  normalize={ v => v ? v === 'true' : v }
  format={ v => v ? 'true' : (v === false ? 'false' : v) }
  list={ I.fromJS([
    { id: 'true', title: 'Active' },
    { id: 'false', title: 'Disabled' },
  ]) }
/>;

export const filters = {
  id: { fieldProps: { type: 'number', min: 0, ...numberFieldProps } },
  title: {},
  expireOn: { formFieldRenderer: dateRange('expireOn', 'placeholder.date') },
  active: { formFieldRenderer: active },
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
