import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

export default function PageNumberLink({ page, active, classes, linkBuilder, format }) {
  return (
    <Link
      to={linkBuilder({ page: format(page) })}
      children={format(page)}
      className={cx(classes.item, { [classes.active]: active })}
      data-page={page}
    />
  );
}

PageNumberLink.propTypes = {
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    active: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool,
  linkBuilder: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired,
};
