import { commonDictionaryFilters, createFilterTransfer } from './common';

export const defaultList = [ 'name', 'city', 'country' ];

export const filters = {
  name: {},
  city: {},
  country: {},
  ...commonDictionaryFilters,
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
