export default {
  container: {
    width: 840,
    display: 'flex',
  },
  box: {
    width: 420,
    maxHeight: 600,
    position: 'relative',
    padding: [10,10],
    '&:nth-first-child': {
      borderLeft: '1px solid gray',
    },
  },
  content: {
    fontWeight: 400,
    lineHeight: '20px',
  },
  buttons: {
    alignSelf: 'flex-end',
  },
  preview: {
    maxWidth: '100%',
    border: '1px dashed black',
    boxSizing: 'border-box',
    maxHeight: 600,
  },
};
