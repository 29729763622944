import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import toCamel from 'to-camel-case';
import cx from 'classnames';

import Table from 'react-immutable-jss-data-table';
import { defineColumns } from 'components/PageTable/defineColumns';
import TBody from 'components/PageTable/TBody';
import RowEmpty from 'components/PageTable/RowEmpty';

import { ConnectedPopup, sheet as popupSheet } from 'components/Popup';

import { getPopupParamsByName } from 'domain/ui';
import { historyList, loading, fetchHistoryAction } from 'domain/history';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

const columns = defineColumns({
  username: {},
  dateCreated: { getValue: ({ value }) => moment(value.substr(0, 19) + 'Z').utcOffset(-5).format('MM/DD/YY, HH:mm A') },
  action: { getValue: ({ value, formatMessage }) => value
    .map(toCamel).filter(v => v !== 'acceptanceFields')
    .map(id => formatMessage({ id })).join(', ') || formatMessage({ id: 'history.noChangedFields' }) },
});

const Popup = injectSheet({ ...popupSheet, container: { ...popupSheet.container, width: 775 } })(ConnectedPopup);

class History extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    fetchHistoryAction: PropTypes.func,
    popupParams: PropTypes.shape({
      modelId: PropTypes.number,
      modelName: PropTypes.string,
    }).isRequired,
  };

  componentDidUpdate = ({ popupParams: { modelId, modelName } }) => {
    const { popupParams: { modelId: currentModelId, modelName: currentModelName } } = this.props;
    if (modelId !== currentModelId || modelName !== currentModelName) this.loadHistory();
  }

  loadHistory = () => {
    const { popupParams: { modelId, modelName }, fetchHistoryAction } = this.props;
    if (!fetchHistoryAction) return;
    fetchHistoryAction({ modelId, modelName });
  }

  render(){
    const { intl: { formatMessage }, ...props } = this.props;
    return (
      <Popup name="HISTORY">
        <Table
          {...props}
          formatMessage={formatMessage}
          columns={columns.all()}
          showColumnsTitles
          TBody={TBody}
          RowEmpty={RowEmpty}
          getCellClassNames={({ classes, columnDef: { name } }) => cx(classes.td, classes[name])}
          Th={({ title, classes }) => <div className={cx(classes.th, classes[title])}>{ formatMessage({ id: `history.${title}` }) }</div>}
        />
      </Popup>
    );
  }
}

export const StyledHistory = compose(
  injectSheet(sheet),
  injectIntl,
  connect({
    popupParams: getPopupParamsByName('HISTORY'),
  }),
)(History);

export default connect({
  items: historyList,
  loading,
  fetchHistoryAction,
})(StyledHistory);
