import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import rules from 'components/form/validation';
import Password from 'components/form/element/Password';

import { resetPassword } from 'domain/env/EnvActions';

function onSubmit(data, dispatch) {
  const submitPromise = new Promise((resolve) => {
    dispatch(resetPassword({ data, resolve }));
  });
  return submitPromise.then((err) => {
    if (err) {
      throw new SubmissionError({
        password: err,
        _error: 'Save failed!',
      });
    }
  });
}

const fields = [
  {
    component: Password,
    name: 'password',
    placeholder: 'New password',
    autoComplete: 'off',
    validate: [ rules.required, rules.passwordLength ],
  },
];

class Form extends React.PureComponent {
  render(){
    return (
      <form>
        { fields.map(props => <Field key={props.name} {...props} />) }
      </form>
    );
  }
}

export default reduxForm({ form: 'reset', onSubmit })(Form);
