import { commonDictionaryFilters, createFilterTransfer } from './common';

export const defaultList = [ 'id', 'itemUrl' ];

export const filters = {
  ...commonDictionaryFilters,
  itemUrl: {},
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
