import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-immutable-jss-data-table';
import I from 'immutable';
import cx from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import Row from 'components/Row';
import { ConnectedPopup, sheet as popupSheet } from 'components/Popup';
import Button from 'components/Button';

import { hidePopupByName, getPopupParamsByName } from 'domain/ui';
import { searchAuthorsAction, authorsList, loading } from 'domain/author';
import { mergeArtistAction } from 'domain/artist';
import { dictionaryById } from 'domain/dictionary';
import { changeStatusAction, lock, remove } from '../../domain/const';
import { getLocationName } from 'domain/router';
import sheet from './sheet';
import injectSheet from 'lib/sheet';
import columns from 'fields/authors';
import Option from 'components/Option';
import TBody from 'components/PageTable/TBody';
import RowEmpty from 'components/PageTable/RowEmpty';
import { tdMarker } from 'components/PageTable/columns';

import { getClassNamesFactory, getRowClassNames } from 'components/PageTable/helpers';

const Popup = injectSheet({
  ...popupSheet,
  container: { ...popupSheet.container, width: 775 },
  content: { ...popupSheet.content, display: 'flex', flexDirection: 'column' },
})(ConnectedPopup);

const Th = ({ title, classes }) => {
  if (title === 'tdOption') return <div className={classes.tdOption}></div>;
  return <div className={cx(classes.th, classes[title])}>{ title && <FM id={title}/> }</div>;
};

Th.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.shape({
    tdOption: PropTypes.string.isRequired,
  }).isRequired,
};

class MergeArtistPopup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    residences: PropTypes.instanceOf(I.Map).isRequired,
    authorsList: PropTypes.instanceOf(I.List).isRequired,
    searchAuthorsAction: PropTypes.func.isRequired,
    mergeArtistAction: PropTypes.func.isRequired,
    hidePopupByName: PropTypes.func.isRequired,
    popupParams: PropTypes.shape({
      term: PropTypes.string,
      children: PropTypes.any,
    }).isRequired,
  };

  state = { term: '', selectedIndex: 0 }

  change = selectedIndex => this.setState({ selectedIndex })

  componentDidUpdate = ({ popupParams: { term: prevPropsTerm } }) => {
    const { popupParams: { term } } = this.props;
    if (prevPropsTerm !== term) this.search(term);
  }

  search = (term) => {
    const { popupParams: { id } } = this.props;
    const excludeParams = id ? `AND NOT(id:${id})` : '';
    const params = { term, params: `AND NOT(merge_with:[0 TO *])${excludeParams}` };
    this.setState({ term, selectedIndex: 0 }, () => this.props.searchAuthorsAction(params));
  }

  render(){
    const { classes, hidePopupByName, authorsList, mergeArtistAction, residences, loading } = this.props;
    const { buttons, search, searchText, close } = classes;
    const { selectedIndex } = this.state;
    const selectedId = authorsList.getIn([selectedIndex, 'id']);
    const { children } = this.props.popupParams;
    const onSave = selectedId ? () => mergeArtistAction({ mergeWith: selectedId, children }) : null;

    const tdOption = {
      title: 'tdOption',
      renderTh: ({ classes }) => <div className={cx(classes.th, classes.tdOption)}/>,
      render: ({ selectedIndex, rowIndex, classes }) => <div className={cx(classes.td, classes.tdOption)}>
        <Option selected={rowIndex === selectedIndex} />
      </div>,
    };

    return (
      <Popup name="MERGE_ARTIST">
        <div style={{ position: 'relative', marginBottom: 2 }}>
          <input
            className={search}
            type="text"
            value={this.state.term}
            onChange={e => this.search(e.target.value)}
          />
          { !!this.state.term && <button type="button" className={close} onClick={() => this.search('')}/> }
          <div className={searchText}>
            <FM id="findArtist"/>
          </div>
        </div>
        <Table
          selectedIndex={selectedIndex}
          columns={[ tdMarker, tdOption, ...columns ]}
          items={authorsList}
          classes={classes}
          showColumnsTitles
          residences={residences}
          Row={Row}
          rowProps={({ rowIndex }) => ({ onClick: () => this.change(rowIndex) })}
          TBody={TBody}
          RowEmpty={RowEmpty}
          loading={loading}
          rowStatusIndicator
          getCellClassNames={getClassNamesFactory('td')}
          getThClassNames={getClassNamesFactory('th')}
          getRowClassNames={getRowClassNames}

        />
        <div className={buttons}>
          <Button
            type="button"
            buttonType="secondary"
            onClick={() => hidePopupByName('MERGE_ARTIST')}
          ><FM id="cancel" /></Button>
          <Button
            type="button"
            buttonType="primary"
            onClick={() => onSave()}
            disabled={!onSave}
          ><FM id="merge" /></Button>
        </div>
      </Popup>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    authorsList,
    changeStatusAction,
    lock,
    push,
    remove,
    location: getLocationName,
    hidePopupByName,
    popupParams: getPopupParamsByName('MERGE_ARTIST'),
    searchAuthorsAction,
    mergeArtistAction,
    residences: dictionaryById('countries'),
    loading,
  }),
)(MergeArtistPopup);
