import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import { showPopup, showTooltip, hideTooltip } from 'domain/ui';
import { changeStatusAction, lock, remove } from 'domain/const';
import { getLocationName } from 'domain/router';
import {
  isEditableSelector as artworkIsEditable,
  hasOutliersSelector,
} from 'domain/artwork';
import { runRtvAction } from 'domain/artist';
import { sheet } from './sheet';
import injectSheet from 'lib/sheet';
import actions from './actions';
import { Link } from 'react-router-dom';

class ButtonPure extends React.Component {
  static propTypes = {
    showTooltip: PropTypes.func.isRequired,
    hideTooltip: PropTypes.func.isRequired,
    title: PropTypes.string,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    translate: PropTypes.bool,
    className: PropTypes.string,
  };

  node = null;

  render() {
    const {
      title,
      hideTooltip,
      showTooltip,
      intl: { formatMessage },
      translate = true,
      className = '',
      ...props
    } = this.props;
    if (!title)
      return <button className={className} type="button" {...props} />;
    const titleProps = {
      type: 'button',
      'data-title': title
        ? (translate ? formatMessage({ id: title }) : title).replace(
            /<.+?>/g,
            '',
          )
        : undefined,
      className: title ? `${className} with-data-title` : className,
    };
    return <button {...titleProps} {...props} />;
  }
}

export const Button = connect({ showTooltip, hideTooltip })(
  injectIntl(ButtonPure),
);

class Actions extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      Actions: PropTypes.string.isRequired,
    }).isRequired,
  };

  renderAction = (
    {
      name,
      type = 'button',
      onClick,
      to,
      disabled = () => false,
      title = () => '',
      translate = true,
      loading = () => false,
    },
    key,
  ) => {
    const { classes, ...props } = this.props;
    switch (type) {
      case 'button':
        return (
          <Button
            key={key}
            className={cx(classes.button, classes[name], {
              [classes.hasLoader]: loading(props),
            })}
            onClick={onClick({ ...props, name })}
            disabled={loading(props) || disabled(props)}
            title={title(props)}
            translate={translate}
          >
            <FormattedMessage id={name} />
            {loading(props) && <span className={classes.loader} />}
          </Button>
        );
      case 'link':
        return (
          <Link
            key={key}
            className={cx(classes.button, classes[name])}
            to={to({ ...props, name })}
            target="_blank"
            title={title(props)}
          >
            <FormattedMessage id={name} />
          </Link>
        );
      default:
        return <div key={key} className={classes.divider}></div>;
    }
  };

  render() {
    const { classes, ...props } = this.props;
    const availableActions = actions
      .filter(({ display = [] }) =>
        [].concat(display).reduce((acc, fn) => acc && fn(props), true),
      )
      .filter(
        ({ isDivider }, index, arr) =>
          !isDivider || (index !== 0 && index !== arr.length),
      )
      .filter((i, index, arr) => {
        if (i.type === 'divider') {
          if (index !== 0 && index !== arr.length - 1) {
            return i;
          }
          return null;
        } else {
          return i;
        }
      });
    if (availableActions.filter((v) => !v.isDivider).length === 0) return null;
    return (
      <div className={classes.Actions}>
        {availableActions.map(this.renderAction)}
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    changeStatusAction,
    lock,
    push,
    remove,
    location: getLocationName,
    artworkIsEditable,
    runRtvAction,
    showPopup,
    hasOutliers: hasOutliersSelector,
  }),
)(Actions);
