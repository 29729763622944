import React from 'react';
import PropTypes from 'prop-types';
import CalendarSchema from 'react-calendar/dist/entry.nostyle';
import injectSheet from 'lib/sheet';
import sheet from './Calendar.jss';

function CalendarWrapper({ onChange, value, classes, ...props }) {
  return (
    <div className={classes.CalendarWrapper}>
      <CalendarSchema
        onChange={onChange}
        value={value}
        {...props}
      />
    </div>
  );
}

CalendarWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  classes: PropTypes.shape({}).isRequired,
};

export default injectSheet(sheet, {
  generateId: rule => rule.key === 'react-calendar' ? rule.key : `react-calendar__${rule.key}`,
})(CalendarWrapper);
