import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import Message from 'components/form/message';

import showPass from './showPass.svg';
import hidePass from './hidePass.svg';
import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';
import { DEFAULT_FONTFAMILY } from 'theme/const';

const sheet = {
  wrapper: {
    position: 'relative',
    marginBottom: 20,
  },
  element: {
    width: '100%',
    height: 42,
    lineHeight: '42px',
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    paddingLeft: 10,
    boxSizing: 'border-box',
  },
  error: {
    borderColor: '#d0021b!important',
  },
  showHidePass: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 23,
    height: 22,
    background: {
      image: `url("${hidePass}")`,
      position: 'center center',
      size: '22px',
      repeat: 'no-repeat',
    },
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  text: {
    backgroundImage: `url("${showPass}")`,
  },
};

class Password extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    validate: PropTypes.arrayOf(
      PropTypes.func,
    ),
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
      wrapper: PropTypes.string.isRequired,
      element: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      showHidePass: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
  }

  state = { visible: false }
  render() {
    const { classes, intl: { formatMessage }, input, meta, ...props } = this.props;
    const { visible } = this.state;
    return (
      <div className={cx(classes.wrapper)}>
        <input
          placeholder={formatMessage({ id: input.name })}
          className={cx(classes.element, { [classes.error]: meta.touched && (meta.error || meta.submitError) } )}
          type={visible ? 'text' : 'password'}
          {...props}
          {...input}/>
        <button
          type="button"
          onClick={() => this.setState({ visible: !visible })}
          className={cx(classes.showHidePass, { [classes.text]: visible })}
        />
        <Message meta={meta} name={input.name} />
      </div>
    );
  }
}

export default injectIntl(injectSheet(sheet)(Password));
