import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { compose } from 'redux';
import { passDocumentDecorator } from 'lib/envGlobals';
import { PositionWatcherWrapper } from 'components/PositionWatcher';
import Calendar from './Calendar';
import injectSheet from 'lib/sheet';
import sheet, { WIDTH, MARGIN } from './sheet';
const HIDE_TIMEOUT = 130;

function renderInput(ref, props) {
  const { defaultValue, ...otherProps } = props;
  return <input ref={ref} value={defaultValue} {...otherProps} autoComplete="off" />;
}

renderInput.propTypes = {
  defaultValue: PropTypes.string,
  ref: PropTypes.func.isRequired,
};

class DateField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
      DateField: PropTypes.string.isRequired,
      catchFocusWrapper: PropTypes.string.isRequired,
      PositionWatcher: PropTypes.string.isRequired,
    }).isRequired,
    // input START
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    // input END
    mask: PropTypes.array,
    momentMask: PropTypes.string,
    minDetail: PropTypes.string,
    maxDetail: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    document: PropTypes.object,
    momentParseMasks: PropTypes.arrayOf(PropTypes.string),
    modifier: PropTypes.string,
  };

  static defaultProps = {
    classes: { DateField: 'DateField' },
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    momentMask: 'MM/DD/YYYY',
    momentParseMasks: ['MM/DD/Y', 'MM/DD/YY', 'MM/DD/YYY', 'MM/DD/YYYY'],
    minDetail: 'century',
    maxDetail: 'month',
  };

  static getDerivedStateFromProps(props, state) {
    const { value, momentParseMasks } = props;
    const { raw, inputFocused } = state;
    let ret = {};
    if (state.prevPropsVal !== value) {
      ret = { ...ret, prevPropsVal: value };
    }
    // ret.prevInputFocused
    if (state.prevInputFocused !== state.inputFocused) {
      ret = { ...ret, prevInputFocused: state.inputFocused };
    }
    // ret.raw
    const changeRaw = !inputFocused && (
      (state.prevInputFocused && raw !== value) ||
      value !== state.prevPropsVal
    );
    if (changeRaw) {
      ret = { ...ret, raw: value || '' };
    }
    // NULL
    if (value === state.prevPropsVal) return Object.keys(ret).length ? ret : null;
    // ret.date: null
    if (!value) {
      return { ...ret, date: null };
    }
    try {
      const d = moment(value, ...momentParseMasks, true);
      if (!d || !d.isValid()) throw new Error('invalid date error');
      const date = d._d;
      ret = { ...ret, date, visible: true, prevPropsVal: value };
      if (state.dateSelectedByCalendar) {
        ret = { ...ret, visible: false };
      }
      return ret;
    } catch (err) {}
    return Object.keys(ret).length ? ret : null;
  }

  componentDidMount() {
    const { document } = this.props;
    document.addEventListener('mousedown', this.onOutsideAction);
    document.addEventListener('focusin', this.onOutsideAction);
  }

  componentWillUnmount() {
    const { document } = this.props;
    this.unmounted = true;
    document.removeEventListener('mousedown', this.onOutsideAction);
    document.removeEventListener('focusin', this.onOutsideAction);
  }

  onOutsideAction = (event) => {
    const { focused } = this.state;
    if (focused && this.rootNode && !this.rootNode.contains(event.target)) {
      this.onBlur();
    }
  }

  state = {
    date: null,
    visible: true,
    focused: false,
    inputFocused: false,
    prevPropsVal: undefined, // string
    prevInputFocused: undefined, // boolean
    raw: '',
  };

  get inputValue() {
    const { raw } = this.state;
    return raw || '';
  }

  onChangeByMaskedInput = (ev) => {
    const { momentParseMasks, onChange } = this.props;
    const raw = ev.target.value;
    this.setState({ dateSelectedByCalendar: false, raw });
    if (!raw) {
      onChange(null);
      return;
    }
    try {
      const d = moment(raw, ...momentParseMasks, true);
      if (d && d.isValid()) {
        this.props.onChange(raw || '');
      }
    } catch (e) {/* ignore any errors while parsing date */}
  }

  onChangeByCalendar = (date) => {
    const { momentMask } = this.props;
    this.setState({ date, visible: false, dateSelectedByCalendar: true });
    this.props.onChange(moment(date).format(momentMask));
  }

  get visible() {
    if (!this.state.focused) return false;
    if (!this.state.visible) return false;
    return true;
  }

  hideTimout = null;

  onFocus = ({ inputFocused }) => {
    this.props.onFocus && this.props.onFocus();
    let more = {};
    if (inputFocused) more = { inputFocused: true };
    if (!this.state.focused || !this.state.visible || !this.state.inputFocused) {
      this.setState({ focused: true, visible: true, ...more });
    }
    if (this.hideTimout) {
      clearTimeout(this.hideTimout);
      this.hideTimout = null;
    }
  }

  onInputFocus = () => {
    this.onFocus({ inputFocused: true });
  }

  onBlur = () => {
    if (this.hideTimout) {
      clearTimeout(this.hideTimout);
      this.hideTimout = null;
    }
    this.hideTimout = setTimeout(() => {
      this.hideTimout = null;
      if (this.unmounted) return;
      this.safeSetState({ focused: false, visible: false });
      this.props.onBlur && this.props.onBlur();
    }, HIDE_TIMEOUT);
  }

  onInputBlur = () => {
    this.setState({ inputFocused: false });
    this.onBlur();
  }

  onKeyUp = (ev) => {
    if (ev.key === 'Escape') {
      this.setState({ visible: !this.state.visible });
    }
  }

  onClick = () => {
    if (this.state.visible) return;
    this.setState({ visible: true });
  }

  safeSetState = (st, f) => {
    if (this.unmounted) return;
    this.setState(st, f);
  }

  render() {
    const { className, classes, placeholder, disabled, id, modifier } = this.props;
    const refRoot = node => this.rootNode = node;
    return (
      <div
        onKeyUp={this.onKeyUp}
        className={cx(classes.DateField, modifier)}
        ref={refRoot}
        onClick={this.onClick}
      >
        <MaskedInput
          className={cx(className, modifier)}
          mask={this.props.mask}
          value={this.inputValue}
          onChange={this.onChangeByMaskedInput}
          keepCharPositions
          render={renderInput}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          guide
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={this.props.name}
          autoComplete="off"
        />
        {
          this.visible &&
            <div onFocus={this.onFocus} onBlur={this.onBlur} tabIndex="-1" className={classes.catchFocusWrapper}>
              <PositionWatcherWrapper classes={classes} width={WIDTH} margin={MARGIN}>
                <Calendar
                  onChange={this.onChangeByCalendar}
                  value={this.state.date}
                  minDetail={this.props.minDetail}
                  maxDetail={this.props.maxDetail}
                  maxDate={this.props.maxDate}
                  minDate={this.props.minDate}
                />
              </PositionWatcherWrapper>
            </div>
        }
      </div>
    );
  }
}
function RemoveThisDecoratorAfterUpdatingJSS(props) {
  return <DateField {...props} />;
}

function protectClassesReplacing(WrappedComponent) {
  function Wrapper({ classes, ...props }) {
    return <WrappedComponent {...props} externalClasses={classes} />;
  }
  Wrapper.propTypes = {
    classes: PropTypes.any,
  };
  return Wrapper;
}
export default compose(
  protectClassesReplacing,
  injectSheet(sheet),
  passDocumentDecorator,
)(RemoveThisDecoratorAfterUpdatingJSS);
