import actions from 'components/Actions/actions';

const { draft, validate, ignore, delete: remove, accept, display } = actions
  .reduce((acc, props) => ({ ...acc, [props.name]: props }), {});

const reduceChecker = (data, props) => (acc, fn) => acc && fn({ data, ...props });

const unLock = data => {
  try {
    return data.set('locked', false);
  } catch (e) {
    return data;
  }
}


const checkTypeAll = v => typeof v === 'number' ? v === 0 : !v;
const checkTypeAtLeastOne = v => typeof v === 'number' ? v !== 0 : v;

const checkType = (v, { location }, { name }) => {
  if (['display', 'accept'].includes(name) && location === 'artworkList') {
    return checkTypeAtLeastOne(v);
  }
  return checkTypeAll(v);
};

const check = ({ display, disabled = () => false, ...more }) => ({ items, ...props }) =>
  checkType(
    items
      .filter(
        data => checkType(
          !!(more[`displayBulk.${props.location}`] || display).reduce(reduceChecker(unLock(data), props), true) &&
          !!(more[`displayBulk.${props.location}`] || !disabled({ data, ...props })),
          props,
          more,
        )
      ).size,
    props,
    more,
  );

export default {
  merge: {
    display: ({ location, items }) => location === 'artistList' && items
      .filter(v => v.hasChildren || v.wfAcceptance === 'ACCEPTED').size === 0,
    action: ({ showPopup, items }) => showPopup({
      name: 'MERGE_ARTIST',
      term: '',
      children: items.toJS(),
      id: `(${items.toJS().join(' OR ')})`,
    }),
  },
  update: {
    display: ({ location }) => ['artworkList', 'lotList'].includes(location),
    action: ({ showPopup, items }) => showPopup({ name: 'BULK_CHANGE_POPUP', subject: items.size, id: items.toJS() }),
  },
  draft: {
    display: check(draft),
    action: ({ showPopup, items }) => showPopup({ name: 'DRAFT', subject: items.size, id: items.toJS() }),
  },
  validate: {
    display: check(validate),
    action: ({ showPopup, items }) => showPopup({ name: 'VALIDATE', subject: items.size, id: items.toJS() }),
  },
  ignore: {
    display: check(ignore),
    action: ({ showPopup, items }) => showPopup({ name: 'IGNORE', subject: items.size, id: items.toJS() }),
  },
  restore: {
    display: ({ items }) => items.filter(({ isDelete = false }) => isDelete === false ).size === 0,
    action: ({ showPopup, items }) => showPopup({ name: 'RESTORE', subject: items.size, id: items.toJS() }),
  },
  remove: {
    display: check(remove),
    action: ({ showPopup, items }) => showPopup({ name: 'REMOVE', subject: items.size, id: items.toJS() }),
  },
  accept: {
    display: check(accept),
    action: ({ showPopup, items }) => showPopup({ name: 'ACCEPT', subject: items.size, id: items.toJS() }),
  },
  display: {
    display: check(display),
    action: ({ showPopup, items }) => showPopup({ name: 'DISPLAY', subject: items.size, id: items.toJS() }),
  }
};
