import { put, call, takeLatest, all } from 'redux-saga/effects';
import { delay } from 'sagas/common';

import Api, { callApi } from 'domain/api';
import { searchAuthorsAction, fetchAuthorsAction } from 'domain/author';

const excludedWords = ['and', 'And'];

function* searchAuthors({ payload: { term = '', params = '' } = { term: '' } }) {
  yield call(delay, 1000);
  const sortBy = params ? 'wf_acceptance' : undefined;
  const modifiedTerm = term
    .trim()
    .split(' ')
    .filter(v => !excludedWords.includes(v) && !!v)
    .join(' ')
    .replace(/[+\-&|!(){}[\]^"~*?:/ ]/g, '\\$&');
  try {
    yield put({ type: searchAuthorsAction.request });
    const { data: { list } } = yield callApi(Api.searchAuthors, { query: {
      filterBy: `(source_type:((EXPERT)OR(LOT)OR(SCRAPER)))AND(full_name:(${modifiedTerm ? '*' + modifiedTerm + '*' : '*' }))${params}`,
      pageSize: 100,
      sortBy,
    } });
    yield put({
      type: searchAuthorsAction.success,
      payload: list,
    });
  } catch (e) {

  }
}

function* loadAuthor(artistId, authors) {
  const { data } = yield callApi(Api.getAuthor, { artistId });
  authors.push(data);
}

function* loadAuthors({ payload }) {
  let authors = [];
  yield all(payload.map(id => call(loadAuthor, id, authors)));
  if (authors.length) yield put({
    type: fetchAuthorsAction.success,
    payload: authors,
  });
}

export function* watchSearch() {
  yield takeLatest(searchAuthorsAction.type, searchAuthors);
}

export function* watchLoadAuthors() {
  yield takeLatest(fetchAuthorsAction.type, loadAuthors);
}
