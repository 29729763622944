import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import logo from './logo.svg';

import LoginForm from './LoginForm';
import { links as r } from 'lib/routes';

class LoginPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      LoginPage: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.LoginPage}>
        <div className={classes.container}>
          <img className={classes.logo} src={logo} alt="logo" />
          <LoginForm classes={classes}>
            { isSubmitting => <>
              <Link to={r.forgot} className={classes.link}>
                <FormattedMessage id="forgotPassword"/>
              </Link>
              <button disabled={isSubmitting} type="submit" className={classes.button}>
                <FormattedMessage id="logIn"/>
              </button>
            </> }
          </LoginForm>
        </div>
      </div>
    );
  }
}

export default injectSheet(sheet)(LoginPage);
