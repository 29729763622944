import common from 'popups/sheet';
import tClasses from 'components/PageTable/sheet';

const { tbody, tdEmpty, loadWrapper, contentWrp, rowEmpty } = tClasses;

export default {
  ...common,
  table: {
    ...common.table,
    margin: 0,
  },
  username: {
    flex: '0 0 140px',
  },
  dateCreated: {
    flex: '0 0 160px',
  },
  action: {
    flex: '1 0 300px',
  },
  thead: {},
  tbody,
  tdEmpty,
  rowEmpty,
  loadWrapper,
  contentWrp,
};
