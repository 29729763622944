import {
  createFilterTransfer,
  dateParser,
  dateRange,
  intRange,
  purchaseStatus,
  stringFilter,
  // intFilter,
} from './common';

export const defaultList = [
  'title',
  'artist',
];

export const filters = {
  title: {},
  artist: stringFilter('Purchase'),
  rtvPrice: { formFieldRenderer: intRange('rtvPrice') },
  purchasedDate: { parser: dateParser, formFieldRenderer: dateRange('purchasedDate') },
  current: { formFieldRenderer: purchaseStatus },
  // @todo add clusterNumber filter
};


export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };

export default filters;
