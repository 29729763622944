import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { loading } from 'domain/ui';

import Loader from 'components/Loader';

const connectLoader = (Component) => {
  const Wrapper = ({ loading, ...props }) => {
    if (loading) return <Loader/>;
    return <Component {...props} />;
  };
  Wrapper.propTypes = {
    loading: PropTypes.bool.isRequired,
  };
  return connect({ loading })(Wrapper);
};

export default connectLoader;
