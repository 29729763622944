import { DEFAULT_FONTFAMILY } from 'theme/const';
import { Colors, zIndex } from 'theme';
import notification, { notificationBase } from 'components/notifications/sheet';

const singleMenu = {
  fontFamily: DEFAULT_FONTFAMILY,
  color: Colors.menu,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  height: 60,
  fontSize: '13px',
  fontWeight: '500',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  padding: [25, 25],
  outline: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: Colors.buttonHover,
  },
  transition: 'all 0.5s',
};

const baseStyle = {
  Menu: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    marginBottom: 10,
    color: Colors.menu,
    fontWeight: 500,
  },
  modalNotification: {
    ...notificationBase,
    ...notification.warning,
    height: 60,
    marginLeft: 280,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    position: 'absolute',
    left: 0,
    userSelect: 'none',
    background: {
      image: `url(${require('./logo.svg')})`,
      size: '40px 40px',
      repeat: 'no-repeat',
      position: '20px',
    },
    height: 40,
    padding: [10, 0, 10, 80],
    lineHeight: '40px',
    fontWeight: 400,
    fontSize: '24px',
  },
  mainMenu: {
    height: 60,
    paddingLeft: 280,
    display: 'flex',
    justifyContent: 'space-between',
  },
  subMenu: {
    paddingLeft: 280,
    backgroundColor: Colors.menu,
    display: 'flex',
  },
  logoBold: {
    fontWeight: 700,
  },
  menu: {
    display: 'inline-flex',
  },
  menuItem: {
    ...singleMenu,
  },
  menuItemOrange: {
    ...singleMenu,
    '&:hover': {
      backgroundColor: `${Colors.attention}`,
    },
  },
  subMenuItem: {
    ...singleMenu,
    padding: [0, 25],
    lineHeight: '40px',
    height: 40,
    color: '#ffffff',
    fontWeight: 500,
    borderBottom: '2px solid transparent',
    '&:hover': {
      borderBottom: '2px solid #ffffff',
    },
  },
  subMenuItemActive: {
    borderBottom: '2px solid #ffffff',
  },
  menuItemActive: {
    backgroundColor: `${Colors.menu}!important`,
    color: '#ffffff',
  },
  menuItemActiveOrange: {
    backgroundColor: `${Colors.menu}`,
    color: '#ffffff',
  },
};

export const userBarStyles = {
  UserBar: {
    display: 'flex',
    alignItems: 'center',
  },
  email: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.75px',
    marginRight: 28,
  },
  ava: {
    width: 32,
    height: 32,
    backgroundColor: '#9013fe',
    marginRight: 12,
  },
  arrow: {
    width: 32,
    height: 32,
    marginRight: 26,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    background: {
      image: `url(${require('./chevron.svg')})`,
      size: '12px 8px',
      repeat: 'no-repeat',
      position: 'center center',
    },
  },
  menu: {
    display: 'flex',
    transition: 'all 0.5s',
    width: 209,
    height: 128,
    right: 20,
    top: 50,
    position: 'fixed',
    zIndex: zIndex.MENU,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.bg,
  },
  hidden: {
    display: 'none',
  },
  menuItem: {
    border: 'none',
    padding: [10, 0, 10, 60],
    lineHeight: '24px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    fontFamily: DEFAULT_FONTFAMILY,
    color: Colors.menu,
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    tabIndex: -1,
    '&:hover': {
      backgroundColor: Colors.warning,
    },
    background: {
      size: '20px 20px',
      repeat: 'no-repeat',
      position: '20px',
    },
  },
  profile: {
    backgroundImage: `url(${require('./ic-account.svg')})`,
  },
  logOut: {
    backgroundImage: `url(${require('./ic-logout.svg')})`,
  },
  resetSettings: {
    backgroundImage: `url(${require('./ic-logout.svg')})`,
  },
};

export default baseStyle;
