import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/form/element/Input';
import { lnk } from 'lib/routes';
import { SEARCH_ARTWORK_WINDOW } from 'domain/const';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

let win = null;

class ArtworkIdSearchField extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    global.onArtworkSelected = this.onArtworkSelected;
  }

  componentWillUnmount() {
    global.onArtworkSelected = null;
  }

  onArtworkSelected = (payload, win) => {
    const { input: { onChange } } = this.props;
    onChange(payload);
    if (win) win.close();
  }

  openSearchWindow = () => {
    if (win && !win.closed) {
      win.focus();
      return;
    }
    const width = global.innerWidth;
    const height = global.innerHeight;
    const top = (global.innerHeight - height) / 2;
    const left = (global.innerWidth - width) / 2;
    win = global.open(lnk('artworkList'), SEARCH_ARTWORK_WINDOW, `width=${width},height=${height},top=${top},left=${left},resizable`);
  }

  render() {
    const { classes, ...props } = this.props;
    return (
      <div
        className={classes.ArtworkIdSearchField}
      >
        <Input {...props} />
        <button
          type="button"
          className={classes.searchBtn}
          onClick={this.openSearchWindow}
        />
      </div>
    );
  }
}

export default injectSheet(styles)(ArtworkIdSearchField);
