import colors from 'theme/Colors';

export default {
  ImageDrop: {
    // width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  ImageDropInline: {
    position: 'relative',
    width: 80,
    height: 80,
  },
  hasLoader: {
    border: `2px dashed ${colors.placeholder}`,
  },
  inlineImage: {
    position: 'absolute',
    display: 'flex',
    top: 7,
    width: 80,
    height: 80,
  },
  box: {
    backgroundColor: 'gray',
    width: 60,
    height: 60,
    margin: [10, 10],
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover $menuBtn': {
      display: 'block',
    },
  },
  deleteBtn: {
    marginLeft: 14,
    height: 37,
    width: 100,
    marginTop: 10,
    cursor: 'pointer',
    color: 'rgba(2, 37, 54 , 0.5)',
    font: '500 16px/37px "Facto", Arial, Helvetica, sans-serif',
    textAlign: 'center',
    background: {
      size: '24px auto',
      repeat: '0px 5px no-repeat',
      image: `url(${require('./del.svg')})`,
    },
  },
  multiBox: {
    width: 320,
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -10,
    marginTop: -10,
  },
  imageBox: {
    display: 'flex',
    position: 'absolute',
    right: 14,
    // top: 12,
    height: 72,
    width: 220,
  },
  imageBoxContainer: {
    cursor: 'pointer',
    position: 'relative',
    margin: 'auto',
    '&:hover $menuBtn': {
      display: 'block',
    },
  },
  dropBox: {
    boxSizing: 'border-box',
    height: 60,
    width: 300,
    border: `2px dashed ${colors.placeholder}`,
    color: colors.placeholder,
    paddingLeft: 70,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10,
    background: {
      size: '30px auto',
      position: '30px center',
      repeat: 'no-repeat',
      image: `url(${require('./upload.svg')})`,
    },
  },
  dropBoxSmall: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    width: 170,
    height: 37,
    margin: [10, 10],
    border: `1px solid #AA4423`,
    color: '#AA4423',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
    fontSize: 14,
    fontWeight: 500,
    background: {
      size: '18px auto',
      position: '12px',
      repeat: 'no-repeat',
      image: `url(${require('./upload.svg')})`,
    },
  },
  dropBoxInline: {
    extend: 'dropBoxSmall',
    width: 80,
    height: 80,
    margin: 0,
    paddingTop: 30,
    backgroundColor: 'white',
    backgroundPosition: 'center 20px',
  },
  cancel: {
    cursor: 'pointer',
    outline: 'none',
    width: 18,
    height: 18,
    borderRadius: 10,
    border: 0,
    background: {
      color: 'transparent',
      size: '18px 18px',
      position: 'center center',
      outline: 'none',
      repeat: 'no-repeat',
      // image: `url(${require('./ic-cancel.svg')})`,
    },
  },
  remove: {
    display: 'none',
    extend: 'cancel',
    background: {
      // image: `url(${require('./ic-remove.svg')})`,
    },
    right: '4%',
    top: '4%',
    position: 'absolute',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  active: {
    color: colors.attention,
    padding: [0, 5],
  },
  loader: {
    margin: [10, 0],
    display: 'flex',
    alignItems: 'center',
    width: 300,
    justifyContent: 'space-between',
  },
  progressBar: {
    height: 3,
    backgroundColor: colors.placeholder,
    position: 'relative',
    width: 200,
    color: colors.placeholder,
    fontSize: 12,
  },
  image: {
    width: 60,
    height: 60,
  },
  error: {
    color: colors.error,
  },
  menuBtn: {
    border: 0,
    position: 'absolute',
    display: 'none',
    background: {
      color: '#00000050',
      // image: `url(${require('./dots.svg')})`,
      repeat: 'no-repeat',
      position: 'center center',
    },
    right: 0,
    borderRadius: 30,
    width: 30,
    height: 30,
    top: 0,
    cursor: 'pointer',
    zIndex: 2,
  },
};
