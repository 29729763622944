import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import Message from 'components/form/message';

import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';
import injectSheet from 'lib/sheet';

export const sheet = {
  wrapper: {
    width: '100%',
    height: 60,
    '&.popupForm': {
      height: 86,
    },
  },
  element: {
    width: '100%',
    height: '100%',
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    paddingLeft: 10,
    boxSizing: 'border-box',
    resize: 'none',
    padding: [8, 10],
  },
  error: {
    borderColor: `${colors.error}!important`,
  },
};

class TextArea extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    type: PropTypes.string,
    Wrapper: PropTypes.string,
    validate: PropTypes.arrayOf(
      PropTypes.func,
    ),
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
    placeholder: PropTypes.string,
    messageKey: PropTypes.string,
    modifier: PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
  }

  render() {
    const { classes: { wrapper, element, error },
      intl: { formatMessage }, input, meta = {},
      placeholder, messageKey, modifier, ...props } = this.props;
    return (
      <div className={cx(wrapper, { [modifier]: modifier })}>
        <textarea
          placeholder={formatMessage({ id: placeholder || `placeholder.${input.name}` })}
          className={cx(element, { [error]: meta.touched && meta.error })}
          {...props}
          {...input}
        />
        <Message meta={meta} name={messageKey || formatMessage({ id: input.name })} />
      </div>
    );
  }
}

export const PureTextArea = injectIntl(TextArea);
export default injectSheet(sheet)(PureTextArea);
