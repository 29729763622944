import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';
import { createForm } from 'pages/common/Form';

import { selectors, actions } from 'domain/medium';
import { filterParams } from 'filters/medium';

import { tableParams, columns } from 'fields/medium';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, createItemAction, updateItemAction } = actions;

const Table = createTable({
  tableName: 'mediumList',
  tableParams,
  updateItemAction,
  allowEdit: () => true,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export const Form = createForm({
  form: 'addMedium',
  action: createItemAction,
  formFields: columns.select('value','rank','order'),
});

export default createListPage({
  name: 'Medium',
  pagination,
  filterParams,
  Table,
  Form,
});
