import colors from "theme/Colors";
import { DEFAULT_FONTFAMILY } from "theme/const";

export default {
  Outliers: {
    // root
  },
  disabled: {
    opacity: 0.5,
  },
  label: {
    color: colors.text,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: "14px",
    lineHeight: "32px",
    fontWeight: 400,
    display: "inline-block",
    verticalAlign: "baseline",
    paddingRight: 32,
  },
  labelText: {
    lineHeight: "22px",
    display: "inline-block",
    verticalAlign: "middle",
    paddingLeft: 8,
  },
  radio: {
    verticalAlign: "middle",
  },
};
