import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';

export default {
  mainContainer: {
    width: '100%',
    height: '100%',
    color: colors.labelLink,
    display: 'flex',
    margin: 0,
    padding: 0,
    fontFamily: DEFAULT_FONTFAMILY,
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: colors.popupBg,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '@global': {
    'html, body': {
      extend: 'mainContainer',
    },
    '.with-data-title': {
      position: 'relative',
      '&:hover': {
        opacity: '1!important',
        '&:after': {
          content: 'attr(data-title)',
          position: 'absolute',
          height: 'auto',
          maxWidth: 350,
          whiteSpace: 'nowrap',
          top: 30,
          left: -20,
          padding: [5, 10],
          color: '#fff',
          fontSize: 11,
          fontWeight: 400,
          background: '#2e343e',
          lineHeight: '15px',
          zIndex: 0,
          pointerEvents: 'none',
        },
      },
    },
    '.with-data-title-absolute': {
      position: 'absolute',
      '&:hover': {
        '&:before': {
          content: 'attr(data-title)',
          position: 'absolute',
          height: 'auto',
          maxWidth: 350,
          whiteSpace: 'nowrap',
          top: 30,
          left: -20,
          padding: [5, 10],
          color: '#fff',
          fontSize: 11,
          fontWeight: 400,
          background: '#2e343e',
          lineHeight: '15px',
          zIndex: 1,
          pointerEvents: 'none',
        },
      },
    },
    '#root': {
      width: '100%',
      minHeight: '100%',
      height: 'auto !important',
    },
    'ul, ol': {
      listStyle: 'none',
      padding: 0,
    },
    'a, button, input': {
      fontFamily: DEFAULT_FONTFAMILY,
      fontSize: 14,
      boxSizing: 'border-box',
      fontWeight: 'inherit',
      outline: 'none',
    },
    'input::-webkit-input-placeholder': {
      color: colors.placeholder,
    },
    'textarea::-webkit-input-placeholder': {
      color: colors.placeholder,
    },
  },
};
