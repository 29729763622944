import searchBg from './images/outline-search-24px.svg';

export default {
  ArtworkIdSearchField: {
    // root
    width: '100%',
    position: 'relative',
  },
  textField: {
  },
  searchBtn: {
    cursor: 'pointer',
    background: {
      image: `url("${searchBg}")`,
      repeat: 'no-repeat',
      position: 'center center',
      size: '20px 20px',
    },
    border: 'none',
    padding: 0,
    height: 32,
    width: 32,
    position: 'absolute',
    left: 'calc(100% + 10px)',
    top: 0,
  },
};
