import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage as FHM, FormattedMessage as FM } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import toSnake from 'to-snake-case';
import I from 'immutable';
import Select from 'components/form/element/Select';
import { Form, Field } from 'react-final-form';
import CheckBox from "components/form/fields/CheckBox/CheckBox";

import { ConnectedPopup, sheet as popupSheet } from 'components/Popup';
import { PureButton, sheet as buttonSheet } from 'components/Button';

import { getPopupParamsByName, hidePopupByName, showPopup } from 'domain/ui';

import sheet from './sheet';
import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';
import { toCamel } from "lib/helpers";
import { BulkCurrencyAutocomplete, CategoryAutoComplete } from "fields/Autocomplete";
import { getLocationName } from "domain/router";
import Input from "components/form/element/Input";

const Button = injectSheet({
  ...buttonSheet,
  ghost: {
    ...buttonSheet.ghost,
    width: undefined,

  },
})(PureButton);

export const confirmationSheet = {
  ...popupSheet,
  container: {
    ...popupSheet.container,
    position: 'fixed',
    right: '50%',
    top: '50%',
    width: 400,
    height: 'auto',
    transform: 'translateY(-50%) translateX(50%)',
    boxShadow: `30px -30px ${ colors.attention }`,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    position: 'relative',
    fontSize: 24,
    fontWeight: 700,
    boxSizing: 'border-box',
    padding: [ 30, 20, 10, 40 ],
    alignItems: 'flex-start',
  },
  content: {
    padding: [ 20, 30, 10, 40 ],
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  close: {
    ...popupSheet.close,
    right: 20,
    top: 30,
  },
};

export const Popup = injectSheet(confirmationSheet)(ConnectedPopup);

const units = I.fromJS([
  { id: 'CM', title: 'CM' },
  { id: 'MM', title: 'MM' },
  { id: 'INCH', title: 'INCH' },
])

const fields = {
  'artworkList': [
    { name: 'category', component: CategoryAutoComplete },
    { name: 'physicalSizeUnits', component: Select, list: units, parseValue: v => v?.get('id') || '' },
  ],
  'lotList': [
    { name: 'currency', component: BulkCurrencyAutocomplete },
    { name: 'saleNumber', component: Input },
    { name: 'isSoldPriceUpdated', type: 'checkbox', label: 'New Sold Price',
      component: ({ label, input: { name } }) => <label for={`bulk-send-field-${name}`}>{label}</label>,
    },
  ]
}

const initialValues = {
  isSoldPriceUpdated: false,
};

const BULK_CHANGE_POPUP = 'BULK_CHANGE_POPUP';

const BulkUpdatePopup = ({
  classes: { buttons, content, row, check, input }, showPopup,
  hidePopupByName, popupParams: { subject, id },
  getLocationName
}) => {

  const [ activeFields, setFields ] = React.useState([]);

  const touchField = useCallback(name => {
    setFields(activeFields
      .includes(name) ? activeFields.filter(title => title !== name) : activeFields.concat(name))
  }, [activeFields]);

  const formFields = useMemo(() => fields[getLocationName] || [], [getLocationName])

  const onSubmit = React.useCallback((formData) => {
    const data = activeFields
      .filter(name => formData[name] !== undefined)
      .reduce((acc, name) => ({ [name]: formData[name], ...acc }) ,{})
    if (Object.keys(data).length > 0){
      showPopup({ name: 'UPDATE', id, data, subject: id.length });
      hidePopupByName(toSnake(BULK_CHANGE_POPUP).toUpperCase());
      setFields([]);
    }
  }, [ showPopup, id, hidePopupByName, activeFields ]);

  return (
    <Popup name={toSnake(BULK_CHANGE_POPUP).toUpperCase()}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={ content }>
              <FHM id={`popupAction.${toCamel(BULK_CHANGE_POPUP)}`} values={{ subject }} />
              {
                formFields.map(props => (
                  <div key={props.name} className={row}>
                    <div className={check}>
                      <CheckBox
                        id={`bulk-send-field-${props.name}`}
                        value={activeFields.includes(props.name)}
                        onChange={() => touchField(props.name)}
                      />
                    </div>
                    <div className={input}><Field key={props.name} {...props} /></div>
                  </div>
                ))
              }
            </div>
            <div className={ buttons }>
              <Button
                type="button"
                buttonType="ghostActive"
                onClick={() => hidePopupByName(toSnake(BULK_CHANGE_POPUP).toUpperCase())}
              ><FM id="cancel"/></Button>
              <Button disabled={activeFields.length === 0 || (pristine && !activeFields.includes('isSoldPriceUpdated')) } type="submit" buttonType="ghost">{`Apply`}</Button>
            </div>
          </form>
        )}
      />
    </Popup>
  );
}

BulkUpdatePopup.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  hidePopupByName: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  popupParams: PropTypes.shape({
    modelId: PropTypes.number,
    modelName: PropTypes.string,
  }).isRequired,
};

export default compose(
  injectSheet(sheet),
  connect({
    popupParams: getPopupParamsByName(toSnake(BULK_CHANGE_POPUP).toUpperCase()),
    hidePopupByName,
    showPopup,
    getLocationName,
  }),
)(BulkUpdatePopup);
