import { action } from 'lib/actions';
import I from 'immutable';
import { field } from 'cpcs-reconnect';

import { getStorageItem, setStorageItem } from 'lib/storage';
import { defaultColumns as artistList } from 'fields/artist';
import { defaultColumns as artworkList } from 'fields/artwork';
import { defaultColumns as artList } from 'fields/webAppArtwork';
import { defaultColumns as lotList } from 'fields/lot';
import { defaultList as artistsFilters } from 'filters/artist';
import { defaultList as artworksFilters } from 'filters/artwork';
import { defaultList as lotsFilters } from 'filters/lot';
import { defaultColumns as auctionList } from 'fields/auction';
import { defaultList as auctionFilters } from 'filters/auction';
import { defaultColumns as subjectList } from 'fields/subject';
import { defaultList as subjectFilters } from 'filters/subject';
import { defaultColumns as surfaceList } from 'fields/surface';
import { defaultList as surfaceFilters } from 'filters/surface';
import { defaultColumns as substrateList } from 'fields/substrate';
import { defaultList as substrateFilters } from 'filters/substrate';
import { defaultColumns as mediumList } from 'fields/medium';
import { defaultList as mediumFilters } from 'filters/medium';
import { defaultColumns as saleList } from 'fields/sale';
import { defaultList as saleFilters } from 'filters/sale';
import { defaultColumns as customerList } from 'fields/customer';
import { defaultList as customerFilters } from 'filters/customer';
import { defaultColumns as enterpriseList } from 'fields/enterprise';
import { defaultList as enterpriseFilters } from 'filters/enterprise';
import { defaultColumns as clusterList } from 'fields/cluster';
import { defaultColumns as alertList } from 'fields/alert';
import { defaultList as alertFilters } from 'filters/alert';
import { defaultColumns as purchaseList } from 'fields/purchase';
import { defaultList as purchaseFilters } from 'filters/purchase';
import { defaultColumns as couponList } from 'fields/coupon';
import { defaultList as couponFilters } from 'filters/coupon';
import { defaultColumns as runnerList } from 'fields/runner';
import { defaultList as runnerFilters } from 'filters/runner';
import { defaultColumns as sessionList } from 'fields/session';
import { defaultList as sessionFilters } from 'filters/session';
import { defaultColumns as itemList } from 'fields/item';
import { defaultList as itemFilters } from 'filters/item';
import { defaultColumns as scrappedList } from 'fields/scrapped';
import { defaultList as scrappedFilters } from 'filters/scrapped';
import { defaultColumns as smcTagList } from 'fields/smcTag'
import { defaultList as smcTagFilters } from 'filters/smcTag';
import { defaultColumns as artworkIntersectionsList } from 'fields/cluster'

export const updateSettings = action('settings/update');
export const resetSettings = action('settings/reset');

const build = process.env.REACT_APP_VERSION;

const defaultState = I.fromJS({
  build,
  artworkIntersectionsList: {
    filters: [],
    tables: {
      artworkIntersectionsList,
    }
  },
  artworksByCluster: {
    filters: [],
    tables: {
      artworksByCluster: artworkIntersectionsList,
    }
  },
  artistList: {
    query: {},
    filters: artistsFilters,
    tables: {
      artistList,
    },
  },
  artworkList: {
    query: {},
    filters: artworksFilters,
    tables: {
      artworkList,
    },
  },
  lotList: {
    query: {},
    filters: lotsFilters,
    tables: {
      lotList,
    },
  },
  auctionList: {
    query: {},
    filters: auctionFilters,
    tables: {
      auctionList,
    },
  },
  subjectList: {
    query: {},
    filters: subjectFilters,
    tables: {
      subjectList,
    },
  },
  smcTagList: {
    query: {},
    filters: smcTagFilters,
    tables: {
      smcTagList,
    }
  },
  mediumList: {
    query: {},
    filters: mediumFilters,
    tables: {
      mediumList,
    },
  },
  substrateList: {
    query: {},
    filters: substrateFilters,
    tables: {
      substrateList,
    },
  },
  surfaceList: {
    query: {},
    filters: surfaceFilters,
    tables: {
      surfaceList,
    },
  },
  saleList: {
    query: {},
    filters: saleFilters,
    tables: {
      saleList,
    },
  },
  customerList: {
    query: {},
    filters: customerFilters,
    tables: {
      customerList,
    },
  },
  enterpriseList: {
    query: {},
    filters: enterpriseFilters,
    tables: {
      enterpriseList,
    },
  },
  considerationList: {
    query: {},
    filters: artworksFilters,
    tables: {
      considerationList: artList,
    },
  },
  clusterList: {
    query: {},
    filters: {},
    tables: {
      clusterList: clusterList,
    },
  },
  artList: {
    query: {},
    filters: artworksFilters,
    tables: {
      artList: artList,
    },
  },
  alertList: {
    query: {},
    filters: alertFilters,
    tables: {
      alertList,
    },
  },
  purchaseList: {
    query: {},
    filters: purchaseFilters,
    tables: {
      purchaseList,
    },
  },
  couponList: {
    query: {},
    filters: couponFilters,
    tables: {
      couponList,
    },
  },
  runnerList: {
    query: {},
    filters: runnerFilters,
    tables: {
      runnerList,
    },
  },
  sessionList: {
    query: {},
    filters: sessionFilters,
    tables: {
      sessionList,
    },
  },
  itemList: {
    query: {},
    filters: itemFilters,
    tables: {
      itemList,
    },
  },
  scrappedList: {
    query: {},
    filters: scrappedFilters,
    tables: {
      scrappedList,
    },
  },
  artist: {
    accordions: {
      statistics: true,
      auctions: true,
    },
  },
});

const savedState = getStorageItem('settings');
let savedStateMap = null;
try {
  savedStateMap = I.fromJS(JSON.parse(savedState));
} catch (e){}

const firstState = savedStateMap && savedStateMap.get('build') === build ? savedStateMap : defaultState;
export const settings = field('settings');

export const reducer = {
  settings(state = firstState, action) { //NOSONAR
    switch (action.type) { //NOSONAR

      case resetSettings.type:
        setStorageItem('settings', JSON.stringify(defaultState.toJSON()));
        return defaultState;

      case updateSettings.type:
        const { path, value } = action.payload;
        const newState = state.setIn(path, I.fromJS(value));
        setStorageItem('settings', JSON.stringify(newState.toJSON()));
        return newState;

      default:
        return state;
    }
  },
};
