import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Wrapper from 'components/OutsideClickDetector';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import styles from './sheet';

class Menu extends React.Component {

  static propTypes = {
    classes: PropTypes.shape({}),
    menu: PropTypes.shape({}),
    btnClass: PropTypes.string.isRequired,
  }

  state = {
    visible: false,
  }

  renderList = () => {
    const { classes: { menu, menuItem }, menu: menuObj = {} } = this.props;
    const onClick = (name) => (e) => {
      this.setState({ visible: false }, menuObj[name](e));
    };
    return (
      <div className={menu} onMouseLeave={() => this.setState({ visible: false })}>
        { Object.keys(menuObj).map(name => <FormattedMessage id={name} key={name}>
          { (text) => <button className={menuItem} onClick={onClick(name)} type="button" children={text} />}
        </FormattedMessage> ) }
      </div>
    );
  }

  render() {
    const { btnClass } = this.props;
    const display = e => {
      e.stopPropagation();
      this.setState({ visible: true });
    };
    return (
      <Wrapper
        className={cx(btnClass, { 'with-data-title-absolute': !this.state.visible })}
        onClick={display}
        onDetect={() => this.setState({ visible: false })}
        data-title="more options"
      >
        { !!this.state.visible && this.renderList() }
      </Wrapper>
    );
  }
}

export default injectSheet(styles)(Menu);
