import moment from 'moment';

const list = {
  auctionDate: v => ({ auctionDate: v ? moment(v).format('YYYY-MM-DD') : undefined }),
  estimatePriceUnit: v => ({ estimatePriceUnit: v, soldPriceUnit: v }),
};

export default (data = {}) => Object.keys(data)
  .filter(key => Object.keys(list).includes(key))
  .reduce((prev, key) => ({ ...prev, ...list[key](data[key], key) }) ,data);
