import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import injectSheet from 'lib/sheet';
import styles from './sheet';
import { isUrl, imgUrl } from 'lib/helpers';

export const fitImage = (e, fit) => {
  const sizes = {};
  if (fit) {
    const { maxWidth, maxHeight } = fit;
    const { naturalHeight, naturalWidth } = e.target;
    const wGain = naturalWidth / maxWidth;
    const hGain = naturalHeight / maxHeight;
    if (hGain >= wGain && hGain > 1) {
      sizes.height = maxHeight;
    } else if (wGain > 1) {
      sizes.width = maxWidth;
    }
  }
  return sizes;
};

const initialState = {
  imageStyles: null,
  error: false,
  sizes: {},
  lightBox: false,
};

function SmartImage({
  className,
  classes,
  src,
  children,
  container,
  originalLink,
  params,
  onClick,
  fit,
  modifier,
}) {
  const [{ imageStyles, error, sizes, lightBox }, setState] =
    React.useState(initialState);

  const mounted = useRef(false);

  const imageSrc = React.useMemo(() => {
    return originalLink ? src : imgUrl(src, params);
  }, [originalLink, src, params]);

  const onImageClick = React.useCallback(() => {
    if (!!onClick) {
      onClick();
    } else {
      setState((state) => ({ ...state, lightBox: true }));
    }
  }, [onClick]);

  React.useEffect(() => {
    mounted.current = true;
    if (!src) return;
    const img = new Image();
    img.onload = (e) => {
      mounted.current &&
        setState((state) => ({
          ...state,
          imageStyles: { backgroundImage: `url(${imageSrc})` },
          error: false,
          sizes: fitImage(e, fit),
        }));
    };
    img.onerror = () =>
      mounted.current && setState((state) => ({ ...state, error: true }));
    img.src = imageSrc;
    return () => {
      mounted.current = false;
    };
  }, [fit, imageSrc, src]);

  const closeLightBox = React.useCallback(() => {
    setState((state) => ({ ...state, lightBox: false }));
  }, []);

  if (container === 'img' && imageStyles) {
    return (
      <div className={cx(classes.imageBox, className)}>
        {src && lightBox && (
          <Lightbox mainSrc={imageSrc} onCloseRequest={closeLightBox} />
        )}
        <img
          onClick={onImageClick}
          alt="cover"
          src={imageSrc}
          className={classes.image}
          style={sizes}
        />
      </div>
    );
  } else {
    return (
      <div
        onClick={onImageClick}
        className={cx(classes.SmartImage, className, modifier)}
        style={{ ...imageStyles, ...sizes }}
      >
        {src && lightBox && (
          <Lightbox mainSrc={imageSrc} onCloseRequest={closeLightBox} />
        )}
        {children}
        {!imageStyles && isUrl(src) && !error && (
          <div className={classes.loader} />
        )}
        {(!src || error) && <div className={cx(classes.noImage, modifier)} />}
      </div>
    );
  }
}

SmartImage.propTypes = {
  classes: PropTypes.shape({
    SmartImage: PropTypes.string.isRequired,
    loader: PropTypes.string.isRequired,
    noImage: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageBox: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  src: PropTypes.string,
  params: PropTypes.object,
  container: PropTypes.string,
  originalLink: PropTypes.bool,
  fit: PropTypes.shape({
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
  }),
  onClick: PropTypes.func,
};

export default injectSheet(styles)(SmartImage);
