import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { createBrowserHistory } from 'history';
import EventDispatcher from 'lib/EventDispatcher';
import { initApiStore } from 'domain/api';

import './index.css';
import configureStore from './domain';
import App from './pages';
import rootSaga from './sagas';

const history = createBrowserHistory ();
const store = configureStore(history);
store.runSaga(rootSaga);
initApiStore(store);

const eventDispatcher = new EventDispatcher();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale="en" messages={require('./lib/trans/en').default}>
        <Provider store={store}>
          <App
            eventDispatcher={eventDispatcher}
          />
        </Provider>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
