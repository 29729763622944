import { fork } from 'redux-saga/effects';

import { actions } from 'domain/customer';
import { itemNavigator } from 'sagas/common';

export default function* navigator(args) {
  yield fork(itemNavigator, {
    modelName: 'Customer',
    actions,
    editableFields: [
      'configuration',
      'trialRemaining',
      'freeRtvPurchaseCount',
      ({ enterprise }) => ({ enterprise: enterprise?.id || null }),
      'seatType',
    ],
  }, args);
}
