import colors from 'theme/Colors';

export default {
  Option: {
    width: 20,
    height: 20,
    display: 'flex',
    border: `1px solid ${colors.buttonHover}`,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: colors.bg,
    alignSelf: 'center',
    flex: '0 0 20px',
    cursor: 'pointer',
  },
  dot: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    backgroundColor: colors.attention,
  },
};
