import colors from 'theme/Colors';

export default {
  NotFound: {
    color: colors.menu,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 700,
    height: '100%',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    fontSize: 80,
  },
  subHeader: {
    fontSize: 24,
    color: colors.text,
    padding: [20, 0],
  },
  image: {
    width: 456,
    height: 442,
    background: {
      image: `url(${require('./image.png')})`,
      repeat: 'no-repeat',
      position: 'center center',
    },
  },
};
