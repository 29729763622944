import { field/*, selector*/ } from 'cpcs-reconnect';
// import record, { integer, string, listOf, bool, enhancedType } from 'cpcs-recordjs';
// import { Artwork } from 'domain/lib';
import { asyncAction } from 'lib/actions';

export const fetchRTVAction = asyncAction('RTV/FETCH');

function ActionObj(data) {
  this.name = data.name || null;    // f.e. "rtv"
  this.title = data.title || null;  // f.e. "Rtv"
  this.desc = data.desc || null;    // f.e. "Rtv"
  return this;
}

function JsonItem(data) {
  this.target_id = data.target_id || null;  // f.e. null
  this.root_id = data.root_id || null;      // f.e. "1452"
  this.doc = data.doc || null;              // Bokeh data
  return this;
}

function PlotData(data) {
  this.action = data.action || null;
  this.action_obj = data.action_obj ? new ActionObj(data.action_obj) : null;
  this.json_item = data.json_item ? new JsonItem(data.json_item) : null;
  return this;
}

function Plot(data) {
  this.normalized = data.normalized ? new PlotData(data.normalized) : null;
  this.raw = data.raw ? new PlotData(data.raw) : null;
  return this;
}

function RTV(data = {}) {
  this.current_action = data.current_action || null;
  this.plot = data.plot ? new Plot(data.plot): null;
  return this;
}

function State(data = {}, oldState = {}) {
  Object.assign(this, oldState);
  this.loading = this.loading || {};
  if (data.loading) {
    this.loading = { ...this.loading, [data.loading]: true };
  }
  if (data.intersect_actions) {
    this.intersect_actions = data.intersect_actions;
  }
  if (data.normal_actions) {
    this.normal_actions = data.normal_actions;
  }
  if (data.current_action) {
    this[data.current_action] = new RTV(data);
    const loading = { ...this.loading };
    delete loading[data.current_action];
    this.loading = loading;
  }
  return this;
}

export const rtv = field('rtv');

export const reducer = {
  rtv(state = new State(), action) { //NOSONAR
    switch (action.type) {
      case '@@router/LOCATION_CHANGE':
        return new State();
      case fetchRTVAction.request:
        return new State({ loading: action.action }, state);
      case fetchRTVAction.success:
        return new State(action.payload, state);
      default:
        return state;
    }
  },
};
