import React from 'react';
import PropTypes from 'prop-types';
import getDocument from './getDocument';

export default function passDocumentDecorator(WrappedComponent) {
  class PassDocument extends React.Component {
    static propTypes = {
      document: PropTypes.object,
    };

    static childContextTypes = {
      document: PropTypes.object,
    };

    static contextTypes = {
      document: PropTypes.object,
    };

    getChildContext() {
      if (this.props.document) {
        return {
          document: this.props.document,
        };
      }
      if (this.context.document) {
        return {
          document: this.context.document,
        };
      }
      return {};
    };

    render() {
      const document = this.props.document || this.context.document || getDocument();
      return (
        <WrappedComponent
          {...this.props}
          document={document}
        />
      );
    }
  }

  return PassDocument;
}
