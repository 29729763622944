import colors from 'theme/Colors';
import { sheet as actions } from 'components/Actions/sheet';

export const actionsList = {
  list: {
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: 220,
    backgroundColor: colors.bg,
    padding: [10, 0],
    top: 42,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
  },
  singleList: {
    display: 'flex',
    flexDirection: 'column',
    width: 220,
    backgroundColor: colors.bg,
    padding: [10, 0],
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.16)',
  },
  action: {
    outline: 'none',
    border: 0,
    cursor: 'pointer',
    height: 32,
    lineHeight: '32px',
    width: '100%',
    display: 'flex',
    paddingLeft: 42,
    background: {
      size: '20px 20px',
      repeat: 'no-repeat',
      position: '10px center',
    },
    '&:hover': {
      backgroundColor: colors.warning,
    },
  },
  merge: actions.merge,
  ignore: actions.ignore,
  remove: actions.delete,
  validate: actions.validate,
  draft: actions.draft,
  restore: actions.restore,
  accept: actions.accept,
  display: actions.display,
  update: actions.restore,
};

export const sheet = {
  PageTableBulkActions: {
    position: 'relative',
    backgroundColor: colors.labelLink,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    color: colors.bg,
    fontSize: 13,
    fontWeight: 400,
    paddingLeft: 20,
    paddingRight: 16,
    justifyContent: 'space-between',
    flex: '0 0 48px',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 21,
      top: 48,
      zIndex: 1,
      borderTop: `10px solid ${colors.labelLink}`,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
    },
  },
  columnSelected: {},
  columnActions: {},
  input: {
    width: 220,
    height: 32,
    color: colors.text,
    padding: [8,20,8,10],
    fontSize: 14,
    border: '1px solid rgba(73, 144, 226, 0.6)',
    outline: 'none',
    background: {
      color: colors.bg,
    },
  },
  menu: {
    position: 'absolute',
    outline: 'none',
    border: 0,
    cursor: 'pointer',
    background: {
      image: `url(${require('./chevron.svg')})`,
      size: '10px 6px',
      repeat: 'no-repeat',
      position: 'center center',
      color: 'transparent',
    },
    height: 32,
    width: 20,
    right: 16,
    top: 8,
  },
  count: {
    fontWeight: 700,
    marginRight: 5,
  },
  cancel: {
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent',
    color: colors.bg,
    fontWeight: 700,
    outline: 'none',
    marginLeft: 30,
  },
  ...actionsList,
};
