import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { getFormValues, change } from "redux-form";

export const LockDetector = (Component) => {
  const LockDetectorPure = ({ getFormValues, change, display = () => true, defaultValue, reset = true, ...props }) => {
    const formName = useMemo(() => props.meta.form, [ props.meta.form ]);
    const formData = useMemo(() => getFormValues(formName) || {}, [ getFormValues, formName ]);

    const visible = useMemo(() => display(formData), [ display, formData ]);

    useEffect(() => {
      if (visible === false && reset && !!props.input.value) {
        change(formName, props.input.name, null);
      }
    }, [ visible, change, reset, props.input.name, props.input.value, formName ])

    useEffect(() => {
      if (visible === true && !props.input.value && defaultValue !== undefined){
        change(formName, props.input.name, defaultValue);
      }
    }, [ visible, change, props.input.name, props.input.value, formName, defaultValue ])

    return visible ? <Component { ...props }/> : '−';
  };

  return connect(state => ({
    getFormValues: (name) => getFormValues(name)(state),
  }), { change })(LockDetectorPure)
}

export default LockDetector;
