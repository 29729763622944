import styles from 'theme/listPageSheet';
import table from 'components/PageTable/sheet';
import colors from 'theme/Colors';

export default {
  ...styles,
  ...table,
  tr: {
    ...table.tr,
    height: 94,
  },
  td: {
    ...table.td,
    width: 110,
  },
  th: {
    ...table.th,
    width: 110,
  },
  methodInfo: {
    width: 400,
  },
  mediumsSurfaces: {
    width: 150,
  },
  mediumsSubstrates: {
    width: 150,
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: 24,
    color: colors.text,
    boxSizing: 'border-box',
    textTransform: 'capitalize',
  },
  csvHolder: {
    top: 110,
    position: 'absolute',
    left: -130,
  }
};
