import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import I from 'immutable';
import installDevTools from 'immutable-devtools';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { initialState as envState } from 'domain/env';
import { chainReducers } from 'domain/lib';

installDevTools(I);

const logMiddleware = store => {
  if (window.localStorage && window.localStorage.getItem('logActions')) {
    global.logActions = console.log;
  }
  return next => action => {
    if (global.logActions) {
      global.logActions(action);
    }
    next(action);
  }
};

function configureStore(history, ...additionalMiddleware) {
  let composeEnhancers = compose;
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 100,
      actionsBlacklist: [
        //'@@redux-form/REGISTER_FIELD',
        //'@@redux-form/UNREGISTER_FIELD',
        //'@@redux-form/FOCUS',
        //'@@redux-form/BLUR',
        //'@@redux-form/CHANGE',
        //'@@redux-form/DESTROY',
        //'ui/SHOW_TOOLTIP',
        //'ui/HIDE_TOOLTIP',
      ],
    });
  }
  const sagaMiddleware = createSagaMiddleware();
  const initialState = { env: envState() };

  const store = createStore(
    combineReducers({
      ...require('./env').reducer,
      ...require('./ui').reducer,
      ...require('./settings').reducer,
      ...require('./history').reducer,
      ...require('./dictionary').reducer,
      ...require('./author').reducer,
      ...require('./artist').reducer,
      ...require('./artwork').reducer,
      ...require('./lot').reducer,
      ...require('./artistList').reducer,
      ...require('./artworkList').reducer,
      ...require('./lotList').reducer,
      ...require('./rtv').reducer,
      ...require('./navigation').reducer,
      ...require('./auction').reducer,
      ...require('./subject').reducer,
      ...require('./substrate').reducer,
      ...require('./surface').reducer,
      ...require('./sale').reducer,
      ...require('./customer').reducer,
      ...require('./enterprise').reducer,
      ...require('./webAppArtwork').reducer,
      ...require('./consideration').reducer,
      ...require('./alert').reducer,
      ...require('./purchase').reducer,
      ...require('./cluster').reducer,
      ...require('./coupon').reducer,
      ...require('./runner').reducer,
      ...require('./portfolioList').reducer,
      ...require('./session').reducer,
      ...require('./item').reducer,
      ...require('./scrapped').reducer,
      ...require('./medium').reducer,
      ...require('./smcTag').reducer,
      ...require('./junkCharacter').reducer,
      router: chainReducers(require('./router/RouterModel').prevLocationReducer, connectRouter(history), require('./router/RouterModel').reducer),
      form: require('redux-form').reducer,
      // add other modules here
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(
        logMiddleware,
        sagaMiddleware,
        thunk,
        routerMiddleware(history),
        ...additionalMiddleware,
      ),
    ),
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}

export default configureStore;
