import React from 'react';
import { reduxForm } from 'redux-form';

import injectSheet from 'lib/sheet';
import ValuesList, { sheet } from 'components/ValuesList';
import { createLotAction } from 'domain/lotList';
import columns from 'fields/lot';
import { createFormConfig, submit } from 'lib/helpers';

const formFields = columns.select(
  'defaultArtImage',
  'artId',
  'condition',
  'auctionId',
  'auctionDate',
  'saleNumber',
  'lotNumber',
  'lotUrls',
  'auctionTitle',
  'auctionLocation',
  'auctionSaleUrl',
  'currency',
  'estimatePrice',
  'soldPrice',
  'auctionSaleDate',
  'auctionSaleTime',
);

const StyledValuesList = injectSheet({
  ...sheet,
  ValuesList: { marginRight: 150, width: 440 },
  value: { width: 300, display: 'flex' },
})(ValuesList);

class AddLotForm extends React.PureComponent {
  render(){
    return (
      <form>
        <StyledValuesList showStars config={createFormConfig(formFields)} rowData={{}} />
      </form>
    );
  }
}

export default reduxForm({
  form: 'createLotForm',
  onSubmit: submit(createLotAction),
})(AddLotForm);
