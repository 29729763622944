import colors from 'theme/Colors';


export default {
  Block: {
    position: 'relative',
    padding: [20, 30],
    display: 'block',
    borderBottom: `1px solid ${colors.labelLink}88`,
  },
};
