import React from 'react';
import { connect } from "cpcs-reconnect";
import allColumns from 'fields/lot';
import ValuesList from "components/ValuesList";
import { dictionaryById } from "domain/dictionary";

const columns = allColumns.select(
  'auctionDate',
  'auctionId',
  'auctionPriceUsd',
  'auctionInterval',
  'auctionCar',
  'highEstimateUsdPlusBp',
  'medianEstimateUsdPlusBp',
  'lowEstimateUsdPlusBp',
)

const numbers = ['st', 'nd', 'rd' ]

const LotList = ({ map, ...props }) => {
  const list = map.valueSeq().sort((a, b) => b.labelNumber - a.labelNumber);
  return (
    <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(3, 590px)', gridGap: '40px 0px' }}>
        {
          list.map((lot, key) => (
            <ValuesList
              key={lot.auctionDate}
              {...props}
              namePrefix={`${lot.labelNumber}${ numbers[lot.labelNumber -1] || 'th' }`}
              rowData={lot}
              config={columns}
            />
          ))
        }
    </div>
  )
}

export default connect({ auctionId: dictionaryById('auctions') })(LotList);
