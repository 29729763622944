import record, { integer, bool, listOf } from 'cpcs-recordjs';
import { field, selector } from 'cpcs-reconnect';

import { Lot, Pagination, Stats } from 'domain/lib';
import { action, asyncAction } from 'lib/actions';

import { lock } from 'domain/const';
import { updateSelectedList } from 'domain/common';
import { lockGuarantee } from "domain/lot";

export const fetchLotsListAction = asyncAction('lot/GET_LIST');
export const createLotAction = asyncAction('lot/CREATE');
export const updateLotInLineAction = asyncAction('lot/UPDATE_INLINE');
export const checkLotAction = action('lot/CHECK');
export const getPricesAction = asyncAction('lot/GET_PRICES');
export const updatePriceAction = asyncAction('lot/UPDATE_PRICE');

export const onEdit = action('lot/EDIT');
export const fetchAwLotsAction = asyncAction('lot/ARTWORK_LOTS');

const State = record('Lots', {
  list: listOf(Lot),
  pagination: Pagination(),
  stats: Stats(),
  selected: listOf(integer),
  onEdit: integer(),
  loading: bool(true),
});

export const lots = field('lotList');
export const lotList = lots.then(State.$list);
export const selectedLots = lots.then(State.$selected);
export const lotListPagination = lots.then(State.$pagination);
export const lotListStats = lots.then(State.$stats);
export const edit = lots.then(State.$onEdit);
export const loading = lots.then(State.$loading);
export const lot = selector(
  lotList,
  edit,
  (list, lotId) => {
    if (list.size === 0) return new Lot();
    if (lotId) return list.find(({ id }) => id === lotId) || list.first();
    return list.first() || new Lot();
  },
);
export const locked = selector(lot, v => v.get('locked'));

const lockLot = (state, value) => {
  const id = state.get('onEdit') || state.get('list').first().get('id');
  return state.update('list', list => list.map(v => id === v.get('id') ? v.set('locked', value) : v ));
};

const lockGuaranteeLot = (state) => {
  const id = state.get('onEdit') || state.get('list').first().get('id');
  return state.update('list', list => list.map(v => id === v.get('id') ? v.update('guaranteeLocked', v => !v) : v ));
};

export const reducer = {
  lotList(state = new State(), { type, payload }) { //NOSONAR
    switch (type) {

      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$list.clear(),
          State.$selected.clear(),
          State.$loading.set(true),
          State.$onEdit.set(),
        );

      case updatePriceAction.success:
        return state.apply(
          State.$list.update(list => list.map(v => v.id === payload.id ? new Lot.parse(payload) : v )),
        );

      case onEdit.type:
        return state.apply(
          State.$onEdit.set(payload),
        );

      case lockGuarantee.type:
        return lockGuaranteeLot(state);

      case lock.type:
        return payload.subject === 'Lot' ? lockLot(state, payload.value) : state;

      case fetchLotsListAction.request:
      case fetchAwLotsAction.request:
        return state.apply(
          State.$loading.set(true),
        );

      case fetchLotsListAction.success:
      case fetchAwLotsAction.success:
        return state.apply(
          State.$list.parsed(payload.list),
          State.$pagination.parsed(payload.pagination),
          State.$stats.parsed(payload.stats),
          State.$loading.set(false),
        );

      case updateLotInLineAction.success:
        return state.apply(
          State.$list.update(list => list.set(list.findIndex(v => v.get('id') === payload.id), Lot.parse(payload))),
        );

      case checkLotAction.type:
        return updateSelectedList(state, payload, State);

      default:
        return state;
    }
  },
};
