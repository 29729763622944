import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

function Input({
  classes,
  name,
  value,
  onChange,
  className,
  onFocus,
  onBlur,
  onKeyPress,
  type = 'text',
  placeholder,
  autoComplete = 'disabled',
  intl,
}) {
  const rootClassName = [classes.Input, type=='time'?classes.timeField:'' , className].filter(v => !!v).join(' ');
  return (
    <input
      type={type}
      className={rootClassName}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      autoComplete={autoComplete}
      placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
      onKeyPress={onKeyPress}
    />
  );
}

Input.propTypes = {
  classes: PropTypes.shape({
    Input: PropTypes.string.isRequired,
    timeField: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export { styles as sheet };
export const PureInput = injectIntl(Input);
export default injectSheet(styles)(PureInput);
