import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Field, getFormValues } from 'redux-form';
import Input from 'components/form/element/Input';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import rules from 'components/form/validation';

class TextArray extends React.Component {
  static propTypes = {
    fields: PropTypes.shape({
      map: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
      push: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      Exhibitions: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
      itemFirst: PropTypes.string.isRequired,
      row: PropTypes.string.isRequired,
      addFieldWrapper: PropTypes.string.isRequired,
      addFieldBtn: PropTypes.string.isRequired,
      removeFieldBtn: PropTypes.string.isRequired,
    }).isRequired,
    fieldProps: PropTypes.shape({
      popupForm: PropTypes.bool,
      rowData: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    }).isRequired,
    getFormValues: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      form: PropTypes.string,
    }),
  };

  componentDidMount = () => {
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  }

  render() {
    const { fields, classes, getFormValues, meta: { form }, fieldProps: { inputName = 'value', name: rootName, rowData: { defaultValueId } } } = this.props;
    const values = getFormValues(form);
    return (
      <div className={classes.TextArray}>
        {
          fields.map((name, key) => (
            <div key={key} className={cx(classes.item, { [classes.itemFirst]: key === 0 })} data-key={key} data-value={name}>
              <div className={cx(classes.row, { lastItem: fields.length === (key + 1) })}>
                <div className={classes.addFieldWrapper}>
                  {
                    (fields.length === (key + 1)) &&
                      <button
                        type="button"
                        disabled={!(values[rootName][key] && values[rootName][key] && values[rootName][key][inputName])}
                        onClick={() => fields.push({ [inputName]: '' })}
                        className={classes.addFieldBtn}
                      />
                  }
                  {
                    (fields.length > 1) &&
                      <button
                        type="button"
                        onClick={() => fields.remove(key)}
                        className={classes.removeFieldBtn}
                        disabled={defaultValueId === values[rootName][key].id}
                      />
                  }
                </div>
                <Field
                  name={`${name}.${inputName}`}
                  type="text"
                  component={Input}
                  placeholder={`placeholder.${rootName}.${inputName}`}
                  messageKey={`${rootName}.${inputName}`}
                  validate={[ rules.required ]}
                  disabled={defaultValueId === values[rootName][key].id}
                />
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect(state => ({
    getFormValues: (form) => getFormValues(form)(state),
  })),
)(TextArray);
