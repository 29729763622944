import { createSimpleForm } from 'pages/common/Form';
import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/medium';
import { createFormConfig } from 'lib/helpers';

import { columns } from 'fields/medium';

const { item } = selectors;
const { updateItemAction } = actions;

export const editableFields = ['value', 'order', 'rank'];

export default createItemPage({
  name: 'medium',
  connections: {
    item,
  },
  titleFn: data => data.get('value'),
  config: {
    basicInfo: [
      createFormConfig(columns.select(...editableFields)),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
  },
  Form: createSimpleForm({
    form: 'mediumForm',
    action: updateItemAction,
  }),
});
