import colors from 'theme/Colors';

export const WIDTH = 492;
export const MARGIN = 20;

export default {
  Filter: {
    position: 'relative',
  },
  visible: {
    marginBottom: 20,
  },
  switcher: {
    cursor: 'pointer',
    outline: 'none',
    width: 110,
    textAlign: 'left',
    position: 'absolute',
    left: 20,
    top: -54,
    color: colors.menu,
    fontSize: 14,
    fontWeight: 500,
    border: 0,
    lineHeight: '32px',
    paddingLeft: 25,
    background: {
      size: '18px 18px',
      repeat: 'no-repeat',
      position: 'left center',
      image: `url(${require('./ic_filter.svg')})`,
    },
    '&:hover': {
      backgroundImage: `url(${require('./ic_filter_active.svg')})`,
      color: colors.attention,
    },
    '&:disabled': {
      opacity: 0.5,
    },
  },
  active: {
    color: colors.attention,
    backgroundImage: `url(${require('./ic_filter_active.svg')})`,
  },
  more: {
    cursor: 'pointer',
    lineHeight: '32px',
    fontSize: 14,
    fontWeight: 500,
    textDecoration: 'underline',
    padding: 0,
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
  },
  buttonPopupWrapper: {
    // wrapper for button with popup
  },
  PositionWatcher: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    borderRadius: 6,
    backgroundColor: colors.attention,
    color: colors.bg,
    lineHeight: '10px',
    fontSize: 8,
    display: 'flex',
    justifyContent: 'center',
    width: 10,
    height: 10,
    left: 12,
    top: 3,
    fontWeight: 400,
    border: `1px solid ${colors.bg}`,
  },
  container: {
    display: 'flex',
    boxShadow: 'inset 0px 1px 3px rgba(0, 0, 0, 0.5)',
    backgroundColor: colors.buttonHover,
    padding: [10,10],
    flexWrap: 'wrap',
  },
  hidden: {
    display: 'none',
  },
  element: {
    flex: '0 0 290px',
    height: 32,
    margin: [10,10],
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    width: 290,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    overflow: 'hidden',
    marginRight: 5,
  },
  field: {
    width: 220,
    height: 32,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
};
