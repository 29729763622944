import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import I from 'immutable';

import { getRowClassNames } from 'components/PageTable/helpers';

class Row extends React.PureComponent {
  static propTypes = {
    getRowClassNames: PropTypes.func,
    columns: PropTypes.array.isRequired,
    rowIndex: PropTypes.number.isRequired,
    rowData: PropTypes.oneOfType([
      PropTypes.instanceOf(I.Record),
      PropTypes.instanceOf(I.List),
      PropTypes.instanceOf(I.Map),
      PropTypes.instanceOf(I.Collection),
    ]).isRequired,
    classes: PropTypes.object.isRequired,
    items: PropTypes.instanceOf(I.Collection).isRequired,
    Cell: PropTypes.any,
    render: PropTypes.any,
    getValue: PropTypes.any,
    defaultValue: PropTypes.any,
    rowProps: PropTypes.func,
  };

  static defaultProps = {
    getRowClassNames,
  };

  render() {
    const { columns, Cell, getRowClassNames, rowData, rowProps = () => {} } = this.props;
    const classNames = getRowClassNames(this.props);
    return (
      <div className={this.props.classes.rowWrapper}>
        <div
          className={cx(classNames)}
          role="row"
          {...rowProps(this.props)}
        >
          {
            columns.map((columnDef, key) => (
              <Cell
                key={`${key}-${columnDef.name}`}
                {...{ columnDef, ...this.props }}
                render={columnDef.render || this.props.render}
                getValue={columnDef.getValue || this.props.getValue}
                defaultValue={columnDef.defaultValue || this.props.defaultValue}
                fieldPath={columnDef.fieldPath}
                value={columnDef.fieldPath ? rowData.getIn(columnDef.fieldPath) : void 0}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

export default Row;
