import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import Form from './Form';


import Popup from 'components/NewItemPopup';

import { lotListPagination, lotListStats } from 'domain/lotList';
import { showPopup } from 'domain/ui';
import { getQuery } from 'domain/router';
import LotsListTable from './LotsListTable';
import ListHeader from 'components/ListHeader';
import Pagination from 'components/pagination';

import injectSheet from 'lib/sheet';
import styles from 'theme/listPageSheet';

import Filter from 'components/Filter';
import { filterParams } from 'filters/lot';
import confirmPopups from 'components/ConfirmPopups';
import NewPrices from 'popups/NewPrices';
import Exporter from 'components/Exporter';
import BulkUpdate from 'popups/BulkUpdate';

const Header = connect({
  pagination: lotListPagination,
  stats: lotListStats,
  query: getQuery,
  action: () => showPopup({ name: 'ADD_LOT' }),
})(ListHeader);

class LotsListPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.instanceOf(I.Record),
  };

  render() {
    const { classes, pagination } = this.props;
    return (
      <div className={classes.ListPage}>
        { confirmPopups }
        <NewPrices />
        <BulkUpdate />
        <Popup name="ADD_LOT" formName="createLotForm">
          <Form />
        </Popup>
        <div className={classes.header}>
          <Header
            title="lots"
            linkName="lotList"
            actionTextKey="addLot"
            showInvalid="lot"
          />
          <div className={classes.subHeader}>
            <Pagination pagination={pagination} />
          </div>
        </div>
        <Filter context="lot" {...filterParams} >
          <Exporter filters={filterParams.filters} tableName="lotList" apiKey="getLotsListCSV" />
        </Filter>
        <div className={classes.tableContainer}>
          <LotsListTable pagination={pagination} />
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    pagination: lotListPagination,
  }),
)(LotsListPage);
