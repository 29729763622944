import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { Field } from 'redux-form';
import { hideTooltip, showTooltip } from 'domain/ui';
import RenderOrderedColumnTh from 'components/PageTable/RenderOrderedColumnTh';
import Input from 'components/form/element/Input';

export const getColumnDef = (columns, col) => {
  const columnDef = columns[col];
  if (columnDef) {
    return { ...columnDef, name: columnDef.name || col };
  } else {
    console.error(`There is no column definition «${ col }» in columns map`);
    return null;
  }
};

export const columnsSelector = (columns) => (...keys) =>
  keys.map(col => getColumnDef(columns, col)).filter(v => !!v);


const checkValue = (value, defaultValue = '−') => {
  // if (typeof value === 'object') return (value.props.value === null || value.props.value === undefined ) ? defaultValue : value;
  return (value === null || value === undefined || value === '') ? defaultValue : value;
};

class InlineField extends React.Component {

  static propTypes = {
    isEdit: PropTypes.bool,
    columnDef: PropTypes.object.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    showTooltip: PropTypes.func.isRequired,
    hideTooltip: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      contentWrp: PropTypes.string,
    }),
    defaultValue: PropTypes.any,
  };

  node = null;

  onOver = (e) => {
    let { scrollWidth, offsetWidth } = e.target;
    const { showTooltip } = this.props;
    if (scrollWidth > offsetWidth) {
      showTooltip(e.target, e.target.innerText);
    }
  }

  render() {
    const { isEdit, columnDef, value, className, classes, defaultValue } = this.props;
    const { component = Input, inLineComponent, inLineFieldRenderer, fieldProps = {} } = columnDef;
    if (isEdit && columnDef.inlineEdit) {
      return <div className={ className }>
        { inLineFieldRenderer ? inLineFieldRenderer(this.props) :
          <Field name={ columnDef.name } component={ inLineComponent || component } { ...fieldProps } />
        }
      </div>;
    }
    return (
      <div
        className={className}
        onMouseOver={this.onOver}
        onMouseOut={() => this.props.hideTooltip()}
        data-column-name={columnDef.name}
      >
        <div ref={ (node) => this.node = node } className={ classes.contentWrp }>{ checkValue(value,
          defaultValue) }</div>
      </div>
    );
  }
}

InlineField = connect({ showTooltip, hideTooltip })(InlineField);


/**
 * returns object<{
 *  // cell content
 *  // try { (parser || getValue)({ ...props }) } catch { Value = parser || getValue, <Value /> }
 *  parser: Function | React.Component | React.PureComponent
 *  getValue: Function | React.Component | React.PureComponent
 *  sortable: boolean,
 *  className: string,
 *  sortByName: string,
 *  hiddenForTable: boolean,
 *  // custom path for Immutable getIn(path)
 *  fieldPath: string[],
 *  // component: see { createFormConfig } from 'lib/helpers'; used for forms
 *  component: React.Component | React.PureComponent | Function,
 *  // by default it set to { Field } from 'redux-form'; can be set to custom component
 *  formFieldRenderer: React.Component | React.PureComponent | Function,
 *  fieldProps: Object,
 *  visibleInValueList(): ({ formData = {}, isEdit, rowData = {} }: { formData: {}, isEdit: boolean, rowData: {} }): AnyBooleanLikeValue,
 *  inlineEdit: boolean,
 *  inLineComponent: React.Component | React.PureComponent | Function,
 *  required: boolean,
 *  title: string,
 * }>
**/
const generateColumnDefinition = (name, col) => ({
  component: Input,
  ...col,
  title: col.title || name,
  name,
  fieldPath: col.fieldPath || [ name ],
  sortable: !!col.sortable || false,
  renderTh: RenderOrderedColumnTh,
  render: InlineField,
});

/*
id: {
  title: 'ID', // skip
  fieldPath: ['id'], // skip
  renderTh: RenderOrderedColumnTh, // bool
},
*/
const fromString = (col) => generateColumnDefinition(col, {});

const fromObj = (cols) => Object.keys(cols)
  .reduce(
    (prev, key) => ({
      ...prev,
      [key]: generateColumnDefinition(key, cols[key]),
    }),
    {},
  );

const buildDefinition = cols => cols.reduce((prev, col) => {
  if (typeof col === 'string') {
    return {
      ...prev,
      [col]: fromString(col),
    };
  }
  return {
    ...prev,
    ...fromObj(col),
  };
}, {});

function Columns(columns, context = 'table') {
  const COLUMN_DEFINITIONS = buildDefinition(columns);
  let keys = Object.keys(COLUMN_DEFINITIONS);
  return {
    COLUMN_DEFINITIONS,
    getHashTable() { return this.COLUMN_DEFINITIONS },
    keys() { return keys },
    unusedFields: [...keys],
    select(...keys) {
      //if(context === 'Artwork') console.log(this.unusedFields.length, this.unusedFields);
      this.unusedFields = this.unusedFields.filter(v => !keys.includes(v));
      return keys.map(col => getColumnDef(this.COLUMN_DEFINITIONS, col)).filter(v => !!v);
    },
    setDefaultOrder(...orderedKeys) { this.keys = orderedKeys },
    all() {
      return keys
        .map(col => getColumnDef(this.COLUMN_DEFINITIONS, col))
        .filter(v => !!v)
        .filter(({ hiddenForTable }) => !hiddenForTable);
    }
  }
}

export const defineColumns = (...cols) => {
  return new Columns(cols);
};
