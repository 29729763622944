import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import { compose } from 'redux';

import injectSheet from 'lib/sheet';
import { draggable, droppable, COLUMN, columnSource, columnTarget } from 'components/dnd';
import CheckBox from 'components/form/fields/CheckBox/CheckBox';

class PureColumn extends React.PureComponent{
  static propTypes = {
    name: PropTypes.string.isRequired,
    classes: PropTypes.shape({
      column: PropTypes.string.isRequired,
      checkBox: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    columns: PropTypes.any,
  }
  // TODO: all translations with context;
  render() {
    const { name, classes, onClick, checked = false, columns, context } = this.props;
    let columnsOpts;
    if (columns) {
      columnsOpts = columns.select(name);
      if (!columnsOpts.length) {
        columnsOpts = [{ title: name, modifier: 'badColumn', hint: 'this column is obsolete' }];
      }
    }
    const [{ title, modifier, hint }] = columnsOpts || [{ title: name }];
    return (
      <div
        className={cx(classes.column, modifier)}
        title={hint}
        data-column-name={name}
      >
        <CheckBox
          value={!!checked}
          onChange={() => onClick(name)}
          className={classes.checkBox}
        />
        <FM id={`${context === 'enterprise' ? context + '.' : ''}${title || name}`} data-column-name={name} />
      </div>
    );
  }
}

const StyledColumn = injectSheet({
  column: {
    height: 32,
    width: 160,
    display: 'flex',
    alignItems: 'center',
    cursor: 'move',
    '&.badColumn': {
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
  },
  checkBox: {
    marginLeft: '14px!important',
    marginRight: '10px!important',
  },
})(PureColumn);

const Column = compose(
  droppable(COLUMN, columnTarget),
  draggable(COLUMN, columnSource),
)(StyledColumn);

class Settings extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      Settings: PropTypes.string.isRequired,
    }).isRequired,
    // TODO one type
    list: PropTypes.any.isRequired,

  }

  render(){
    const { classes, list, ...props } = this.props;
    return (
      <div className={classes.Settings}>
        { list.map((name, index) => <Column {...props} index={index} key={name} name={name}  />)}
      </div>
    );
  }
}

export default injectSheet({
  Settings: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 492,
    paddingTop: 10,
  },
})(Settings);
