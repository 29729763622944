import { COLUMN } from 'components/dnd/types';

export const columnTarget = {
  canDrop: ({ move }, monitor) => monitor.getItemType() === COLUMN && !!move,

  drop({ move, index }, monitor) {
    const { name } = monitor.getItem();
    move(name, index);
  },
};
