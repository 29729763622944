
export default {
  buttons: {
    alignSelf: 'flex-end',
  },
  bold: {
    fontWeight: 500,
  },
  content: {
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: 20,
  },
};
