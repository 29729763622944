import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import PaginationSchema from './Pagination';

import linkBuilderDecorator from './linkBuilderDecorator';
import paginationDefaultProps from './paginationDefaultProps';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import * as items from './items';
import HelpersFactory, { parsePage, formatPage } from './helpers';
import Calculator from './calculator';

function getPagination({
  PageItem = items.PageItem,
  PrevPageLink = items.PrevPageLink,
  NextPageLink = items.NextPageLink,
  PageNumberLink = items.PageNumberLink,
  Dots = items.Dots,
  ItemsPerPage = items.ItemsPerPage,
  JumpToPage = items.JumpToPage,
  Schema = PaginationSchema,
} = {}) {

  const helpers = HelpersFactory({ PageNumberLink, Dots });

  /*
   * page: 0    // currentPageNumber
   * pages: 2   // totalPagesAmount
   * size: 10   // itemsPerPage
   * total: 11  // totalItems
  **/
  const calculate = Calculator(helpers);

  class Pagination extends React.PureComponent {
    static propTypes = {
      classes: PropTypes.shape({
        Pagination: PropTypes.string.isRequired,
        item: PropTypes.string.isRequired,
        active: PropTypes.string.isRequired,
        dots: PropTypes.string.isRequired,
        nextPage: PropTypes.string.isRequired,
        prevPage: PropTypes.string.isRequired,
        disabled: PropTypes.string.isRequired,
        ItemsPerPage: PropTypes.string.isRequired,
        ItemsPerPageLabel: PropTypes.string.isRequired,
        ItemsPerPageSelect: PropTypes.string.isRequired,
        JumpToPage: PropTypes.string.isRequired,
        JumpToPageLabel: PropTypes.string.isRequired,
        JumpToPageInput: PropTypes.string.isRequired,
      }).isRequired,
      page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      pages: PropTypes.number,
      onSizeChange: PropTypes.func.isRequired,
      onPageChange: PropTypes.func.isRequired,
      pageSize: PropTypes.number,
      parse: PropTypes.func,
      format: PropTypes.func,
      className: PropTypes.string,
      linkBuilder: PropTypes.func.isRequired,
    };

    static defaultProps = {
      parse: parsePage,
      format: formatPage,
    };

    render() {
      const { classes, page, pages, linkBuilder, pageSize, onSizeChange, onPageChange, parse, format, className } = this.props;
      return <Schema
        {...this.props}
        PageItem={PageItem}
        PrevPageLink={PrevPageLink}
        NextPageLink={NextPageLink}
        PageNumberLink={PageNumberLink}
        Dots={Dots}
        ItemsPerPage={ItemsPerPage}
        JumpToPage={JumpToPage}
        calculate={calculate}
        classes={classes}
        page={page}
        pages={pages}
        linkBuilder={linkBuilder}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        parse={parse}
        format={format}
        className={className}
      />;
    }
  }
  return Pagination;
}

export {
  getPagination,
};

export default compose(
  injectSheet(styles),
  linkBuilderDecorator,
  paginationDefaultProps,
)(getPagination());
