import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/scrapped';
import { filterParams } from 'filters/scrapped';

import { tableParams } from 'fields/scrapped';
import { dictionaryById } from 'domain/dictionary';

const { list: items, onInlineEdit: edit, loading, pagination } = selectors;
const { inlineEditAction: onEdit, updateItemAction } = actions;

const Table = createTable({
  tableName: 'scrappedList',
  tableParams,
  updateItemAction,
  allowEdit: () => false,
  connections: {
    items,
    edit,
    onEdit,
    loading,
    pagination,
    auction: dictionaryById('auctions'),
  },
});

export default createListPage({
  name: 'Scrapped',
  pagination,
  filterParams,
  Table,
  softDelete: false,
});
