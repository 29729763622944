import colors from 'theme/Colors';
import btn from 'theme/Buttons';

export const root = {
  width: '100%',
  height: '100%',
  display: 'flex',
  backgroundColor: colors.popupBg,
  justifyContent: 'center',
  alignItems: 'center',
};

export default {
  LoginPage: root,
  requiredSubmitButton: {
    display: 'none',
  },
  container: {
    height: '100%',
    paddingTop: 300,
    width: 340,
    boxSizing: 'border-box',
  },
  logo: {
    userSelect: 'none',
    width: '100%',
    marginBottom: 60,
  },
  button: {
    ...btn.add,
    width: '100%',
    fontSize: 16,
    height: 50,
  },
  link: {
    color: colors.attention,
    float: 'right',
    marginBottom: 30,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  field: {
    width: '100%',
    lineHeight: '46px',
    height: 46,
    color: 'black',
    fontWeight: 400,
    border: '1px solid #e6e6e6',
    backgroundColor: '#ffffff',
    paddingLeft: 15,
    paddingRight: 50,
  },
  fieldWrapper: {
    marginBottom: 20,
  },
  '@keyframes fly': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
};
