import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "cpcs-reconnect";

import { userProfile } from 'domain/env';
import { matchRoute } from 'lib/routes';
import { Route } from "react-router";
import NotFoundPage from 'pages/NotFoundPage';

const isGranted = (pathName, { isSuperuser, acl }) => isSuperuser || acl.includes(pathName);

function AclRoute({ userProfile, ...props }) {
  try {
    const { route: { name } } = matchRoute(props.path);
    if (!isGranted(name, userProfile)) return <NotFoundPage/>;
  } catch (e) {}
  return <Route {...props} />
}

AclRoute.propTypes = {
  userProfile: PropTypes.object,
  path: PropTypes.string,
}

export default connect({ userProfile })(AclRoute);
