import colors from 'theme/Colors';
export default {
  dragRoot: {
    position: 'relative',
    border: '1px dashed transparent',
    '&:hover': {
      backgroundColor: colors.warning,
    },
  },
  dropRoot: {
    position: 'relative',
    border: '1px dashed transparent',
  },
  drag: {
    backgroundColor: 'transparent!important',
    border: '1px dashed #d1d3d5',
    opacity: 0.7,
  },
  dragOver: {
    backgroundColor: colors.buttonHover,
    opacity: 0.6,
    //boxShadow: '0 2px 8px 5px rgba(203, 105, 84, 0.9)',
  },
  canDrop: {
    //boxShadow: '0 2px 8px 2px rgba(203, 105, 84, 0.5);',
  },
  raised: {
    width: '100%',
  },
};
