import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import cx from 'classnames';
import I from 'immutable';
import { dateToString } from 'lib/serialize';

import Table from 'react-immutable-jss-data-table';
import { defineColumns } from 'components/PageTable/defineColumns';
import TBody from 'components/PageTable/TBody';
import RowEmpty from 'components/PageTable/RowEmpty';
import Link from 'components/Link';

import { ConnectedPopup, sheet as popupSheet } from 'components/Popup';

import { getPopupParamsByName } from 'domain/ui';
import { updatePriceAction } from 'domain/lotList';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

const Apply = ({ rowData, updatePrice, classes }) => {
  const { soldPrice, currency, currencyId, soldPriceRaw } = rowData.toJS();
  if (soldPrice >= 0 && currency && currencyId ) {
    return <button
      type="button"
      className={classes.apply}
      onClick={() => updatePrice({ soldPrice, currency: currencyId, soldPriceRaw })}
    >
      APPLY
    </button>;
  } else return null;
};

const itemUrl = ({ value, classes }) => <div className={classes.url}><Link target="_blank" url={value} force /></div>;

itemUrl.propTypes = {
  value: PropTypes.string,
  classes: PropTypes.shape({}),
};

const columns = defineColumns({
  createdDate: { getValue: dateToString('MM/DD/YYYY','−') },
  soldPriceRaw: {},
  itemUrl: { getValue: itemUrl },
  actions: { getValue: Apply },
});

const Popup = injectSheet({ ...popupSheet, container: { ...popupSheet.container, width: 620 } })(ConnectedPopup);

class NewPrices extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    updatePriceAction: PropTypes.func.isRequired,
    popupParams: PropTypes.shape({
      modelId: PropTypes.number,
      modelName: PropTypes.string,
    }).isRequired,
  };

  render(){
    const { intl: { formatMessage }, updatePriceAction, popupParams: { title, lotId, prices = [] }, ...props } = this.props;

    const renderTh = ({ title, classes }) => <div
      className={cx(classes.th, classes[title])}
    >{ title === 'actions' ? '' : formatMessage({ id: `lotPrices.${title}` }) }</div>;

    return (
      <Popup name="NEW_PRICES" getTitle={() => title}>
        <Table
          items={I.fromJS(prices)}
          {...props}
          updatePrice={data => updatePriceAction({ lotId, ...data })}
          loading={false}
          formatMessage={formatMessage}
          columns={columns.all()}
          showColumnsTitles
          TBody={TBody}
          RowEmpty={RowEmpty}
          getCellClassNames={({ classes, columnDef: { name } }) => cx(classes.td, classes[name])}
          Th={renderTh}
        />
      </Popup>
    );
  }
}

export default compose(
  injectSheet(sheet),
  injectIntl,
  connect({
    popupParams: getPopupParamsByName('NEW_PRICES'),
    updatePriceAction,
  }),
)(NewPrices);
