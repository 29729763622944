import { createSimpleForm } from 'pages/common/Form';
import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/auction';
import { createFormConfig } from 'lib/helpers';

import { dictionaryById } from 'domain/dictionary';
import { columns } from 'fields/auction';

const { item } = selectors;
const { updateItemAction } = actions;

export const editableFields = ['name', 'country', 'city'];

export default createItemPage({
  name: 'auction',
  connections: {
    item,
    country: dictionaryById('countries'),
  },
  config: {
    basicInfo: [
      createFormConfig(columns.select(...editableFields)),
      [],
      columns.select('id','createdDate','updatedDate','lotsCount'),
    ],
  },
  Form: createSimpleForm({
    form: 'auctionForm',
    action: updateItemAction,
  }),
});
