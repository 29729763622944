import { createFilterTransfer, noCasingIntFilter, noCasingStringFilter } from './common';

export const defaultList = [];

export const filters = {
  portfolioId: noCasingIntFilter(),
  enterprise: noCasingStringFilter(),
  userName: noCasingStringFilter(),
  portfolioTitle: noCasingStringFilter(),
  // assetId: noCasingIntFilter(),
  artworkTitle: noCasingStringFilter(),
  artistName: noCasingStringFilter(),
  // acquisitionValue: noCasingStringFilter(),
  // projectedValue: noCasingStringFilter(),
  // status: noCasingStringFilter(),
};
export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
