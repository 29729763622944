import { generateItems } from 'domain/common';
import { Runner } from 'domain/lib';
import { asyncAction } from 'lib/actions';

const { actions: defaultAction, selectors, reducer } = generateItems('Runner', Runner);

const actions = {
  runnerAction: asyncAction('SPIDER_ACTION'),
  ...defaultAction,
};

export { actions, selectors, reducer };
