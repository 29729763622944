import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import Dropzone from 'react-dropzone';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { FormattedHTMLMessage as FM } from 'react-intl';
import axios from 'axios';
import Crop from 'components/Crop';
import Menu from 'components/ContextMenu';

import colors from 'theme/Colors';
import injectSheet from 'lib/sheet';
import styles from './sheet';
import SmartImage from 'components/SmartImage';
import { connect } from 'cpcs-reconnect';
import { showPopup } from 'domain/ui';

import { token } from 'domain/env';
import { Image as ImageRecord } from 'domain/lib';

import Message from 'components/form/message';

import Api from 'domain/api';

const MB = 1024 * 1024;

let images = 0;
let acceptedFiles = [];
let rejectedFiles = [];

export function cutName(name = '') {
  if (name.length < 24) return name;
  return name.substr(0, 10) + '...' + name.substr(-10);
}

const CancelToken = axios.CancelToken;

class ImageDrop extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    imagesName: PropTypes.string,
    defaultName: PropTypes.string,
    source: PropTypes.instanceOf(I.List),
    token: PropTypes.string.isRequired,
  };

  static defaultProps = {
    imagesName: 'images',
    defaultName: 'defaultArtImage',
    source: new I.List(),
  };

  state = {
    images: I.List(),
    acceptedFiles,
    rejectedFiles,
    loading: {},
    photoIndex: -1,
    onEdit: {},
  };

  updateImage = (file, preview) => {
    const { id } = this.state.onEdit;
    const newFile = new File([file], 'temp.jpg', { type: 'image/jpeg' });
    newFile.preview = preview;
    this.setState({ onEdit: {} }, () => this.load(newFile, 0, id));
  };

  load = (file, index, replaceId) => {
    const { token } = this.props;
    let data = new FormData();
    const key = file.name;
    data.append('image_file', file);
    const source = CancelToken.source();
    const cancel = () => {
      source.cancel('User abort');
      const {
        loading: { [key]: _, ...loading },
      } = this.state;
      this.setState({ loading });
    };

    this.setState({
      loading: { ...this.state.loading, [key]: { file, cancel } },
    });

    const onUploadProgress = ({ loaded, total }) => {
      if (this.state.loading[key]) {
        const {
          loading: { [key]: current },
        } = this.state;
        const progress = Math.floor((loaded * 100) / total);
        this.setState({
          loading: { ...this.state.loading, [key]: { ...current, progress } },
        });
      }
    };
    Api.uploadImage({
      data,
      token,
      onUploadProgress,
      cancelToken: source.token,
    })
      .then(({ data }) => this.move(key, data, index === 0, replaceId))
      .catch(() => null);
  };

  move = (key, image, isFirst, replaceId) => {
    const { defaultName, imagesName } = this.props;
    const { id } = image;
    let {
      [defaultName]: {
        input: { onChange: setDefault, value: defaultImageId },
      },
      [imagesName]: {
        input: { onChange: setImages, value: images },
      },
    } = this.props;
    images = images || [];
    defaultImageId = defaultImageId || null;
    const {
      loading: { [key]: _, ...loading },
    } = this.state;
    this.setState(
      {
        images: this.state.images.push(new ImageRecord(image)),
        loading,
      },
      () => {
        setImages(images.concat(image.id));
        if (defaultImageId === replaceId) setDefault(id);
        if (!defaultImageId && isFirst && !replaceId) setDefault(id);
      },
    );
  };

  preLoadImage = (file) => {
    const img = new Image();
    img.onload = ({ target: e }) => {
      if (e.width < 300 || e.height < 300) {
        rejectedFiles.push({ name: cutName(file.name), err: 'dimensionError' });
        this.forceUpdate();
      } else {
        acceptedFiles.push(file);
      }
      images > 1 ? images-- : this.change(); //NOSONAR
    };
    img.src = file.preview || file.fileName;
  };

  change = () => {
    images = 0;
    acceptedFiles.map((file, index) => this.load(file, index));
    acceptedFiles = [];
    rejectedFiles = [];
  };

  /**
   * @param af - accepted files
   * @param rf - rejected files
   **/
  onChange = (af, rf) => {
    if (rf.length)
      rejectedFiles = rf.map(({ name }) => ({
        name: cutName(name),
        err: 'sizeError',
      }));
    af.length === 0 ? this.change() : af.map(this.preLoadImage);
  };

  renderImage = (id) => {
    const source = this.state.images.concat(this.props.source);
    const { imageFile: src } = source.find((v) => v.get('id') === id) || {};
    const {
      classes: { box, menuBtn },
      imagesName,
      defaultName,
    } = this.props;
    const {
      [imagesName]: {
        input: { onChange: setImages, value: images },
      },
      [defaultName]: {
        input: { onChange: setDefault },
      },
    } = this.props;
    const onRemove = (e) => {
      e.stopPropagation();
      setImages(images.filter((v) => v !== id));
    };
    const menu = {
      mnuSetDefault: () => setDefault(id),
      mnuCrop: () => this.setState({ onEdit: { id, src } }),
      mnuDelete: onRemove,
    };
    return (
      <div key={id} className={box}>
        <img width={60} height={60} src={src} alt={src} />
        <Menu btnClass={menuBtn} menu={menu} key={id} />
      </div>
    );
  };

  renderLoader = (key, index, list) => {
    const { file, progress, cancel } = this.state.loading[key];
    const { classes } = this.props;
    return (
      <div key={key} className={classes.loader}>
        <img
          className={classes.image}
          src={file.preview || file}
          alt={file.name}
        />
        <div
          className={classes.progressBar}
          style={{
            boxShadow: `inset ${progress * 2}px 0px 0 0px ${colors.links}`,
          }}
        >
          <div style={{ position: 'absolute' }}>
            <FM
              id="uploading"
              values={{ index: index + 1, total: list.length }}
            />
          </div>
        </div>
        <button onClick={cancel} type="button" className={classes.cancel} />
      </div>
    );
  };

  render() {
    const { classes, imagesName, defaultName } = this.props;
    const { onEdit } = this.state;
    const source = this.state.images.concat(this.props.source);
    let {
      [imagesName]: {
        input: { value: images = [] },
        meta,
      },
      [defaultName]: {
        input: { value: defaultImageId },
      },
    } = this.props;
    images = images || [];
    defaultImageId = defaultImageId || null;
    const defaultImage = source.find(({ id }) => id === defaultImageId);
    const photoIndex = this.state.photoIndex;
    const photoList = source.map((v) => v.get('imageFile')).toJS();
    const menu = {
      mnuCrop: () =>
        this.setState({
          onEdit: { src: defaultImage.imageFile, id: defaultImageId },
        }),
    };
    return (
      <div className={classes.ImageDrop}>
        {photoIndex > -1 && (
          <Lightbox
            mainSrc={photoList[photoIndex]}
            nextSrc={photoList[(photoIndex + 1) % photoList.length]}
            prevSrc={
              photoList[(photoIndex + photoList.length - 1) % photoList.length]
            }
            onCloseRequest={() => this.setState({ photoIndex: -1 })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + photoList.length - 1) % photoList.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % photoList.length,
              })
            }
          />
        )}
        {defaultImage && (
          <div className={classes.imageBox}>
            <div className={classes.imageBoxContainer}>
              <SmartImage
                container="img"
                fit={{ maxWidth: 300, maxHeight: 300 }}
                originalLink
                params={{ width: 300, height: 300 }}
                src={defaultImage.imageFile}
                onClick={() =>
                  this.setState({ photoIndex: source.indexOf(defaultImage) })
                }
              />
              <Menu
                key={defaultImageId}
                btnClass={classes.menuBtn}
                menu={menu}
              />
            </div>
          </div>
        )}
        {images.length < 2 && (
          <Dropzone
            accept="image/jpeg"
            onDrop={this.onChange}
            maxSize={15 * MB + 1}
            className={classes.dropBox}
          >
            <FM id="textLeft" />
            <span className={classes.active}>
              <FM id="browse" />
            </span>
            <FM id="textRight" />
          </Dropzone>
        )}
        <div className={classes.multiBox}>
          {images.filter((id) => id !== defaultImageId).map(this.renderImage)}
          {images.length > 1 && (
            <Dropzone
              accept="image/jpeg"
              onDrop={this.onChange}
              maxSize={15 * MB + 1}
              className={classes.dropBoxSmall}
            >
              <FM id="upload" />
            </Dropzone>
          )}
        </div>
        {!!rejectedFiles.length && (
          <div className={classes.error}>
            {rejectedFiles.map(({ name, err }, index) => (
              <div key={`${name}-${index}`}>
                <span className={classes.fileName}>{name}</span> -{' '}
                <FM id={err} />
              </div>
            ))}
          </div>
        )}
        {Object.keys(this.state.loading).map(this.renderLoader)}
        {!Object.keys(this.state.loading).length && <Message meta={meta} />}
        {!!onEdit.src && (
          <Crop
            src={onEdit.src}
            onApply={this.updateImage}
            onClose={() => this.setState({ onEdit: {} })}
          />
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(connect({ token, showPopup })(ImageDrop));
