import { asyncAction } from 'lib/actions';
import { field, selector } from 'cpcs-reconnect';
import I from 'immutable';
import { listToMap } from 'lib/serialize';

export const dictionariesList = {
  auctions: (params) => ({ title: params.name, ...params }),
  mediums: (params) => ({ title: params.value, ...params }),
  categories: (params) => ({ title: params.name, ...params }),
  countries: (params) => ({ title: params.name, ...params }),
  conditions: (params) => ({ title: params.name, ...params }),
  currencies: (params) => ({ title: params.alpha3, ...params }),
  subjects: (params) => ({ title: params.name, ...params }),
  substrates: (params) => ({ title: params.description, ...params }),
  surfaces: (params) => ({ title: params.description, ...params }),
  substrateValues: (params) => ({ title: params.value, ...params }),
  surfaceValues: (params) => ({ title: params.value, ...params }),
  defaultCarValueFields: (params) => ({ title: params.title, ...params }),
};

export const defaults = {
  defaultCarValueFields: [
    {
      id: 'HIGH',
      title: 'HIGH',
    },
    {
      id: 'MIDDLE',
      title: 'MIDDLE',
    },
    {
      id: 'LOW',
      title: 'LOW',
    },
  ],
  categories: [
    {
      id: 2,
      title: '2D',
    },
    {
      id: 4,
      title: '2D Edition',
    },
    {
      id: 1,
      title: '3D',
    },
    {
      id: 3,
      title: '3D Edition',
    },
  ],
};

const toDefaultList = ({ id: value, title: label }) => ({ value, label });

export const dictionaries = field('dictionaries');
export const dictionaryList = (key, mapper = v => v) => selector(dictionaries, v => mapper(v.get(key) || new I.List()));
export const dictionaryById = key => selector(dictionaries, v => listToMap(v.get(key)));
export const subjectsList = selector(dictionaryList('subjects'), list => ([
  { label: 'Primary', options: list.filter(v => v.get('level') === 'PRIMARY').toJS().map(toDefaultList) },
  { label: 'Secondary', options: list.filter(v => v.get('level') === 'SECONDARY').toJS().map(toDefaultList) },
]));

const defaultState = I.fromJS(Object.keys(dictionariesList).reduce((p, n) => ({ ...p, [n]: defaults[n] || [] }) ,{}));

export const fetchDictionaryAction = asyncAction('dictionary/FETCH');
export const fetchDictionaryItemsAction = asyncAction('dictionary/FETCH_ITEMS');

export const reducer = {
  dictionaries(state = defaultState, { type, payload }) { //NOSONAR
    switch (type) { //NOSONAR

      case fetchDictionaryAction.success:
        return state.update(payload.name, list => list
          .filter(v => !payload.list.map(({ id }) => id ).includes(v.get('id')))
          .concat(I.fromJS(payload.list)));

      case fetchDictionaryItemsAction.success:
        return state.update(payload.name, list => list
          .filter(v => !payload.list.map(({ id }) => id ).includes(v.get('id')))
          .concat(I.fromJS(payload.list.map(dictionariesList[payload.name]))));

      default:
        return state;
    }
  },
};
