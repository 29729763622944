import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import Popup from 'components/NewItemPopup';
import Form from './Form';
import Exporter from 'components/Exporter';

import { artistListPagination, artistListStats } from 'domain/artistList';
import { showPopup } from 'domain/ui';
import { getQuery } from 'domain/router';
import confirmPopups from 'components/ConfirmPopups';
import ArtistsListTable from './ArtistsListTable';
import ListHeader from 'components/ListHeader';
import Pagination from 'components/pagination';

import injectSheet from 'lib/sheet';
import styles from 'theme/listPageSheet';

import Filter from 'components/Filter';
import { filterParams } from 'filters/artist';
import MergeArtist from 'popups/MergeArtist';

const Header = connect({
  pagination: artistListPagination,
  stats: artistListStats,
  query: getQuery,
  action: () => showPopup({ name: 'ADD_ARTIST' }),
})(ListHeader);

class ArtistsListPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.instanceOf(I.Record),
  };

  render() {
    const { classes, pagination } = this.props;
    return (
      <div className={classes.ListPage}>
        { confirmPopups }
        <MergeArtist/>
        <Popup name="ADD_ARTIST" formName="createArtistForm">
          <Form/>
        </Popup>
        <div className={classes.header}>
          <Header
            title="artists"
            linkName="artistList"
            actionTextKey="addArtist"
            showInvalid="artist"
          />
          <div className={classes.subHeader}>
            <Pagination pagination={pagination} />
          </div>
        </div>
        <Filter context="artist" {...filterParams}>
          <Exporter tableName="artistList"  filters={filterParams.filters} apiKey="getArtistsListCSV" />
        </Filter>
        <div className={classes.tableContainer}>
          <ArtistsListTable pagination={pagination} />
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    pagination: artistListPagination,
  }),
)(ArtistsListPage);
