import { createFilterTransfer, intRange } from './common';

export const defaultList = [ 'description' ];

export const filters = {
  description: {},
  rank: { formFieldRenderer: intRange('rank') },
  order: { formFieldRenderer: intRange('order') },
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
