import { createFilterTransfer } from './common';

export const defaultList = [];

export const filters = {};

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };

export default filters;
