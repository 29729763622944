import React from 'react';
import PropTypes from 'prop-types';

import {
  commonDictionaryFields,
  connectAcceptance,
  createIdLink,
  toNumber,
  toNotEmptyUsdPrice,
} from './common';

import Input from 'components/form/element/Input';

import { defineColumns } from 'components/PageTable/defineColumns';

import { dateToString, Entity, listOfEntity } from 'lib/serialize';
import Image from '../components/SmartImage';
import { Field } from 'redux-form';
import Select from '../components/form/element/Select';
import I from 'immutable';
import { validURL } from 'components/Link';
import { numberFieldProps } from '../lib/helpers';
import Dropdown from "fields/Dropdown";
import LockDetector from "fields/LockDetector";
import { Link } from "react-router-dom";
import { lnk } from 'lib/routes';

export const sortEnterprises = list => list.sort((a, b) => {
  if (a.enterpriseName > b.enterpriseName) return 1;
  if (a.enterpriseName < b.enterpriseName) return -1;
  return 0;
})
const seatTypeMap = {
  PREMIUM_ARTPRO: 'Premium ARTPro',
  PREMIUM_WEALTH_ADVISORY: 'Premium Wealth Advisory',
  PREMIUM_INSURANCE: 'Premium Insurance',
  STANDARD_ART_PROFESSIONAL: 'Standard ARTPro',
};

const formatSeatType = (seatType) => {
  if (seatType === null || seatType === undefined) return '−';
  return seatTypeMap[seatType] || seatType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const FreeRtv = <Field
  name="freeRtvPurchaseCount"
  component={LockDetector(Input)}
  display={({ configuration }) => !['Enterprise','Internal'].includes(configuration)}
  type="number"
  min="0"
  reset={false}
  {...numberFieldProps}
/>;

const Configuration = <Field
  name="configuration"
  component={Select}
  parseValue={v => v.get('id')}
  list={I.fromJS([
    { id: 'Enterprise', title: 'Enterprise' },
    { id: 'Chargeable', title: 'Chargeable' },
    { id: 'Internal', title: 'Internal' },
    { id: 'Custom', title: 'Custom' },
  ])}
/>;

const SeatType = <Field
  name="seatType"
  component={Select}
  parseValue={v => v.get('id')}
  list={I.fromJS([
    { id: 'PREMIUM_ARTPRO', title: 'Premium ARTPro' },
    { id: 'PREMIUM_WEALTH_ADVISORY', title: 'Premium Wealth Advisory' },
    { id: 'PREMIUM_INSURANCE', title: 'Premium Insurance' },
    { id: 'STANDARD_ART_PROFESSIONAL', title: 'Standard ARTPro' },
  ])}
/>;

const EnterpriseLink = ({ value, classes }) => {
  if (!value?.enterpriseName) return  '−';
  return <Link className={classes.link} to={lnk('enterprise', { id: value.id })}>{value.enterpriseName}</Link>
}

const trialRemaining = ({ value, rowData: { locked, userStatus } }) => locked ? `${ value }` : <Field
  name="trialRemaining"
  component={Input}
  type="number"
  min="0"
  disabled={ !['Trial','Inactive'].includes(userStatus) }
/>

const Avatar = ({ value }) => <Image originalLink src={value} />;

Avatar.propTypes = {
  value: PropTypes.string,
};

const BigAvatar = ({ value }) => <Image fit={{ maxWidth: 245, maxHeight: 245 }} container="img" originalLink src={value} />;

BigAvatar.propTypes = {
  value: PropTypes.string,
};

const StripeUrl = ({ value, classes }) => validURL(value) ? <a
  className={classes.link}
  href={value}
  children={value}
  target="_blank"
  rel="noopener noreferrer"
/> : '−';

StripeUrl.propTypes = {
  value: PropTypes.number,
  classes: PropTypes.any,
};

const abvLimit = ({ rowData: { enterprise, configuration }, value }) => {
  if ( ['Enterprise','Internal'].includes(configuration) && (!enterprise || enterprise.limitedAbv === false)) return '−';
  return `${value}`;
}

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('customer'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  active: { sortable: true, getValue: ({ value }) => value ? 'Yes' : 'No' },
  email: { sortable: true },
  emailVerified: { sortable: true, getValue: ({ value }) => value ? 'Yes' : 'No' },
  picture: { getValue: Avatar, parser: BigAvatar },
  firstName: { sortable: true },
  lastName: { sortable: true },
  country: { sortable: true, getValue: Entity },
  marketingEmailConsent: { sortable: true, getValue: ({ value }) => value ? 'Yes' : 'No' },
  sharedWithUser: { sortable: true },
  sharedByUser: { sortable: true },
  rtv: { sortable: true },
  occupations: { getValue: listOfEntity },
  lastLogin: {
    sortable: true,
    className: 'date',
    getValue: dateToString('MM/DD/YYYY','−'),
    parser: dateToString(),
  },
  byMobile: { getValue: ({ value }) => !!value ? 'Mobile' : 'WebApp', sortable: true },
  artsCount: { sortable: true, getValue: toNumber },
  considerationsCount: { sortable: true, getValue: toNumber },
  alertsCount: { sortable: true, getValue: toNumber  },
  totalAbv: { getValue: toNotEmptyUsdPrice },
  subscription: { sortable: true, getValue: ({ value }) => value.replace(/\w/, (v) => v.toUpperCase()) },
  userStatus: { sortable: true },
  configuration: {
    sortable: true,
    formFieldRenderer: ({ value, rowData: { locked } }) => locked ? value : Configuration,
  },
  seatType: {
    sortable: true,
    formFieldRenderer: ({ value, rowData: { locked } }) => locked ? formatSeatType(value) : SeatType,
  },
  renewalDate: {
    sortable: true,
    getValue: dateToString('MM/DD/YYYY','−'),
    parser: dateToString(),
  },
  abvPurchases: { sortable: true, getValue: toNumber },
  stripeUserUrl: { hiddenForTable: true, getValue: StripeUrl },
  freeRtvPurchaseCount: {
    getValue: abvLimit,
    className: 'number',
    formFieldRenderer: (props) => {
      return props.rowData.locked ? abvLimit(props): FreeRtv
    },
  },
  trialRemaining: {
    formFieldRenderer: trialRemaining,
  },
  enterprise: ({
    getValue: (props) => <EnterpriseLink {...props} />,
    formFieldRenderer: ({ value, rowData: { locked }, classes }) => locked ? <EnterpriseLink
      value={value}
      classes={classes}
    /> : <Dropdown
      isClearable={true}
      apiKey="getEnterpriseList"
      parseLabel={ data => data.get('enterpriseName')}
      parseValue={ data => data.get('id') }
      display={({ configuration }) => configuration === 'Enterprise' }
      name="enterprise"
      placeholder="Enterprise"
      sort={sortEnterprises}
    /> }),
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = [
  'picture',
  'firstName',
  'lastName',
  'country',
  'lastLogin',
  'createdDate',
  'updatedDate',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
