import record, { listOf, bool } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';

import { History } from 'domain/lib';
import { asyncAction } from 'lib/actions';

export const fetchHistoryAction = asyncAction('history/FETCH');

const State = record('History', {
  loading: bool(true),
  list: listOf(History),
});

const history = field('history');
export const historyList = history.then(State.$list);
export const loading = history.then(State.$loading);

export const reducer = {
  history(state = new State(), action) { //NOSONAR
    switch (action.type) {

      case fetchHistoryAction.request:
        return state.apply(
          State.$list.clear(),
          State.$loading.set(true),
        );

      case fetchHistoryAction.success:
        return state.apply(
          State.$list.parsed(action.payload),
          State.$loading.set(false),
        );

      default:
        return state;
    }
  },
};
