import colors from './Colors';
import { DEFAULT_FONTFAMILY } from 'theme/const';

const btn = {
  flexShrink: 0,
  cursor: 'pointer',
  display: 'inline-block',
  border: '1px solid transparent',
  textAlign: 'center',
  fontFamily: DEFAULT_FONTFAMILY,
  textTransform: 'uppercase',
  textDecoration: 'none',
  letterSpacing: 1,
  fontSize: 12,
  padding: 0,
  opacity: 0.8,
  '&:disabled': {
    opacity: 0.5,
  },
};

const btnDefault = {
  ...btn,
  color: colors.menu,
  border: `1px solid ${colors.menu}`,
  '&:hover': {
    backgroundColor: colors.transparentHover,
  },
};

const btnAdd = {
  ...btn,
  fontWeight: 400,
  background: colors.attention,
  color: colors.bg,
  '&:hover': {
    background: '#983C1B',
  },
  '&:active': {
    background: '#873518',
  },
};

const btnBig = {
  ...btnAdd,
  fontSize: 18,
  letterSpacing: '1.5px',
  width: 320,
  height: 56,
  lineHeight: '56px',
  fontWeight: '600',
};

const btnCancel = {
  ...btn,
  color: colors.menu,
  '&:hover': {
    backgroundColor: colors.transparentHover,
  },
};

const btnDraft = {
  ...btn,
  backgroundColor: '#b9b4b4',
  color: 'black',
};

const btnDone = {
  ...btn,
  color: colors.attention,
  '&:hover': {
    backgroundColor: colors.transparentHover,
  },
};

export default {
  add: btnAdd,
  cancel: btnCancel,
  done: btnDone,
  default: btnDefault,
  draft: btnDraft,
  big: btnBig,
};
