import React from 'react';
import {connect} from "cpcs-reconnect";
import { compose } from "redux";
import { getBulkActionsData, bulkActionsClear } from "../../domain/ui";
import { getLocationName } from 'domain/router';
import injectSheet from "../../lib/sheet";
import { confirmationSheet } from 'popups/Confirmation'
import { PurePopup } from "../Popup";
import { FormattedMessage as FM } from "react-intl";
import { Link } from 'react-router-dom';
import classes from './style.module.css';

function Indicator({ data, location = '', bulkActionsClear, ...props }) {
  const { name = 'test', progress = 10, total = 100, errors } = data;

  const width = React.useMemo(() => {
    return total ? Math.floor(progress * 100 / total) + '%' : 0
  }, [ progress, total ]);

  if (!name || !total) return null;

  return <PurePopup
    getTitle={() => (<><FM id="bulkAction"/>&nbsp;<FM id={name}/></>)}
    onClose={bulkActionsClear}
    {...props}
  >
    <div className={classes.progressBar}>
      <div className={classes.progress} style={{ width }}></div>
      { progress } / { total }
    </div>
    <ul className={classes.errorList}>
      { errors.map(({ id, link, errors: objErrors }) =>
        <li key={id} className={classes.error}>
          <Link target="_blank" className={classes.link} to={link}>ID: {id}</Link>
          <ul className={classes.subErrorList}>
            { Object.keys(objErrors).map(fieldName =>
            <li key={fieldName} className={classes.subError}>
              <FM id={fieldName} /> { ' - ' } { objErrors[fieldName] }
            </li>
            )}
          </ul>
        </li>
      )}
    </ul>
  </PurePopup>
}


export default compose(
  injectSheet({
    ...confirmationSheet,
    container: {
      ...confirmationSheet.container,
      width: 800,
    }
  }),
  connect({
    data: getBulkActionsData,
    location: getLocationName,
    bulkActionsClear,
  })
)(Indicator);
