import React, { useCallback, useEffect, useMemo, useState } from "react";
import Paper from "components/Paper";
import classes from './trial.module.scss';
import { FormattedMessage } from "react-intl";
import { Form, Field } from 'react-final-form';
import rules from "components/form/validation";
import Input from 'components/form/element/Input'
import { token, userProfile, addNotification } from "domain/env";
import { connect } from "cpcs-reconnect";
import Api from "domain/api";
import { useSubmit } from "lib/hooks";
import Loader from "components/FormLoader";
import Button from 'components/Button'
import Block from 'components/Block';
import { StyledHistory as HistoryPopup } from "popups/History";
import { dateTimeToString } from "../../lib/serialize";
import { showPopup } from "../../domain/ui";
import I from 'immutable';
import { BannerComponent } from "../../fields/artist";

const fields = [
  {
    component: Input,
    name: 'freeAbvPurchasesCount',
    type: 'number',
    min: 0,
    validate: value => rules.required(value) || rules.positive(value),
    autoFocus: true,
  },
];

const bannerFields = [
  {
    name: 'bannerUrl',
    component: Input,
    validate: value => rules.required(value) || rules.validURL(value)
  },
  {
    name: 'banner343x120',
    component: BannerComponent(106, 343),
    validate: value => rules.required(value)
  },
  {
    name: 'banner720x120',
    component: BannerComponent(222, 720),
    validate: value => rules.required(value)
  },
  {
    name: 'banner970x120',
    component: BannerComponent(300, 970),
    validate: value => rules.required(value)
  },
].reverse();

const SettingsPage = ({ token, userProfile, addNotification, showPopup }) => {

  const [ { loading, initData, banners, history }, setData ] = useState({
    loading: true,
    initData: {
      trialPeriod: 0,
      freeAbvPurchasesCount: 0,
      username: userProfile.username,
    },
    banners: {},
    history: [],
  });

  const showHistory = useCallback(() => {
    showPopup({ name: 'HISTORY' });
  }, [ showPopup ])

  const trialHistory = useMemo(() => {
    return history.length ? [
      'Updated',
      dateTimeToString()({ value: history[history.length - 1].dateCreated }),
      'by',
      history[history.length - 1].username,
    ].filter(v => !!v).join(' ') : ''
  }, [history])

  const loadTrial = useCallback(({ trialPeriod, freeAbvPurchasesCount, history, ...banners }) => {
    setData({
      initData: { trialPeriod, freeAbvPurchasesCount, username: userProfile.username },
      banners,
      history: history.map(({ date: dateCreated, ...history }) => ({ ...history, dateCreated })),
      loading: false
    });
  }, [userProfile])



  useEffect(() => {
    (async () => {
      const { data } = await Api.getTrial({ token });
      loadTrial(data)
    })()
  }, [loadTrial, token])

  const callBack = useCallback(initData => {
    loadTrial(initData)
    addNotification({ title: 'Changes were successfully saved.', translate: false, type: 'success' })
  }, [loadTrial, addNotification]);

  const { onSubmit: submitTrial } = useSubmit({ key: 'updateTrial', requestProps: { token }, callBack })

  const submitBanners = useCallback(async (values, ...props) => {
    const data = new FormData();
    Object.keys(values).forEach(key => {
      if (typeof values[key] !== "string" || key === 'bannerUrl') data.append(key, values[key]);
    })
    if ([...data.keys()].length) {
      submitTrial(data, ...props).then();
    }
  }, [ submitTrial ])

  if(loading) return <Loader/>

  return (
    <div className={classes.TrialPage}>
      <div><HistoryPopup items={I.fromJS(history)} /></div>
      <h2 className={classes.header}><FormattedMessage id="settingsPage"/></h2>
      <Paper>
        <Block>
          <Form
            initialValues={initData}
            onSubmit={submitTrial}
            render={({ handleSubmit, pristine, submitting }) => (
              <form onSubmit={handleSubmit} >
                <Field name="username" component="input" type="hidden" />
                { fields.map(props => <div key={props.name} className={classes.field}>
                  <label htmlFor={props.name}>
                    <FormattedMessage id={props.name}/>
                  </label>
                  <Field {...props} />
                </div>) }
                <Button buttonType="primary" disabled={pristine || submitting} type="submit" className={classes.button}>
                  <FormattedMessage id="save"/>
                </Button>
              </form>
            )}
          />
          { trialHistory && <div className={classes.history}>
            { trialHistory }
            <button className={classes.historyBtn} type="button" onClick={showHistory}/>
          </div>}
        </Block>
        <Block>
          <Form
            initialValues={banners}
            onSubmit={submitBanners}
            render={({ handleSubmit, pristine, submitting }) => (
              <form onSubmit={handleSubmit} >
                <Field name="username" component="input" type="hidden" />
                { bannerFields.map(props => <div key={props.name} className={classes.field}>
                  <label htmlFor={props.name}>
                    <FormattedMessage id={props.name}/>
                  </label>
                  <Field {...props} />
                </div>) }
                <Button buttonType="primary" disabled={pristine || submitting} type="submit" className={classes.button}>
                  <FormattedMessage id="save"/>
                </Button>
              </form>
            )}
          />
        </Block>
      </Paper>
    </div>
  )
}

export default connect({
  token,
  userProfile,
  addNotification,
  showPopup,
})(SettingsPage);
