import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import toCamel from 'to-camel-case';
import { FormattedMessage as FM } from 'react-intl';

import { visiblePopupsNames, hidePopupByName } from 'domain/ui';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

class PurePopup extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      overlay: PropTypes.string,
      container: PropTypes.string.isRequired,
      header: PropTypes.string,
      content: PropTypes.string.isRequired,
      close: PropTypes.string,
    }),
    children: PropTypes.node,
    onClose: PropTypes.func,
    getTitle: PropTypes.func,
  };

  render() {
    const {
      classes: { container, header, overlay, content, close },
      onClose,
      getTitle,
      children = null,
    } = this.props;
    return (
      <div>
        <div className={overlay}/>
        <div className={container}>
          <div className={header}>
            { getTitle && getTitle(this.props) }
            <button onClick={() => onClose()} className={close} type="button"/>
          </div>
          <div className={content}>
            { children }
          </div>
        </div>
      </div>
    );
  }
};

class Popup extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    visiblePopupsNames: PropTypes.array,
    hidePopupByName: PropTypes.func.isRequired,
    getTitle: PropTypes.func,
  };

  static defaultProps = {
    getTitle: ({ name }) => <FM id={`popup.${toCamel(name)}`} />,
  }

  componentWillUnmount = () => {
    const { hidePopupByName, visiblePopupsNames, name } = this.props;
    if (visiblePopupsNames.includes(name)) hidePopupByName(name);
  }

  render() {
    const { name = '', visiblePopupsNames, hidePopupByName, getTitle, ...props } = this.props;
    if (!visiblePopupsNames.includes(name)) return null;
    return (
      <PurePopup
        onClose={() => hidePopupByName(name)}
        getTitle={() => getTitle(this.props)}
        {...props}
      />
    );
  }
};

export { sheet, Popup, PurePopup } ;
export const ConnectedPopup = connect({ visiblePopupsNames, hidePopupByName })(Popup);

export default injectSheet(sheet)(ConnectedPopup);
