import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { setPropTypes } from 'lib/helpers';
import CheckBox from 'components/form/fields/CheckBox/CheckBox';
import cx from 'classnames';

import rowActions from 'components/PageTable/BulkActions/actions';

import {
  REVIEW_STATUS_NEW,
  REVIEW_STATUS_DRAFT,
  REVIEW_STATUS_VALID,
  REVIEW_STATUS_ACCEPTED,
  REVIEW_STATUS_IGNORED,
  REVIEW_STATUS_DISPLAY,
} from 'domain/const';

const statusToClass = {
  [REVIEW_STATUS_NEW]: 'tdMarkerNew',
  [REVIEW_STATUS_DRAFT]: 'tdMarkerDraft',
  [REVIEW_STATUS_VALID]: 'tdMarkerValid',
  [REVIEW_STATUS_ACCEPTED]: 'tdMarkerAccepted',
  [REVIEW_STATUS_IGNORED]: 'tdMarkerIgnored',
  [REVIEW_STATUS_DISPLAY]: 'tdMarkerDisplay',
  'Active Stripe': 'tdMarkerNew',
  'Trial': 'tdMarkerDraft',
  'Beta': 'tdMarkerValid',
  'Active By Payment': 'tdMarkerAccepted',
  'Inactive': 'tdMarkerIgnored',
};

export const tdMarker = {
  name: 'tdMarker',
  render: setPropTypes(
    ({ classes, rowData }) =>
      <div className={classes[statusToClass[rowData.get('wfAcceptance') || rowData.get('userStatus') ]] || classes.tdMarker} />,
    {
      classes: PropTypes.shape({ tdMarker: PropTypes.string.isRequired }).isRequired,
      rowData: PropTypes.instanceOf(I.Collection).isRequired,
    },
    'tdMarker.render',
  ),
  renderTh: setPropTypes(
    ({ classes }) => <div className={classes.tdMarkerTh} />,
    {
      classes: PropTypes.shape({ tdMarkerTh: PropTypes.string.isRequired }).isRequired,
    },
    'tdMarker.renderTh',
  ),
  shouldCellUpdate: ({ nextProps, props }) => nextProps.rowData.get('wfAcceptance') !== props.rowData.get('wfAcceptance'),
};

const CheckAll = ({ items, toggleRowCheck, selectedItems }) => {
  const value = items.size > 0 && items.filter(v => !selectedItems.includes(v.get('id'))).size === 0;
  const props = { value, onChange: () => toggleRowCheck(value) };
  return <CheckBox {...props} />;
};

class RowActions extends React.Component {

  static propTypes = {
    classes: PropTypes.shape({
      actions: PropTypes.string.isRequired,
      actionButton: PropTypes.string.isRequired,
      more: PropTypes.string.isRequired,
      edit: PropTypes.string.isRequired,
      save: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
    }).isRequired,
    onEdit: PropTypes.func,
    isEdit: PropTypes.bool,
    submit: PropTypes.func.isRequired,
    tableName: PropTypes.string.isRequired,
    rowData: PropTypes.instanceOf(I.Record).isRequired,
    showActions: PropTypes.func,
    allowEdit: PropTypes.func,
    modalMode: PropTypes.string,
  }

  render() {
    const { classes, onEdit, isEdit, submit, tableName, rowData, showActions, modalMode, allowEdit = () => false } = this.props;
    const { actions, actionButton, more, edit, save, cancel } = classes;
    const formName = `${tableName}_${rowData.get('id')}`;
    const hasActions = Object.keys(rowActions)
      .filter(key => rowActions[key].display({ ...this.props, items: new I.List([ rowData ]) }))
      .length > 0;
    if (modalMode) return null;
    return (
      <div className={actions}>
        { isEdit &&  <button type="button" onClick={() => onEdit(null)} className={cx(actionButton, cancel)}/> }
        { isEdit && <button type="button" onClick={() => submit(formName)} className={cx(actionButton, save)}/> }
        { !isEdit && allowEdit(rowData) && <button type="button" onClick={() => onEdit(rowData.get('id'))} className={cx(actionButton, edit)}/> }
        { !isEdit && hasActions && showActions && <button type="button" onClick={(e) => showActions(rowData.get('id'), e)} className={cx(actionButton, more)}/> }
      </div>
    );
  }
}

CheckAll.propTypes = {
  toggleRowCheck: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(I.Collection).isRequired,
  items: PropTypes.instanceOf(I.Collection).isRequired,
};

const CheckOne = ({ toggleRowCheck, rowData, selectedItems }) => {
  return <CheckBox
    value={selectedItems.includes(rowData.get('id'))}
    onChange={() => toggleRowCheck(rowData.get('id'))}
  />;
};

CheckOne.propTypes = {
  toggleRowCheck: PropTypes.func.isRequired,
  selectedItems: PropTypes.instanceOf(I.Collection).isRequired,
  rowData: PropTypes.instanceOf(I.Collection),
};

export const tdCheck = {
  name: 'tdCheck',
  className: 'tdCheck',
  getValueTh: CheckAll,
  getValue: CheckOne,
  shouldCellUpdate: () => true,
};

export const tdActions = {
  name: 'tdActions',
  className: 'tdActions',
  getValueTh: () => null,
  getValue: RowActions,
  shouldCellUpdate: () => true,
};

export const columnOptions = {
  name: 'tdOptions',
  className: 'tdOptions',
  renderTh: setPropTypes(
    ({ className, classes, toggleColumnsSelector, columnsSelectorShown }) =>
      <div className={className}>
        <button
          className={columnsSelectorShown ? classes.optionsButtonPushed : classes.optionsButton}
          type="button"
          onClick={toggleColumnsSelector}
        />
      </div>,
    {
      className: PropTypes.string.isRequired,
      classes: PropTypes.shape({
        optionsButtonPushed: PropTypes.string.isRequired,
        optionsButton: PropTypes.string.isRequired,
      }).isRequired,
      toggleColumnsSelector: PropTypes.func.isRequired,
      columnsSelectorShown: PropTypes.bool,
    },
    'columnOptions.renderTh',
  ),
  render: setPropTypes(
    ({ className }) => <div className={className} />,
    {
      className: PropTypes.string.isRequired,
    },
    'columnOptions.render',
  ),
  shouldCellUpdate: () => false,
};
