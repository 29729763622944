import { lnk } from 'lib/routes';
import { getTerm, isEmpty } from 'lib/helpers';
import { formValues } from 'domain/artwork/fieldRules';
import { defaults } from 'domain/dictionary';
import { pushUpdateAction } from 'domain/artwork';
import moment from "moment";

const notEmptyOrPopulated = (field, value, data) => {
  if (!isEmpty(value)) return true;
  const populateKey = { physicalSizeRaw: 'sheetSizeRaw' }[field];
  if (populateKey) return !isEmpty(data[populateKey]);
  return false;
};

const allowAccept = (data = {}, acceptanceFields = []) => acceptanceFields
  .filter(key => formValues[defaults.categories.find(v => v.id === (data.category || 2)).title].includes(key))
  .filter(key => !notEmptyOrPopulated(key, data[key], data)).length === 0;

const getRecordType = obj => (!!obj && !!obj.toString) ? (obj.toString().split(' '))[0] : 'UNKNOWN';

const ignoredDisplayFields = ['defaultArtImage','edition','imageSizeRaw','physicalSizeRaw'];

const lock = value => ({ data, lock }) => {
  const objType = (data.toString().split(' '))[0];
  return () => lock({ subject: objType, value });
};

const acceptTitle = ({ data, formData, isFormValid = true, acceptanceFields = [], ...props }) => {
  if (isFormValid === false) {
    return 'invalidForm';
  }
  if (getRecordType(data) === 'Artwork'){
    if (formData){
      if (!props.artistId || !props.artistId.has(parseInt(formData.artistId, 10))) return null;
      if (props.artistId.get(parseInt(formData.artistId, 10)).wfAcceptance !== 'ACCEPTED') return 'artistNotAccepted';
      if (!formData.defaultArtImage) return 'defaultImageRequired';
    } else {
      if (data.getIn(['artist','wfAcceptance']) !== 'ACCEPTED') return 'artistNotAccepted';
      if (!data.get('defaultImageUrl')) return 'defaultImageRequired';
    }
  }
  if (getRecordType(data) === 'Lot'){
    if (formData && props.artwork){
      if (props.artwork.wfAcceptance !== 'ACCEPTED') return 'artworkNotAccepted';
      if (!formData.defaultArtImage) return 'defaultImageRequired';
    } else {
      if (data.getIn(['art','wfAcceptance']) !== 'ACCEPTED') return 'artworkNotAccepted';
      if (!data.defaultArtImage) return 'defaultImageRequired';
    }
  }
  return !allowAccept(formData, acceptanceFields) ? 'someRequiredFieldsAreEmpty' : null;
};

const acceptDisabled = props => !!acceptTitle(props);

const displayTitle = ({ data, formData, isFormValid = true, acceptanceFields = [], ...props }) => {
  if (isFormValid === false) return 'invalidForm';

  if (getRecordType(data) === 'Artwork'){
    if (data.getIn(['countOfLots']) === 0) return 'noLots';
    if (formData){
      if (!props.artistId || !props.artistId.has(parseInt(formData.artistId, 10))) return null;
      if (props.artistId.get(parseInt(formData.artistId, 10)).wfAcceptance !== 'ACCEPTED') return 'artistNotAccepted';
    } else {
      if (data.getIn(['artist','wfAcceptance']) !== 'ACCEPTED') return 'artistNotAccepted';
    }
  }

  if (getRecordType(data) === 'Lot'){
    // check art
    if (formData && props.artwork){
      if (!['ACCEPTED','DISPLAY'].includes(props.artwork.wfAcceptance)) return 'artworkNotAccepted';
    } else {
      if (!['ACCEPTED','DISPLAY'].includes(data.getIn(['art','wfAcceptance']))) return 'artworkNotAccepted';
    }
    const date = formData ? formData.auctionDate : data.get('auctionDate');
    const isDateBefore = moment(date).isBefore();
    // check sold price & currency
    if (isDateBefore) {
      if (formData) {
        // check currency
        if (formData.currency === '' || formData.currency === undefined) return 'currencyIsRequired';
        // check sold price
        if (formData.soldPrice === '' || formData.soldPrice === undefined) return 'soldPriceIsRequired';
      } else {
        // check currency
        if (data.getIn(['currency'], '') === '') return 'currencyIsRequired';
        // check sold price
        if (data.getIn(['soldPrice'], '') === '') return 'soldPriceIsRequired';
      }
    }
  }

  return !allowAccept(formData, acceptanceFields.filter(v => !ignoredDisplayFields.includes(v))) ? 'someRequiredFieldsAreEmpty' : null;
};

const displayDisabled = props => !!displayTitle(props);

const notCustomer = ({ data: { sourceType } }) => sourceType !== 'CUSTOMER';
const artworkOrArtistOrLot = ({ location }) => ['artwork','artist','artworkList','artistList','lotList'].includes(location);

const canDelete = ({ location, userProfile }) => {
  if (!userProfile) return true;
  return userProfile?.get('isSuperuser') || (userProfile?.acl || []).includes(location + 'Delete');
}

const displayLockUnlock = display => ({ data: { wfAcceptance, locked } }) => wfAcceptance === 'ACCEPTED' && locked === display;

const getConfirmationSubject = (data, location) => {
  switch (location){
    case 'artist':
      return data.fullName;

      case 'artwork':
      return data.title;

    default:
      return undefined;
  }
}

export default [
  {
    name: 'configure',
    display: [ ({ location }) => location === 'customer' ],
    onClick: lock(false),
  },
  {
    name: 'unlock',
    display: [ notCustomer, artworkOrArtistOrLot, displayLockUnlock(true) ],
    onClick: lock(false),
  },
  {
    name: 'lock',
    display: [ notCustomer, artworkOrArtistOrLot, displayLockUnlock(false) ],
    onClick: lock(true),
  },
  {
    name: 'accept',
    'displayBulk.artworkList': [
      ({ location }) => location === 'artworkList',
    ],
    'displayBulk.lotList': [
      ({ location }) => location === 'lotList',
    ],
    display: [
      notCustomer,
      artworkOrArtistOrLot,
      ({ data: { wfAcceptance } }) => ['NEW','DRAFT','VALID','DISPLAY'].includes(wfAcceptance),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ wfAcceptance: 'ACCEPTED' }),
    disabled: acceptDisabled,
    title: acceptTitle,
  },
  {
    name: 'display',
    'displayBulk.artworkList': [
      ({ location }) => location === 'artworkList',
    ],
    'displayBulk.lotList': [
      ({ location }) => location === 'lotList',
    ],
    display: [
      notCustomer,
      ({ location }) => ['artwork','artworkList','lotList'].includes(location),
      ({ data: { wfAcceptance, locked = false } }) => wfAcceptance === 'ACCEPTED' ? !locked : wfAcceptance !== 'DISPLAY',
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ wfAcceptance: 'DISPLAY' }),
    disabled: displayDisabled,
    title: displayTitle,
  },
  {
    name: 'draft',
    display: [
      notCustomer,
      artworkOrArtistOrLot,
      ({ data: { wfAcceptance } }) => ['VALID','IGNORE'].includes(wfAcceptance),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ wfAcceptance: 'DRAFT' }),
  },
  {
    name: 'validate',
    display: [
      notCustomer,
      artworkOrArtistOrLot,
      ({ data: { wfAcceptance, locked = false } }) => ['NEW','DRAFT','DISPLAY']
        .includes(wfAcceptance) || (wfAcceptance === 'ACCEPTED' && !locked),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ wfAcceptance: 'VALID' }),
  },
  {
    name: 'ignore',
    display: [
      notCustomer,
      artworkOrArtistOrLot,
      ({ data: { wfAcceptance, locked } }) => !['ACCEPTED','IGNORE']
        .includes(wfAcceptance) || (wfAcceptance === 'ACCEPTED' && !locked),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ wfAcceptance: 'IGNORE' }),
  },
  {
    name: 'delete',
    display: [
      notCustomer,
      canDelete,
      ({ data: { isDelete } }) => !isDelete,
      ({ data: { wfAcceptance } }) => wfAcceptance !== 'ACCEPTED',
    ],
    onClick: ({ data, showPopup, location }) =>
      () => showPopup({ name: 'REMOVE', id: data.id, subject: getConfirmationSubject(data, location) }),
  },
  {
    name: 'activate',
    display: [
      ({ location }) => location.includes('customer'),
      ({ data: { active, enterprise, locked = false }}) => !active && !enterprise && !locked,
    ],
    onClick: ({ updateItemAction, data: { freeRtvPurchaseCount, configuration } }) => () => updateItemAction({ active: true, freeRtvPurchaseCount, configuration }),
  },
  {
    name: 'deactivate',
    display: [
      ({ location }) => location.includes('customer'),
      ({ data: { active, locked = false }}) => !!active && !locked,
    ],
    onClick: ({ updateItemAction, data: { freeRtvPurchaseCount, configuration } }) => () => updateItemAction({ active: false, freeRtvPurchaseCount, configuration }),
  },
  { type: 'divider' },
  {
    name: 'merge',
    display: [
      ({ data: { wfAcceptance }, location }) => wfAcceptance !== 'ACCEPTED' && location === 'artist',
    ],
    onClick: ({ data: { id, fullName }, showPopup }) => () => showPopup({ name: 'MERGE_ARTIST', term: getTerm(fullName), id }),
  },
  {
    name: 'showWebRtv',
    display: [
      notCustomer,
      ({ data: { wfAcceptance, showRtv, locked = false }, location }) => ['ACCEPTED']
        .includes(wfAcceptance) && !showRtv && location === 'artist' && !locked,
      ({ userProfile = { acl: [] } }) => userProfile.isSuperuser || userProfile.acl.includes('rtv'),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ showRtv: true }),
  },
  {
    name: 'hideWebRtv',
    display: [
      notCustomer,
      ({ data: { wfAcceptance, showRtv, locked = false }, location }) => ['accepted','valid']
        .includes((wfAcceptance || '').toLowerCase()) && showRtv && location === 'artist' && !locked,
      ({ userProfile = { acl: [] } }) => userProfile.isSuperuser || userProfile.acl.includes('rtv'),
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ showRtv: false }),
  },
  {
    name: 'showAos',
    display: ({ location }) => location === 'artist',
    onClick: ({ push, data: { id } }) => () => push(`${lnk('artworkList')}?artistIdId=${id}`),
  },
  {
    type: 'link',
    name: 'showRepeatSalePairs',
    icon: 'show_aos',
    display: ({ location }) => location === 'artist',
    to: ({ data: { id, fullName } }) => () => (`${lnk('lotList')}?artistSalePairs=${id}&sortBy=-art_id_id,-auction_date`),
  },
  {
    name: 'showSimilar',
    type: 'link',
    display: [
      notCustomer,
      ({ location }) => location === 'artwork',
    ],
    to: ({ data: { id: artworkId = 0 } }) => lnk('artworksSimilarList', { artworkId }),
  },
  {
    name: 'addAo',
    display: [
      notCustomer,
      ({ location, data: { isDelete } }) => location === 'artist' && isDelete === false,
    ],
    onClick: ({ showPopup }) => () => showPopup({ name: 'ADD_ARTIST_ARTWORK' }),
  },
  {
    name: 'addLot',
    display: [
      notCustomer,
      ({ location }) => location === 'artwork',
      ],
    onClick: ({ showPopup }) => () => showPopup({ name: 'ADD_LOT' }),
  },
  {
    name: 'enableOutliers',
    icon: 'outliers',
    display: [
      ({ location, artworkIsEditable, formData = {}, data: { sourceType } }) =>
        location === 'artwork' && ( artworkIsEditable || sourceType === 'CUSTOMER') && !formData.outliers,
    ],
    onClick: ({ enableOutliers }) => () => enableOutliers(),
  },
  {
    name: 'disableOutliers',
    icon: 'outliers',
    display: [
      ({ location, artworkIsEditable, formData = {}, data: { sourceType } }) =>
      location === 'artwork' && ( artworkIsEditable || sourceType === 'CUSTOMER') && !!formData.outliers,
    ],
    onClick: ({ disableOutliers }) => () => disableOutliers(),
  },
  {
    name: 'runRtv',
    display: [
      notCustomer,
      ({ location, data: { wfAcceptance } }) => location === 'artist' && wfAcceptance === 'ACCEPTED',
      ({ userProfile = { acl: [] } }) => userProfile.isSuperuser || userProfile.acl.includes('rtv'),
    ],
    disabled: ({ data: { rtvCalc } }) => rtvCalc === true,
    title: ({ data: { rtvCalc } }) => rtvCalc,
    translate: false,
    onClick: ({ showPopup }) => () => showPopup({ name: 'RUN_ABV' }),
  },
  {
    name: 'showCollect',
    type: 'link',
    display: [
      ({ location }) => location === 'customer',
    ],
    to: ({ data: { id: userId } }) => lnk('artList', { userId }),
  },
  {
    name: 'showTrack',
    type: 'link',
    display: [
      ({ location }) => location === 'customer',
    ],
    to: ({ data: { id: userId } }) => lnk('considerationList', { userId }),
  },
  {
    name: 'showAlerts',
    type: 'link',
    display: [
      ({ location }) => location === 'customer',
    ],
    to: ({ data: { id: userId } }) => lnk('alertList', { userId }),
  },
  {
    name: 'showPurchases',
    type: 'link',
    display: [
      ({ location }) => location === 'customer',
    ],
    to: ({ data: { id: userId } }) => lnk('purchaseList', { userId }),
  },
  {
    name: 'enable',
    display: [
      ({ location, data: { wfAcceptance } }) => location === 'runner' && wfAcceptance === 'DISABLED',
    ],
    onClick: ({ data: { id }, runnerAction }) => () => runnerAction({ id, action: 'enable' }),
  },
  {
    name: 'disable',
    display: [
      ({ location, data: { wfAcceptance } }) => location === 'runner' && ['ENABLED','STARTERROR'].includes(wfAcceptance),
    ],
    onClick: ({ data: { id }, runnerAction }) => () => runnerAction({ id, action: 'disable' }),
  },
  {
    name: 'start',
    display: [
      ({ location, data: { wfAcceptance } }) => location === 'runner' && !['DISABLED','STARTED'].includes(wfAcceptance),
    ],
    onClick: ({ data: { id }, runnerAction }) => () => runnerAction({ id, action: 'start' }),
  },
  {
    name: 'stop',
    display: [
      ({ location, data: { wfAcceptance } }) => location === 'runner' && wfAcceptance === 'STARTED',
    ],
    onClick: ({ data: { id }, runnerAction }) => () => runnerAction({ id, action: 'stop' }),
  },
  {
    name: 'updateArtItem',
    display: [
      ({ location }) => ['scrapped'].includes(location),
    ],
    onClick: ({ data: { id }, updateArtItemAction }) => () => updateArtItemAction(id),
  },
  {
    name: 'setCorrect',
    display: [
      ({ data: { isCorrect }, location }) => ['scrapped'].includes(location) && !isCorrect,
    ],
    onClick: ({ updateItemAction }) => () => updateItemAction({ isCorrect: true }),
  },
  {
    name: 'setIncorrect',
    display: [
      ({ data: { isCorrect }, location }) => ['scrapped'].includes(location) && isCorrect,
    ],
    onClick: ({ updateItemAction }) => () => updateItemAction({ isCorrect: false }),
  },
  {
    name: 'pushUpdate',
    loading: ({ requestList = [] }) => requestList.includes(pushUpdateAction.type),
    display: [
      ({ data: { sourceType, wfAcceptance }, location }) =>
        location === 'artwork' && ( wfAcceptance === 'ACCEPTED' || sourceType === 'CUSTOMER'),
      ({ userProfile = { acl: [] } }) => userProfile.isSuperuser || userProfile.acl.includes('rtv'),
    ],
    onClick: ({ pushUpdateAction, data: { id } }) => () => pushUpdateAction(id),
  },
  {
    name: 'updateAeAbv',
    icon: 'run_rtv',
    display: [
      ({ data: { wfAcceptance }, location }) => wfAcceptance === 'ACCEPTED' && location === 'artist',
    ],
    onClick: ({ showPopup }) => () => showPopup({ name: 'UPDATE_ABV' }),
  },
  {
    name: 'syncWebAbv',
    icon: 'run_rtv',
    display: [
      ({ data: { wfAcceptance }, location }) => wfAcceptance === 'ACCEPTED' && location === 'artist',
    ],
    onClick: ({ showPopup }) => () => showPopup({ name: 'SYNC_ABV' }),
  },
];
