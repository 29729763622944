import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { submit } from 'redux-form';
import { push } from 'connected-react-router';

import injectSheet from 'lib/sheet';
import { lnk } from 'lib/routes';

import Paper from 'components/Paper';
import Block from 'components/Block';
import Header from 'components/Header';
import Accordion from 'components/Accordion';
import ValuesList from 'components/ValuesList';
import Actions from 'components/Actions';
import HistoryPopup from 'popups/History';
import confirmation from 'popups/Confirmation';
import { remove } from 'domain/const';
import Navigation from 'components/Navigation';
import { userProfile } from 'domain/env';

const RemovePopup = confirmation('remove', remove, 'popupAction.remove.single');

const sheet = {
  ItemPage: {
    display: 'flex',
    flexDirection: 'column',
    padding: [20, 20],
    height: 'calc(100vh - 110px)',
    boxSizing: 'border-box',
  },
  container: {},
  box: {
    padding: [20, 0],
    display: 'flex',
  },
};

const getHeaderTitle = (item) => item.get('name') || item.get('title');

const filterInternalEnt = (item, config) => {
  const cloneConfig = { ...config };
  if (item.id === 31) {
    delete cloneConfig['clientPersonalizationSpace'];
  }
  return cloneConfig;
};

export const createItemPage = ({
  name,
  config,
  Form,
  connections,
  titleFn = getHeaderTitle,
  readOnly = false,
}) => {
  class ItemPage extends React.Component {
    static propTypes = {
      classes: PropTypes.shape({
        ItemPage: PropTypes.string,
        container: PropTypes.string,
        logo: PropTypes.string,
        link: PropTypes.string,
        button: PropTypes.string,
      }).isRequired,
      submit: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
      item: PropTypes.shape({}).isRequired,
    };

    renderItems = () => {
      const { classes, item, ...props } = this.props;
      const _config = filterInternalEnt(item, config);
      return [
        Object.keys(_config).map((key) => (
          <Block key={key}>
            <Accordion title={key}>
              <div className={classes.box}>
                {typeof _config[key] === 'function'
                  ? _config[key](item)
                  : _config[key].map((fields, index) => {
                      return (
                        <ValuesList
                          key={`${key}-${index}`}
                          config={fields}
                          {...props}
                          rowData={item}
                        />
                      );
                    })}
              </div>
            </Accordion>
          </Block>
        )),
      ];
    };

    render() {
      const { classes, item, submit, ...props } = this.props;
      if (!item.id) return null;
      return (
        <div className={classes.ItemPage}>
          <Navigation linkBuilder={(id) => lnk(name, { id })} />
          <HistoryPopup />
          <RemovePopup />
          <Header
            title={titleFn(item)}
            object={item}
            onSave={readOnly || !Form ? null : () => submit(`${name}Form`)}
            onCancel={() => this.props.push(lnk(`${name}List`))}
          />
          {!item.isDelete && <Actions data={item} {...props} />}
          <Paper className={classes.container}>
            {readOnly || !Form || item.locked ? (
              this.renderItems()
            ) : (
              <Form classes={classes} children={this.renderItems()} />
            )}
          </Paper>
        </div>
      );
    }
  }

  return compose(
    injectSheet(sheet),
    connect({
      ...connections, //{item... updateAction...}
      submit,
      userProfile,
      push,
    }),
  )(ItemPage);
};
