import colors from 'theme/Colors';

export default {
  menu: {
    width: 180,
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fafafa',
    padding: [14, 0],
    right: 0,
    position: 'absolute',
  },
  menuItem: {
    height: 32,
    paddingLeft: 24,
    color: colors.text,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: '#fafafa',
    width: '100%',
    textAlign: 'left',
    '&:hover': {
      color: colors.attention,
      backgroundColor: colors.buttonHover,
    },
  },
};
