import {
  createFilterTransfer,
  dateParser,
  dateRange, floatRange,
  intRange,
} from './common';

export const filters = {
  id: {},
  auctionDate: { parser: dateParser, formFieldRenderer: dateRange('auctionDate', 'placeholder.date') },
  baseSoldPrice: { formFieldRenderer: floatRange('baseSoldPrice') },
  cpiNormalBaseSoldPrice: { formFieldRenderer: floatRange('cpiNormalBaseSoldPrice') },
  ppsi: { formFieldRenderer: floatRange('ppsi') },
  area: { formFieldRenderer: intRange('area') },
  mediumsSurfaces: { },
  primarySurfaceRank: { formFieldRenderer: intRange('primarySurfaceRank') },
  mediumsSubstrates: { },
  primarySubstrateRank: { formFieldRenderer: intRange('primarySubstrateRank') },
  methodInfo: {},
};

export const filterTransfer = createFilterTransfer(filters);
export const filterParams = {
  filters,
  filterTransfer,
  columnsTransfer: {},
  defaultList: Object.keys(filters)
};

export default filters;
