let ret = global;
if (process.env.NODE_ENV === 'test') {
  ret = {
    addEventListener: () => null,
    removeEventListener: () => null,
  };
}

const getWindow = () => {
  return ret;
};
export const setRet = (newRet) => {
  ret = newRet;
};
export default getWindow;
