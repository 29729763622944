export const commonFields = [
  'artistId',
  'title',
  'yearCreatedFrom',
  'yearCreatedTo',
  'category',
  'physicalSizeRaw',
  'physicalSizeHeight',
  'physicalSizeDepth',
  'physicalSizeWidth',
  'physicalSizeUnits',
  'subjects',
  'signature',
  'catalogRaisonne',
  'notes',
  'exhibitions',
  'literature',
  'images',
  'defaultArtImage',
  'provenances',
  'outliers',
  'carValue',
  'medium',
  'useCarPrice',
  'hasRepeatSales',
  'hasSimilarImages',
];

export const formValues = {
  '2D': [
    ...commonFields,
  ],
  '2D Edition': [
    ...commonFields,
    'conceptionDateFrom',
    'conceptionDateTo',
    'sheetSizeRaw',
    'sheetSizeHeight',
    'sheetSizeDepth',
    'sheetSizeWidth',
    'sheetSizeUnits',
    'edition',
    'plate',
    'stamps',
    'printer',
    'publisher',
    'authenticityLetter',
    'inscription',
    'numberedNo',
    'numberedOf',
    'editionSize',
    'artistProof',
    'pressProof',
  ],
  '3D': [
    ...commonFields,
    'inscription',
    'studio',
    'foundry',
  ],
  '3D Edition': [
    ...commonFields,
    'conceptionDateFrom',
    'conceptionDateTo',
    'edition',
    'stamps',
    'foundry',
    'authenticityLetter',
    'inscription',
    'studio',
    'numberedNo',
    'numberedOf',
    'editionSize',
    'artistProof',
  ],
};

export const basicInfo = {
  '2D': [
    'artistId',
    'category',
    'title',
    'year',
    'medium',
    'subjects',
    'signature',
    'imageSizeRaw',
    'hasRepeatSales',
    'hasSimilarImages',
  ],
  '2D Edition': [
    'artistId',
    'category',
    'title',
    'year',
    'medium',
    'subjects',
    'signature',
    'imageSizeRaw',
    'sheetSizeRaw',
    'conceptionDate',
    'edition',
    'hasRepeatSales',
    'hasSimilarImages',
  ],
  '3D': [
    'artistId',
    'category',
    'title',
    'year',
    'medium',
    'subjects',
    'signature',
    'physicalSizeRaw',
    'hasRepeatSales',
    'hasSimilarImages',
  ],
  '3D Edition': [
    'artistId',
    'category',
    'title',
    'year',
    'medium',
    'subjects',
    'signature',
    'physicalSizeRaw',
    'conceptionDate',
    'edition',
    'hasRepeatSales',
    'hasSimilarImages',
  ],
};

export const details = {
  '2D': [],
  '2D Edition': [
    [
      'plate',
      'stamps',
      'printer',
    ],
    [
      'publisher',
      'authenticityLetter',
      'inscription',
    ],
  ],
  '3D': [
    [
      'inscription',
      'foundry',
    ],
    [
      'studio',
    ],
  ],
  '3D Edition': [
    [
      'stamps',
      'foundry',
    ],
    [
      'authenticityLetter',
      'inscription',
      'studio',
    ],
  ],
};
