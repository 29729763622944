import table from 'components/PageTable/sheet';

export default {
  ...table,
  tr: {
    ...table.tr,
    height: 94,
  },
  trEdit: {
    ...table.trEdit,
    height: 94,
    lineHeight: 94,
  },
  currency: {
    width: 180,
  },
  soldPrice: { extend: 'number', width: 150 },
  soldPriceUsd: { extend: 'number', width: 150 },
  estimatePrice: { extend: 'number', width: 200 },
  itemUrl: { width: 200 },
  title: { width: 300 },
  artist: { width: 300 },
};
