import React from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import I from 'immutable';
import { FormattedMessage } from 'react-intl';
import { isValidReactComponent } from 'react-immutable-jss-data-table';

import Message from 'components/form/message';
import { styles, flatStyles, groupStyles } from './sheet';
import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';

export const formatGroupLabel = data => {
  return <div style={groupStyles}>
    <span>{data.label}</span>
  </div>;
};

export function filterOption({ label = '' }, term = ''){
  if (!label) return false;
  if (term.includes(' ')){
    return term.toLowerCase().split(' ')
      .reduce((p,n) => label.toString().toLowerCase().includes(n) && p, true);
  }
  return label.toString().toLowerCase().includes(term.toLowerCase());
}

const inputStyle = isHidden => ({
  background: 0,
  border: 0,
  fontSize: 'inherit',
  opacity: isHidden ? 0 : 1,
  outline: 0,
  padding: 0,
  color: 'inherit',
});

const SingleValue = ({ children, innerProps, ...props }) => {
  const title = typeof children === 'string' ? children : undefined;
  return (
    <components.SingleValue {...{ ...props, children, innerProps: { ...innerProps, title } }}/>
  );
};

SingleValue.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.any,
};

const MultiValueLabel = ({ children, innerProps, ...props }) => {
  const title = typeof children === 'string' ? children : undefined;
  return (
    <components.MultiValueLabel {...{ ...props, children, innerProps: { ...innerProps, title } }} />
  );
};

MultiValueLabel.propTypes = {
  children: PropTypes.any,
  innerProps: PropTypes.any,
};

const Input = (props) => {
  return (
    <components.Input
      {...props}
      id={`${props.id}-search`}
      inputStyle={{
        ...inputStyle(props.isHidden),
        fontFamily: DEFAULT_FONTFAMILY,
        color: colors.menu,
        fontSize: '14px' }}
    />
  );
};

Input.propTypes = {
  isHidden: PropTypes.bool,
  id: PropTypes.string,
};

const checkForGroupedList = list => I.List.isList(list) ? list : list.reduce((p,n) => p.concat(n.options),[]);

class PureSelect extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    parseValue: PropTypes.func,
    parseLabel: PropTypes.func,
    filterOptions: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    grouped: PropTypes.bool,
    isClearable: PropTypes.bool,
    styles: PropTypes.func.isRequired,
    list: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
      name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    hasWarning: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    MenuList: isValidReactComponent,
    MultiValueContainer: isValidReactComponent,
    Placeholder: isValidReactComponent,
    ValueContainer: isValidReactComponent,
  }

  static defaultProps = {
    saveValues: false,
    parseValue: v => v && v.get ? parseInt(v.get('id'), 10) : '',
    parseLabel: v => v && v.get ? v.get('title') : '',
    isMulti: false,
    isClearable: false,
    grouped: false,
    styles,
    list: new I.List(),
    filterOption,
    noOptionsMessage: () => null,
    hasWarning: false,
    MenuList: components.MenuList,
    MultiValueContainer: components.MultiValueContainer,
    Placeholder: components.Placeholder,
    ValueContainer: components.ValueContainer,
  }

  getValue(){
    const { input: { value }, parseValue, list, isMulti, grouped } = this.props;
    const pV = grouped ? ({ value }) => (value) : parseValue;
    if (!value) return isMulti ? [] : '';
    if (typeof value !== 'string' && !!value.reduce){
      return value.map(key => checkForGroupedList(list).find(v => pV(v).toString() === key.toString()));
    }
    return checkForGroupedList(list).find(v => pV(v).toString() === value.toString());
  }

  toFormValue = value => {
    const { parseValue, isMulti, grouped } = this.props;
    const parser = grouped ? v => v.value : parseValue;
    if (value) {
      return isMulti ? value.map(parser) : parser(value);
    }
    return isMulti ? [] : null;
  }

  render() {
    const {
      input: { name, onFocus, onChange }, grouped, id, placeholder,
      MenuList, MultiValueContainer, Placeholder, ValueContainer,
      closeMenuOnSelect,
      meta, list, parseValue, parseLabel, styles, ...props
    } = this.props;
    const placeHolder = <FormattedMessage id={placeholder || `placeholder.${name}`}/>;
    const dependsOngroupedProps = grouped ?
      { formatGroupLabel } :
      {
        getOptionValue: parseValue,
        getOptionLabel: parseLabel,
      };
    return <div data-name={`autocomplete--${name}`} style={{ width: '100%' }}>
      <ReactSelect
        name={name}
        inputId={id}
        id={`${id || ''}-container`}
        onChange={(v) => onChange(this.toFormValue(v))}
        value={this.getValue()}
        options={list}
        styles={styles({ ...this.props, grouped })}
        onFocus={() => onFocus && onFocus()}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{ Input, MultiValueContainer, MultiValueLabel, SingleValue, MenuList, Placeholder, ValueContainer }}
        placeholder={placeHolder}
        {...dependsOngroupedProps}
        {...props}
      />
      <Message  meta={meta} name={name} />
    </div>;
  }
}

export const PureFlatSelect = ({ ...props }) => <ReactSelect
  styles={flatStyles(props)}
  isSearchable={false}
  {...props}
/>;

export class AsyncMultiAutocomplete extends React.PureComponent {
  static propTypes = {
    parseValue: PropTypes.func,
    getValues: PropTypes.func,
    isMulti: PropTypes.bool,
    storage: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    list: PropTypes.oneOfType([ PropTypes.array, PropTypes.instanceOf(I.List)]).isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.array ]),
      name: PropTypes.string,
    }).isRequired,
    onInputChange: PropTypes.func,
  }

  static defaultProps = {
    parseValue: v => v && v.get ? parseInt(v.get('id'), 10) : '',
    isMulti: false,
  }

  getArrayValue = () => {
    const { input: { value }, isMulti } = this.props;
    return isMulti ? value : [ value ].filter(v => !!v);
  }

  checkValues = () => {
    const { parseValue, getValues, storage } = this.props;
    const idList = this.getArrayValue().filter(v => !storage.find(i => parseValue(i).toString() === v.toString()));
    if (idList && getValues) getValues(idList);
  }

  componentDidUpdate = ({ input: { value } }) => {
    if (this.props.isMulti) {
      if (!!value && !!this.props.input.value && value.length !== this.props.input.value.length) this.checkValues();
    } else {
      if (value !== this.props.input.value) this.checkValues();
    }
  }

  componentDidMount = () => {
    this.checkValues();
    if (this.props.list.size === 0) this.props.onInputChange();
  }

  render(){
    const { list, storage, ...props } = this.props;
    const convertedInput = this.getArrayValue().map(v => v);
    const selectedOptions = storage.filter(v => convertedInput.includes(props.parseValue(v)));
    let options = [];
    if (props.grouped) {
      options = I.fromJS(list).update(0, v => v.update('options', v => v.push(...selectedOptions))).toJS();
    } else {
      options = selectedOptions.concat(list);
    }
    return <PureSelect hideSelectedOptions filterOption={() => true} list={options} {...props} />;
  }
}

export default PureSelect;
export { styles, flatStyles, groupStyles };
