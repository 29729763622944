import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';

import Select, { flatStyles } from 'components/form/element/Select';
const ITEMS = I.fromJS([10, 20, 50, 100, 500].map(v => ({ id: v, title: v })));

export default function ItemsPerPage({ limit, defaultLimit = 10, onChange, classes }) {
  const input = {
    name: 'limit',
    value: limit || defaultLimit,
    onChange,
  };
  const meta = {};
  return (
    <label
      className={classes.ItemsPerPage}
    >
      <span
        children="Show"
        className={classes.ItemsPerPageLabel}
      />
      <Select
        className={classes.ItemsPerPageSelect}
        list={ITEMS}
        isClearable={false}
        isSearchable={false}
        input={input}
        meta={meta}
        styles={flatStyles}
      />
    </label>
  );
}

ItemsPerPage.propTypes = {
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.shape({
    ItemsPerPage: PropTypes.string,
    ItemsPerPageLabel: PropTypes.string,
    ItemsPerPageSelect: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};
