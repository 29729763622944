import {
  commonDictionaryFilters,
  CountryAutoComplete,
  createFilterTransfer,
  dateParser,
  dateRange,
  intRange,
  stringFilter,
} from './common';
import { Field } from "redux-form";
import Select from "../components/form/element/Select";
import I from "immutable";
import React from "react";
import Dropdown from "fields/Dropdown";
import { sortEnterprises } from "fields/customer";

const Enterprise = <Dropdown
  isClearable={true}
  apiKey="getEnterpriseList"
  parseLabel={ data => data.get('enterpriseName')}
  parseValue={ data => data.get('id') }
  name="enterprise"
  placeholder="Enterprise"
  sort={sortEnterprises}
/>;

const Configuration = <Field
  name="configuration"
  component={Select}
  parseValue={v => v.get('id')}
  isClearable={true}
  list={I.fromJS([
    { id: 'Enterprise', title: 'Enterprise' },
    { id: 'Chargeable', title: 'Chargeable' },
    { id: 'Internal', title: 'Internal' },
    { id: 'Custom', title: 'Custom' },
  ])}
/>;

const UserStatus = <Field
  name="userStatus"
  component={Select}
  parseValue={v => v.get('id')}
  isClearable={true}
  list={I.fromJS([
    { id: 'active', title: 'Active' },
    { id: 'deactivated', title: 'Deactivated' },
  ])}
/>;

export const defaultList = [ 'id', 'firstName', 'lastName', 'country' ];

export const filters = {
  id: {},
  artwork: {},
  firstName: stringFilter('Customer'),
  lastName: stringFilter('Customer'),
  email: stringFilter('Customer'),
  configuration: { formFieldRenderer: Configuration },
  country: {
    component: CountryAutoComplete,
    fieldProps: { parseValue: v => v && v.get ? v.get('title') : '' },
  },
  lastLogin: { parser: dateParser, formFieldRenderer: dateRange('lastLogin', 'lastLogin') },
  artsCount: { formFieldRenderer: intRange('artsCount') },
  considerationsCount: { formFieldRenderer: intRange('considerationsCount') },
  alertsCount: { formFieldRenderer: intRange('alertsCount') },
  sharedWithUser: { formFieldRenderer: intRange('sharedWithUser') },
  sharedByUser: { formFieldRenderer: intRange('sharedByUser') },
  totalAbv: { formFieldRenderer: intRange('totalAbv') },
  userStatus: { formFieldRenderer: UserStatus },
  subscription: {},
  enterprise: { formFieldRenderer: Enterprise },
  ...commonDictionaryFilters,
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
