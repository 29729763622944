import formStyles from 'components/form/sheet';

export default {
  ArtistPage: {
    display: 'flex',
    flexDirection: 'column',
    padding: [20,20],
    height: '100%',
  },
  container: {
  },
  box: {
    padding: [20,0],
    display: 'flex',
  },
  auctions: {
    extend: 'box',
    padding: [14,0],
    flexWrap: 'wrap',
    width: 1260,
  },
  biography: {
    resize: 'none',
    height: '69px!important',
    padding: [8, 10],
  },
  notes: {
    resize: 'none',
    height: '86px!important',
    padding: [8, 10],
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  ...formStyles,
};
