import colors from 'theme/Colors';

export default {
  newPrice: {
    paddingRight: 24,
    color: colors.links,
    display: 'inline-block',
    fontSize: 13,
    cursor: 'pointer',
    border: 0,
    outline: 0,
    background: {
      repeat: 'no-repeat',
      position: 'right center',
      image: `url(${require('./ic-warning.svg')})`,
      width: 16,
      height: 16,
    },
  },
};
