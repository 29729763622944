import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage as FM } from 'react-intl';
import { reset } from 'redux-form';
import { changeStatusAction } from 'domain/lot';
import { lot } from 'domain/lotList';
import { connect } from 'cpcs-reconnect';

import { compose } from 'redux';
import cx from 'classnames';

import { remove, lock } from 'domain/const';
import { showPopup, showTooltip, hideTooltip } from 'domain/ui';
import baseActions from 'components/Actions/actions';
import sheet from './menuSheet';
import injectSheet from 'lib/sheet';
import { Button } from 'components/Actions';
import { getLocationName as location } from 'domain/router';

const Divider = ({ classes }) => <div className={classes.divider}/>;
Divider.propTypes = { classes: PropTypes.shape({ divider: PropTypes.string.isRequired }) };

const actions = [
  ...baseActions.filter(({ name }) => [ 'lock', 'unlock', 'accept', 'display', 'validate', 'draft', 'ignore' ].includes(name)),
  {
    name: 'delete',
    display: [
      ({ data: { isDelete } }) => !isDelete,
    ],
    onClick: ({ data: { id }, showPopup }) => () => showPopup({ name: 'REMOVE', subject: id, id: 'lot' }),
  },
  {
    name: 'guarantee',
    display: [ ({ data: { wfAcceptance, locked = false }}) => wfAcceptance === 'ACCEPTED' ? !locked : true ],
    onClick: ({ lockGuarantee }) => () => lockGuarantee()
  },
  {
    name: 'privateSale',
    display: [
      ({ data }) => {
      const { auctionId, auctionDate, lotUrls } = data;
      return !!auctionId && !!auctionDate && !!lotUrls?.get(0)?.get('itemUrl') && !data.privateSale
      }
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ privateSale: true }),
  },
  {
    name: 'notPrivateSale',
    display: [
      ({ data: { privateSale } }) => privateSale,
    ],
    onClick: ({ changeStatusAction }) => () => changeStatusAction({ privateSale: false }),
  },
  { name: 'divider', Render: Divider },
  { name: 'save', onClick: ({ changeStatusAction }) => () => changeStatusAction({}) },
  { name: 'cancel', onClick: ({ reset }) => () => reset('lotForm') },
];

class Menu extends React.Component {

  state = {
    open: false,
  }

  node = null;

  static propTypes = {
    classes: PropTypes.shape({
    }).isRequired,
    showTooltip: PropTypes.func.isRequired,
    hideTooltip: PropTypes.func.isRequired,
    lot: PropTypes.shape({
      wfAcceptance: PropTypes.string.isRequired,
    }),
    isEdit: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.node && !this.node.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  renderMenu = () => {
    const { classes, lot: data, isEdit, ...props } = this.props;
    const actionProps = { data, ...props };
    return (
      <div className={classes.LotMenu}>
        { actions
          .filter(v => isEdit ? true : ![ 'save', 'cancel', 'divider' ].includes(v.name) )
          .filter(({ display = [() => true] }) => display.reduce((acc, fn) => acc && fn(actionProps), true))
          .map(({ name, onClick, Render, title = () => null, disabled = () => false }) => Render ? <Render key={name} {...this.props}/> : <Button
            className={cx(classes.btn, classes[name])}
            key={name}
            title={title(actionProps)}
            disabled={disabled(actionProps)}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ open: false }, () => {
                onClick(actionProps)();
              });
            }}><FM id={name}/></Button>) }
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div
        className={cx(classes.dots, { 'with-data-title': !open })}
        onClick={() => this.setState({ open: true })}
        ref={node => this.node = node}
        data-title="more options"
        onMouseOut={() => this.props.hideTooltip()}
      >
        { open && this.renderMenu() }
      </div>
    );
  }
}

export default compose(
  connect({
    lot,
    changeStatusAction,
    reset,
    remove,
    showPopup,
    lock,
    showTooltip,
    hideTooltip,
    location,
  }),
  injectSheet(sheet),
)(Menu);
