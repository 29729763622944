import { fork, all, takeEvery, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';

import * as Router from 'domain/router';

export function* pushPath({ payload }) {
  let params;
  if (typeof payload === 'string') {
    params = {
      pathname: payload,
    };
  } else if (payload.pathname) {
    params = {
      ...payload,
    };
  } else throw new Error('Incorrect payload');
  const { pathname, search } = yield select(Router.getLocation);
  yield put(
    push({
      ...params,
      state: { prevPath: `${pathname}${search}` },
    }),
  );
}

function* updateQuery({ payload }) {
  const location = yield select(Router.getLocation);
  const query = yield select(Router.getQuery);
  delete query.page;
  yield put(
    push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...payload }, { arrayFormat: 'bracket' }),
    }),
  );
}

function*  watchPushPath() {
  yield takeEvery(Router.pushPath.type, pushPath);
}

function* watchUpdateQuery() {
  yield takeEvery(Router.updateQuery.type, updateQuery);
}

export default function* router() {
  yield all([
    fork(watchPushPath),
    fork(watchUpdateQuery),
  ]);
}
