import React from "react";
import { callApiAction } from "domain/api";
import IndexTableBody from "components/IndexTable";
import Loader from "components/Loader";
import classes from "./style.module.css";
import SubColumns from "components/IndexTable/SubColumns";

const convertText = (text) => {
  if (text.includes(" ")) {
    return text;
  }

  const words = text.split(/(?=[A-Z])/);
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const convertedText = capitalizedWords.join(" ");

  return convertedText;
};

function PublishedSectionsTable({ table, sectionName }) {
  const [dataTable, setDataTable] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [rowHeader, setRowHeader] = React.useState([]);
  const [loadingRow, setLoadingRow] = React.useState(true);
  const [dataError, setDataError] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    setDataTable(null);
    const fetchData = async () => {
      let fetchPromise;
      const nameSplit = table.split('.');
      const fetchTableData = await callApiAction(`getTable${sectionName}`);
      if (sectionName === 'X004'){
        const liq_ind = nameSplit[0]
        const year = nameSplit[1];
        fetchPromise = fetchTableData({
          urlParams: { liq_ind: liq_ind, year: year || 'ALL'},
        });
      }else {
        if (sectionName !== "X002") {
          const chart_id = table;
          fetchPromise = fetchTableData({ query: { chart_id } });
        } else {
          fetchPromise = fetchTableData();
        }
      }

      fetchPromise
        .then((data) => {
          if (data.err) {
            console.log(data.err);
            setDataError(`something went wrong: ${table}`);
          } else {
            setDataTable(data.data);
          }
        })
        .catch((error) => {
          console.log(
            `error occured in section ${sectionName} table ${table} : ` + error
          );
        })
        .finally(() => setLoading(false));
    };
    fetchData();
  }, [sectionName, table]);

  React.useEffect(() => {
    setLoadingRow(true);
    if (dataTable && dataTable.data.length > 0) {
      const dataObject = dataTable.data[0];
      const getKeysName = Object.keys(dataObject);
      getKeysName[0] = table;
      const convertedTexts = getKeysName.map((text) => convertText(text));
      setRowHeader([...convertedTexts]);
    }
    setLoadingRow(false);
  }, [dataTable, table]);

  if (dataError) return <p className={classes.error}>{dataError}</p>;

  return (
    <div>
      {!!loading && (
        <div className={classes.loading}>
          <Loader />
        </div>
      )}
      {!loadingRow && !loading && (
        <>
          {sectionName!=='X004' ? <IndexTableBody rowHeader={rowHeader} data={dataTable.data} /> : <SubColumns data={dataTable}/>}
        </>
      )}
    </div>
  );
}

export default PublishedSectionsTable;
