import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/enterprise';
import { filterParams } from 'filters/enterprise';

import { tableParams } from 'fields/enterprise';

import styles from 'components/PageTable/sheet';
import { createForm } from "pages/common/Form";

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const tableSheet = {
  ...styles,
  tr: {
    ...styles.tr,
    height: 94,
  },
};

const Table = createTable({
  tableName: 'enterpriseList',
  tableParams,
  updateItemAction,
  sheet: tableSheet,
  connections: {
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
    stats: selectors.stats,
  },
});

export const Form = createForm({
  form: 'addEnterprise',
  action: actions.createItemAction,
  formFields: tableParams.columns.select('enterpriseName', 'active', 'limitedAbv', 'includedAbv', 'expirationDate'),
  initialValues: { active: true, limitedAbv: false },
});

export default createListPage({
  name: 'Enterprise',
  pagination,
  filterParams,
  Table,
  softDelete: false,
  Form,
});
