import { connect } from 'cpcs-reconnect';

import {
  commonDictionaryFields,
  connectAcceptance,
  createIdLink,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import { dict } from 'lib/serialize';
import { dictionaryList } from 'domain/dictionary';
import rules from 'components/form/validation';
import Select from 'components/form/element/Select';

const AuctionsAutoComplete = connect({ list: dictionaryList('auctions') })(Select);

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('sale'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  title: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  auction: {
    inlineEdit: true,
    component: AuctionsAutoComplete,
    getValue: dict(),
    fieldProps: { validate: [ rules.required ] },
  },
  saleNumber: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, ['title','auction','saleNumber']),
);

export const defaultColumns = [
  'title',
  'auction',
  'saleNumber',
  'createdDate',
  'updatedDate',
];

export const tableParams = { columns, defaultColumns };

export default columns;
