import fieldsArrSheet from 'fields/FieldsArrSheet';
import addFieldImg from '../FieldsArrSheet/images/AddField.svg';
import removeFieldImg from '../FieldsArrSheet/images/removeField.svg';

export default {
  LotLinks: {
    width: '100%',
  },
  ...fieldsArrSheet,
  item: {
    width: 204,
    position: 'relative',
    marginTop: 12,
    '&:nth-of-type(1)': {
      margin: 0,
    },
  },
  addFieldWrapper: {
    position: 'absolute',
    left: '100%',
    top: 0,
    paddingLeft: 32,
    display: 'flex',
  },
  btn: {
    width: 32,
    height: 32,
    padding: 0,
    background: {
      size: [20, 20],
      position: 'center center',
      repeat: 'no-repeat',
    },
    border: 'none',
    cursor: 'pointer',
  },
  addFieldBtn: {
    extend: 'btn',
    backgroundImage: `url("${addFieldImg}")`,
  },
  removeFieldBtn: {
    extend: 'btn',
    backgroundImage: `url("${removeFieldImg}")`,
  },
};
