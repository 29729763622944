import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/runner';

import { columns } from 'fields/runner';

const { item } = selectors;

export const editableFields = [
  'domain',
  'spiderName',
  'type',
  'wfAcceptance',
];

export default createItemPage({
  name: 'runner',
  connections: {
    item,
    runnerAction: actions.runnerAction,
  },
  config: {
    basicInfo: [
      columns.all().slice(2),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
  },
  readOnly: true,
  titleFn: item => item.get('spiderName'),
});
