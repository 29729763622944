import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { isEmpty } from 'lib/helpers';
import Radio from 'components/form/fields/Radio';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';


let FieldComponent = ({ input: { onChange, value }, classes }) => {
  if (isEmpty(value)) return null;
  return <div className={classes.Outliers}>
    <label className={classes.label}>
      <Radio
        value="HIGH"
        checked={value === 'HIGH'}
        onChange={onChange}
        className={classes.radio}
      />
      <span className={classes.labelText} children="HIGH" />
    </label>
    <label className={classes.label}>
      <Radio
        value="LOW"
        checked={value === 'LOW'}
        onChange={onChange}
        className={classes.radio}
      />
      <span className={classes.labelText} children="LOW" />
    </label>
  </div>;
};

FieldComponent.propTypes = {
  classes: PropTypes.shape({
    Outliers: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radio: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

FieldComponent = injectSheet(styles)(FieldComponent);

export default class Outliers extends React.PureComponent {
  render() {
    return (
      <Field name="outliers" component={FieldComponent} />
    );
  }
}
