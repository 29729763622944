import colors from 'theme/Colors';

export default {
  ghost: {
    fontSize: '12px',
    border: '1px solid transparent',
    fontFamily: '"Facto", Arial, Helvetica, sans-serif',
    boxSizing: 'border-box',
    height: 32,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    minWidth: 110,
    textTransform: 'uppercase',
    color: colors.labelLink,
    letterSpacing: '1px',
    borderRadius: 4.5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.buttonHover,
    },
    '&:disabled': {
      opacity: 0.5,
    },
    backgroundColor: 'transparent',
    marginLeft: 10,
  },
  primary: {
    border: `1px solid ${colors.attention}`,
    extend: 'ghost',
    backgroundColor: colors.attention,
    color: colors.bg,
    '&:hover': {
      backgroundColor: colors.attention,
      opacity: 0.9,
    },
  },
  secondary: {
    extend: 'ghost',
    color: colors.attention,
    backgroundColor: colors.popupBg,
    border: `1px solid ${colors.attention}`,
    '&:hover': {
      opacity: 0.9,
    },
  },
  ghostActive: {
    extend: 'ghost',
    color: colors.attention,
  },
  chartPeriod: {
    border: `1px solid ${colors.labelLink}`,
    extend: 'ghost',
    backgroundColor: colors.labelLink,
    color: colors.bg,
    '&:hover': {
      backgroundColor: colors.attention,
      opacity: 0.9,
    },
  },
};
