import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import { selectors } from 'domain/cluster';
import Pagination from 'components/pagination';

import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import columns, { defaultColumns } from 'fields/cluster';

import { FormattedMessage as FM } from 'react-intl';
import getPageTable from 'components/PageTable';
import {filterParams} from "../../filters/cluster";
import Filter from "components/Filter";

const { list: items, loading, pagination } = selectors;

const Table = getPageTable({
  columns,
  tableName: 'artworksByCluster',
  defaultColumns,
  readOnly: true,
})

const ArtworkListPage = ({ pagination, ...props }) => {
  const { classes } = props;

  return (
    <div className={classes.ListPage}>
      <div className={classes.header}>
        <div className={classes.pageTitle}>Arts</div>
        <div className={classes.subHeader}>
          <Pagination pagination={pagination} />
        </div>
      </div>
      <Filter context="artwork" {...filterParams}/>
      <div className={classes.tableContainer}>
        <Table
          {...props}
          {...filterParams}
          RowEmpty={() => <div className={classes.rowEmpty}><FM id="noIntersections"/></div>}
        />
      </div>
    </div>
  );
}

ArtworkListPage.propTypes = {
  classes: PropTypes.object.isRequired,
  pagination: PropTypes.instanceOf(I.Record),
};

export default compose(
  injectSheet(styles),
  connect({
    items,
    loading,
    pagination,
  }),
)(ArtworkListPage);
