import React, { useEffect, useRef, useState } from 'react';
import Api from 'domain/api';
import { connect } from 'cpcs-reconnect';
import { token } from 'domain/env';
import Select from 'components/form/element/Select';
import { Field } from "redux-form";
import { fromJS } from "immutable";
import LockDetector from "fields/LockDetector";

const Dropdown = ({ apiKey, parseValue = ({ id }) => id, parseLabel = ({ title }) => title, token, sort = v => v, ...props }) => {

  const [ list, setList ] = useState([]);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (apiKey && token && Api.hasOwnProperty(apiKey)) {
      Api[apiKey]({ token })
        .then(({ data: { list }}) => {
          if (mounted.current) setList(list);
        })
    }
    return () => {
      mounted.current = false;
    }
  },[apiKey, token, setList])

  return !!list.length ? <Field
    component={LockDetector(Select)}
    parseValue={parseValue}
    parseLabel={parseLabel}
    list={fromJS(sort(list))}
    { ...props }
  /> : '-';
}

export default connect({ token })(Dropdown);
