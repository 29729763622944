import { createItemPage } from 'pages/common/ItemPage';

import { selectors } from 'domain/session';

import { columns } from 'fields/session';

const { item } = selectors;

export const editableFields = [];

export default createItemPage({
  name: 'session',
  titleFn: ({ id }) => `Spider Session #${id}`,
  connections: {
    item,
  },
  config: {
    basicInfo: [
      columns.select('jobId','spiderName','createdCountLots','updatedCountLots','totalCountLots'),
      [],
      columns.select('id','pendingDate','openDate','closeDate','wfAcceptance'),
    ],
  },
});
