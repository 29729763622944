import { createFilterTransfer } from './common';

export const defaultList = [ 'title', 'level' ];

export const filters = {
  title: {},
  level: {},
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
