import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Field, getFormValues } from 'redux-form';
import TextArea from 'components/form/element/TextArea';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

class Exhibitions extends React.PureComponent {
  static propTypes = {
    fields: PropTypes.shape({
      map: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
      push: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      Exhibitions: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
      itemFirst: PropTypes.string.isRequired,
      row: PropTypes.string.isRequired,
      addFieldWrapper: PropTypes.string.isRequired,
      addFieldBtn: PropTypes.string.isRequired,
      removeFieldBtn: PropTypes.string.isRequired,
    }).isRequired,
    fieldProps: PropTypes.shape({
      popupForm: PropTypes.bool,
      rowData: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    }).isRequired,
    getFormValues: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      form: PropTypes.string,
    }),
  };

  render() {
    const { fields, classes, getFormValues, meta: { form }, fieldProps: { rowData: { id }, popupForm, title } } = this.props;
    const values = getFormValues(form);
    return (
      <div className={classes.Exhibitions}>
        {
          fields.map((name, key) => (
            <div key={key} className={cx(classes.item, { popupForm, [classes.itemFirst]: key === 0 })} data-key={key} data-value={values[title][key]}>
              <div className={cx(classes.row, { lastItem: fields.length === (key + 1) })}>
                <div className={classes.addFieldWrapper}>
                  {
                    (fields.length === (key + 1) && fields.length < 10) &&
                      <button
                        type="button"
                        disabled={!(values[title] && values[title][key] && values[title][key].name)}
                        onClick={() => fields.push({ artId: id })}
                        className={classes.addFieldBtn}
                      />
                  }
                  {
                    (fields.length > 1) &&
                      <button
                        type="button"
                        onClick={() => fields.remove(key)}
                        className={classes.removeFieldBtn}
                      />
                  }
                </div>
                <Field
                  name={`${name}.name`}
                  type="text"
                  component={TextArea}
                  modifier={popupForm ? 'popupForm' : ''}
                  placeholder={`placeholder.${title}.name`}
                  messageKey={`${title}.name`}
                />
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

Exhibitions = compose(
  injectSheet(sheet),
  connect(state => ({
    getFormValues: (form) => getFormValues(form)(state),
  })),
)(Exhibitions);
export default Exhibitions;
