import { takeLatest, call, put } from 'redux-saga/effects';

import Api from 'domain/api';
import { forgotPassword } from 'domain/env';
import { links as r } from 'lib/routes';

const domain = window.location.origin;
const url = `${domain}${r.resetPassword}`;

function* tryResetPasswordRequest({ payload }) {
  try {
    yield call(Api.forgotPassword, { data: { ...payload.data, 'restore_url': url } });
    payload.resolve();
    yield put({ type: forgotPassword.success });
  } catch (err) {
    payload.resolve('wrong_email');
  }
}

export default function* navigator() {
  yield takeLatest(forgotPassword.type, tryResetPasswordRequest);
}
