import moment from 'moment';
import { validURL } from 'components/Link';

/**
 * (value, values, componentProps, name) => (undefined || { id: 'errorId' });
**/

const isYearValid = (value) => {
  return /^[1-9]\d{0,3}$/.test(value)
    && moment(value, 'YYYY').isBefore(moment());
};

const isEmailValid = (v) => /^([a-zA-Z0-9_.\-\\+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(v);

const isNotEmpty = (v = '') => typeof v === 'number' ? true : [].concat(!!v).filter(v => !!v).length > 0;

const required = value => {
  switch (typeof value) {
    case "object":
      if (!value) return { id: 'required' }; // null
      return value.length === 0 ? { id: 'required' } : undefined; // array
    case "number":
      return undefined;
    default:
      return isNotEmpty(value) ? undefined : { id: 'required' };
  }
}

const artworkYearCreated = (value, { artistId, category }, { artistId: dict }) => {
  if (value && dict && dict.has && dict.has(parseInt(artistId, 10))) {
    let { birthDate, deathDate } = dict.get(parseInt(artistId, 10));
    if (!birthDate && !deathDate) return undefined;
    const deathDateCalculated = [3,4].includes(category) ? moment().format('YYYY') * 1  : deathDate || moment().format('YYYY') * 1;
    return (birthDate * 1 + 10) <= value && deathDateCalculated >= value ? undefined : { id: 'lifeRange', birthDate: birthDate + 10 , deathDate: deathDateCalculated };
  }
  return undefined;
};

const artworkYearCreatedFrom = (v, { yearCreatedTo }) => {
  return (!!v && !!yearCreatedTo && v*1 > yearCreatedTo*1 ? { id: 'invalidDeathDate' } : undefined );
};

const lotCurrency = (
  value,
  { soldPrice, estimatePriceStart, estimatePriceEnd },
  ) => ( soldPrice || estimatePriceEnd || estimatePriceStart ) ? required(value) : undefined;

const provenanceTitle = (value, values, ...args) => {
  const index = parseInt(args[1].match(/[\d]/)[0], 10);
  if (!values.provenances || !values.provenances[index]) return;
  const data = values.provenances[index];
  return Object.keys(data)
    .filter(v => !['art','owner','id','uuid'].includes(v))
    .map(key => data[key])
    .filter(v => !!v)
    .length > 0 && !value ? { id: 'required' } : undefined;
};

const provenanceYearTo = (value, values, ...args) => {
  const index = parseInt(args[1].match(/[\d]/)[0], 10);
  if (!value || !values.provenances || !values.provenances[index]) return;
  const { yearFrom } = values.provenances[index];
  if (!yearFrom) return;
  return parseInt(value, 10) >= parseInt(yearFrom, 10) ? undefined : { id: 'provenanceYearTo', yearFrom };
};

const bannerFields = [
  'banner970x120',
  'banner720x120',
  'banner343x120',
  'bannerUrl',
  'bannerDisplayDateFrom',
  'bannerDisplayDateTo'
];

const bannerRequired = (value, allValues = {}) => {
  for (let key of bannerFields) {
    if (!!allValues[key]) return required(value)
  }
  return undefined
}

const bannerDisplayDateTo = (value, { bannerDisplayDateFrom: from }) =>
  !!value && !!from && moment(from).isAfter(moment(value)) ? { id: 'invalidBannerDate' } : undefined

const auctionDate = (value, { auctionStartDate: Start }) =>
  !!value && !!Start && moment(Start).isAfter(moment(value)) ? { id: 'invalidEndDate' } : undefined

export const rules = {
  required,
  positive: value => parseInt(value || '0', 10) >= 0 ? undefined : { id: 'positiveNumber' },
  bannerRequired,
  bannerDisplayDateTo,
  auctionDate,
  passwordLength: value => (value && value.length < 8 ? { id: 'passwordLength' } : undefined ),
  deathDate: (v, { birthDate }) => (!!v && !!birthDate && v*1 < birthDate*1 ? { id: 'invalidDeathDate' } : undefined ),
  year: v => (v && !isYearValid(v) ? { id: 'invalidYear' } : undefined  ),
  artworkYearCreated,
  artworkYearCreatedFrom,
  lotCurrency,
  validURL: v => (!!v && !validURL(v)) ? { id: 'invalidUrl' } : undefined,
  email: (v = '') => !v || isEmailValid(v) ? undefined : { id: 'wrongEmail' },
  provenanceTitle,
  provenanceYearTo,
};

export default rules;
