import {
    connectAcceptance,
    toFloat,
    toPrice
  } from './common';
  
  import { defineColumns } from 'components/PageTable/defineColumns';
  import { dateTimeToString } from '../lib/serialize';
  const config = {
    id: {
      title: 'Art Asset ID',
      sortable: true,
      className: 'id',
      parser: ({ value }) => value === undefined ? '' : value,
    },
    enterprise: { sortable: true, className: 'td200' },
    userName: { sortable: true, className: 'td200' },
    portfolioId: {
      sortable: true,
      className: 'id',
      parser: ({ value }) => value === undefined ? '' : value
    },
    portfolioTitle: { sortable: true, className: 'td200' },
    artworkTitle: { sortable: true, className: 'td200' },
    artistName: { sortable: true, className: 'td200' },
    acquisitionDate: {
      sortable: true,
      getValue: dateTimeToString('MM/DD/YYYY', '−'),
      parser: dateTimeToString(),
    },
    acquisitionValue: { sortable: true, className: 'price', getValue: toPrice },
    projectedValue: { sortable: true, className: 'price', getValue: toPrice },
    ltmGain: { sortable: true, className: 'price', getValue: toPrice },
    ltmGainPercentage: { sortable: true, className: 'price', getValue: toFloat },
    totalGain: { sortable: true, className: 'price', getValue: toPrice },
    totalGainPercentage: { sortable: true, className: 'price', getValue: toFloat },
    status: { sortable: true, className: 'td200' },
    dateCreated: {
      sortable: true,
      getValue: dateTimeToString('MM/DD/YYYY', '−'),
      parser: dateTimeToString(),
    },
    dateUpdated: {
      sortable: true,
      getValue: dateTimeToString('MM/DD/YYYY', '−'),
      parser: dateTimeToString(),
    },
  };
  
  export const columns = defineColumns(
    connectAcceptance(config, []),
  );
  
  export const defaultColumns = Object.keys(config).slice(1);
  
  export const tableParams = { columns, defaultColumns, readOnly: true };
  
  export default columns;
  