import { defineColumns } from 'components/PageTable/defineColumns';
import rules from 'components/form/validation';
import { dateToString } from 'lib/serialize';

export const config = {
  id: {
    sortable: false,
    className: 'id',
    // getValue: ArtworkLink,
  },
  junkSymbol: {
    sortable: false,
    hiddenForTable: false,
    inlineEdit: true,
    className: 'junkSymbol',
    required: true,
    fieldProps: { validate: [rules.required] },
    // getValue: ({ rowData }) => console.log(rowData.toJS()) || 'foo',
  },
  correctLetter: {
    sortable: false,
    hiddenForTable: false,
    inlineEdit: true,
    className: 'correctLetter',
    required: true,
    fieldProps: { validate: [rules.required] },
  },
  createdDate: {
    sortable: false,
    hiddenForTable: false,
    inlineEdit: false,
    className: 'createdDate',
    getValue: dateToString('MM/DD/YYYY h:mm a', '−'),
  },
  updatedDate: {
    sortable: false,
    hiddenForTable: false,
    inlineEdit: false,
    className: 'updatedDate',
    getValue: dateToString('MM/DD/YYYY h:mm a', '−'),
  },
  // hiddenForTable: false
  // inlineEdit: true
  // fieldProps: { validate: [rules.required] },
  // formFieldRenderer: () => <Edition/>
  // inLineFieldRenderer: ArtworkImageInline
};

export const columns = defineColumns(config);

export const defaultColumns = [
  'junkSymbol',
  'correctLetter',
];


export const tableParams = { columns, defaultColumns };

export default columns;
