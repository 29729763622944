import React from 'react';
import { connectAcceptance, createIdLink, toFloat } from './common';
import Image from 'components/SmartImage';
import PropTypes from 'prop-types';

import { defineColumns } from 'components/PageTable/defineColumns';
import { dateToString } from '../lib/serialize';

const clusterImage = ({ value }) => <Image originalLink src={value} params={{ width: 80, height: 80 }} />;
clusterImage.propTypes = {
  value: PropTypes.string,
};

const displayZero = ({ value }) => value > -1 ? value : '−';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('artwork', 'artworkId'),
    fieldPath: ['artId'],
    parser: ({ value }) => value === undefined ? '' : value,
  },
  url: {
    sortable: true,
    getValue: clusterImage,
  },
  cluster: { sortable: true, getValue: displayZero },
  auctionDate: { sortable: true, getValue: dateToString('MM/DD/YYYY') },
  yearCreatedFrom: { sortable: true, getValue: dateToString('MM/DD/YYYY') },
  baseSoldPrice: { sortable: true, className: 'price', getValue: toFloat },
  cpiNormalBaseSoldPrice: { sortable: true, className: 'price', getValue: toFloat },
  area: { sortable: true, className: 'price', getValue: toFloat },
  ppsi: { sortable: true, className: 'price', getValue: toFloat },
  mediumsSurfaces: { sortable: true },
  primarySurfaceRank: { className: 'td150', sortable: true, getValue: displayZero },
  mediumsSubstrates: { sortable: true },
  primarySubstrateRank: { className: 'td150', sortable: true, getValue: displayZero },
  methodInfo: { sortable: true },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns };

export default columns;
