import { config as artworkConfig } from 'fields/artwork';

import { defineColumns } from '../components/PageTable/defineColumns';
import { connectAcceptance, createIdLink } from './common';
import { lnk } from '../lib/routes';
import { artistNormalize } from '../lib/serialize';
import PropTypes from 'prop-types';
import React from 'react';
import IndicatorLink from 'components/IndicatorLink';

export const ArtistLink = ({ rowData: { artist }, classes }) => {
  return artist ?
    <IndicatorLink
      to={lnk('artist', { artistId: artist.id })}
      status={artist.wfAcceptance}
      children={artistNormalize(artist)}
    /> : null;
};

ArtistLink.propTypes = {
  rowData: PropTypes.shape({
    artist: PropTypes.any,
  }).isRequired,
};

delete(artworkConfig.artistId);
delete(artworkConfig.artistIdId);

export const config = {
  ...artworkConfig,
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('artwork', 'artworkId'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  artist: {
    getValue: ArtistLink,
    sortable: true,
  },
  abvLastSoldPrice: { ...artworkConfig.abvLastSoldPrice, sortable: true },
  spLast: { ...artworkConfig.spLast, sortable: true },
};


export const columns = defineColumns(
  connectAcceptance(config, []),
);
export const defaultColumns = [
  'defaultImageUrl',
  'category',
  'sourceType',
  'artist',
  'title',
  'medium',
  'yearCreatedFrom',
  'signature',
  'physicalSizeRaw',
];

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
