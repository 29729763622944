import React from 'react';
// import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import injectSheet from 'lib/sheet';
import ValuesList, { sheet } from 'components/ValuesList';
import { createJunkCharacterAction } from 'domain/junkCharacter';
import columns from 'fields/junkCharacter';
import { createFormConfig } from 'lib/helpers';
import { JUNK_CHARACTER_FORM } from 'domain/const';

// import rules from 'components/form/validation';
import { submit } from 'lib/helpers';

const StyledValuesList = injectSheet({
  ...sheet,
  ValuesList: { marginRight: 150, width: 440 },
  value: { width: 300, display: 'flex' },
})(ValuesList);

const formFields = createFormConfig(columns.select(
  'junkSymbol',
  'correctLetter',
));

class JunkCharacterForm extends React.Component {
  render () {
    return (
      <form>
        <StyledValuesList showStars config={formFields} rowData={{}} popupForm />
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: JUNK_CHARACTER_FORM,
    onSubmit: submit(createJunkCharacterAction),
    // validate: ({ images }) => ({ images: rules.required(images) ? { id: 'defaultImageRequired' } : undefined }),
    // initialValues: { literature: [{ name: '' }], exhibitions: [{ name: '' }] },
  }),
)(JunkCharacterForm);
