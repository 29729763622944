import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';
import { createForm } from 'pages/common/Form';

import { selectors, actions } from 'domain/sale';
import { filterParams } from 'filters/sale';

import { dictionaryById } from 'domain/dictionary';
import { tableParams, columns } from 'fields/sale';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, createItemAction, updateItemAction } = actions;

const Table = createTable({
  tableName: 'saleList',
  tableParams,
  updateItemAction,
  allowEdit: () => true,
  connections: {
    items,
    auction: dictionaryById('auctions'),
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export const Form = createForm({
  form: 'addSale',
  action: createItemAction,
  formFields: columns.select('title', 'auction', 'saleNumber'),
});

export default createListPage({
  name: 'Sale',
  pagination: selectors.pagination,
  filterParams,
  Table,
  Form,
});
