import colors from 'theme/Colors';

export default {
  DataTable: {
    color: colors.text,
  },
  trh: {
    borderBottom: `1px solid ${colors.labelLink}`,
    backgroundColor: 'transparent!important',
  },
  tr: {
    display: 'flex',
    '&:nth-of-type(odd)': {
      backgroundColor: colors.bg,
    },
  },
  td: {
    width: 80,
    flex: '0 0 auto',
    display: 'block',
    lineHeight: '48px',
    paddingLeft: 20,
    height: 48,
    boxSizing: 'border-box',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  th: {
    extend: 'td',
    fontWeight: 500,
  },
};
