import { put, select } from 'redux-saga/effects';
import I from 'immutable';

import { checkQuery } from 'lib/helpers';
import { settings } from 'domain/settings';
import { updateNavigation } from 'domain/navigation';

import Api, { callApi } from 'domain/api';

const paramsByModel = {
  art: { name: 'artworkList', filters: require('filters/artwork').filters },
  artist: { name: 'artistList', filters: require('filters/artist').filters },
};

export function* loadNavigation(model, id) {
  try {
    const { name, filters } = paramsByModel[model];
    const queryParams = (yield select(settings)).getIn([name, 'query'], new I.Map()).toJS() || {};
    const query = checkQuery(queryParams, filters);
    const { data: { prev, next } } = yield callApi(Api.getNavigation, { query, model, id });
    yield put(updateNavigation({ prev, next }));
  } catch (e) {}
}
