import table from 'components/PageTable/sheet';

export default {
  ...table,
  tr: {
    ...table.tr,
    height: 94,
  },
  td: {
    ...table.td,
    '&.estimateDiapasonUsd': {
      width: 210,
    },
  },
  th: {
    ...table.th,
    '&.estimateDiapasonUsd': {
      width: 210,
    },
  },
  actions: {
    ...table.actions,
    height: 94,
  },
  trEdit: {
    ...table.trEdit,
    height: 94,
    lineHeight: 94,
  },
  fieldBox: {
    ...table.fieldBox,
  },
  name: {
    width: 300,
  },
  rtvDate: {
    width: 170,
  },
  artistId: {
    width: 300,
  },
  defaultImageUrl: {
    display: 'flex',
    alignItems: 'center',
  },
  subjects: {
    width: 210,
  },
};
