import React from 'react';
import jss, { SheetsManager } from 'jss';
import jssGlobal from 'jss-plugin-global';
import nested from 'jss-plugin-nested';
import camelCase from 'jss-plugin-camel-case';
import expand from 'jss-plugin-expand';
import extend from 'jss-plugin-extend';
import vendorPrefixer from 'jss-plugin-vendor-prefixer';
import defaultUnit from 'jss-plugin-default-unit';

jss.use(
  jssGlobal(),
  extend(),
  nested(),
  camelCase(),
  defaultUnit({
      width: 'px',
      height: 'px',
      maxWidth: 'px',
      maxHeight: 'px',
      fontSize: 'px',
      padding: 'px',
      marginRight: 'px',
      right: 'px',
      top: 'px',
      bottom: 'px',
      left: 'px',
      lineHeight: 'px',
      margin: 'px',
  }),
  expand(),
  vendorPrefixer(),
);

const manager = new SheetsManager();

const injected = (sheet, props) => Component => {
    const styleSheet = jss.createStyleSheet(sheet, props);

    const { classes } = styleSheet;
    const key = {};

    manager.add(key, styleSheet) // index
    manager.manage(key); // sheet

    const Wrapper = ({ ...props }) => {
        return <Component { ...{ ...props, classes } } />;
    };
    return Wrapper;
};
export default injected;
