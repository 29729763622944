import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage } from 'react-intl';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import logo from 'pages/LoginPage/logo.svg';

import { links as r } from 'lib/routes';
import { resetPasswordInProgress } from 'domain/env';
import ForgotForm from './ForgotForm';

class ForgotPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      ForgotPage: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    resetPasswordInProgress: PropTypes.bool,
  };

  static defaultProps = {
    resetPasswordInProgress: false,
  }

  render() {
    const { classes, submit, resetPasswordInProgress } = this.props;
    return (
      <div className={classes.ForgotPage}>
        <div className={classes.container}>
          <img className={classes.logo} src={logo} alt="logo" />
          { resetPasswordInProgress ?
            <h2 className={classes.header}>
              <span className={classes.success}>
                <FormattedMessage id="done"/>
              </span>
              <FormattedMessage id="forgotSuccessText"/>
            </h2> :
            <h2 className={classes.header}>
              <FormattedMessage id="forgotText"/>
            </h2>
          }
          <ForgotForm classes={classes}  />
          <button onClick={() => submit('forgot')} className={classes.button} type="button">
            <FormattedMessage id={resetPasswordInProgress ? 'sendAgain' : 'resetPassword'}/>
          </button>
          <div className={classes.linkHolder}>
            <Link to={r.login} className={classes.link}>
              <FormattedMessage id="returnToLogIn"/>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({ submit, resetPasswordInProgress }),
)(ForgotPage);
