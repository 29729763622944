import style, { root } from 'pages/LoginPage/sheet';

export default {
  ...style,
  ForgotPage: root,
  header: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    margin: [0, 0, 20, 0],
  },
  success: {
    fontWeight: 600,
    marginRight: 5,
  },
  link: {
    ...style.link,
    float: 0,
  },
  linkHolder: {
    textAlign: 'center',
    marginTop: 20,
  },
};
