import { createItemPage } from 'pages/common/ItemPage';

import { selectors, actions } from 'domain/scrapped';

import { columns } from 'fields/scrapped';

const { item } = selectors;

export const editableFields = [];

export default createItemPage({
  name: 'item',
  titleFn: ({ id }) => `Spider Art Item #${id}`,
  connections: {
    item,
    updateArtItemAction: actions.updateArtItemAction,
    updateItemAction: actions.updateItemAction,
  },
  config: {
    basicInfo: [
      columns.select('version','isCorrect','exceptionText','item'),
      [],
      columns.select('id','createdDate','updatedDate'),
    ],
    relations: [
      columns.select('artist', 'art','auctionLot','auction'),
    ],
  },
});
