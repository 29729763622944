import { connectAcceptance, toFloat, toFloatWithUSD10, toFloatWithUSD2Symbols } from './common';

import { defineColumns } from 'components/PageTable/defineColumns';
import { dateToString } from '../lib/serialize';

const displayZero = ({ value }) => value > -1 ? `${value}` : '−';
const valueOrDash = ({ value }) => value || '−';

const config = {
  auctionDate: { sortable: true, getValue: dateToString('MM/DD/YYYY'), title: 'saleDate' },
  yearCreatedFrom: { sortable: true, getValue: valueOrDash },
  baseSoldPrice: { sortable: true, className: 'price', getValue: toFloatWithUSD10 },
  cpiNormalBaseSoldPrice: { sortable: true, className: 'price', getValue: toFloatWithUSD10 },
  area: { sortable: true, className: 'price', getValue: toFloat },
  ppsi: { sortable: true, className: 'price', getValue: toFloatWithUSD2Symbols },
  primarySurfaceRank: { className: 'td150', sortable: true, getValue: displayZero },
  primarySubstrateRank: { className: 'td150', sortable: true, getValue: displayZero },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config).slice(1);

export const tableParams = { columns, defaultColumns };

export default columns;
