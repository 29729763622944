import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import DateFieldInput from 'components/form/fields/DateField';

import Message from 'components/form/message';

import injectSheet from 'lib/sheet';
import colors from 'theme/Colors';
import { DEFAULT_FONTFAMILY } from 'theme/const';

export const sheet = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  },
  element: {
    width: '100%',
    height: 32,
    color: colors.text,
    fontSize: 14,
    fontFamily: DEFAULT_FONTFAMILY,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    paddingLeft: 10,
    boxSizing: 'border-box',
  },
  error: {
    borderColor: '#d0021b!important',
  },
  warning: {
    backgroundColor: colors.warning,
  },
  err: {
    flex: '0 1 100%',
    display: 'flex',
  },
};

class DateField extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      name: PropTypes.string,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    validate: PropTypes.arrayOf(
      PropTypes.func,
    ),
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
      wrapper: PropTypes.string.isRequired,
      element: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    placeholder: PropTypes.string,
    hasWarning: PropTypes.bool,
  }

  render() {
    const { classes, intl: { formatMessage }, input, meta, placeholder, hasWarning, ...props } = this.props;
    return (
      <div className={cx(classes.wrapper)}>
        <DateFieldInput
          className={cx(classes.element, { [classes.error]: meta.touched && meta.error, [classes.warning]: hasWarning } )}
          placeholder={formatMessage({ id: placeholder || `placeholder.${input.name}` })}
          {...props}
          {...{ ...input, onBlur: () => null }}
        />
        <div className={classes.err}>
          { !!meta && meta.error && <Message meta={meta} name={input.name} /> }
        </div>
      </div>
    );
  }
}
export const DateFieldPure = injectIntl(DateField);
const DateFieldStyled = injectSheet(sheet)(DateFieldPure);
export default DateFieldStyled;

export function YearPure(props) {
  return <DateFieldPure
    mask={[/\d/, /\d/, /\d/, /\d/]}
    momentMask="YYYY"
    momentParseMasks={['Y', 'YY', 'YYY', 'YYYY']}
    maxDetail="decade"
    {...props}
  />;
}

export const Year = injectSheet(sheet)(YearPure);
