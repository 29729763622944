import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'redux';
import { DEFAULT_FONTFAMILY } from 'theme/const';
import colors from 'theme/Colors';

import Message from 'components/form/message';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

class _CheckBox extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.bool ]),
    name: PropTypes.string,
    classes: PropTypes.shape({
      CheckBox: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    onChange: () => null,
  };

  onClick = (e) => {
    e.stopPropagation();
  }

  render() {
    const { classes, className, ...props } = this.props;
    return (
      <input
        className={cx(classes.CheckBox, className)}
        checked={!!this.props.value}
        {...props}
        onChange={this.props.onChange}
        onClick={this.onClick}
        type="checkbox"
      />
    );
  }
}

export { sheet, _CheckBox as CheckBox };

const CheckBox = injectSheet(sheet)(_CheckBox);
export default CheckBox;

const fieldSheet = {
  CheckBoxField: { width: '100%', display: 'block', height: 20, lineHeight: '20px' },
  CheckBox: {
    font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    width: 14,
    height: 14,
    color: colors.text,
    fontWeight: 400,
    border: `1px solid ${colors.fieldBorder}`,
    backgroundColor: colors.bg,
    display: 'inline-block',
    boxSizing: 'border-box',
    marginRight: 10,
    '&:disabled': {
      opacity: 0.5,
    },
  },
  label: {
    font: `400 14px/20px ${DEFAULT_FONTFAMILY}`,
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {},
  error: {
    borderColor: `${colors.error}!important`,
  },
  warning: {
    backgroundColor: colors.warning,
  },
};

class _CheckBoxField extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.bool ]),
      name: PropTypes.string,
    }).isRequired,
    type: PropTypes.string,
    validate: PropTypes.arrayOf(
      PropTypes.func,
    ),
    classes: PropTypes.shape({
      error: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    hasWarning: PropTypes.bool,
    messageKey: PropTypes.string,
    label: PropTypes.string,
  }

  static defaultProps = {
    type: 'checkbox',
    autoComplete: 'disabled',
    hasWarning: false,
  }

  onChange = (e) => {
    return this.props.input.onChange(!this.props.input.checked);
  }

  render() {
    const { classes, input, meta = {}, validate, label,
      autoComplete, placeholder, hasWarning, messageKey, ...props } = this.props;
    const className = cx(classes.CheckBox, {
      [classes.error]: meta.touched && (meta.error || meta.submitError),
      [classes.warning]: hasWarning,
    });
    return (
      <div className={classes.CheckBoxField}>
        <label className={classes.label}>
          <CheckBox
            {...input}
            {...props}
            value={!!input.checked}
            onChange={this.onChange}
            className={className}
          />
          <span className={classes.labelText} children={label} />
        </label>
        <Message meta={meta} name={messageKey || input.name} />
      </div>
    );
  }
}

export const CheckBoxField = compose(
  injectSheet(fieldSheet),
)(_CheckBoxField);

