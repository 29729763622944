import colors from 'theme/Colors';

import { indicator } from 'components/IndicatorLink';
const { indicator: indicatorDefault, ...statusColors } = indicator;

export default {
  ValuesList: {
    marginRight: 150,
    width: 460,
    '&.clusterList': {
      marginRight: 0,
      width: 160,
    },
  },
  row: {
    marginBottom: 12,
    display: 'flex',
    lineHeight: '22px',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '&.clusterList': {
      display: 'flex',
      marginBottom: 0,
    },
  },
  label: {
    width: 165,
    flexShrink: 0,
    fontSize: 14,
    fontWeight: 400,
    color: '#000000',
    '&.clusterList': {
      width: 71,
      whiteSpace: 'nowrap',
      fontSize: 13,
      '&.sourceType-CUSTOMER': {
        color: 'red',
      },
      '&.sourceType-NONREF': {
        color: 'blue',
      },
      '&.sourceType-REF': {
        color: 'black',
      },
    },

  },
  value: {
    width: 300,
    color: colors.text,
    display: 'flex',
    wordBreak: 'break-word',
    '&.clusterList': {
      fontSize: 13,
      whiteSpace: 'nowrap',
      width: 'auto',
      fontWeight: 500,
      '&.sourceType-CUSTOMER': {
        color: 'red',
      },
      '&.sourceType-NONREF': {
        color: 'blue',
      },
      '&.sourceType-REF': {
        color: 'black',
      },
    },
  },
  required: {
    '&:after': {
      content: '"*"',
      color: colors.attention,
    },
  },
  link: {
    color: colors.links,
    textDecoration: 'none',
  },
  indicatorLink: {
    color: colors.links,
    position: 'relative',
    paddingLeft: 27,
  },
  indicator: {
    ...indicatorDefault,
    top: 8,
  },
  ...statusColors,
  history: {
    width: 20,
    height: 20,
    border: 0,
    cursor: 'pointer',
    top: 4,
    position: 'relative',
    marginLeft: 15,
    background: {
      size: '20px 20px',
      repeat: 'no-repeat',
      position: 'left center',
    },
    backgroundImage: 'url(' + require('./ic-history.svg') + ')',
    '&:hover': {
      backgroundImage: 'url(' + require('./ic-history-hover.svg') + ')',
    },
  },
};
