import { put, call, fork, select, takeEvery, all, takeLatest, take } from 'redux-saga/effects';
import { initialize, change, submit } from 'redux-form';
import { setStorageItem } from 'lib/storage';
import { delay } from 'sagas/common';

import Api, { callApi } from 'domain/api';
import {
  fetchArtistAction,
  editableFields,
  updateArtistAction,
  artist,
  isEditable,
  removeArtistAction,
  mergeArtistAction,
  getChildrenAction,
  runRtvAction,
  getRtvAction,
  updateAeAbvAction,
  syncWebAbvAction,
} from 'domain/artist';

import { saveForm as createArtwork } from 'pages/ArtworkListPage/saga';
import { createArtworkAction } from 'domain/artworkList';
import { fetchAuthorsAction } from 'domain/author';
import { getEditableData } from 'domain/lib';
import { changeStatusAction, lock, remove, restore, approve } from 'domain/const';
import { addNotification } from 'domain/env';
import { err404Action, hidePopupByName, showPopup } from 'domain/ui';
import { watchLoadHistory } from 'domain/history/saga';
import { loadNavigation } from 'domain/navigation/saga';
import { onRestore } from 'sagas/actions';
import { assignObjectProps } from 'lib/helpers';

function* loadArtistData(artistId) {
  try {
    const { data: payload } = yield callApi(Api.getArtist, { artistId });
    const { mergeWith } = payload;
    if (mergeWith) yield put(fetchAuthorsAction([ mergeWith ]));
    yield put({ type: fetchArtistAction.success, payload });
    if (!mergeWith) {
      const { data } = yield callApi(Api.searchAuthors, { query: { filterBy: `merge_with_id:${artistId}` } });
      yield put({ type: getChildrenAction.success, payload: data.list });
    }
    yield call(checkEditableAndInit);
  } catch (e) {
    console.error(e);
    yield put(err404Action(true));
  }
}

function* removeArtist({ payload: { id: artistId } }) {
  try {
    const { data: payload } = yield callApi(Api.removeArtist, { artistId });
    yield put({ type: removeArtistAction.success, payload });
    yield put(addNotification({ title: 'artistRemoved' }));
  } catch (e) {
    yield put(addNotification({ title: 'artistNotRemoved', type: 'error' }));
  }
}

function* changeStatusAndSubmit({ payload }){
  yield all(Object.keys(payload).map(key => put(change('artistForm', key, payload[key]))));
  yield call(delay, 100);
  yield put(submit('artistForm'));
}

function* hasAcceptedArts( artistId ) {
  const { data } = yield callApi(Api.getArtworksList, {
    query: { filterBy: `(artist_id_id:${artistId})AND(wf_acceptance:ACCEPTED)`, pageSize: 1 },
  });
  return data.list.length > 0;
}

export function* saveForm(artistId, { payload, resolve }) {
  yield call(delay, 500);
  const { wfAcceptance, ...dataToSave } = payload;
  if(dataToSave['carTag'].length>1){
    let tempArray = dataToSave['carTag']?.slice(1);
    dataToSave['carTag'] = dataToSave['carTag'][0];
    dataToSave['secondaryCarTag'] = [...tempArray];
  }else if(dataToSave['carTag'].length==1){
    dataToSave['carTag'] = dataToSave['carTag'][0];
    dataToSave['secondaryCarTag'] = [];
  }else if(dataToSave['carTag'].length === 0){
    dataToSave['carTag'] = null;
    dataToSave['secondaryCarTag'] = [];
  }
  let approved = true;
  try {
    const frmData = new FormData();
    let hasFilesToUpload = false;
    for(const [key, value] of Object.entries(dataToSave)) {
      if (['banner970x120', 'banner720x120', 'banner343x120'].includes(key)) {
        if (value instanceof File) {
          hasFilesToUpload = true;
          frmData.append(key, value);
          delete (dataToSave[key])
        }
        if (typeof value === 'string') delete (dataToSave[key])
      }
    }
    let result;
    const { data } = yield callApi(
      Api.updateArtist,
      { artistId, data: dataToSave },
      );
    result = data;
    if (hasFilesToUpload) {
      const { data: dataWithImages } = yield callApi(
        Api.updateArtist,
        { artistId, data: frmData },
      );
      result = dataWithImages;
    }
    yield put({ type: updateArtistAction.success, payload: result });
        yield call(checkEditableAndInit);
    if (wfAcceptance){
      const { wfAcceptance: wfAcceptancePrevious } = yield select(artist);
      if (wfAcceptancePrevious === 'ACCEPTED' ){
        const needApprove = yield call(hasAcceptedArts, artistId);
        if (needApprove){
          yield put(showPopup({ name: 'APPROVE_ARTIST' }));
          const { type } = yield take([ hidePopupByName.type, approve.type ]);
          if (type === hidePopupByName.type) approved = false;
        }
      }
      try {
        if (!approved) throw assignObjectProps(new Error('User abort'), { response: { data: {} } });
        const { data } = yield callApi(Api.updateArtist, { artistId, data: { wfAcceptance } });
        yield put({ type: updateArtistAction.success, payload: data });
        yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success', translate: false }));
        yield call(checkEditableAndInit);
        setStorageItem('acceptedArtist', JSON.stringify(data));
      } catch (e) {
        yield put({ type: updateArtistAction.success, payload: data });
        yield call(checkEditableAndInit);
        resolve(e);
        const { wf_acceptance: wfAcceptance = '' } = e.response.data;
        yield put(addNotification({
          title: `Changes were successfully saved. But state no updated. ${wfAcceptance}`,
          translate: false,
        }));
      }
    } else {
      setStorageItem('acceptedArtist', JSON.stringify(data));
      resolve();
      yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success', translate: false }));
    }
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error', translate: false }));
  }
}

function* checkEditableAndInit() {
  const data = (yield select(artist)).toJS();
  if (isEditable(data)){
    const formData = getEditableData(editableFields, data);
    yield put(initialize('artistForm', formData));
  }
}

function* mergeArtist(artistId, { payload: { mergeWith } }) {
  try {
    const { data } = yield callApi(Api.updateArtist, { artistId, data: { mergeWith } });
    yield put({ type: mergeArtistAction.success, payload: data });
    yield put(addNotification({ title: 'Artist was successfully merged.', type: 'success' }));
    yield put(hidePopupByName('MERGE_ARTIST'));
  } catch (e) {
    yield put(addNotification({ title: 'Artist was not merged.', type: 'error' }));
  }
}

function* runRtv(artistId){
  try {
    yield callApi(Api.runArtistRtv, { artistId });
    yield put(addNotification({ title: 'ABV calculation started', type: 'success', translate: false }));
  } catch (e) {
    yield put(addNotification({ title: 'Something went wrong. Please try again', type: 'error', translate: false }));
  }
}

function* checkRtv(artistId) {
  try {
    const { data } = yield callApi(Api.getArtistRtv, { artistId });
    yield put({
      type: getRtvAction.success,
      payload: data.status,
    });
    yield call(delay, 30000);
    yield call(checkRtv, artistId);
  } catch (e) {};
}

function* syncAbv(artistId) {
  try {
    yield callApi(Api.runArtistWebAbvSync, { artistId });
    yield put(addNotification({ title: 'Web ABV sync is running', type: 'success', translate: false }));
  } catch (e) {
    yield put(addNotification({ title: 'Something went wrong. Please try again', type: 'error' }));
  }
}

function* updateAbv(artistId) {
  try {
    yield callApi(Api.runArtistAeAbvUpdate, { artistId });
    yield put(addNotification({ title: 'AE ABV update is running', type: 'success', translate: false }));
  } catch (e) {
    yield put(addNotification({ title: 'Something went wrong. Please try again', type: 'error' }));
  }
}

export default function* navigator({ params: { artistId } }) {
  yield call(loadArtistData, artistId);
  yield fork(loadNavigation, 'artist', artistId);
  yield takeEvery(changeStatusAction.type, changeStatusAndSubmit);
  yield takeLatest(updateArtistAction.type, saveForm, artistId);
  yield takeEvery(mergeArtistAction.type, mergeArtist, artistId);
  yield takeEvery(lock.type, checkEditableAndInit);
  yield takeEvery(remove.type, removeArtist);
  yield takeEvery(runRtvAction.type, runRtv, artistId);
  yield takeEvery(restore.type, onRestore, { artistId }, Api.updateArtist, loadArtistData, artistId);
  yield takeEvery(updateAeAbvAction.type, updateAbv, artistId);
  yield takeEvery(syncWebAbvAction.type, syncAbv, artistId);
  yield fork(watchLoadHistory);
  yield fork(checkRtv, artistId);
  yield takeEvery(createArtworkAction.type, createArtwork);
}
