import { fork, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'domain/scrapped';
import { itemNavigator } from 'sagas/common';
import { editableFields } from './index';
import Api, { callApi } from '../../domain/api';

export function* runAction({ payload: id }) {
  try {
    const { data: payload } = yield callApi(Api.updateArtItem, { id });
    yield put({
      type: actions.fetchItemAction.success,
      payload,
    });
  } catch (e) {

  }
}

export default function* navigator(args) {
  yield takeLatest(actions.updateArtItemAction.type, runAction);
  yield fork(itemNavigator, { modelName: 'Scrapped', actions, editableFields }, args);
}
