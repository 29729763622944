import {
  connectAcceptance,
  createIdLink,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import rules from 'components/form/validation';
import RadioSelect from 'components/form/fields/RadioSelect';

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('subject'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  title: {
    sortable: true,
    inlineEdit: true,
    className: 'name',
    fieldProps: { validate: [ rules.required ] },
  },
  level: {
    inlineEdit: true,
    component: RadioSelect,
    fieldProps: { values: ['PRIMARY', 'SECONDARY'] },
  },
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = [
  'title',
  'level',
];

export const tableParams = { columns, defaultColumns };

export default columns;
