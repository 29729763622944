import React from 'react';

import * as actionActionCreators from 'domain/const';
import actions from 'components/PageTable/BulkActions/actions';
import confirm from 'popups/Confirmation';

const confirmPopups = Object.keys(actions)
  .filter(v => v !== 'merge')
  .concat('pushAbv','confirmUpdate')
  .map(key => {
  const Component = confirm(key, actionActionCreators[key]);
  return <Component key={key}/>;
});

export default confirmPopups;
