import React, { useEffect, useState } from 'react';
import Api from 'domain/api';
import { columns } from "fields/customer";
import { List } from 'immutable';
import { connect } from "cpcs-reconnect";
import { compose } from 'redux';
import { token } from 'domain/env';
import { Customer } from "domain/lib";
import styles from "components/PageTable/sheet";
import Table from 'react-immutable-jss-data-table';
import injectSheet from "lib/sheet";
import { getClassNamesFactory, getRowClassNames } from "components/PageTable/helpers";

const tableSheet = {
  ...styles,
  tr: {
    ...styles.tr,
    height: 94,
  },
};

const UserList = ({ id, token, classes }) => {

  const [ list, setList ] = useState(List());

  useEffect(() => {
      if (token) Api.getCustomerList({ token, query: { filterBy: `(enterprise:${id})` } })
        .then(({ data: { list }}) => {
          setList(List(list.map(item => new Customer(item))));
        })
  },[ token, id ])

  return <Table
    classes={classes}
    items={list}
    columns={columns.select(
      'id',
      'picture',
      'firstName',
      'lastName',
      'country',
    )}
    showColumnsTitles
    rowStatusIndicator
    rowStatusBg
    getCellClassNames={getClassNamesFactory('td')}
    getThClassNames={getClassNamesFactory('th')}
    getRowClassNames={getRowClassNames}
  />;
}

export default compose(
  connect({ token }),
  injectSheet(tableSheet),
)(UserList);
