import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _DataTable, { getRowClassNames, getCellClassNames, getValue } from 'react-immutable-jss-data-table';
import I from 'immutable';
import { callApiAction } from 'domain/api';
import Loader from 'components/Loader';

import { DEFAULT_FONTFAMILY } from 'theme/const';
import Charts from 'components/ArtistCARCalculations/Charts';

import injectSheet from 'lib/sheet';
import tableSheet from 'components/PageTable/sheet';

const fetchArtistRtv = callApiAction('getArtistCarData');

const getNumberValue = (maximumFractionDigits = 2, unit = '') => ({ value: v }) => {
  if (v === null || ![v].join('')) return '-';
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: maximumFractionDigits, maximumFractionDigits }).format(v) + unit;
}

const mapColumn = ([key, title, getValue = getNumberValue(2)]) => ({
  name: key,
  title,
  fieldPath: [key],
  className: 'ArtistCARCalculations__td',
  getValue,
});

const columnsLiquidity = [
  ['liquidityLevel', <span>Liquidity<br/>Group</span>, getValue],
  ['liquidityRanking', <span>Liquidity<br/>Ranking</span>, getNumberValue(0)],
  ['liquidityScore', <span>Liquidity<br/>Score</span>, getNumberValue(1)],
  ['carRanking', <span>Car Ranking</span>, getNumberValue(0)],
  ['overallCarRanking', <span>Overall Car<br/>Ranking</span>, getNumberValue(0)],
  ['buyInRate', <span>Buy-in Rate</span>, getNumberValue(1, '%')],
  ['meanMarketAdjustedCar', <span>Mean Market<br />Adjusted Car</span>, getNumberValue(2, '%')],
  ['riskRanking', <span>Risk Ranking</span>, getNumberValue(3)],
].map(mapColumn);

const columnsRepeatSales = [
  ['last5Years', <span>Last 5 Years</span>, getNumberValue(1)],
  ['last10Years', <span>Last 10 Years</span>, getNumberValue(1)],
  ['last20Years', <span>Last 20 Years</span>, getNumberValue(1)],
  ['overallCarRanking', <span>Overall</span>, getNumberValue(0)],
].map(mapColumn);

const columnsMedianMean = [
  ['label', null, getValue],
  ['overall', <span>Overall</span>, getNumberValue(2, '%')],
  ['bottom20', <span>Bottom 20%</span>, getNumberValue(2, '%')],
  ['bottom80', <span>Bottom 80%</span>, getNumberValue(2, '%')],
  ['middle60', <span>Middle 60%</span>, getNumberValue(2, '%')],
  ['top80', <span>Top 80%</span>, getNumberValue(2, '%')],
  ['top20', <span>Top 20%</span>, getNumberValue(2, '%')],
].map(mapColumn);


const DataTable = injectSheet(tableSheet)(_DataTable);

const rowClassName = props => {
  const cn = getRowClassNames(props);
  return [...cn, 'ArtistCARCalculations__row'];
}

const cellClassNames = props => {
  const cn = getCellClassNames(props);
  if (props.columnDef.name === 'label') {
    return [...cn, 'ArtistCARCalculations__td__label'];
  }
  return cn;
}

const rowKeysToLabel = rowData => Object.keys(rowData)
  .reduce((prev, label) => [...prev, { label, ...rowData[label] }], []);

function ArtistCARCalculations({ artistId, classes }) {
  const [loading, setLoading] = useState(true);
  const [liquidity, setLiquidity] = useState(I.List());
  const [repeatSales, setRepeatSales] = useState(I.List());
  const [allData, setAllData] = useState(I.List());
  const [last5Years, setLast5Years] = useState(I.List());
  const [last10Years, setLast10Years] = useState(I.List());
  const [last20Years, setLast20Years] = useState(I.List());
  const [last5And10Years, setLast5And10Years] = useState(I.List());
  const [referencePeriod, setReferencePeriod] = useState(I.List());
  useEffect(() => {
    if (!artistId) return;
    const parseResponse = (resp) => {
      const liquidity = resp.liquidity;
      setLiquidity(I.fromJS(liquidity ? [liquidity] : []));

      const repeatSalesPerYear = resp.repeatSalesPerYear;
      setRepeatSales(I.fromJS(repeatSalesPerYear ? [repeatSalesPerYear] : []));

      let allData = resp.allData;
      allData = allData ? rowKeysToLabel(allData) : [];
      setAllData(I.fromJS(allData));

      let last5Years = resp.last5Years;
      last5Years = last5Years ? rowKeysToLabel(last5Years) : [];
      setLast5Years(I.fromJS(last5Years));

      let last10Years = resp.last10Years;
      last10Years = last10Years ? rowKeysToLabel(last10Years) : [];
      setLast10Years(I.fromJS(last10Years));

      let last20Years = resp.last20Years;
      last20Years = last20Years ? rowKeysToLabel(last20Years) : [];
      setLast20Years(I.fromJS(last20Years));

      let last5And10Years = resp.last5And10Years;
      last5And10Years = last5And10Years ? rowKeysToLabel(last5And10Years) : [];
      setLast5And10Years(I.fromJS(last5And10Years));

      let referencePeriod = resp.referencePeriod;
      referencePeriod = referencePeriod ? rowKeysToLabel(referencePeriod) : [];
      setReferencePeriod(I.fromJS(referencePeriod));
    };
    // if (process.env.NODE_ENV === 'development') {
    //   const resp = require('./mockCarData').default;
    //   parseResponse(resp);
    // }
    fetchArtistRtv({ urlParams: { artistId } })
      .then(({ data: { result: resp } }) => {
          parseResponse(resp);
      })
      .catch((err) => {
        console.warn('catch err', err);
      })
      .finally(() => setLoading(false));
  }, [artistId]);
  let renderedTables = null;
  if (loading) {
    renderedTables = (
      <div className={classes.loading}>
        <Loader />
      </div>
    );
  }
  if (!loading) {
    const tables = [
      [['Liquidity', columnsLiquidity, liquidity], ['Repeat Sales Per Year', columnsRepeatSales, repeatSales]],
      [['All Data', columnsMedianMean, allData], ['Last 5 Years', columnsMedianMean, last5Years]],
      [['Last 10 Years', columnsMedianMean, last10Years], ['Last 20 Years', columnsMedianMean, last20Years]],
      [['Reference Period', columnsMedianMean, referencePeriod], ['5Y/10Y', columnsMedianMean, last5And10Years]],
    ]
    renderedTables = tables.map((tablesRow, index) => (
      <div className={classes.row} key={index}>
        {
          tablesRow.map(([title, columns, tableData]) => (
            <div className={classes.column} key={title}>
              <div
                className={classes.columnTitle}
                children={title}
              />
              <DataTable
                columns={columns}
                items={tableData}
                showColumnsTitles
                className="ArtistCARCalculations__table"
                getRowClassNames={rowClassName}
                getCellClassNames={cellClassNames}
              />
            </div>
          ))
        }
      </div>
    ));
  }
  return (
    <div className={classes.ArtistCARCalculations}>
      {renderedTables}
      {
        !!artistId &&
          <Charts artistId={artistId} />
      }
    </div>
  );
}

ArtistCARCalculations.propTypes = {
  artistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape({
    ArtistCARCalculations: PropTypes.string.isRequired,
    row: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
    columnTitle: PropTypes.string.isRequired,
  }).isRequired,
};

const sheet = {
  ArtistCARCalculations: {},
  loading: {
    position: 'relative',
    height: 120,
  },
  row: {
    display: 'flex',
  },
  column: {
    paddingLeft: 20,
    flex: '1 1 500px',
    '&:first-child': {
      paddingLeft: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  columnTitle: {
    font: `500 14px/20px ${DEFAULT_FONTFAMILY}`,
    padding: [15, 0],
    textTransform: 'uppercase',
  },
};

export default injectSheet(sheet)(ArtistCARCalculations);
