import React from 'react';
import PropTypes from 'prop-types';
// import { isEmpty } from 'lib/helpers';
import Radio from 'components/form/fields/Radio';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const RadioSelect = ({
  input: { onChange, value },
  classes,
  values,
  parseValue = (v) => v,
  parseLabel = (v) => v,
}) => {
  // if (isEmpty(value)) return null;
  return (
    <div className={classes.Outliers}>
      {values.map((v) => (
        <label key={parseValue(v)} className={classes.label}>
          <Radio
            value={parseValue(v)}
            checked={value === parseValue(v)}
            onChange={onChange}
            className={classes.radio}
            modifier="checkbox"
          />
          <span className={classes.labelText} children={parseLabel(v)} />
        </label>
      ))}
    </div>
  );
};

RadioSelect.propTypes = {
  classes: PropTypes.shape({
    Outliers: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radio: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
  }).isRequired,
  parseValue: PropTypes.func,
  parseLabel: PropTypes.func,
  values: PropTypes.array.isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default injectSheet(styles)(RadioSelect);
