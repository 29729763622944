import loader from './loader.gif';
import emptyImage from './no-image.png';
import uploadImage from './upload.svg';

export default {
  SmartImage: {
    width: 80,
    height: 80,
    position: 'relative',
    background: {
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
    },
    '&.clusterListPageImage': {
      width: 160,
      height: 140,
    },
    '&.imageUpload': {
      width: 220,
      height: 72,
    },
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    width: 40,
    height: 40,
    background: {
      position: 'center center',
      size: 'cover',
      repeat: 'no-repeat',
      image: `url(${loader})`,
    },
  },
  noImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: {
      position: 'center center',
      size: 'contain',
      repeat: 'no-repeat',
      image: `url(${emptyImage})`,
    },
    '&.imageUpload': {
      background: {
        position: 'center center',
        size: 'contain',
        repeat: 'no-repeat',
        image: `url(${uploadImage})`,
      },
    },
  },
  image: {
    width: 'auto',
    height: 'auto',
  },
  imageBox: {
    textAlign: 'center',
    maxHeight: 300,
    cursor: 'pointer',
    lineHeight: 0,
  },
};
