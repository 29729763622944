import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { updateArtworkAction } from 'domain/artwork';
import { updateLotAction } from 'domain/lot';
import { submit } from 'lib/helpers';

//import rules from 'components/form/validation';

class Form extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render(){
    const { children } = this.props;
    return (
      <form>
        { children }
      </form>
    );
  }
}

function onSubmitFail() {
  const { change } = arguments[3] || {};
  change('wfAcceptance', '');
}

export default reduxForm({
  form: 'artworkForm',
  onSubmit: submit(updateArtworkAction),
  //validate: ({ images }) => ({ images: rules.required(images) ? { id: 'defaultImageRequired' } : undefined }),
  onSubmitFail,
})(Form);

const LotForm = reduxForm({
  form: 'lotForm',
  onSubmit: submit(updateLotAction),
  onSubmitFail,
})(Form);

export { LotForm };
