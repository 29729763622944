import React from 'react';
import injectSheet from 'lib/sheet';
import sheet from './sheet';
import cx from 'classnames';
import { connect } from 'cpcs-reconnect';
import { firstNotification, removeNotification, clearOutdatedNotifications } from 'domain/env';
import { restoreAction } from 'domain/const';
import { push } from 'connected-react-router';
import { currentTime } from 'lib/helpers';
import { lnk } from 'lib/routes';
import PropTypes from 'prop-types';
import I from 'immutable';
import { FormattedMessage as FM } from 'react-intl';

function Notifications({
 classes,
 notification,
 restoreAction,
 removeNotification,
 clearOutdatedNotifications,
 push,
}) {
  const timer = React.useRef(0);

  React.useEffect(() => {
    clearTimeout(timer.current);
    if (!!notification){
      const currentTimestamp = parseInt(currentTime.format('x'), 10);
      const remainingTime = notification.get('showTime') + notification.get('timestamp') - currentTimestamp;

      if (remainingTime > 0){
        timer.current = setTimeout(() => clearOutdatedNotifications(currentTimestamp + remainingTime), remainingTime);
      } else {
        clearOutdatedNotifications(currentTimestamp);
      }
    }
    return () => {
      clearTimeout(timer.current);
    }
  }, [ notification, clearOutdatedNotifications, timer ]);

  const onCancel = React.useCallback(() => {
    restoreAction(notification.get('restore') || {});
    removeNotification(notification.get('timestamp'));
  }, [ notification, restoreAction, removeNotification ]);

  const onLink = React.useCallback(() => {
    push(lnk(notification.getIn(['link', 'route']), notification.getIn(['link', 'params'])));
    removeNotification(notification.get('timestamp'));
  }, [ notification, push, removeNotification ]);

  if (!notification) return null;

  const { type, title, translate = true, translateValues = {} } = notification;
  return (
    <div className={cx(classes.notify, classes[type])}>
      <span>{ translate ? <FM id={title} values={{ ...notification.toJS(), ...translateValues }} /> : title }</span>
      <div className={classes.controls}>
        { notification.get('restore') && <button
          className={cx(classes.control, classes.restore)}
          onClick={onCancel}
        >UNDO</button> }
        { notification.getIn(['link', 'route']) && <button
          className={classes.control}
          onClick={onLink}
        >{ notification.getIn(['link', 'title']) }</button> }
      </div>
    </div>
  );
}

Notifications.propTypes = {
  classes: PropTypes.shape({
    notify: PropTypes.string.isRequired,
    controls: PropTypes.string.isRequired,
    control: PropTypes.string.isRequired,
  }).isRequired,
  notification: PropTypes.instanceOf(I.Record),
  restoreAction: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  clearOutdatedNotifications: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

export const NotificationsPure = injectSheet(sheet)(Notifications);
export default connect({
  notification: firstNotification,
  removeNotification,
  restoreAction,
  clearOutdatedNotifications,
  push,
})(NotificationsPure);
