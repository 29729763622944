import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import toCamel from 'to-camel-case';
import { Field } from 'redux-form';
import Select from 'components/form/element/Select';
import Input, { PureInput, sheet as inputSheet } from 'components/form/element/Input';

import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import { numberFieldProps } from 'lib/helpers';

import injectSheet from 'lib/sheet';
import { category, edition } from 'domain/artwork';
import sheet from './sheet';

export const editions = [ 'Numbered', 'Edition Size', 'Artist Proof', 'Press Proof', 'Unknown' ];

export const editionsList = I.fromJS(editions.map(title => ({ id: toCamel(title), title })));

export const editionSelect = category => <Field
  name="edition"
  component={Select}
  list={editionsList.filter(v => category === '3D Edition' ? v.get('id') !== 'pressProof' : true)}
  parseValue={(v) => !!v && !!v.get ? v.get('id') : ''}
/>;

const NumberedInput = injectSheet({
  ...inputSheet,
  wrapper: {
    ...inputSheet.wrapper,
    width: 110,
  },
})(PureInput);

const Numbered = ({ classes: { numberedBox, star } }) => <div className={numberedBox}>
  <div>no.<span className={star}>*</span></div>
  <Field {...numberFieldProps} name="numberedNo" component={NumberedInput} />
  <div>of<span className={star}>*</span></div>
  <Field {...numberFieldProps} name="numberedOf" component={NumberedInput} />
</div>;

Numbered.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Edition = ({ category = '', edition = '', classes }) => <div className={classes.Edition}>
  { editionSelect(category) }
  { !!edition && edition !== 'unknown' && <div className={classes.box}>
    { edition === 'numbered' && <Numbered classes={classes} /> }
    { [ 'editionSize', 'artistProof', 'pressProof' ].includes(edition) && <Field
      name={edition}
      component={Input}
      {...numberFieldProps}
    /> }
  </div> }
</div>;

Edition.propTypes = {
  category: PropTypes.string,
  edition: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect({ category, edition }),
  injectSheet(sheet),
)(Edition);
