import art from './artwork';
import artist from './artist';
import auctionLot from './lot';
import auction from './auction';
import customer from './customer';
import alert from './alert';
import purchase from './purchase';
import medium3d from './medium';

export default {
  art,
  artist,
  auctionLot,
  auction,
  customer,
  alert,
  consideration: art,
  purchase,
  medium3d,
};
