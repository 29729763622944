import { Colors, zIndex } from 'theme';

export default {
  container: {
    position: 'fixed',
    right: 0,
    top: 0,
    width: 560,
    height: '100%',
    backgroundColor: Colors.popupBg,
    zIndex: zIndex.POPUP,
    color: Colors.text,
  },
  overlay: {
    display: 'block',
    content: '""',
    position: 'fixed',
    zIndex: zIndex.POPUP - 1,
    backgroundColor: Colors.text,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
  header: {
    height: 120,
    display: 'flex',
    padding: [40, 60, 10, 60],
    position: 'relative',
    alignItems: 'flex-end',
    fontSize: 24,
    fontWeight: 700,
    borderBottom: `2px solid ${Colors.attention}`,
    boxSizing: 'border-box',
  },
  close: {
    position: 'absolute',
    right: 60,
    top: 40,
    width: 22,
    height: 22,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    background: {
      image: `url(${require('./ic_close.svg')})`,
      size: '22px 22px',
      repeat: 'no-repeat',
      position: 'center center',
    },
  },
  content: {
    padding: [30,60],
    overflowY: 'auto',
    height: 'calc(100% - 120px)',
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
};
