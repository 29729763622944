//TODO change URL to LinkName

const aeItems = [
  { title: 'artists', url: '/ae/artists', linkName: 'artistList' },
  { title: 'artworks', url: '/ae/artworks', linkName: 'artworkList' },
  { title: 'auctionLots', url: '/ae/lots', linkName: 'lotList' },
];

const customerDataTeam = [
  { title: 'alerts', url: '/wa/alerts', linkName: 'alertList' },
  { title: 'arts', url: '/wa/artworks', linkName: 'artList' },
  { title: 'track', url: '/wa/considerations', linkName: 'considerationList' },
  { title: 'customers', url: '/wa/users', linkName: 'customerList' },
  { title: 'enterprises', url: '/wa/enterprises', linkName: 'enterpriseList' },
  //{ title: 'salesHistory', url: '/wa/sales-history' },
  { title: 'coupons', url: '/wa/coupons', linkName: 'couponList' },
  { title: 'settings', url: '/wa/settings', linkName: 'settings' },
];

const admin = [
  { title: 'auctionSales', url: '/ae/sales', linkName: 'saleList' },
  { title: 'auctions', url: '/ae/auctions', linkName: 'auctionList' },
  { title: 'subjects', url: '/ae/subjects', linkName: 'subjectList' },
  { title: 'substrates', url: '/ae/substrates', linkName: 'substrateList' },
  { title: 'surfaces', url: '/ae/surfaces', linkName: 'surfaceList' },
  { title: 'medium3d', url: '/ae/mediums', linkName: 'mediumList' },
  { title: 'smctagList', url: '/ae/smc-tag', linkName: 'smcTagList' },
  { title: 'portfolioData', url: '/ae/portfolios', linkName: 'portfolioList' },
];

const spiders = [
  { title: 'runners', url: '/spiders/runners', linkName: 'runnerList' },
  { title: 'sessions', url: '/spiders/sessions', linkName: 'sessionList' },
  { title: 'items', url: '/spiders/items', linkName: 'itemList' },
  { title: 'arts', url: '/spiders/arts', linkName: 'scrappedList' },
  { title: 'junkCharacters', url: '/spiders/junk-characters', linkName: 'junkCharacterList' },
];

const indexes = [
  { title: 'published', url: '/indexes/published', linkName: 'published' },
];

const items = [
  {
    title: 'artexpert',
    url: '/ae',
    children: [ ...aeItems, ...admin ],
  },
  {
    title: 'webapp',
    url: '/wa',
    children: customerDataTeam,
  },
  {
    title: 'spiders',
    url: '/spiders',
    children: spiders,
  },
  {
    title: 'indexes',
    url: '/indexes',
    children: indexes,
  },
];

export default function ({ isSuperuser, acl }) {
  if (isSuperuser) return { isSingle: false, items };
  const grantedItems = items
    .map(v => ({ ...v, children: v.children.filter(v => acl.includes(v.linkName)) }))
    .filter(v => v.children.length > 0)
  const isSingle = grantedItems.length === 1;
  return {
    isSingle,
    items: isSingle ? grantedItems[0].children : grantedItems,
  };
};
