import {
  arrayParser,
  boolFilter,
  commonFilters,
  CountriesAutoComplete,
  createFilterTransfer,
  floatRange,
  intRange,
  source,
  stringFilter,
  yearRange,
} from './common';
import { TagAutocompleteInline } from "../fields/Autocomplete";

export const defaultList = [ 'fullName', 'residences', 'mergeWith' ];

const arrayParserModified = v => '(' + v.join(' OR ') + ')OR(secondary_car_tag_id:(' + v.join(' OR ') + '))';

export const filters = {
  id: {},
  sourceType: { formFieldRenderer: source },
  fullName: stringFilter('artist'),
  namePrefix: stringFilter('artist'),
  firstName: stringFilter('artist'),
  middleName: stringFilter('artist'),
  lastName: stringFilter('artist'),
  nameSuffix: stringFilter('artist'),
  residences: { parser: arrayParser, component: CountriesAutoComplete, fieldProps: { isMulti: true } },
  birthDate: { formFieldRenderer: yearRange('birthDate', 'placeholder.year') },
  deathDate: { formFieldRenderer: yearRange('deathDate', 'placeholder.year') },
  mergeWith: stringFilter('artist'),
  carTag: { parser: arrayParserModified, component: TagAutocompleteInline, fieldProps: { isMulti: true } },
  carValue: { formFieldRenderer: floatRange('carValue') },
  usernameLastUpdate: stringFilter('artist'),
  searchCount: { formFieldRenderer: intRange('searchCount') },
  alertsCount: { formFieldRenderer: intRange('alertsCount') },
  showRtv: { formFieldRenderer: boolFilter('showRtv') },
  ...commonFilters,
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
