import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage as FM } from 'react-intl';
import Api from 'domain/api';

import colors from 'theme/Colors';
import styles from 'components/ImageDrop/sheet';
import { cutName } from 'components/ImageDrop';
import injectSheet from 'lib/sheet';
import { token } from 'domain/env';

import SmartImage from 'components/SmartImage';
import { Loader, styles as loaderStyles } from 'components/Loader';

const SmallLoader = injectSheet({
  ...loaderStyles,
  loader: {
    ...loaderStyles.loader,
    '&:after': {
      content: '" "',
      display: 'block',
      width: '38px',
      height: '38px',
      margin: '1px',
      borderRadius: '50%',
      border: `5px solid ${colors.attention}`,
      borderColor: `${colors.attention} transparent ${colors.attention} transparent`,
      transform: 'scale(0.5)',
    },
  },
})(Loader);

const MB = 1024 * 1024;

let images = 0;
let acceptedFiles = [];
let rejectedFiles = [];

class ImageInlinePure extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    imagesName: PropTypes.string,
    defaultName: PropTypes.string,
    src: PropTypes.string,
    token: PropTypes.string.isRequired,
  };

  state = {
    file: null,
  };

  static defaultProps = {
    imagesName: 'images',
    defaultName: 'defaultArtImage',
  };

  load = (file) => {
    const { token } = this.props;
    let data = new FormData();
    data.append('image_file', file);

    this.setState({ file });

    Api.uploadImage({ data, token })
      .then(({ data }) => this.move(data))
      .catch(() => null);
  };

  move = ({ id }) => {
    const { defaultName, imagesName } = this.props;
    const {
      [defaultName]: {
        input: { onChange: setDefault },
      },
      [imagesName]: {
        input: { value = [], onChange: setImages },
      },
    } = this.props;
    setImages(value.concat(id));
    setDefault(id);
  };

  preLoadImage = (file) => {
    const img = new Image();
    img.onload = ({ target: e }) => {
      if (e.width < 300 || e.height < 300) {
        rejectedFiles.push({ name: cutName(file.name), err: 'dimensionError' });
      } else {
        acceptedFiles.push(file);
      }
      images > 1 ? images-- : this.change(); //NOSONAR
    };
    img.src = file.preview;
  };

  change = () => {
    images = 0;
    acceptedFiles.map(this.load);
    acceptedFiles = [];
    rejectedFiles = [];
  };

  /**
   * @param af - accepted files
   * @param rf - rejected files
   **/
  onChange = (af, rf) => {
    if (rf.length)
      rejectedFiles = rf.map(({ name }) => ({
        name: cutName(name),
        err: 'sizeError',
      }));
    af.length === 0 ? this.change() : af.map(this.preLoadImage);
  };

  render() {
    const { classes, src, defaultName } = this.props;
    const {
      [defaultName]: {
        input: { value: defaultImage },
      },
    } = this.props;
    const { file } = this.state;
    console.log(src, defaultImage, file);
    if (src)
      return (
        <SmartImage
          className={classes.inlineImage}
          container="img"
          src={src}
          params={{ width: 80, height: 80 }}
          fit={{ maxWidth: 80, maxHeight: 80 }}
        />
      );

    if (defaultImage)
      return (
        <SmartImage
          className={classes.inlineImage}
          container="img"
          src={file.preview}
          originalLink
          params={{ width: 80, height: 80 }}
          fit={{ maxWidth: 80, maxHeight: 80 }}
        />
      );

    return (
      <div
        className={cx(classes.ImageDropInline, { [classes.hasLoader]: file })}
      >
        {file ? (
          <SmallLoader />
        ) : (
          <Dropzone
            onDrop={this.onChange}
            maxSize={15 * MB + 1}
            className={classes.dropBoxInline}
            multiple={false}
          >
            <FM id="upload" />
          </Dropzone>
        )}
      </div>
    );
  }
}

const Styled = injectSheet(styles)(ImageInlinePure);

export default connect({ token })(Styled);
