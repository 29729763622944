export default {
  ListPage: {
    padding: [ 20, 0, 0, 0 ],
    height: 'calc(100vh - 100px)',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: [0, 20],
    flexGrow: '0',
  },
  tableContainer: {
    padding: [0, 20, 20, 20],
    height: 110,
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
    minHeight: 34,
  },
};
