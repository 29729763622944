import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import Dropzone from 'react-dropzone';
import { FormattedHTMLMessage as FM } from 'react-intl';
import axios from 'axios';
import colors from 'theme/Colors';
import injectSheet from 'lib/sheet';
import styles from './sheet';
import SmartImage from 'components/SmartImage';
import { connect } from 'cpcs-reconnect';
import { showPopup } from 'domain/ui';
import { token } from 'domain/env';
import { Image as ImageRecord } from 'domain/lib';
import Message from 'components/form/message';
import Api from 'domain/api';
import NoImage from './no-image.svg';

const MB = 1024 * 1024;

let images = 0;
let acceptedFiles = [];
let rejectedFiles = [];
let imagesMap = new Map();

export function cutName(name = '') {
  if (name.length < 24) return name;
  return name.substr(0, 10) + '...' + name.substr(-10);
}

const CancelToken = axios.CancelToken;

class ImageUpload extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    imagesName: PropTypes.string,
    defaultName: PropTypes.string,
    source: PropTypes.any,
    token: PropTypes.string.isRequired,
  };

  static defaultProps = {
    imagesName: 'images',
    defaultName: 'defaultArtImage',
    source: new I.List(),
  };

  state = {
    images: I.List(),
    acceptedFiles,
    rejectedFiles,
    loading: {},
  };

  load = (file, index, replaceId) => {
    const { token } = this.props;
    let data = new FormData();
    const key = file.name;
    data.append('image_file', file);
    const source = CancelToken.source();
    const cancel = () => {
      source.cancel('User abort');
      const {
        loading: { [key]: _, ...loading },
      } = this.state;
      this.setState({ loading });
    };

    this.setState({
      loading: { ...this.state.loading, [key]: { file, cancel } },
    });

    const onUploadProgress = ({ loaded, total }) => {
      if (this.state.loading[key]) {
        const {
          loading: { [key]: current },
        } = this.state;
        const progress = Math.floor((loaded * 100) / total);
        this.setState({
          loading: { ...this.state.loading, [key]: { ...current, progress } },
        });
      }
    };
    Api.uploadImage({
      data,
      token,
      onUploadProgress,
      cancelToken: source.token,
    })
      .then(({ data }) => {
        if (localStorage.getItem('del' + this.props.defaultName) === 'true') {
          localStorage.setItem('del' + this.props.defaultName, undefined);
        }
        localStorage.setItem(this.props.defaultName, data.id);
        imagesMap.set(data.id, data.imageFile);
        this.move(key, data, index === 0, replaceId);
      })
      .catch(() => null);
  };

  move = (key, image, isFirst, replaceId) => {
    const { defaultName, imagesName } = this.props;
    const { id } = image;
    let {
      [defaultName]: {
        input: { onChange: setDefault, value: defaultImageId },
      },
      [imagesName]: {
        input: { onChange: setImages, value: images },
      },
    } = this.props;
    images = images || [];
    defaultImageId = defaultImageId || null;
    const {
      loading: { [key]: _, ...loading },
    } = this.state;
    this.setState(
      {
        images: this.state.images.push(new ImageRecord(image)),
        loading,
      },
      () => {
        setImages([images].concat(image.id));
        setDefault(image.id);
      },
    );
  };

  preLoadImage = (file) => {
    const img = new Image();
    img.onload = ({ target: e }) => {
      // if (e.width < 300 || e.height < 300) {
      //   rejectedFiles.push({ name: cutName(file.name), err: 'dimensionError' });
      //   this.forceUpdate();
      // } else {
      //   acceptedFiles.push(file);
      // }
      acceptedFiles.push(file);
      images > 1 ? images-- : this.change(); //NOSONAR
    };
    img.src = file.preview || file.fileName;
  };

  change = () => {
    images = 0;
    acceptedFiles.map((file, index) => this.load(file, index));
    acceptedFiles = [];
    rejectedFiles = [];
  };

  /**
   * @param af - accepted files
   * @param rf - rejected files
   **/
  onChange = (af, rf) => {
    if (rf.length)
      rejectedFiles = rf.map(({ name }) => ({
        name: cutName(name),
        err: 'sizeError',
      }));
    af.length === 0 ? this.change() : af.map(this.preLoadImage);
  };

  renderLoader = (key, index, list) => {
    const { file, progress, cancel } = this.state.loading[key];
    const { classes } = this.props;
    return (
      <div key={key} className={classes.loader}>
        <img
          className={classes.image}
          src={file.preview || file}
          alt={file.name}
        />
        <div
          className={classes.progressBar}
          style={{
            boxShadow: `inset ${progress * 2}px 0px 0 0px ${colors.links}`,
          }}
        >
          <div style={{ position: 'absolute' }}>
            <FM
              id="uploading"
              values={{ index: index + 1, total: list.length }}
            />
          </div>
        </div>
        <button onClick={cancel} type="button" className={classes.cancel} />
      </div>
    );
  };

  render() {
    const { classes, imagesName, defaultName } = this.props;
    let source =
      this.state.images.size > 0
        ? this.state.images
        : this.props.input.value
        ? this.state.images.concat(this.props.input.value)
        : new I.List();

    let {
      [imagesName]: {
        input: { value: images = [], onChange: changeImage },
        meta,
      },
      [defaultName]: {
        input: { value: defaultImageId, onChange: setDefault },
      },
    } = this.props;
    images = images || [];
    defaultImageId = defaultImageId || null;
    return (
      <div className={classes.ImageDrop}>
        <div className={classes.multiBox}>
          <Dropzone
            accept="image/png,image/jpeg"
            onDrop={this.onChange}
            maxSize={15 * MB + 1}
            multiple={false}
            className={classes.dropBoxSmall}
          >
            <FM id="upload_logo" />
          </Dropzone>
          {source.size > 0 && (
            <div
              className={classes.deleteBtn}
              onClick={() => {
                this.setState({ images: I.List() });
                changeImage('');
                source = [];
                localStorage.setItem('del' + this.props.defaultName, true);
              }}
            >
              Delete
            </div>
          )}
        </div>

        <div className={classes.imageBox}>
          <div className={classes.imageBoxContainer}>
            <SmartImage
              container="img"
              fit={{ maxWidth: 220, maxHeight: 72 }}
              originalLink
              params={{ width: 220, height: 72 }}
              src={
                (source.size > 0 &&
                  (typeof defaultImageId === 'number'
                    ? imagesMap.get(defaultImageId)
                    : defaultImageId)) ||
                NoImage
              }
              modifier="imageUpload"
            />
          </div>
        </div>

        {!!rejectedFiles.length && (
          <div className={classes.error}>
            {rejectedFiles.map(({ name, err }, index) => (
              <div key={`${name}-${index}`}>
                <span className={classes.fileName}>{name}</span> -{' '}
                <FM id={err} />
              </div>
            ))}
          </div>
        )}
        {Object.keys(this.state.loading).map(this.renderLoader)}
        {!Object.keys(this.state.loading).length && <Message meta={meta} />}
      </div>
    );
  }
}

export default injectSheet(styles)(connect({ token, showPopup })(ImageUpload));
