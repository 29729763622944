import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import DataTable, { Row } from 'react-immutable-jss-data-table';
import columnsSelector from 'components/PageTable/columnsSelector';
import bulkActionsConnector from 'components/PageTable/BulkActions';
import { push as pushAction } from 'connected-react-router';
import { reduxForm, submit } from 'redux-form';

import { getClassNamesFactory, getRowClassNames } from './helpers';
import { orderByToObject, orderByToString, submit as submitWithResolve } from 'lib/helpers';
import { getMatch, getQuery } from 'domain/router';
import { lnk } from 'lib/routes';
import { showPopup } from 'domain/ui';
import TBody from './TBody';
import RowEmpty from './RowEmpty';

const onSubmit = (id, updateAction) => (data, dispatch) => submitWithResolve(updateAction)({ id, ...data }, dispatch);

const PureForm = ({ children, className }) => <form className={ className }>{ children }</form>;

PureForm.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
};

const getFormData = (rowData) => (p, { name, getFieldValue }) => {
  if (getFieldValue) return { ...p, ...getFieldValue(rowData) };
  return { ...p, [name]: rowData.toJS()[name] };
};

const RenderRow = (allProps) => {
  const { rowData, edit, tableName, updateAction, columns, ...props } = allProps;
  if (edit === rowData.get('id')) {
    const form = `${ tableName }_${ rowData.get('id') }`;
    const Form = reduxForm({
      form: form,
      onSubmit: onSubmit(rowData.get('id'), updateAction),
    })(PureForm);
    const values = columns
      .filter(({ inlineEdit }) => inlineEdit)
      .reduce(getFormData(rowData), {});
    return <Form initialValues={ values } { ...props } className={ props.classes.rowWrapper }>
      <Row { ...allProps } isEdit/>
    </Form>;
  }

  return <div className={ props.classes.rowWrapper }><Row { ...allProps } /></div>;
};

RenderRow.propTypes = {
  rowData: PropTypes.instanceOf(I.Record).isRequired,
  edit: PropTypes.number,
  tableName: PropTypes.string.isRequired,
  updateAction: PropTypes.func,
  columns: PropTypes.array.isRequired,
  classes: PropTypes.shape({
    rowWrapper: PropTypes.string.isRequired,
  }).isRequired,
}

export default function getPageTable({ tableName, columns, defaultColumns, columnSections, readOnly = false }) {
  class Table extends React.PureComponent {
    static propTypes = {
      toggleRowCheck: PropTypes.func,
      selectedItems: PropTypes.instanceOf(I.List).isRequired,
      classes: PropTypes.shape({
        tableWrapper: PropTypes.string.isRequired,
      }).isRequired,
      // @todo .isRequired
      columns: PropTypes.array,
      push: PropTypes.func.isRequired,
      query: PropTypes.object,
      route: PropTypes.object,
      defaultValue: PropTypes.any,
    };

    state = {
      /**
       * instance of I.List because we need order
       * each item is a I.Map, like I.Map({ field: 'someField', direction: 'ASC' })
       **/
      orderedBy: I.List(),
    }

    componentDidMount = () => {
      const { query: { sortBy } } = this.props;
      this.setState({ orderedBy: I.fromJS(orderByToObject(sortBy)) });
    }

    componentDidUpdate(prevProps) {
      if (this.props.columns.map(({ name }) => name).join('−') !==
        prevProps.columns.map(({ name }) => name).join('−') &&
        !!prevProps.edit
      ) {
        this.props.onEdit(null);
      }
    }

    updateOrderBy = () => {
      const { push, query, route: { name, params } } = this.props;
      const { orderedBy } = this.state;
      const sortBy = orderByToString(orderedBy.toJS()) || undefined;
      push(lnk(name, { ...params, query: { ...query, sortBy } }));
    }

    toggleOrderBy = (field) => {
      const { orderedBy } = this.state;
      const entry = orderedBy.findEntry(v => v.get('field') === field);
      let newOrderedBy;
      if (entry) {
        const [ key, item ] = entry;
        const direction = item.get('direction') === 'ASC' ? 'DESC' : 'ASC';
        newOrderedBy = orderedBy.set(key, item.set('direction', direction));
      } else {
        newOrderedBy = orderedBy.push(I.Map({ field, direction: 'ASC' }));
      }
      this.setState({ orderedBy: newOrderedBy }, this.updateOrderBy);
    }

    removeOrderBy = (field) => {
      const { orderedBy } = this.state;
      const foundKey = orderedBy.findKey(v => v.get('field') === field);
      if (foundKey === void 0) return;
      this.setState({ orderedBy: orderedBy.remove(foundKey) }, this.updateOrderBy);
    }

    render() {
      const { query: { sortBy } } = this.props;
      const orderedBy = I.fromJS(orderByToObject(sortBy));
      return (
        <div className={ this.props.classes.tableWrapper }>
          <DataTable
            columns={ this.props.columns }
            showColumnsTitles
            defaultValue={ this.props.defaultValue || '−' }
            Row={ RenderRow }
            TBody={ TBody }
            RowEmpty={ RowEmpty }
            getCellClassNames={ getClassNamesFactory('td') }
            getThClassNames={ getClassNamesFactory('th') }
            getRowClassNames={ getRowClassNames }
            { ...{
              ...this.props,
              toggleOrderBy: this.toggleOrderBy,
              removeOrderBy: this.removeOrderBy,
              orderedBy,
              tableName,
            } }
          />
        </div>
      );
    }
  }

  return compose(
    connect({
      push: pushAction,
      query: getQuery,
      route: getMatch,
      submit,
      showPopup,
    }),
    bulkActionsConnector({ readOnly }),
    columnsSelector({ columns, tableName, defaultColumns, readOnly, columnSections }),
  )(Table);
}
