import { action, asyncAction } from 'lib/actions';

export const restoreAction = asyncAction('RESTORE');

export const changeStatusAction = action('CHANGE_PARAMS');
export const lock = action('LOCK_EDIT');
export const ignore = action('IGNORE');
export const remove = action('REMOVE');
export const approve = action('APPROVE');
export const restore = action('RESTORE');
export const accept = action('ACCEPT');
export const display = action('DISPLAY');
export const draft = action('DRAFT');
export const update = action('CHANGE_BULK');
export const confirmUpdate = action('CONFIRM_UPDATE');
export const validate = action('VALIDATE');
export const clearSelection = action('CLEAR_SELECTION');
export const loadMore = action('LOAD_MORE');
export const pushAbv = asyncAction('PUSH_ABV');

// review status

export const status = {
  new: 'NEW',
  draft: 'DRAFT',
  valid: 'VALID',
  accepted: 'ACCEPTED',
  ignore: 'IGNORE',
  display: 'DISPLAY',
};

export const paramNameByLocation = {
  artList: 'status',
  considerationList: 'status',
  customerList: 'userStatus',
  portfolioList: 'status',
};

export const tagCarValueMap = {
  LOW: 'lowCarValue',
  MIDDLE: 'carValue',
  HIGH: 'highCarValue',
};

export const REVIEW_STATUS_NEW = 'NEW';
export const REVIEW_STATUS_DRAFT = 'DRAFT';
export const REVIEW_STATUS_VALID = 'VALID';
export const REVIEW_STATUS_ACCEPTED = 'ACCEPTED';
export const REVIEW_STATUS_IGNORED = 'IGNORE';
export const REVIEW_STATUS_DISPLAY = 'DISPLAY';
// tables
export const ARTIST_LIST_TABLE = 'artistList';
export const ARTWORK_LIST_TABLE = 'artworkList';
export const JUNK_CHARACTER_LIST_TABLE = 'junkCharacterList';
export const LOT_LIST_TABLE = 'lotList';

// FORMS
export const JUNK_CHARACTER_FORM = 'JUNK_CHARACTER_FORM';

// popup
export const ADD_JUNK_CHARACTER = 'ADD_JUNK_CHARACTER';


// other
export const SEARCH_ARTWORK_WINDOW = 'SEARCH_ARTWORK_WINDOW';
