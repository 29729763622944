import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';
import Table from 'react-immutable-jss-data-table';
import { FormattedMessage as FM } from 'react-intl';
import Button from 'components/Button';
import  cx from 'classnames';
import connectLoader from 'components/PageWithLoader';
import { dictionaryById } from 'domain/dictionary';
import TBody from 'components/PageTable/TBody';

import { lnk } from 'lib/routes';
import { Link } from 'react-router-dom';

import Paper from 'components/Paper';
import { BlockPure, sheet as blockSheet } from 'components/Block';

import { mergeArtworkAction, artwork } from 'domain/artwork';
import { artworkList, loading } from 'domain/artworkList';
import { authorsById } from 'domain/author';

import columns from 'fields/artwork';

import { tdMarker } from 'components/PageTable/columns';

import { getRowClassNames, getClassNamesFactory } from 'components/PageTable/helpers';

import { showPopup } from 'domain/ui';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

import confirmation from 'popups/Confirmation';

const MergePopup = confirmation('merge', mergeArtworkAction);

const Block = injectSheet({ Block: { ...blockSheet.Block, borderBottom: 'none', padding: [20, 30, 23, 41] } })(BlockPure);

// const fields = ['defaultImageUrl', 'artistId', 'title', 'notes', 'createdDate', 'updatedDate', 'sourceType'];
// Size: size | physicalSizeRaw | imageSizeRaw
const fields = ['defaultImageUrl', 'artistId', 'title', 'yearCreatedFrom', 'medium', 'imageSizeRaw', 'auctionDateLast', 'auctionHouseLast' ];

class SimilarPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.instanceOf(I.Record),
    artworkList: PropTypes.instanceOf(I.List).isRequired,
    artwork: PropTypes.instanceOf(I.Record).isRequired,
    showPopup: PropTypes.func.isRequired,
};

  emptyRow = () => <div className={this.props.classes.rowEmpty}><FM id="noSimilar"/></div>;

  render() {
    const { artworkList, artwork, showPopup, ...props } = this.props;
    const { classes } = this.props;

    const artist = props.artistId.getIn([artwork.artistId, 'fullName']);
    const tdAction = {
      title: 'tdAction',
      renderTh: () => <div />,
      render: ({ rowData: { id, title }, classes }) => <div className={cx(classes.td, classes.action)}>
        <Button
          buttonType="primary"
          onClick={() => showPopup({ name: 'MERGE', id, subject: title })}
        ><FM id="merge"/></Button>
      </div>,
    };

    return (
      <div
        className={classes.SimilarPage}
      >
        { !!artwork.id && <Link className={classes.pageLink} to={lnk('artwork', { artworkId: artwork.id })}>
          <FM id="backTo"/> {artist} "<span className={classes.bold}>{artwork.title}</span>"
        </Link> }
        <div className={classes.pageHeader}>
          <FM id="similarTo"/> {artist} "<span className={classes.bold}>{artwork.title}</span>"
        </div>
        <MergePopup/>
        <Paper className={classes.container}>
          <Block>
            <div className={classes.header}>
              <FM id="currentAO"/>
            </div>
            { !!artwork.id && <Table
              {...props}
              columns={[ tdMarker, ...columns.select(...fields)]}
              items={new I.List().push(artwork)}
              showColumnsTitles
              rowStatusBg
              getRowClassNames={getRowClassNames}
              getCellClassNames={getClassNamesFactory('td')}
              getThClassNames={getClassNamesFactory('th')}
            /> }
          </Block>
          <Block>
            <div className={classes.header}>
              <FM id="similarAOs"/>
            </div>
            <Table
              {...props}
              TBody={TBody}
              RowEmpty={this.emptyRow}
              columns={[ tdMarker, ...columns.select(...fields), tdAction ]}
              items={artworkList}
              showColumnsTitles
              rowStatusBg
              getRowClassNames={getRowClassNames}
              getCellClassNames={getClassNamesFactory('td')}
              getThClassNames={getClassNamesFactory('th')}
            />
          </Block>
        </Paper>
      </div>
    );
  }
}

export default compose(
  connectLoader,
  injectSheet(styles),
  connect({
    artistId: authorsById,
    artworkList,
    artwork,
    showPopup,
    loading,
    auctionHouseLast: dictionaryById('auctions'),
  }),
)(SimilarPage);
