import colors from 'theme/Colors';

import addFieldImg from './images/AddField.svg';
import removeFieldImg from './images/removeField.svg';

const fieldsArrBtn = ({ right, img }) => ({
  position: 'absolute',
  right,
  width: 30,
  height: 30,
  padding: 0,
  background: {
    image: `url("${img}")`,
    size: [20, 20],
    position: 'center',
    repeat: 'no-repeat',
  },
  cursor: 'pointer',
  border: 'none',
  '&:disabled': {
    opacity: 0.5,
  },
});

export default {
  firstBtn: {
    border: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    color: colors.attention,
    cursor: 'pointer',
  },
  item: {
  },
  itemFirst: {
    padding: 0,
  },
  row: {
    margin: [0, 0, 12],
    '&.lastItem': {
      margin: 0,
    },
  },
  addFieldWrapper: {
    position: 'relative',
  },
  addFieldBtn: {
    ...fieldsArrBtn({ right: -40, img: addFieldImg }),
  },
  removeFieldBtn: {
    ...fieldsArrBtn({ right: -80, img: removeFieldImg }),
  },
};
