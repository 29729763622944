import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedHTMLMessage as FM } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { push } from 'connected-react-router';
import I from 'immutable';

import { clearSelection } from 'domain/const';
import { getQuery, getLocationName, getParams } from 'domain/router';
import { settings, updateSettings } from 'domain/settings';
import { lnk } from 'lib/routes';
import { PositionWatcherWrapper } from 'components/PositionWatcher';
import { getActiveFilters, splitQuery, cleanEmpty } from 'lib/helpers';
import { ColumnsPopup as PopupPure, styles as popupSheet } from 'components/PageTable/columnsPopup';
import { PureButton, sheet as btnSheet } from 'components/Button';
import { PureInput } from 'filters/common';

import injectSheet from 'lib/sheet';
import sheet, { WIDTH, MARGIN } from './sheet';

import allFilters from 'filters';
import Wrapper from 'components/OutsideClickDetector';

const ColumnsPopup = injectSheet({
  ...popupSheet,
  ColumnsPopup: {
    ...popupSheet.ColumnsPopup,
    top: 0,
    right: undefined,
  },
})(PopupPure);

const Button = injectSheet({
  ...btnSheet,
  ghost: {
    ...btnSheet.ghost,
    marginLeft: 0,
    marginRight: 10,
  },
})(PureButton);


function onSubmit(data, dispatch, { push, query: prevQuery, linkName, initialize, clearSelection, params = {} }) { //NOSONAR
  const { isDelete, wfAcceptance, pageSize, status, userStatus, sortBy } = prevQuery;
  const query = { pageSize, sortBy, wfAcceptance, isDelete, status, userStatus, ...cleanEmpty(data) };
  clearSelection();
  push(lnk(linkName, { ...params, query }));
  initialize(data);
}

class Filter extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      Filter: PropTypes.string,
    }),
    className: PropTypes.string,
    filters: PropTypes.object.isRequired,
    defaultList: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    params: PropTypes.object,
    columnsTransfer: PropTypes.object.isRequired,
    linkName: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    updateSettings: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    clearSelection: PropTypes.func.isRequired,
    settings: PropTypes.instanceOf(I.Map),
    children: PropTypes.node,
  }

  state = {
    hidden: true,
    displayMore: false,
  }

  initForm = (query, updateFilters = true) => {
    const { initialize, linkName, filters, settings, updateSettings } = this.props;
    const { filterValues } = splitQuery(query);
    const enabledFilters = settings.getIn([ linkName, 'filters' ], new I.List()).toList();
    const active = getActiveFilters(query, filters);
    const addActive = enabledFilters.toJS().concat(active).filter((v, index, arr) => index === arr.indexOf(v));
    if (updateFilters && addActive.length > enabledFilters.size){
      updateSettings({ path: [ linkName, 'filters' ], value: new I.List(addActive) });
    }
    initialize(filterValues);
  }

  componentDidMount = () => {
    this.initForm(this.props.query);
  }

  renderFilter = (name, config) => {
    if (!config) return null;
    const { component = PureInput, fieldProps = {}, formFieldRenderer } = config;
    const { classes, context, filters } = this.props;
    const props = {
      name,
      placeholder: `filter.${context}.placeholder.${name}`,
      component,
      filters: {
        ...allFilters,
        [context === 'consideration' ? 'art' : context]: filters,
      },
      ...fieldProps,
    };

    return (
      <div key={name} className={classes.element} data-filter-name={name}>
        <div className={classes.label}>
          <FM id={`filter.${context}.label.${name}`}/>
        </div>
        <div className={classes.field}>
          { !!formFieldRenderer ? formFieldRenderer : <Field {...props} /> }
        </div>
      </div>
    );
  }

  render(){
    const { hidden, displayMore } = this.state;
    const { classes, filters, query, linkName, push, initialize, settings, updateSettings, pristine,
      defaultList, columnsTransfer, clearSelection, params = {} } = this.props;
    const activeFilters = getActiveFilters(query, filters);
    const activeFiltersCount = activeFilters.length;
    const { commonParams } = splitQuery(query);
    const onReset = () => {
      push(lnk(linkName, { ...params, query: commonParams }));
      initialize({});
    };
    const enabledFilters = settings.getIn([ linkName, 'filters' ], new I.List()).toList();
    const enabledColumns = settings.getIn([ linkName, 'tables', linkName ], new I.List());
    const update = (value, checkBox) => {
      const removedActiveFilters = activeFilters.filter(v => !value.includes(v));
      if (removedActiveFilters.length > 0){
        const keysToRemove = removedActiveFilters
          .concat(removedActiveFilters.map(v => v + 'From'))
          .concat(removedActiveFilters.map(v => v + 'To'))
          .filter(v => Object.keys(query).includes(v));
        clearSelection();
        const newQuery = Object.keys(query)
          .filter(v => !keysToRemove.includes(v))
          .reduce((p, key) => ({ ...p, [key]: query[key] }), {});
        this.initForm(newQuery, false);
        push(lnk(linkName, { ...params, query: newQuery }));
      }
      updateSettings({ path: [ linkName, 'filters' ], value });
      if (checkBox){
        updateSettings({
          path: [ linkName, 'tables', linkName ],
          value: enabledColumns
            .concat(value.reduce((acc, value) => acc.concat(columnsTransfer[value] || value) ,[]))
            // distinct
            .filter((v, index, arr) => index === arr.indexOf(v))
            .filter(v => v !== 'id'),
        });
      }
    };
    return (
      <div className={cx(classes.Filter, { [classes.visible]: !hidden })}>
        <button
          type="button"
          className={cx(classes.switcher, { [classes.active]: !!activeFiltersCount })}
          onClick={() => this.setState({ hidden: !hidden })}
          disabled={!!query.isInvalid}
        >
          <FM id={hidden ? 'fcShow' : 'fcHide'} />
          { !!activeFiltersCount && <span className={classes.badge}>{ activeFiltersCount }</span> }
        </button>
        { this.props.children }
        <form>
          <div className={cx(classes.container, { [classes.hidden]: hidden })}>
            { enabledFilters.map((key) => this.renderFilter(key, filters[key])) }
            <div className={classes.element}>
              <Button buttonType="primary" disabled={pristine} onClick={() => this.props.submit()}>
                <FM id="apply"/>
              </Button>
              <Button buttonType="secondary" disabled={activeFiltersCount === 0 && pristine} onClick={onReset}>
                <FM id="reset"/>
              </Button>
              <div className={classes.buttonPopupWrapper}>
                <button type="button" className={classes.more} onClick={() => this.setState({ displayMore: !displayMore })}>
                  <FM id="more"/>
                </button>
                {
                  displayMore &&
                    <PositionWatcherWrapper classes={classes} width={WIDTH} margin={MARGIN}>
                      <Wrapper onDetect={() => this.setState({ displayMore: false })}>
                        <ColumnsPopup
                          context={this.props.context}
                          title="applyToColumns"
                          selected={enabledFilters}
                          list={Object.keys(filters)}
                          update={update}
                          defaultList={defaultList}
                          close={() => this.setState({ displayMore: false })}
                        />
                      </Wrapper>
                    </PositionWatcherWrapper>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(
  connect({
    settings,
    updateSettings,
    query: getQuery,
    linkName: getLocationName,
    params: getParams,
    push,
    clearSelection,
  }),
  reduxForm({ form: 'filter', onSubmit }),
  injectSheet(sheet),
)(Filter);
