import React from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import './crop-fix.css';
import { getPopupParamsByName, hidePopupByName } from 'domain/ui';
import { addNotification } from 'domain/env';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import injectSheet from 'lib/sheet';
import sheet from './sheet';

import { confirmationSheet } from 'popups/Confirmation';
import { PurePopup } from 'components/Popup';
import { FormattedMessage as FM } from 'react-intl';

import { PureButton, sheet as buttonSheet } from 'components/Button';

const Button = injectSheet({
  ...buttonSheet,
  ghost: {
    ...buttonSheet.ghost,
    width: undefined,

  },
})(PureButton);

const Popup = injectSheet({
  ...confirmationSheet,
  container: {
    ...confirmationSheet.container,
    width: 920,
  },
})(PurePopup);

const imageUrlFromProxy = value => value ? '/amazon/' + (value || '').split('/').pop(): value;

class Cropper extends React.Component {

  static propTypes = {
    src: PropTypes.string,
    classes: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
  }

  state = {
    scaleY: 1,
    scaleX: 1,
    crop: {},
    croppedImageUrl: null,
  }

  onCropChange = crop => {
    this.setState({ crop });
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const { file: croppedImageUrl, blob } = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl, blob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ file: this.fileUrl, blob });

      }, 'image/jpeg');
    });
  }

  onImageLoaded = (image) => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    if (image.naturalHeight < 300 || image.naturalWidth < 300){
      this.props.addNotification({ title: 'cropImpossible', type: 'error' });
      this.props.onClose();
    } else {
      this.imageRef = image;
      this.setState({ scaleY, scaleX, loaded: true });
    }
  };

  static defaultProps = {
    params: {},
  }

  render() {
    const { classes, src, onClose, onApply } = this.props;
    const { crop, croppedImageUrl, blob, scaleY, scaleX } = this.state;
    const imageSrc = imageUrlFromProxy(src);
    return (
      <Popup
        getTitle={() => <FM id={'popup.crop'} />}
        onClose={() => onClose()}
      >
        <div className={classes.container}>
          <div className={classes.box}>
            <ReactCrop
              src={imageSrc}
              crop={crop}
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
              onImageLoaded={this.onImageLoaded}
              minWidth={Math.ceil(300 / scaleX)}
              minHeight={Math.ceil(300 / scaleY)}
            />
          </div>
          <div className={classes.box}>
            { !!croppedImageUrl && <img
              alt="Crop"
              className={classes.preview}
              src={croppedImageUrl}
            /> }
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            type="button"
            buttonType="ghostActive"
            onClick={() => onClose()}
          ><FM id="cancel" /></Button>
          <Button
            type="button"
            buttonType="ghost"
            onClick={() => onApply(blob, croppedImageUrl)}
          ><FM id="save" /></Button>
        </div>
      </Popup>
    );
  }

}

export default compose(
  connect({ params: getPopupParamsByName('CROP'), hidePopupByName, addNotification }),
  injectSheet(sheet),
)(Cropper);
