import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/session';
import { filterParams } from 'filters/session';

import { tableParams } from 'fields/session';

const { list: items, onInlineEdit: edit, loading, pagination } = selectors;
const { inlineEditAction: onEdit, updateItemAction } = actions;

const Table = createTable({
  tableName: 'sessionList',
  tableParams,
  updateItemAction,
  allowEdit: () => false,
  connections: {
    items,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export default createListPage({
  name: 'Session',
  pagination,
  filterParams,
  Table,
  softDelete: false,
});
