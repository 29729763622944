const getDocument = () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      addEventListener: () => null,
      removeEventListener: () => null,
      body: {
        getBoundingClientRect: () => ({ x: 100 }),
      },
    };
  }
  return global.document;
};
export default getDocument;
