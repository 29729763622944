import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import Popup from 'components/NewItemPopup';
import BulkUpdate from 'popups/BulkUpdate';
import confirmPopups from 'components/ConfirmPopups';

import { junkCharacterListPagination } from 'domain/junkCharacter';
import JunkCharacterListTable from 'pages/JunkCharacterListPage/JunkCharacterListTable';
import ListHeader from 'components/ListHeader';
import { getQuery } from 'domain/router';
import { showPopup } from 'domain/ui';
import JunkCharacterForm from 'pages/JunkCharacterListPage/JunkCharacterForm/JunkCharacterForm';
import { ADD_JUNK_CHARACTER, JUNK_CHARACTER_FORM } from 'domain/const';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const Header = connect({
  pagination: junkCharacterListPagination,
  query: getQuery,
  action: () => showPopup({ name: ADD_JUNK_CHARACTER }),
})(ListHeader);

class JunkCharacterListPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.instanceOf(I.Record),
  };

  render() {
    const { classes, pagination } = this.props;
    return (
      <div className={classes.ListPage}>
        {confirmPopups}
        <Popup name="ADD_JUNK_CHARACTER" formName={JUNK_CHARACTER_FORM}>
          <JunkCharacterForm />
        </Popup>
        <BulkUpdate />
        <header className={classes.header}>
          <Header
            title="junkCharacters"
            linkName="junkCharacterList"
            actionTextKey="addJunkCharacter"
            softDelete={false}
          />
        </header>
        <div className={classes.tableContainer}>
          <JunkCharacterListTable pagination={pagination} />
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({
    pagination: junkCharacterListPagination,
  }),
)(JunkCharacterListPage);
