import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';

import { Field, stopSubmit } from 'redux-form';
import Input from 'components/form/element/Input';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import IconLink from 'components/Link';
import rules from 'components/form/validation';

const InputWithLink = (props) => {
  return <IconLink url={props.input.value} absolute target="_blank">
    <Input {...props}/>
  </IconLink>;
};

InputWithLink.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    form: PropTypes.string,
  }),
};

class LotLinks extends React.Component {
  static propTypes = {
    fields: PropTypes.shape({
      map: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
      push: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({}).isRequired,
    stopSubmit: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      LotLinks: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
      itemFirst: PropTypes.string.isRequired,
      row: PropTypes.string.isRequired,
      addFieldWrapper: PropTypes.string.isRequired,
      addFieldBtn: PropTypes.string.isRequired,
      removeFieldBtn: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidUpdate({ fields: prevFields }) {
    const { fields, meta: { form }, stopSubmit } = this.props;
    // @warning this makes redux-form dirty :(
    if (fields.length === 0) fields.push({});
    if ( prevFields.length !== fields.length){
      stopSubmit(form, {});
    }
  }

  componentDidMount() {
    const { fields } = this.props;
    if (fields.length === 0) fields.push({});
  }

  render() {
    const { fields, classes } = this.props;
    return (
      <div className={classes.LotLinks}>
        {
          fields.map((name, key) => (
            <div key={key} className={classes.item}>
              <Field
                name={`${name}.itemUrl`}
                type="text"
                component={InputWithLink}
                placeholder={'placeholder.itemUrl'}
                messageKey={'itemUrl'}
                validate={[ rules.validURL ]}
              />
              <div className={classes.addFieldWrapper}>
                { fields.length > 1 && <button
                  type="button"
                  onClick={() => fields.remove(key)}
                  className={classes.removeFieldBtn}
                /> }
                {
                  (fields.length === (key + 1) && fields.length < 10) &&
                  <button
                    type="button"
                    onClick={() => fields.push({})}
                    className={classes.addFieldBtn}
                  />
                }
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

LotLinks = connect({ stopSubmit })(injectSheet(sheet)(LotLinks));
export default LotLinks;
