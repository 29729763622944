import record, { integer, string, listOf, bool, enhancedType } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';
import { getStorageItem, setStorageItem, setStorageType, LOCAL_STORAGE } from 'lib/storage';

import * as A from './EnvActions';

const Obj = enhancedType({
  typeName: 'obj',
  parse(value) {
    return value;
  },
});

const Link = record('notificationAction', {
  title: string('VIEW'),
  route: string,
  params: Obj(),
});

const Notification = record('notification', {
  title: string,
  type: string('default'),
  showTime: integer(5000),
  timestamp: integer(),
  restore: Obj(),
  link: Link(),
  translate: bool(true),
  translateValues: Obj(),
});

export const User = record('user', {
  id: integer(0),
  isSuperuser: bool(false),
  acl: listOf(string),
  email: string(''),
  firstname: string(''),
  lastname: string(''),
  phone: string(''),
  picture: string,
  defaultGallery: integer,
  resetPasswordInProgress: bool(false),
  resetPasswordTokenIsValid: bool(false),
  username: string(''),
});

export const BreadCrumb = record('BreadCrumb', {
  title: string,
  link: string,
});

const State = record('Env', {
  isAuthorized: bool(false),
  authChecked: bool(false),
  token: '',
  user: User(),
  breadcrumbs: listOf(BreadCrumb),
  notifications: listOf(Notification),
  requestList: listOf(string),
});

export function initialState() {
  const state = State();
  const token = getStorageItem('token');
  const notificationsFromStorage = JSON.parse(getStorageItem('notifications') || '[]');
  return token ? state.apply(
    State.$authChecked.set(false),
    State.$token.set(token),
    State.$notifications.parsed(notificationsFromStorage),
  ) : state;
}

export const env = field('env');
export const isAuthorized = env.then(State.$isAuthorized);
export const authChecked = env.then(State.$authChecked);
export const token = env.then(State.$token);
export const userProfile = env.then(State.$user);
export const requestList = env.then(State.$requestList);
export const resetPasswordInProgress = userProfile.then(User.$resetPasswordInProgress);
export const resetPasswordTokenIsValid = userProfile.then(User.$resetPasswordTokenIsValid);
export const notifications = env.then(State.$notifications);
export const firstNotification = notifications.then((list) => list.get(0));

setStorageType(LOCAL_STORAGE);

const checkForRequest = (state, { type = '' }) => {
  if (typeof type === 'string'){
    const check = type.match(/(.+)\/(REQUEST|SUCCESS|FAILURE)/);
    if (check){
      switch (check[2]) {
        case 'REQUEST':
          return state.update('requestList', list => list.push(check[1]));

        case 'SUCCESS':
        case 'FAILURE':
          return state.update('requestList', list => list.filter(v => v !== check[1]));

        default:
          return state;
      }
    }
  }
  return state;
}

export const reducer = {
  env(state = new State(), action) { //NOSONAR
    let newState = {};
    switch (action.type) {

      case A.addBreadCrumbs.type:
        return state.apply(
          State.$breadcrumbs.parsed(action.payload),
        );

      case A.addNotification.type:
        newState = state.apply(
          State.$notifications.updateBy('unshift', Notification.parse(action.payload)),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.clearOutdatedNotifications.type:
        newState = state.apply(
          State.$notifications.filter(n => n.get('timestamp') + n.get('showTime') > action.payload),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.removeNotification.type:
        newState = state.apply(
          State.$notifications.filter(item => item.get('timestamp') !== parseInt(action.payload, 10)),
        );
        setStorageItem('notifications', JSON.stringify(newState.get('notifications').toJSON()));
        return newState;

      case A.resetPasswordTokenChecked.type:
        return state.apply(
          State.$user.update(
            User.$resetPasswordTokenIsValid.set(true),
          ),
        );

      case A.resetPassword.success:
        return state.apply(
          State.$user.update(
            User.$resetPasswordTokenIsValid.set(false),
          ),
        );

      case A.userProfileAction.success:
        return state.apply(
          State.$authChecked.set(true),
          State.$isAuthorized.set(true),
          State.$user.parsed(action.payload),
        );

      case A.logIn.success:
        setStorageItem('token', action.payload.token);
        return state.apply(
          State.$token.set(action.payload.token),
        );

      case A.forgotPassword.success:
        return state.apply(
          State.$user.update(
            User.$resetPasswordInProgress.set(true),
          ),
        );

      case A.forgotPassword.failure:
        return state.apply(
          State.$user.update(
            User.$resetPasswordInProgress.set(false),
          ),
        );

      case A.checkAuth.success:
        return state.apply(
          State.$token.set(action.payload.token || state.token),
        );

      case A.checkAuth.failure:
        setStorageItem('token', '');
        return state.apply(
          State.$authChecked.set(true),
          State.$isAuthorized.set(false),
          State.$token.set(''),
        );

      case A.logOut.type:
        setStorageItem('token', '');
        return new State({ authChecked: true });

      default:
        return checkForRequest(state, action);
    }
  },
};
