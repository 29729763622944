import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Field } from 'redux-form';
import Input from 'components/form/element/Input';
import { CountryAutoComplete } from 'fields/Autocomplete';
import { rules } from 'components/form/validation';
import { DateField } from 'fields/common.jsx';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const isProvenanceEmpty = (data) => Object.keys(data)
  .filter(v => !['art','id','uuid'].includes(v))
  .map(key => data[key])
  .filter(v => !!v)
  .length === 0;

class Provenances extends React.PureComponent {
  static propTypes = {
    fields: PropTypes.shape({
      map: PropTypes.func.isRequired,
      length: PropTypes.number.isRequired,
      push: PropTypes.func.isRequired,
      remove: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
      Provenances: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
      itemFirst: PropTypes.string.isRequired,
      row: PropTypes.string.isRequired,
      diapasonFields: PropTypes.string.isRequired,
      rangeSeparator: PropTypes.string.isRequired,
      addFieldWrapper: PropTypes.string.isRequired,
      addFieldBtn: PropTypes.string.isRequired,
      removeFieldBtn: PropTypes.string.isRequired,
    }).isRequired,
    fieldProps: PropTypes.shape({
      rowData: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    }).isRequired,
  };

  render() {
    const { fields, classes, fieldProps: { rowData: { id } } } = this.props;
    const values = fields.getAll();
    return (
      <div className={classes.Provenances}>
        {
          fields.map((name, key) => (
            <div key={key} className={cx(classes.item, { [classes.itemFirst]: key === 0 })}>
              <div className={classes.row}>
                <div className={classes.addFieldWrapper}>
                  {
                    (fields.length === (key + 1) && fields.length < 10) &&
                      <button
                        disabled={isProvenanceEmpty(values[key])}
                        type="button"
                        onClick={() => fields.push({ art: id })}
                        className={classes.addFieldBtn}
                      />
                  }
                  { key !== 0 && <button
                    type="button"
                    onClick={() => fields.remove(key)}
                    className={classes.removeFieldBtn}
                  /> }
                </div>
                <Field
                  name={`${name}.owner`}
                  type="text"
                  component={Input}
                  placeholder={'placeholder.provenances.owner'}
                  messageKey={'provenances.owner'}
                  validate={[ rules.provenanceTitle ]}
                />
              </div>
              <div className={cx(classes.row, classes.diapasonFields)}>
                <Field
                  name={`${name}.yearFrom`}
                  type="text"
                  placeholder={'placeholder.provenances.yearFrom'}
                  messageKey={'provenances.yearFrom'}
                  normalize={v => v ? parseInt(v, 10) : v}
                  component={DateField}
                  maxLength="4"
                />
                <Field
                  name={`${name}.yearTo`}
                  type="text"
                  placeholder={'placeholder.provenances.yearTo'}
                  messageKey={'provenances.yearTo'}
                  normalize={v => v ? parseInt(v, 10) : v}
                  component={DateField}
                  maxLength="4"
                  validate={[ rules.provenanceYearTo ]}
                />
              </div>
              <div className={classes.row}>
                <Field
                  name={`${name}.country`}
                  type="text"
                  component={CountryAutoComplete}
                  placeholder={'placeholder.provenances.country'}
                  messageKey={'provenances.country'}
                />
              </div>
              <div className={classes.row}>
                <Field
                  name={`${name}.city`}
                  type="text"
                  component={Input}
                  placeholder={'placeholder.provenances.city'}
                  messageKey={'provenances.city'}
                />
              </div>
              {/* <Field name={`${name}.testIgnore`} /> */}
            </div>
          ))
        }
        {
          fields.length === 0 &&
            <button
              type="button"
              className={classes.firstBtn}
              onClick={() => fields.push({ art: id })}
              children="Add provenance"
            />
        }
      </div>
    );
  }
}

Provenances = injectSheet(sheet)(Provenances);
export default Provenances;
