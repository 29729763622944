import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import Popup from 'components/NewItemPopup';

import { artworkListPagination, artworkListStats } from 'domain/artworkList';
import { showPopup } from 'domain/ui';
import { getQuery } from 'domain/router';
import { authorsById } from 'domain/author';
import ArtworksListTable from './ArtworksListTable';
import ListHeader from 'components/ListHeader';
import Pagination from 'components/pagination';

import injectSheet from 'lib/sheet';
import styles from 'theme/listPageSheet';

import Filter from 'components/Filter';
import { filterParams } from 'filters/artwork';

import Form from './Form';

import confirmPopups from 'components/ConfirmPopups';
import CSVExporter from 'components/Exporter';
import BulkUpdate from 'popups/BulkUpdate';

const ConnectedForm = connect({ artistId: authorsById })(Form);

const Header = connect({
  pagination: artworkListPagination,
  stats: artworkListStats,
  query: getQuery,
  action: () => showPopup({ name: 'ADD_ARTWORK' }),
})(ListHeader);

class ArtworkListPage extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.instanceOf(I.Record),
  };

  render() {
    const { classes, pagination } = this.props;
    return (
      <div className={classes.ListPage}>
        {confirmPopups}
        <BulkUpdate />
        <Popup name="ADD_ARTWORK" formName="artworkForm">
          <ConnectedForm />
        </Popup>
        <div className={classes.header}>
          <Header
            title="artworks"
            linkName="artworkList"
            actionTextKey="addArtwork"
            showInvalid="art"
          />
          <div className={classes.subHeader}>
            <Pagination pagination={pagination} />
          </div>
        </div>
        <Filter context="artwork" {...filterParams}>
          <CSVExporter
            tableName="artworkList"
            filters={filterParams.filters}
            apiKey="getArtworksListCSV"
          />
        </Filter>
        <div className={classes.tableContainer}>
          <ArtworksListTable pagination={pagination} />
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    pagination: artworkListPagination,
  }),
)(ArtworkListPage);
