import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import colors from "theme/Colors";
import { statusColors } from 'theme/Colors';
import injectSheet from 'lib/sheet';

export const indicator = {
  indicator: {
    left: 5,
    top: '50%',
    transform: 'translateY(-7px)',
    width: 11,
    height: 11,
    borderRadius: '50%',
    border: '1px solid #0001',
    position: 'absolute',
  },
  ...statusColors,
};

const sheet = {
  ...indicator,
  IndicatorLink: {
    color: colors.links,
    position: 'relative',
    paddingLeft: 27,
    '&.text': {
      color: 'inherit',
    },
    '&.commonFiltersLabel': {
      paddingLeft: 20,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

function IndicatorLink({ to, classes, children, modifier, status = '' }) {
  const Component = to ? Link : 'div';
  const props = to ? { to } : {};
  return (
    <Component
      className={cx(classes.IndicatorLink, modifier, { text: !to })}
      {...props}
    >
      <div className={cx(classes.indicator, classes[status.toLowerCase()] || classes.draft )} />
      {children}
    </Component>
  );
}

IndicatorLink.propTypes = {
  to: PropTypes.string,
  modifier: PropTypes.string,
  classes: PropTypes.shape({
    indicator: PropTypes.string,
    new: PropTypes.string,
    draft: PropTypes.string,
    valid: PropTypes.string,
    accepted: PropTypes.string,
    ignore: PropTypes.string,
    display: PropTypes.string,
    activeStripe: PropTypes.string,
    trial: PropTypes.string,
    beta: PropTypes.string,
    activeByPayment: PropTypes.string,
    inactive: PropTypes.string,
  }),
  status: PropTypes.oneOf([
    'NEW',
    'DRAFT',
    'VALID',
    'ACCEPTED',
    'IGNORE',
    'DISPLAY',
    'ACTIVESTRIPE',
    'TRIAL',
    'BETA',
    'ACTIVEBYPAYMENT',
    'INACTIVE',
  ]),
};

export default injectSheet(sheet)(IndicatorLink);
