import record, { integer, bool, listOf } from 'cpcs-recordjs';
import { field } from 'cpcs-reconnect';

import { Artwork, Pagination, Stats } from 'domain/lib';
import { action, asyncAction } from 'lib/actions';
import { updateSelectedList } from 'domain/common';

export const fetchArtworksListAction = asyncAction('artwork/GET_LIST');
export const createArtworkAction = asyncAction('artwork/CREATE');
export const updateArtworkInLineAction = asyncAction('artwork/UPDATE_INLINE');
export const checkArtworkAction = action('artwork/CHECK');
export const onEdit = action('artwork/EDIT');
export const fetchLatestRtv = asyncAction('artwork/GET_RTV');

const State = record('Artworks', {
  list: listOf(Artwork),
  pagination: Pagination(),
  stats: Stats(),
  selected: listOf(integer),
  onEdit: integer(),
  loading: bool(true),
});

export const artworks = field('artworkList');
export const artworkList = artworks.then(State.$list);
export const selectedArtworks = artworks.then(State.$selected);
export const artworkListPagination = artworks.then(State.$pagination);
export const artworkListStats = artworks.then(State.$stats);
export const edit = artworks.then(State.$onEdit);
export const loading = artworks.then(State.$loading);
export const removeFromListById = action('artwork/REMOVE_FROM_LIST');

export const reducer = {
  artworkList(state = new State(), { type, payload }) { //NOSONAR
    switch (type) {

      case '@@router/LOCATION_CHANGE':
        return state.apply(
          State.$list.clear(),
          State.$selected.clear(),
          State.$loading.set(true),
          State.$onEdit.set(),
        );

      case onEdit.type:
        return state.apply(
          State.$onEdit.set(payload),
        );

      case fetchLatestRtv.success:
        return state.apply(
          State.$list.update(list => list
            .update(list.findIndex(v => v.id === payload.id), v => v.set('aeRtv', payload.value))),
        );

      case fetchArtworksListAction.request:
        return state.apply(
          State.$loading.set(true),
        );

      case fetchArtworksListAction.success:
        return state.apply(
          State.$list.parsed(payload.list),
          State.$pagination.parsed(payload.pagination),
          State.$stats.parsed(payload.stats),
          State.$loading.set(false),
        );

      case updateArtworkInLineAction.success:
        return state.apply(
          State.$list.update(list => list.set(list.findIndex(v => v.get('id') === payload.id), Artwork.parse(payload))),
        );

      case checkArtworkAction.type:
        return updateSelectedList(state, payload, State);

      case removeFromListById.type:
        return state.apply(
          State.$list.update(list => list.filter(v => v.get('id') !== payload)),
        );

      default:
        return state;
    }
  },
};
