import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';
import { connect } from 'cpcs-reconnect';

import { selectors, actions } from 'domain/webAppArtwork';
import { filterParams } from 'filters/webAppArtwork';

import { dictionaryById } from 'domain/dictionary';
import { tableParams } from 'fields/webAppArtwork';

import styles from 'components/PageTable/sheet';
import { authorsById } from 'domain/author';
import PageHeader from 'components/CustomerListHeader';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const tableSheet = {
  ...styles,
  tr: {
    ...styles.tr,
    height: 94,
  },
};

const Table = createTable({
  tableName: 'artList',
  tableParams,
  updateItemAction,
  sheet: tableSheet,
  connections: {
    items,
    artistId: authorsById,
    category: dictionaryById('categories'),
    subjects: dictionaryById('subjects'),
    currency: dictionaryById('currencies'),
    lastLotCurrency: dictionaryById('currencies'),
    surfaces: dictionaryById('surfaces'),
    substrates: dictionaryById('substrates'),
    surfaceValues: dictionaryById('surfaceValues'),
    substrateValues: dictionaryById('substrateValues'),
    auctionHouseLast: dictionaryById('auctions'),
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
  },
});

export default createListPage({
  name: 'Art',
  pagination,
  filterParams,
  Table,
  PageHeader: connect({ stats: selectors.stats })(PageHeader),
  csvApiKey: isCustomer => isCustomer ? 'getCustomerArtListCSV' : 'getArtListCSV',
});
