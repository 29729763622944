import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import Api from 'domain/api';
import { resetPassword, resetPasswordTokenChecked, addNotification } from 'domain/env';
import { getQuery } from 'domain/router';
import { links as r } from 'lib/routes';

function* tryResetPassword({ payload: { resolve, data: { password } } }) {
  const { uid, token } = yield select(getQuery);
  try {
    yield call(Api.resetPassword, { data: { 'new_password1': password, 'new_password2': password, uid, token } });
    resolve();
    yield put({ type: resetPassword.success });
    yield put(addNotification({ title: 'Password was successfully changed. Try to log in now.', type: 'success' }));
    yield put(push(r.login));
  } catch (err) {
    resolve({ id: 'customMessage', message: err.response.data['new_password2'].join(',') });
  }
}

function* checkToken() {
  const { uid, token } = yield select(getQuery);
  try {
    yield call(Api.resetPassword, { data: { uid, token } });
    return true;
  } catch (err){
    if (err.response.data && err.response.data.token) return false;
    return true;
  }
}

export default function* navigator() {
  const tokenIsValid = yield call(checkToken);
  if (!tokenIsValid) {
    yield put(addNotification({ title: 'Link is invalid or expired, try reset your password again.', type: 'error' }));
    yield put(push(r.login));
  }
  yield put(resetPasswordTokenChecked());
  yield takeLatest(resetPassword.type, tryResetPassword);
}
