import { createFilterTransfer, PureInput } from './common';

export const defaultList = [ 'id', 'spiderName' ];

export const filters = {
  id: {
    component: PureInput,
    parser: v => v,
    fieldProps: { type: 'number', min: 0 },
  },
  spiderName: {},
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
