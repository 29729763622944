import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'lightbox-react';
import I from 'immutable';
import 'lightbox-react/style.css';

import SmartImage from 'components/SmartImage';
import injectSheet from 'lib/sheet';
import styles from 'components/ImageDrop/sheet';

class Gallery extends React.Component {
  static propTypes = {
    images: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.instanceOf(I.Collection),
    ]),
    mainId: PropTypes.number,
    classes: PropTypes.object,
  };

  state = {
    photoIndex: -1,
  };

  renderImage = ({ id, imageFile: src }, photoIndex) => {
    const {
      classes: { box },
    } = this.props;
    return (
      <div
        key={id}
        className={box}
        onClick={() => this.setState({ photoIndex })}
      >
        <SmartImage
          container="img"
          fit={{ maxWidth: 60, maxHeight: 60 }}
          originalLink
          src={src}
          alt={src}
        />
      </div>
    );
  };

  render() {
    const { classes, images = [], mainId } = this.props;
    const { photoIndex } = this.state;
    const photoList = images.map((v) => v.get('imageFile')).toJS();
    const defaultImage = images.find(({ id }) => id === mainId);
    return (
      <div className={classes.ImageDrop}>
        {photoIndex > -1 && (
          <Lightbox
            mainSrc={photoList[photoIndex]}
            nextSrc={photoList[(photoIndex + 1) % photoList.length]}
            prevSrc={
              photoList[(photoIndex + photoList.length - 1) % photoList.length]
            }
            onCloseRequest={() => this.setState({ photoIndex: -1 })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + photoList.length - 1) % photoList.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % photoList.length,
              })
            }
          />
        )}
        {defaultImage && (
          <div className={classes.imageBox}>
            <SmartImage
              container="img"
              fit={{ maxWidth: 300, maxHeight: 300 }}
              originalLink
              params={{ width: 300, height: 300 }}
              src={defaultImage.imageFile}
              onClick={() =>
                this.setState({ photoIndex: images.indexOf(defaultImage) })
              }
            />
          </div>
        )}
        <div className={classes.multiBox}>
          {images.filter(({ id }) => id !== mainId).map(this.renderImage)}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Gallery);
