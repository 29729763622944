import { takeEvery, fork, call, put } from 'redux-saga/effects';
import { delay } from 'sagas/common';
import { loadListData } from 'sagas/common';
import { ADD_JUNK_CHARACTER } from 'domain/const';

import {
  fetchJunkCharacterListAction,
  checkJunkCharacterAction,
  createJunkCharacterAction,
  onEditAction,
  updateJunkCharacterInLineAction,
  junkCharacterList,
} from 'domain/junkCharacter';
import { watchCommonActionsCall } from 'sagas/actions';
import { addNotification } from 'domain/env';
import { hidePopupByName } from 'domain/ui';
import Api, { callApi } from 'domain/api';

// import filters from 'filters/artwork';

export function* onCreate({ payload, resolve }) {
  yield call(delay, 500);
  try {
    const { data } = yield callApi(Api.addJunkCharacter, { data: payload });
    yield put(hidePopupByName(ADD_JUNK_CHARACTER));
    yield put({
      type: createJunkCharacterAction.success,
      payload: data,
    });
    resolve();
    // yield put(push(lnk('artwork', { artworkId })));
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'itemNotCreated', type: 'error' }));
  }
}

function* loadData(resetChecked = true) {
  const params = {
    modelName: 'JunkCharacter',
    actions: { fetchListAction: fetchJunkCharacterListAction, selectAction: checkJunkCharacterAction },
    filters: {},
    selectors: { list: junkCharacterList },
  };
  yield call(loadListData, params, resetChecked );
}

function* onInlineEdit({ payload: { id, ...data }, resolve }) {
  try {
    const { data: payload } = yield callApi(Api.updateJunkCharacter, { junkCharacterId: id, data });
    resolve();
    yield put(onEditAction(null));
    yield put(addNotification({ title: 'Changes were successfully saved.', type: 'success' }));
    yield put({
      type: updateJunkCharacterInLineAction.success,
      payload,
    });
  } catch (e) {
    resolve(e);
    yield put(addNotification({ title: 'Changes were not saved.', type: 'error' }));
  }
}
export default function* navigator() {
  yield fork(loadData, false);
  yield takeEvery(updateJunkCharacterInLineAction.type, onInlineEdit);
  yield takeEvery(createJunkCharacterAction.type, onCreate);
  yield fork(watchCommonActionsCall, 'JunkCharacter');
}
