import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import injectSheet from 'lib/sheet';
import sheet from './sheet';

const Block = ({ classes: { Block }, className = null, children = null }) => {
  return (
    <div className={cx(Block, { [className]: !!className })}>
      { children }
    </div>
  );
};

Block.propTypes = {
  classes: PropTypes.shape({
    Block: PropTypes.string,
  }),
  className: PropTypes.string,
  children: PropTypes.node,
};

export { Block as BlockPure, sheet };
export default injectSheet(sheet)(Block);
