import React from 'react';
import PropTypes from 'prop-types';
import I from 'immutable';
import { connect } from 'cpcs-reconnect';
import cx from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

import { showPopup } from 'domain/ui';
import { getLocationName, getQuery } from 'domain/router';
import injectSheet from 'lib/sheet';
import { sheet } from './sheet.js';
import actions from './actions';

const ActionsList = ({ classes, actions, hide = () => null, single = false }) => {
  return (
    <div className={single ? classes.singleList : classes.list}>
      { Object.keys(actions)
        .map(v => <button
          key={v}
          onMouseDown={() => { hide(); actions[v](); }}
          className={cx(classes.action, classes[v])}
          type="button"
        ><FM id={`bulk.${v}`}/></button>)
      }
    </div>
  );
};

ActionsList.propTypes = {
  classes: PropTypes.shape({
    list: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.object.isRequired,
  hide: PropTypes.func,
  single: PropTypes.bool,
};

class PageTableBulkActions extends React.PureComponent {

  static propTypes = {
    classes: PropTypes.shape({
      PageTableBulkActions: PropTypes.string.isRequired,
      count: PropTypes.string.isRequired,
      cancel: PropTypes.string.isRequired,
      menu: PropTypes.string.isRequired,
    }).isRequired,
    selected: PropTypes.instanceOf(I.List).isRequired,
    items: PropTypes.instanceOf(I.List).isRequired,
    onCancel: PropTypes.func.isRequired,
    showPopup: PropTypes.func.isRequired,
    page: PropTypes.string,
    query: PropTypes.object.isRequired,
  }

  state = {
    term: '',
    listShown: false,
  }

  render(){
    const { classes, items: allItems, selected, onCancel, page, showPopup, query: { isDelete = false }, userProfile } = this.props;
    const items = allItems.filter(({ id }) => selected.includes(id));
    const { term = '', listShown } = this.state;
    const activeActions = Object.keys(actions)
      .filter(key => actions[key].display({ location: page, items, userProfile }))
      .filter(key => isDelete ? key === 'restore' : key !== 'restore');
    const actionsParams = activeActions
      .reduce((acc, key) => ({ ...acc, [key]: () => actions[key].action({ showPopup, items: selected }) }), {});

    return (
      <div className={classes.PageTableBulkActions}>
        <div className={classes.columnSelected}>
          <span className={classes.count}>{items.size}</span>
          { items.size === 1 ? <FM id="itemSelected" /> : <FM id="itemsSelected" /> }
          <button
            onClick={onCancel}
            type="button"
            className={cx(classes.cancel, { [classes.open]: listShown })}
          ><FM id="cancel"/></button>
        </div>
        <div className={classes.columnActions}>
          <input
            onFocus={() => this.setState({ listShown: true })}
            onBlur={() => this.setState({ listShown: false })}
            className={classes.input}
            type="text"
            placeholder="Select action"
            value={term}
            onChange={e => this.setState({ term: e.target.value })}
          />
          <button onMouseDown={() => this.setState({ listShown: !listShown })} type="button" className={classes.menu}/>
          { (!!term || listShown) && <ActionsList classes={classes} actions={actionsParams} />  }
        </div>
      </div>
    );
  }
}

const PageTableBulkActionsThemed = connect({
  showPopup,
  page: getLocationName,
  query: getQuery,
})(injectSheet(sheet)(PageTableBulkActions));

export default ({ readOnly = false }) => Component => {
  class PageTableBulkActions extends React.Component {
    static propTypes = {
      selectedItems: PropTypes.shape({
        size: PropTypes.number,
      }),
      items: PropTypes.instanceOf(I.List),
      toggleRowCheck: PropTypes.func,
      page: PropTypes.string.isRequired,
      showPopup: PropTypes.func.isRequired,
      classes: PropTypes.object,
    };

    static defaultProps = {
      list: new I.List(),
      selectedItems: new I.List(),
    }

    state = {
      singleActionShowForId: null,
    }

    showActions = (singleActionShowForId, e) => {
      const { clientX, clientY } = e;
      const { x, y } = this.container.getBoundingClientRect();
      this.setState({
        singleActionShowForId,
        position: {
          left: clientX - x - 220 + 24,
          top: clientY - y - 24,
        },
      });
    }

    render() {
      if (!!readOnly) return (<Component {...this.props} />);

      const { items, selectedItems, toggleRowCheck, page, showPopup, userProfile } = this.props;
      const { position, singleActionShowForId: id } = this.state;
      const singleItem = items.filter(({ id: itemId }) => itemId === id);
      const activeActions = Object.keys(actions)
        .filter(key => actions[key].display({ location: page, items: singleItem, userProfile }));
      const actionsParams = activeActions
        .reduce((acc, key) => ({ ...acc, [key]: () => actions[key].action({ showPopup, items: new I.List([ id ]) }) }), {});
      return (
        <div
          style={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}
          ref={node => this.container = node}
        >
          { !!selectedItems.size && <PageTableBulkActionsThemed
            onCancel={() => toggleRowCheck('all')}
            selected={selectedItems}
            items={items}
            userProfile={userProfile}
          /> }
          { !!id && <div
            style={{ ...position, position: 'absolute', zIndex: 3 }}
            ref={node => this.actions = node}
            onMouseLeave={() => this.setState({ singleActionShowForId: null })}
          >
            <ActionsList
              classes={this.props.classes}
              actions={actionsParams}
              hide={() => this.setState({ singleActionShowForId: null })}
              single
            />
          </div> }
          <Component {...this.props} showActions={this.showActions} />
        </div>
      );
    }
  };

  return connect({
    showPopup,
    page: getLocationName,
  })(PageTableBulkActions);
};
