import { commonDictionaryFilters, createFilterTransfer, stringFilter } from './common';

export const defaultList = [ 'title', 'auction', 'saleNumber' ];

export const filters = {
  title: stringFilter('AuctionSale'),
  auction: stringFilter('AuctionSale'),
  saleNumber: stringFilter('AuctionSale'),
  ...commonDictionaryFilters,
};

export default filters;

export const filterTransfer = createFilterTransfer(filters, {});
export const filterParams = { filters, filterTransfer, defaultList, columnsTransfer: {} };
