import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage as FM } from 'react-intl';

import { hidePopupByName } from 'domain/ui';
import Popup from 'components/Popup';
import Button from 'components/Button';

import sheet from './sheet';
import injectSheet from 'lib/sheet';

const NewItemPopup = ({
  classes,
  hidePopupByName,
  submit,
  name,
  formName,
  children,
}) => <Popup name={name}>
  <div>
    {children}
    <div className={classes.buttons}>
      <Button
        type="button"
        buttonType="secondary"
        onClick={() => hidePopupByName(name)}
      ><FM id="cancel" /></Button>
      <Button
        type="button"
        buttonType="primary"
        onClick={() => submit(formName)}
      ><FM id="save" /></Button>
    </div>
  </div>
</Popup>;

NewItemPopup.propTypes = {
  classes: PropTypes.shape({
    buttons: PropTypes.string.isRequired,
  }).isRequired,
  hidePopupByName: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default compose(
  injectSheet(sheet),
  connect({ hidePopupByName, submit }),
)(NewItemPopup);
