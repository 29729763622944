import { dict, dateToString } from 'lib/serialize';
import DateField from 'components/form/element/DateField';
import { dateFieldProps } from 'lib/helpers';
import rules from 'components/form/validation';
import { AuctionAutoComplete } from './Autocomplete';
import {
  toNumber,
  toFloat,
  toPriceRange,
} from 'fields/common';

/**
 * @param prefix used for column name translations
**/
export const getCarLotFieldsConfig = ({ fieldPathPrefix = [], sortableFields = [], inlineEditFields = [], prefix = '' }) => ({
  [prefix + 'auctionDate']: {
    fieldPath: [...fieldPathPrefix, 'auctionDate'],
    inlineEdit: inlineEditFields.includes('auctionDate'),
    titleFn: ({ privateSale }) => privateSale ? 'privateSaleDate' : 'auctionDate',
    sortable: sortableFields.includes('auctionDate'),
    required: true,
    getValue: dateToString('MM/DD/YYYY', '−'),
    component: DateField,
    fieldProps: {
      validate: [ rules.required ],
      ...dateFieldProps,
    },
  },
  [prefix + 'auctionId']: {
    fieldPath: [...fieldPathPrefix, 'auctionId'],
    inlineEdit: inlineEditFields.includes('auctionId'),
    sortable: sortableFields.includes('auctionId'),
    required: true,
    component: AuctionAutoComplete,
    getValue: dict('title', { paramName: 'auctionId' }),
    titleFn: ({ privateSale }) => privateSale ? 'seller' : 'auctionId',
  },
  [prefix + 'auctionPriceUsd']: {
    fieldPath: [...fieldPathPrefix, 'auctionPriceUsd'],
    sortable: sortableFields.includes('auctionPriceUsd'),
    getValue: toNumber,
    hiddenForTable: true,
  },
  [prefix + 'auctionInterval']: {
    fieldPath: [...fieldPathPrefix, 'auctionInterval'],
    getValue: toFloat,
  },
  [prefix + 'auctionCar']: {
    fieldPath: [...fieldPathPrefix, 'auctionCar'],
    getValue: toFloat,
  },
  [prefix + 'highEstimateUsdPlusBp']: {
    fieldPath: [...fieldPathPrefix, 'highEstimateUsdPlusBp'],
    getValue: toNumber,
  },
  [prefix + 'medianEstimateUsdPlusBp']: {
    fieldPath: [...fieldPathPrefix, 'medianEstimateUsdPlusBp'],
    getValue: toNumber,
  },
  [prefix + 'lowEstimateUsdPlusBp']: {
    fieldPath: [...fieldPathPrefix, 'lowEstimateUsdPlusBp'],
    getValue: toNumber,
  },
  
  [prefix + 'estimateDiapasonUsd']: {
    hiddenForTable: true,
    className: 'estimateDiapasonUsd',
    // fieldPath: [...fieldPathPrefix, 'highEstimateUsdPlusBp'],
    getValue: toPriceRange(
      [...fieldPathPrefix, 'estimatePriceStartUsd'],
      [...fieldPathPrefix, 'estimatePriceEndUsd'],
      {
        currency: 'USD',
        displayCurrency: false,
      },
    ),
    defaultValue: '−',
  },
});
