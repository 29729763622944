import { action, asyncAction } from 'lib/actions';
import record, { bool, enhancedType, integer, listOf } from 'cpcs-recordjs';
import { Pagination } from 'domain/lib';
import { field } from 'cpcs-reconnect';
import { lock } from 'domain/const';

export const uploadImageAction = asyncAction('image/UPLOAD');

const Obj = enhancedType({
  typeName: 'obj',
  parse(value) {
    return value;
  },
});

export const updateSelectedList = (state, payload, State) => {
  switch (payload) {
    case null:
    case 'all': // Clear
      return state.apply(State.$selected.clear());

    case false: // checkAll
      return state.apply(
        State.$selected.set(state.get('list').map(({ id }) => id)),
      );

    case true: // uncheckAll
      return state.apply(
        State.$selected.filter(
          (v) =>
            !state
              .get('list')
              .map(({ id }) => id)
              .includes(v),
        ),
      );

    default:
      return state.apply(
        State.$selected.update((list) =>
          list.includes(payload)
            ? list.filter((v) => v !== payload)
            : list.concat(payload),
        ),
      );
  }
};

const defaultListReducer = (state) => state;

export const generateItems = (
  name,
  model,
  listReducer = defaultListReducer,
) => {
  const State = record(`${name.toLowerCase()}List`, {
    list: listOf(model),
    pagination: Pagination(),
    selected: listOf(integer),
    inlineEdit: integer(null),
    loading: bool(true),
    stats: Obj({}),
  });

  const actions = {
    inlineEditAction: asyncAction(`${name.toUpperCase()}_LIST/EDIT`),
    fetchListAction: asyncAction(`${name.toUpperCase()}_LIST/FETCH`),
    selectAction: action(`${name.toUpperCase()}_LIST/SELECT`),
    fetchItemAction: asyncAction(`${name.toUpperCase()}/FETCH`),
    createItemAction: asyncAction(`${name.toUpperCase()}/CREATE`),
    updateItemAction: asyncAction(`${name.toUpperCase()}/UPDATE`),
  };

  const singleStore = field(name.toLowerCase());
  const listStore = field(`${name.toLowerCase()}List`);

  const selectors = {
    list: listStore.then(State.$list),
    selected: listStore.then(State.$selected),
    pagination: listStore.then(State.$pagination),
    onInlineEdit: listStore.then(State.$inlineEdit),
    loading: listStore.then(State.$loading),
    item: singleStore,
    stats: listStore.then(State.$stats),
  };

  const {
    fetchListAction,
    inlineEditAction,
    selectAction,
    fetchItemAction,
    updateItemAction,
  } = actions;

  const list = (state = new State(), { type, payload }) => {
    //NOSONAR
    switch (type) {
      case '@@router/LOCATION_CHANGE':
        return new State().set('pagination', state.get('pagination'));

      case inlineEditAction.type:
        return state.apply(State.$inlineEdit.set(payload));

      case fetchListAction.request:
        return state.apply(State.$loading.set(true));

      case fetchListAction.success:
        return state.apply(
          State.$list.parsed(payload.list),
          State.$pagination.parsed(payload.pagination),
          State.$stats.parsed(payload.stats || null),
          State.$loading.set(false),
        );

      case inlineEditAction.success:
        return state.apply(
          State.$list.update((list) =>
            list.set(
              list.findIndex((v) => v.get('id') === payload.id),
              model.parse(payload),
            ),
          ),
        );

      case selectAction.type:
        return updateSelectedList(state, payload, State);

      default:
        return listReducer(state, { type, payload });
    }
  };

  const item = (state = new model(), { type, payload }) => {
    switch (type) {
      case updateItemAction.success:
      case fetchItemAction.success:
        return new model.parse(payload);

      case '@@router/LOCATION_CHANGE':
        return new model();

      case lock.type:
        return payload.subject === name
          ? state.update('locked', (v) => !v)
          : state;

      default:
        return state;
    }
  };

  const reducer = {
    [name.toLowerCase()]: item,
    [`${name.toLowerCase()}List`]: list,
  };

  return { actions, selectors, reducer };
};
