import { field, selector } from 'cpcs-reconnect';

import { Artwork } from 'domain/lib';
import { action, asyncAction } from 'lib/actions';
import { lock } from 'domain/const';
import { getFormValues } from 'redux-form';
import { dictionaryById } from 'domain/dictionary';

export { Artwork };

export const acceptanceFields = [
  'defaultArtImage',
  'artistId',
  'title',
  'category',
  'medium',
  'physicalSizeRaw',
  'edition',
  'imageSizeRaw',
];

export const isEditable = ({ sourceType, wfAcceptance, isDeleted, locked }) => {
  return !(sourceType === 'CUSTOMER' || isDeleted || (locked && wfAcceptance === 'ACCEPTED'));
};

export const fetchArtworkAction = asyncAction('artwork/GET_BY_ID');
export const applyArtworkAction = asyncAction('artwork/APPLY_BY_ID');
export const updateArtworkAction = asyncAction('artwork/UPDATE');
export const updateClusterAction = asyncAction('artwork/UPDATE_CLUSTER');
export const mergeArtworkAction = asyncAction('artwork/MERGE');
export const removeArtworkAction = asyncAction('artwork/REMOVE');
export const artworkSelectedAction = action('artwork/SELECTED');
export const addLotAction = asyncAction('artwork/ADD_LOT');
export const enableOutliersAction = action('artwork/OUTLIERS_ENABLE');
export const disableOutliersAction = action('artwork/OUTLIERS_DISABLE');
export const pushUpdateAction = asyncAction('artwork/PUSH_UPDATE');

export const artwork = field('artwork');

export const category = selector(
  dictionaryById('categories'),
  (state) => getFormValues('artworkForm')(state),
  artwork,
  (dict, formData = {}, data = {}) => {
    const category = formData.category || data.category;
    return !!dict && !!category ? dict.getIn([ category, 'title' ], '2D') : '2D';
  },
);

export const edition = selector(
  (state) => getFormValues('artworkForm')(state),
  artwork,
  (formData = {}, data = {}) => {
    const edition = formData.edition || data.edition;
    return edition || null;
  },
);

export const hasOutliersSelector = artwork.then(v => !!v.outliers);
export const isEditableSelector = artwork.then(isEditable);

export const reducer = {
  artwork(state = new Artwork(), action) { //NOSONAR
    switch (action.type) {
      case '@@router/LOCATION_CHANGE':
        return new Artwork();

      case mergeArtworkAction.success:
      case removeArtworkAction.success:
      case updateArtworkAction.success:
      case fetchArtworkAction.success:
        return Artwork.parse(action.payload);

      case updateClusterAction.type:
      case applyArtworkAction.success:
        return Artwork.parse({
          ...state.toJS(),
          ...action.payload,
        });

      case lock.type:
        return action.payload.subject === 'Artwork' ? state.set('locked', action.payload.value) : state;

      default:
        return state;
    }
  },
};
