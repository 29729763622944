import React from 'react';
import {
  connectAcceptance,
  createIdLink,
  commonDictionaryFields,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

const Item = ({ value }) => {
  try {
    const data = JSON.parse(value);
    return <div>
      <pre>{ JSON.stringify(data, null, 2) }</pre>
    </div>;
  } catch (e) {
    return null;
  }
};

const config = {
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('scrapped'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  item: {
    hiddenForTable: true,
    getValue: Item,
  },
  art: {
    className: 'td200',
    getValue: createIdLink('artwork', 'artworkId', ({ title }) => title),
  },
  artist: {
    className: 'td200',
    getValue: createIdLink('artist', 'artistId', ({ fullName }) => fullName),
  },
  auction: { getValue: ({ value }) => value ? value.get('name') : null },
  auctionLot: {
    getValue: ({ value }) => value ? value.get('lotName') : null,
  },
  exceptionText: { hiddenForTable: true },
  isCorrect: { getValue: ({ value }) => value ? 'Yes' : 'No' },
  ...commonDictionaryFields,
  spiderItem: {
    className: 'id',
    getValue: createIdLink('item'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  version: {},
};

export const columns = defineColumns(
  connectAcceptance(config, []),
);

export const defaultColumns = Object.keys(config)
  .filter(v => !['id','exceptionText','item'].includes(v));

export const tableParams = { columns, defaultColumns, readOnly: true };

export default columns;
