import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import injectSheet from 'lib/sheet';

import rules from 'components/form/validation';
import { logIn, userProfileAction } from 'domain/env';
import Password from 'components/form/element/Password';
import { PureInput, sheet } from 'components/form/element/Input';
import { useSubmit } from "lib/hooks";
import { getPrevLocation } from "domain/router";
import Api from "domain/api";
import { getAcl } from "lib/helpers";
import { push } from "connected-react-router";

const Input = injectSheet({
  ...sheet,
  element: { ...sheet.element, height: 46, lineHeight: '46px' },
  wrapper: { marginBottom: 20 },
})(PureInput);

const errorsMapper = () => ({ password: { id: 'wrongCredentials' } })

const fields = [
  {
    component: Input,
    name: 'username',
    autoComplete: 'email',
    validate: rules.required,
    autoFocus: true,
  },
  {
    component: Password,
    name: 'password',
    autoComplete: 'new-password',
    validate: rules.required,
  },
];

const LoginForm = ({ children, dispatch, prevLocation }) => {

  const callBack = useCallback(async ({ token }) => {
    dispatch({ type: logIn.success, payload: { token } });
    const { data } = await Api.getUserData({ token });
    dispatch({ type: userProfileAction.success, payload: { ...data, acl: getAcl(data) } });
    dispatch(push(prevLocation ? prevLocation.pathname + prevLocation.search : '/'));
  }, [dispatch, prevLocation]);

  const { onSubmit } = useSubmit({ key: 'login', callBack, errorsMapper })

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          { fields.map(props => <Field key={props.name} {...props} />) }
          { typeof children === 'function' ? children(submitting) : children }
        </form>
      )}
    />
  )
}

export default injectIntl(
  connect(state => ({ prevLocation: getPrevLocation(state) }))(LoginForm)
);
