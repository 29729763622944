import {
  connectAcceptance,
  createIdLink,
  commonDictionaryFields,
} from './common';

import { defineColumns } from 'components/PageTable/defineColumns';

import { config as baseConfig } from './substrate';

const config = {
  ...baseConfig,
  id: {
    sortable: true,
    className: 'id',
    getValue: createIdLink('surface'),
    parser: ({ value }) => value === undefined ? '' : value,
  },
  ...commonDictionaryFields,
};

export const columns = defineColumns(
  connectAcceptance(config, ['description', 'order','rank']),
);

export const defaultColumns = ['description', 'order','rank'];

export const tableParams = { columns, defaultColumns };

export default columns;
