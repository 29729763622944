import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';
import I from 'immutable';

import Popup from 'components/NewItemPopup';

import { showPopup } from 'domain/ui';
import { getQuery } from 'domain/router';
import { userProfile } from 'domain/env';
import confirmPopups from 'components/ConfirmPopups';
import ListHeader from 'components/ListHeader';
import Pagination from 'components/pagination';

import injectSheet from 'lib/sheet';
import styles from 'theme/listPageSheet';

import Filter from 'components/Filter';
import Exporter from "../../components/Exporter";

export const createListPage = ({
  name,
  pagination,
  filterParams,
  sheet = styles,
  Table,
  Form,
  softDelete = false,
  PageHeader = ListHeader,
  csvApiKey: csvKeyFn,
}) => {
  const Header = connect({
    pagination,
    userProfile,
    query: getQuery,
    action: () => showPopup({ name: `ADD_${name.toUpperCase()}` }),
  })(PageHeader);

  class ListPage extends React.PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      pagination: PropTypes.instanceOf(I.Record),
      userProfile: PropTypes.object,
      stats: PropTypes.object,
    };

    render() {
      const { classes, pagination, match: { params: { userId }}, userProfile } = this.props;
      const csvApiKey = typeof csvKeyFn === 'function' ? csvKeyFn(!!userId) : csvKeyFn;
      const tableName = name.toLowerCase() + 'List';
      return (
        <div className={classes.ListPage}>
          { confirmPopups }
          { !!Form && <Popup name={`ADD_${name.toUpperCase()}`} formName={`add${name}`}>
            { !!Form && <Form/> }
          </Popup> }
          <div className={classes.header}>
            <Header
              title={`${name.toLowerCase()}List`}
              linkName={`${name.toLowerCase()}List`}
              actionTextKey={!!Form ? `add${name}` : null}
              softDelete={softDelete}
            />
            <div className={classes.subHeader}>
              <Pagination pagination={pagination} />
            </div>
          </div>
          { filterParams && <Filter context={name.toLowerCase()} {...filterParams}>
            { !!csvApiKey && <Exporter tableName={tableName}  filters={filterParams.filters} apiKey={csvApiKey} /> }
          </Filter> }
          <div className={classes.tableContainer}>
            <Table filterTransfer={filterParams?.filterTransfer || {}} userProfile={userProfile} />
          </div>
        </div>
      );
    }
  }

  return compose(
    injectSheet(sheet),
    connect({ pagination, userProfile }),
  )(ListPage);
};
