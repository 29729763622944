import { createListPage } from 'pages/common/ListPage';
import { createTable } from 'pages/common/ListTable';

import { selectors, actions } from 'domain/purchase';
import { filterParams } from 'filters/purchase';

import { tableParams } from 'fields/purchase';

import styles from 'components/PageTable/sheet';
import PageHeader from 'components/CustomerListHeader';
import { connect } from 'cpcs-reconnect';
import { token, addNotification } from 'domain/env';

const { list: items, onInlineEdit: edit, selected: selectedItems, loading, pagination } = selectors;
const { selectAction, inlineEditAction: onEdit, updateItemAction } = actions;

const tableSheet = {
  ...styles,
  tr: {
    ...styles.tr,
    height: 94,
  },
};

const Table = createTable({
  tableName: 'purchaseList',
  tableParams,
  updateItemAction,
  sheet: tableSheet,
  connections: {
    addNotification,
    items,
    toggleRowCheck: selectAction,
    toggleAllSelected: selectAction,
    selectedItems,
    edit,
    onEdit,
    loading,
    pagination,
    token,
  },
});

export default createListPage({
  name: 'Purchase',
  pagination,
  filterParams,
  Table,
  PageHeader: connect({ stats: selectors.stats })(PageHeader),
  csvApiKey: 'getCustomerPurchasesListCSV'
});
