import React from "react";
import PublishedSections from "./PublishedSections";

const Sections = {
  X001: {
    tables: ["x001", "x001.10", "x001.5", "x001.1"],
    publish: "addPublishX001",
    calculate: "addCalculateX001",
    generateQuarterly: "addGenerateQuarterly",
  },
  X002: {
    tables: ["x002"],
    publish: "addPublishX002",
    calculate: "addCalculateX002",
  },
  X003: {
    tables: ["x003", "x003.10", "x003.5", "x003.1"],
    publish: "addPublishX003",
    calculate: "addCalculateX003",
    generateSemiannual: "addGenerateSemiannual",
  },
  X004: {
    tables: ["All Artists", "All Artists.10", "All Artists.5", "All Artists.1",
             "Highly Liquid Artists", "Highly Liquid Artists.10", "Highly Liquid Artists.5", "Highly Liquid Artists.1",
             "Liquid Artists", "Liquid Artists.10", "Liquid Artists.5", "Liquid Artists.1",
             "Active Artists", "Active Artists.10", "Active Artists.5", "Active Artists.1",
             "Frequent Artists", "Frequent Artists.10", "Frequent Artists.5", "Frequent Artists.1",
             "Infrequent Artists", "Infrequent Artists.10", "Infrequent Artists.5", "Infrequent Artists.1",
             "Illiquid Artists", "Illiquid Artists.10", "Illiquid Artists.5", "Illiquid Artists.1",
           ],
    publish: "addPublishX004",
    calculate: "addCalculateX004",
  },
};

function PublishedPage() {
  return (
    <div>
      <h2
        style={{ background: "#032437", color: "white", marginTop: "-0.65rem" }}
      >
        ARTDAI INDEXES
      </h2>
      {Object.keys(Sections).map((section, _idx) => (
        <PublishedSections
          key={_idx}
          sectionName={section}
          data={Sections[section]}
        />
      ))}
    </div>
  );
}

export default PublishedPage;
