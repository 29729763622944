export default {
  message: {
    fontSize: 12,
    marginTop: 3,
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '12px',
  },
  warning: {
    color: '#d0021b',
  },
  error: {
    color: '#d0021b',
  },
};
