import React from 'react';
import Table from 'react-immutable-jss-data-table';
import PropTypes from 'prop-types';

import sheet from 'components/PageTable/sheet';
import injectSheet from 'lib/sheet';
import { columns } from 'fields/scrapped';

import { getClassNamesFactory, getRowClassNames } from '../../components/PageTable/helpers';

const ArtItems = ({ items, classes }) => <Table
  classes={classes}
  columns={columns.select('id', 'artist', 'art','auctionLot','auction','createdDate','updatedDate','version','isCorrect')}
  items={items}
  showColumnsTitles
  getCellClassNames={getClassNamesFactory('td')}
  getThClassNames={getClassNamesFactory('th')}
  getRowClassNames={getRowClassNames}
/>;

ArtItems.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.any,
};

export default injectSheet({
  ...sheet,
  DataTable: {
    overflow: 'auto',
    flex: '1 1 100%',
  },
})(ArtItems);
