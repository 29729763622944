import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import {
  artistList,
  selectedArtists,
  checkArtistAction,
  edit,
  onEdit,
  updateArtistInLineAction,
  loading,
} from 'domain/artistList';
import { dictionaryById } from 'domain/dictionary';
import { authorsById } from 'domain/author';
import getPageTable from 'components/PageTable';
import { tableParams } from 'fields/artist';
import { ARTIST_LIST_TABLE } from 'domain/const';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { getQuery } from 'domain/router';
import { filterTransfer } from 'filters/artist';

const PageTable = getPageTable({ tableName: ARTIST_LIST_TABLE, ...tableParams });

class artistListTable extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object,
  };

  render() {
    const { query, ...props } = this.props;
    return (
      <PageTable
        allowEdit={({ sourceType }) => sourceType !== 'CUSTOMER'}
        rowStatusBg={(query.wfAcceptance || []).length !== 1}
        updateAction={updateArtistInLineAction}
        {...props}
        {...{ filterTransfer }}
      />
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    items: artistList,
    residences: dictionaryById('countries'),
    mergeWith: authorsById,
    query: getQuery,
    toggleRowCheck: checkArtistAction,
    selectedItems: selectedArtists,
    edit,
    onEdit,
    loading,
  }),
)(artistListTable);
