import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import injectSheet from 'lib/sheet';
import { filterSelectSheet } from './sheet';
import { injectIntl } from 'react-intl';

import Select from './index';

class MenuList extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      selectedItemsContainer: PropTypes.string.isRequired,
      selectedItem: PropTypes.string.isRequired,
      removeItem: PropTypes.string.isRequired,
    }).isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    children: PropTypes.node,
    selectProps: PropTypes.shape({
      getOptionLabel: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    const { classes, getValue, setValue, children, selectProps: { getOptionLabel } } = this.props;
    const value = getValue();
    return (
      <Fragment>
        {
          (value.length > 0) &&
            <div className={classes.selectedItemsContainer}>
              {
                value.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    className={classes.selectedItem}
                    onClick={() => setValue(value.filter(v => v !== item))}
                  >
                    { getOptionLabel(item) }
                    <i className={classes.removeItem} />
                  </button>
                ))
              }
            </div>
        }
        <components.MenuList {...this.props}>
          {children}
        </components.MenuList>
      </Fragment>
    );
  }
}
MenuList = injectSheet(filterSelectSheet)(MenuList);

class SelectedItemsAll extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      SelectedItemsAll: PropTypes.string.isRequired,
    }).isRequired,
    selectProps: PropTypes.shape({
      inputValue: PropTypes.any,
      menuIsOpen: PropTypes.bool,
      name: PropTypes.string,
    }).isRequired,
    getValue: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    const { getValue, selectProps: { inputValue, menuIsOpen, name, getOptionLabel }, classes,
      intl: { formatMessage },
    } = this.props;
    const value = getValue();
    if (value.length === 0 || inputValue || menuIsOpen) return null;
    const className = { className: classes.SelectedItemsAll };
    if (value.length === 1) return <div children={getOptionLabel(value[0])} {...className} />;
    const message = formatMessage({ id: `selected.items.${name}`, defaultMessage: '{amount} items selected' }, { amount: value.length });
    if ((value.length > 1)) return (
      <div
        children={message}
        {...className}
      />
    );
  }
}
SelectedItemsAll = injectIntl(SelectedItemsAll);
SelectedItemsAll = injectSheet(filterSelectSheet)(SelectedItemsAll);

class ValueContainer extends React.PureComponent {
  static propTypes = {
    getValue: PropTypes.func.isRequired,
    selectProps: PropTypes.object,
  };

  render() {
    return (
      <Fragment>
        <SelectedItemsAll getValue={this.props.getValue} selectProps={this.props.selectProps} />
        <components.ValueContainer {...this.props} />
      </Fragment>
    );
  }
}

export default class FiltersSelect extends React.PureComponent {
  render() {
    return <Select
      MenuList={MenuList}
      MultiValueContainer={() => null}
      ValueContainer={ValueContainer}
      hideSelectedOptions
      {...this.props}
    />;
  }
}
