import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';
import { compose } from 'redux';

import { filterTransfer } from 'filters/lot';

import {
  lotList,
  selectedLots,
  checkLotAction,
  edit,
  onEdit,
  updateLotInLineAction,
  loading,
} from 'domain/lotList';
import { dictionaryById } from 'domain/dictionary';
import { authorsById } from 'domain/author';
import getPageTable from 'components/PageTable';
import { tableParams } from 'fields/lot';
import { LOT_LIST_TABLE } from 'domain/const';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';
import { getQuery } from 'domain/router';

const PageTable = getPageTable({ tableName: LOT_LIST_TABLE, ...tableParams });

class LotsListTable extends React.PureComponent {
  static propTypes = {
    query: PropTypes.object,
  };

  render() {
    const { query, ...props } = this.props;
    return (
      <PageTable
        allowEdit={() => true}
        rowStatusBg={(query.wfAcceptance || []).length !== 1}
        updateAction={updateLotInLineAction}
        {...props}
        {...{ filterTransfer }}
      />
    );
  }
}

export default compose(
  injectSheet(styles),
  connect({
    items: lotList,
    category: dictionaryById('categories'),
    auctionId: dictionaryById('auctions'),
    condition: dictionaryById('conditions'),
    soldPriceUnit: dictionaryById('currencies'),
    currency: dictionaryById('currencies'),
    mergeWith: authorsById,
    query: getQuery,
    toggleRowCheck: checkLotAction,
    selectedItems: selectedLots,
    edit,
    onEdit,
    loading,
  }),
)(LotsListTable);
