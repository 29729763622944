import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'cpcs-reconnect';

import { getPricesAction } from 'domain/lotList';
import injectSheet from 'lib/sheet';
import styles from './sheet.js';

const NewPrice = ({ value, lotId, title, getPricesAction, classes: { newPrice } }) => !!value ? <button
  type="button"
  onClick={() => getPricesAction({ lotId, title })}
  className={newPrice}
>Yes</button> : '−';

NewPrice.propTypes = {
  value: PropTypes.bool.isRequired,
  lotId: PropTypes.number,
  title: PropTypes.string,
  getPricesAction: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    newPrice: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect({ getPricesAction })(injectSheet(styles)(NewPrice));
