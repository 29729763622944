import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'cpcs-reconnect';
import { FormattedMessage } from 'react-intl';

import injectSheet from 'lib/sheet';
import sheet from './sheet';
import logo from 'pages/LoginPage/logo.svg';
import Loader from 'components/Loader';

import { links as r } from 'lib/routes';
import ResetPasswordForm from './ResetPasswordForm';
import { resetPasswordTokenIsValid } from 'domain/env';

class ResetPasswordPage extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      ResetPasswordPage: PropTypes.string,
      container: PropTypes.string,
      logo: PropTypes.string,
      link: PropTypes.string,
      button: PropTypes.string,
    }).isRequired,
    submit: PropTypes.func.isRequired,
    resetPasswordTokenIsValid: PropTypes.bool,
  };

  static defaultProps = {
    resetPasswordTokenIsValid: false,
  }

  render() {
    const { classes, submit, resetPasswordTokenIsValid } = this.props;
    if (!resetPasswordTokenIsValid) return <Loader/>;
    return (
      <div className={classes.ResetPasswordPage}>
        <div className={classes.container}>
          <img className={classes.logo} src={logo} alt="logo" />
          <h2 className={classes.header}>
            <FormattedMessage id="enterNewPass"/>
            <br/>
            <FormattedMessage id="passwordHelper"/>
          </h2>
          <ResetPasswordForm classes={classes}  />
          <button onClick={() => submit('reset')} className={classes.button} type="button">
            <FormattedMessage id="saveChanges"/>
          </button>
          <div className={classes.linkHolder}>
            <Link to={r.login} className={classes.link}>
              <FormattedMessage id="returnToLogIn"/>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  injectSheet(sheet),
  connect({ submit, resetPasswordTokenIsValid }),
)(ResetPasswordPage);
