import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import injectSheet from 'lib/sheet';
import styles from './sheet';
import { compose } from 'redux';
import { DraggableComponent, DroppableComponent } from 'components/dnd/decorators/components';

export function draggable(type, source, force = false) {
  return (WrappedComponent) => {
    if (process.env.NODE_ENV === 'test' && !force) return WrappedComponent;

    return compose(
      DragSource(type, source, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
      })),
      injectSheet(styles),
    )(DraggableComponent(WrappedComponent));
  };
}

draggable.PropTypes = {
  source: PropTypes.object.isRequired,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  force: PropTypes.bool,
};

export function droppable(type, target, force = false) {
  return (WrappedComponent) => {
    if (process.env.NODE_ENV === 'test' && !force) return WrappedComponent;

    return compose(
      DropTarget(type, target, (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      })),
      injectSheet(styles),
    )(DroppableComponent(WrappedComponent));
  };
}

droppable.PropTypes = {
  source: PropTypes.object.isRequired,
  type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  force: PropTypes.bool,
};
